enum ActionTypes {
    SAVE_TASKS = "SAVE_TASKS",
    LOADING_TASKS = "LOADING_TASKS",
    LOADED_TASKS = "LOADED_TASKS",
    REMOVE_TASK = "REMOVE_TASK",
    LOGOUT_ACTION = "LOGOUT_ACTION",
    SAVE_TEMPLATES = "SAVE_TEMPLATES",
    LOADED_TEMPLATES = "LOADED_TEMPLATES",
    LOADING_TEMPLATES = "LOADING_TEMPLATES",
}

export default ActionTypes;
