import { formatUrl } from "../../../helpers/misc";
import {
    ICompanyInfo,
    IContactAttachment,
    IContactFileNote,
    ICustomerInfo,
    IIndividualInfo,
    ITrustInfo,
} from "../../../models/Apps/Migration/XPlanTypes";
import { QueryResultCallback } from "../../../models/Callback/Callback";
import { APIManager } from "../../APIManager";
import { XPlanMigratorNetworkHelper } from "./NetworkHelper";

export class XPlanMigratorManager {
    networkHelper = XPlanMigratorNetworkHelper;
    async apiFetchCustomerInfo(customerId: string, cb: QueryResultCallback<ICustomerInfo>) {
        try {
            const resp = await APIManager.get(APIManager.APP_XPLAN.CUSTOMER_ITEM_API(customerId));
            cb(null, resp.data);
            return resp.data;
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiResetMigration(customerId: string, cb: QueryResultCallback<ICustomerInfo>) {
        try {
            const resp = await APIManager.get(APIManager.APP_XPLAN.CUSTOMER_RESET_API(customerId));
            cb(null, resp.data);
            return resp.data;
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiStartMigration(info: any, cb: QueryResultCallback<ICustomerInfo>) {
        try {
            const resp = await APIManager.post(APIManager.APP_XPLAN.CUSTOMERS_API, info);
            cb(null, resp.data);
            return resp.data;
        } catch (err) {
            cb(`${err}`);
        }
    }

    // async apiTestCredentials(
    //     creds: { iressURI: string; username: string; password: string },
    //     cb: QueryResultCallback<{ isCredentialsCorrect: boolean }>,
    // ) {
    //     try {
    //         const resp = await APIManager.post(APIManager.APP_XPLAN.CUSTOMER_TEST_CREDS_API, creds);
    //         cb(null, resp.data);
    //         return resp.data;
    //     } catch (err) {
    //         cb(`${err}`);
    //     }
    // }

    async apiFetchIndividuals(customerId: string, group: string | null, cb: QueryResultCallback<IIndividualInfo[]>) {
        try {
            const resp = await APIManager.get(formatUrl(APIManager.APP_XPLAN.INDIVIDUALS_API(customerId), { group }));
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiFetchCompanies(customerId: string, group: string | null, cb: QueryResultCallback<ICompanyInfo[]>) {
        try {
            const resp = await APIManager.get(formatUrl(APIManager.APP_XPLAN.COMPANIES_API(customerId), { group }));
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiFetchTrusts(customerId: string, group: string | null, cb: QueryResultCallback<ITrustInfo[]>) {
        try {
            const resp = await APIManager.get(formatUrl(APIManager.APP_XPLAN.TRUSTS_API(customerId), { group }));
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiFetchContactFileNotes(customerId: string, contactId: string, cb: QueryResultCallback<IContactFileNote[]>) {
        try {
            const resp = await APIManager.get(APIManager.APP_XPLAN.CONTACT_FILE_NOTES_API(customerId, contactId));
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiFetchContactFileNoteAttachments(
        customerId: string,
        contactId: string,
        fileNoteId: string,
        cb: QueryResultCallback<IContactAttachment[]>,
    ) {
        try {
            const resp = await APIManager.get(APIManager.APP_XPLAN.CONTACT_FILE_NOTE_ATTACHMENTS_API(customerId, contactId, fileNoteId));
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiDeleteIndividual(customerId: string, indId: string, cb: QueryResultCallback<IIndividualInfo>) {
        try {
            const resp = await APIManager.delete(APIManager.APP_XPLAN.INDIVIDUAL_ITEM_API(customerId, indId));
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiDeleteCompany(customerId: string, compId: string, cb: QueryResultCallback<ICompanyInfo>) {
        try {
            const resp = await APIManager.delete(APIManager.APP_XPLAN.COMPANY_ITEM_API(customerId, compId));
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiDeleteTrust(customerId: string, trustId: string, cb: QueryResultCallback<ITrustInfo>) {
        try {
            const resp = await APIManager.delete(APIManager.APP_XPLAN.TRUST_ITEM_API(customerId, trustId));
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiFinalizeMigration(customerId: string, refMappings: any, cb: QueryResultCallback<ITrustInfo>) {
        try {
            const resp = await APIManager.post(APIManager.APP_XPLAN.COMPLETE_MIGRATION_API(customerId), refMappings);
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }
}
