import { Company } from "./Company/Company";
import { Individual } from "./Individual/Individual";
import { Trust } from "./Trust/Trust";
import { TContact, TContactAttrs } from "./Types";

export class ContactUtils {
    static getDisplayName(contact: TContactAttrs | TContact) {
        if (contact instanceof Company || contact instanceof Individual || contact instanceof Trust) {
            return contact.displayName;
        } else {
            return contact.contactType === "Individual" ? `${contact.firstName} ${contact.lastName}` : `${contact.name}`;
        }
    }
}
