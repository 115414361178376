import React from "react";
import Modal from "antd/lib/modal";
import Toast from "antd/lib/notification";
import Message from "antd/lib/message";
import BellIcon from "@ant-design/icons/BellFilled";
import Loading from "../components/Loading/Loading";
import WarningIcon from "@ant-design/icons/WarningFilled";
import CheckIcon from "@ant-design/icons/CheckOutlined";

export default class AlertManager {
    static success(title: React.ReactNode, message: React.ReactNode, onOk?: () => void) {
        return Modal.success({ content: message, title, onOk });
    }

    static info(title: React.ReactNode, message: React.ReactNode, onOk?: () => void) {
        return Modal.info({ content: message, title, onOk });
    }

    static warning(title: React.ReactNode, message: React.ReactNode, onOk?: () => void) {
        return Modal.warning({ content: message, title, onOk });
    }

    static error(title: React.ReactNode, message: React.ReactNode, onOk?: () => void) {
        return Modal.error({ content: message, title, onOk });
    }

    static loadingModal(message: string) {
        const modal = Modal.info({
            icon: null,
            width: 350,
            centered: true,
            okButtonProps: { style: { display: "none" } },
            content: (
                <div style={{ height: 250, position: "relative" }}>
                    <Loading text={message} />
                </div>
            ),
        });
        return modal;
    }

    static toastSuccess(message: React.ReactNode) {
        return Toast.success({ message });
    }

    static toastInfo(message: React.ReactNode) {
        return Toast.info({ message });
    }

    static toastWarning(message: React.ReactNode) {
        return Toast.warning({ message });
    }

    static toastError(message: React.ReactNode) {
        return Toast.error({ message });
    }

    static msgSuccess(content: React.ReactNode) {
        return Message.success({ content });
    }

    static msgInfo(content: React.ReactNode) {
        return Message.info({ content });
    }

    static msgWarning(content: React.ReactNode) {
        return Message.warning({ content });
    }

    static msgError(content: React.ReactNode) {
        return Message.error({ content });
    }

    static confirmAction(
        title: React.ReactNode,
        message: React.ReactNode,
        cb: (res: "OK" | "CANCELLED") => void,
        btnConfigs?: { okText?: string; cancelText?: string },
    ) {
        return Modal.confirm({
            content: message,
            title,
            onOk: () => cb("OK"),
            onCancel: () => cb("CANCELLED"),
            okText: btnConfigs?.okText,
            cancelText: btnConfigs?.cancelText,
        });
    }

    static notify(
        title: React.ReactNode,
        message: React.ReactNode,
        type: "default" | "error" | "success" = "default",
        position?: "bottomLeft" | "bottomRight" | "topLeft" | "topRight",
        duration = 10,
    ) {
        return Toast.info({
            message: title,
            description: message,
            duration,
            icon: (
                <div
                    style={{
                        fontSize: 20,
                        width: 30,
                        height: 30,
                        background: "rgba(0,0,0,0.15)",
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <BellIcon style={{ color: type == "error" ? "red" : type === "success" ? "green" : undefined }} />
                </div>
            ),
            placement: position || "bottomLeft",
        });
    }
}
