import { User } from "../../../models/User/User";
import createReducer from "../../utils/createReducer";
import ActionTypes from "./actionTypes";

export type UserStateT = {
    user?: User;
    loadingUser: boolean;
    userLoaded: boolean;
};

export const initialState: UserStateT = {
    loadingUser: false,
    userLoaded: false,
    user: undefined,
};

// App Reducer
export default createReducer<UserStateT>(initialState, {
    [ActionTypes.SAVE_USER]: (state, payload: User) => ({
        ...state,
        user: payload,
        userLoaded: true,
    }),
    [ActionTypes.LOADING_USER]: (state, payload: boolean) => ({
        ...state,
        loadingUser: payload,
    }),

    [ActionTypes.LOGOUT_ACTION]: (state) => ({
        ...state,
        ...initialState,
    }),
});
