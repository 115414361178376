import React, { useState } from "react";
import { Button, Card } from "antd";
import Styles from "./UserInvitesSetup.scss";
import { InviteUsersView } from "../../../Settings/GeneralSettingsPage/AccountSettingsTab/UserInviteModal/MemberInviteModal";
import ArrowRightIcon from "@ant-design/icons/ArrowRightOutlined";
import { AppManager } from "../../../../../manager";
import { Organization } from "../../../../../models/Organization/Organization";
import { RootState } from "../../../../../redux/reducers";
import { useSelector } from "react-redux";

const UserInviteSetup: React.FC<{ onNext: () => void }> = ({ onNext }) => {
    const activeOrg = useSelector<RootState, Organization>((state) => state.orgs.activeOrg!);
    const [preparingNext, setPreparingNext] = useState(false);
    const [skipping, setSkipping] = useState(false);

    const handleNext = () => {
        setPreparingNext(true);
        AppManager.org.apiAddCompletedInitialSetupItem(activeOrg.id, "invite_users", (err) => {
            setPreparingNext(false);
            if (err) AppManager.alert.error("Error", `${err}`);
            else onNext();
        });
    };

    const handleSkip = () => {
        AppManager.alert.confirmAction(
            "Confirm Action",
            "Skip user invitions? You can always do this from your settings page",
            (res) => {
                if (res === "OK") {
                    setSkipping(true);
                    AppManager.org.apiAddCompletedInitialSetupItem(activeOrg.id, "invite_users", (err) => {
                        setSkipping(false);
                        if (err) AppManager.alert.error("Error", `${err}`);
                        else onNext();
                    });
                }
            },
            { okText: "YES, SKIP" },
        );
    };

    return (
        <Card className="match-parent animate__animated animate__zoomIn animate__faster">
            <div className={Styles.Container}>
                <div className={Styles.Header}>Invite Users</div>
                <div className={Styles.InviteContainer}>
                    <InviteUsersView
                        renderClose={(completed) =>
                            completed ? (
                                <Button type="primary" onClick={handleNext}>
                                    Next <ArrowRightIcon />
                                </Button>
                            ) : (
                                <Button
                                    onClick={handleSkip}
                                    style={{ marginLeft: 10 }}
                                    type="link"
                                    loading={skipping}
                                    disabled={skipping || preparingNext}
                                    danger
                                >
                                    Skip this setup
                                </Button>
                            )
                        }
                        onClose={handleNext}
                    />
                </div>
            </div>
        </Card>
    );
};

export default UserInviteSetup;
