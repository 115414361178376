import moment from "moment";
import { ISimpleUser } from "../../User/SimpleUserType";
import { TIndividualAttrs } from "../Types";

export type IndividualDependentAttrs = {
    _id: string;
    title: string;
    contact: string;
    firstName: string;
    lastName: string;
    relation: string;
    isDependent: boolean;
    dateOfBirth?: Date;
    createdBy: string | ISimpleUser;
    modifiedBy?: string | ISimpleUser;
    createdAt: Date;
    updatedAt: Date;
    dependentContact?: string | TIndividualAttrs;
};

export class IndividualDependent {
    public readonly id: string;
    constructor(public attrs: IndividualDependentAttrs) {
        this.id = attrs._id;
        if (attrs.dateOfBirth) this.attrs.dateOfBirth = moment(attrs.dateOfBirth).toDate();
    }

    updateDependent(depAttrs: IndividualDependentAttrs) {
        this.attrs = depAttrs;
        if (depAttrs.dateOfBirth) this.attrs.dateOfBirth = moment(depAttrs.dateOfBirth).toDate();
        return this;
    }

    static fromArray(dependents: IndividualDependentAttrs[]) {
        return dependents.map((t) => new IndividualDependent(t));
    }
}
