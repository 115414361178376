import { QueryResultCallback } from "../../models/Callback/Callback";
import { Project } from "../../models/Task/Project";
import { ProjectTemplate } from "../../models/Task/ProjectTemplate";
import { TProjectCatg, TProjectAttr, TNewProjectAttr, TTaskAttr } from "../../models/Task/Types";
import { ProjectStoreActions } from "../../redux/services/project/actions";
import { APIManager } from "../APIManager";
import { ProjectNetworkHelper } from "./ProjectNetworkHelper";
import { ProjectTemplateManager } from "./ProjectTemplateManager";

export class ProjectManager {
    template = new ProjectTemplateManager();
    networkHelper = ProjectNetworkHelper;

    static newProjectObj(data: TProjectAttr) {
        return new Project(data);
    }

    getProject(projectId: string): [Project, TProjectCatg] | undefined {
        const projectsMap = ProjectStoreActions.getProjects();

        for (const categ of Object.keys(projectsMap)) {
            const projects = projectsMap[categ as TProjectCatg];

            // Check if projects is an array before using find
            if (Array.isArray(projects)) {
                const found: Project | undefined = projects.find((t) => t.id === projectId);
                if (found) return [found, categ as TProjectCatg];
            } else {
                console.warn(`Projects for category ${categ} is not an array.`);
            }
        }

        return undefined;
    }

    getMyProjects() {
        const projectsMap = ProjectStoreActions.getProjects();
        const myProjects = projectsMap.myProjects;
        if (myProjects) return (myProjects || {}) as any;
    }

    async convertProjectToTemplate(mProject: Project, cb: QueryResultCallback<ProjectTemplate>) {
        try {
            const resp = await APIManager.get(APIManager.TASKS.PROJECTS_SINGLE_API(mProject.id));
            const projAttr: TProjectAttr = resp.data;
            const phases = projAttr.phases.map((phase) => {
                const tasks = phase.tasks.map((task) => {
                    const taskItem = task as TTaskAttr;
                    return {
                        title: taskItem.title,
                        priority: taskItem.priority,
                        assignee: taskItem.assignee,
                        contact: taskItem.contact,
                        tasklist: taskItem.tasklist?.map((t) => ({
                            title: t.title,
                            description: t.description,
                        })),
                    };
                });
                return { tasks };
            });

            cb(null, ProjectTemplateManager.newTemplateObj({ _id: projAttr._id, title: projAttr.title, phases }));
        } catch (err) {
            cb(`${err}`);
        }
    }

    detectProjectCategory(project: Project): TProjectCatg {
        return ProjectStoreActions.detectCateg(project.data.attrs);
    }

    async apiFetchMyProjects(cb: QueryResultCallback<Project[]>) {
        try {
            ProjectStoreActions.loadingProjects("myProjects");
            const resp = await APIManager.get(APIManager.TASKS.PROJECTS_MY_API());
            ProjectStoreActions.saveProjects({ myProjects: resp.data });
            ProjectStoreActions.setProjectsLoaded("myProjects");
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        } finally {
            ProjectStoreActions.loadingProjects(null);
        }
    }

    async apiFetchAllProjects(projectCatg: TProjectCatg, cb: QueryResultCallback<Project[]>) {
        try {
            ProjectStoreActions.loadingProjects(projectCatg);
            const resp = await APIManager.get(APIManager.TASKS.PROJECTS_API(projectCatg));
            const projects: Project[] = resp.data.map((t: TProjectAttr) => this.updateProjectAttrOrAddNew(t, false));
            ProjectStoreActions.saveProjects({ [projectCatg]: projects });
            ProjectStoreActions.setProjectsLoaded(projectCatg);
            cb(null, projects);
        } catch (err) {
            cb(`${err}`);
        } finally {
            ProjectStoreActions.loadingProjects(null);
        }
    }

    async apiFetchProjectAttrs(projectId: string, cb: QueryResultCallback<Project>) {
        try {
            const resp = await APIManager.get(APIManager.TASKS.PROJECTS_SINGLE_API(projectId));
            const projectAttr: TProjectAttr = resp.data;
            const project: Project = this.updateProjectAttrOrAddNew(projectAttr, false);
            ProjectStoreActions.addOrUpdateToProjects(project);
            cb(null, project);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiDeleteProject(projectId: string, cb: QueryResultCallback<Project>) {
        try {
            const resp = await APIManager.delete(APIManager.TASKS.PROJECTS_SINGLE_API(projectId));
            const project = this.getProject(projectId);
            if (project) {
                project[0].markDeleted();
                ProjectStoreActions.removeProject(project[0]);
            }
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiAddProject(projData: TNewProjectAttr, cb: QueryResultCallback<Project>) {
        try {
            const resp = await APIManager.post(APIManager.TASKS.PROJECTS_API(), projData);
            const project: Project = ProjectManager.newProjectObj(resp.data);
            ProjectStoreActions.addOrUpdateToProjects(project);
            cb(null, project);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiUpdateProject(projectId: string, projectData: TNewProjectAttr, cb: QueryResultCallback<Project>) {
        try {
            const resp = await APIManager.put(APIManager.TASKS.PROJECTS_SINGLE_API(projectId), projectData);
            const project = this.updateProjectAttrOrAddNew(resp.data);
            cb(null, project);
        } catch (err) {
            cb(`${err}`);
        }
    }

    updateProjectAttrOrAddNew(projectAttr: TProjectAttr, saveToStore = true) {
        const projectTpl = this.getProject(projectAttr._id);
        if (projectTpl) {
            projectTpl[0].updateProjectAttrs(projectAttr);
            if (saveToStore) ProjectStoreActions.addOrUpdateToProjects(projectTpl[0], projectTpl[1]);
            return projectTpl[0];
        } else {
            const project = ProjectManager.newProjectObj(projectAttr);
            if (saveToStore) ProjectStoreActions.addOrUpdateToProjects(project);
            return project;
        }
    }

    removeProject(projectId: string) {
        const projectTpl = this.getProject(projectId);
        if (projectTpl) {
            projectTpl[0].remove();
            ProjectStoreActions.removeProject(projectTpl[0]);
        }
    }
}
