import React, { useEffect, useState } from "react";
import Styles from "./UserDisplay.scss";
import classNames from "classnames";
import { TOrgMemberAttr } from "../../../models/Organization/Types";
import { OrgMember } from "../../../models/Organization/OrgMember";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
import { Organization } from "../../../models/Organization/Organization";
import { Avatar, Tag, Tooltip } from "antd";
import { User } from "../../../models/User/User";
import HighlightText from "../../HighlightText/HighlightText";
import { ISimpleUser } from "../../../models/User/SimpleUserType";
import { AppManager } from "../../../manager";

type UserDisplayProps = {
    user?: OrgMember | string | ISimpleUser;
    short?: boolean;
    noAvatar?: boolean;
    avatarOnly?: boolean;
    firstNameOnly?: boolean;
    avatarSize?: number;
    link?: boolean;
    notFoundText?: React.ReactNode;
    searchTexts?: string[];
    showType?: boolean;
    color?: string;
    inferYou?: boolean;
};

const UserDisplay: React.FC<UserDisplayProps> = ({
    user,
    short,
    color,
    noAvatar = false,
    avatarOnly,
    firstNameOnly,
    avatarSize = 30,
    showType = false,
    link = true,
    notFoundText,
    searchTexts,
    inferYou,
}) => {
    const [displayName, setDisplayName] = useState<string>();
    const [memberObj, setMember] = useState<OrgMember | undefined>(user instanceof OrgMember ? user : undefined);
    const [memberAttrs, setMemberAttrs] = useState<TOrgMemberAttr>();
    const activeOrg = useSelector<RootState, Organization>((state) => state.orgs.activeOrg!);
    const thisUser = useSelector<RootState, User>((state) => state.user.user!);

    const handleDeleted = () => {
        setDisplayName(undefined);
    };

    useEffect(() => {
        if (user && typeof user === "string") {
            const member = activeOrg.getMember(user);
            if (member) setMember(member);
        } else if (user instanceof OrgMember) {
            setMember(user);
        } else if (user && typeof user !== "string") {
            const member = activeOrg.getMember(user._id);
            if (member) setMember(member);
        }
    }, [user]);

    useEffect(() => {
        if (memberObj) {
            setMemberAttrs(memberObj.attrs);
            memberObj.watch("ATTRIBUTES_UPDATED", setMemberAttrs);
            memberObj.watch("MEMBER_DELETED", handleDeleted);

            return () => {
                memberObj.unwatch("ATTRIBUTES_UPDATED", setMemberAttrs);
                memberObj.unwatch("MEMBER_DELETED", handleDeleted);
            };
        }
    }, [memberObj]);

    useEffect(() => {
        if (memberAttrs) {
            const { user } = memberAttrs;
            setDisplayName(
                user && user.lastName ? `${user.firstName} ${firstNameOnly ? "" : short ? `${user.lastName[0]}.` : user.lastName}` : undefined,
            );
        }
    }, [memberAttrs]);

    const handleClick = () => {
        // if (link && (contact || memberObj)) AppManager.route.gotoSingleContact(typeof contact === "string" ? contact : memberObj!.id);
    };
    return displayName ? (
        <div className={classNames(Styles.Container, { [Styles.IsLink]: link })} onClick={handleClick}>
            <div className={Styles.NameAvatarWrapper}>
                {(!noAvatar || avatarOnly) && (
                    <div className={Styles.UserAvatarWrapper}>
                        {avatarOnly ? (
                            <Tooltip title={displayName}>
                                <Avatar size={avatarSize}>{displayName[0]}</Avatar>
                            </Tooltip>
                        ) : (
                            <Avatar size={avatarSize}>{displayName[0]}</Avatar>
                        )}
                    </div>
                )}
                {!avatarOnly && (
                    <span className={Styles.DisplayName}>
                        <div className={Styles.Inner} style={{ color }}>
                            {inferYou && AppManager.user.getActiveUser()?.id === memberObj?.attrs.user._id ? (
                                "You"
                            ) : searchTexts?.length ? (
                                <HighlightText text={displayName} searches={searchTexts} />
                            ) : (
                                displayName
                            )}
                        </div>
                    </span>
                )}
            </div>
            {memberAttrs && showType && !avatarOnly && (
                <div className={Styles.UserTypeTag}>
                    {thisUser.id === memberAttrs.user._id ? (
                        <Tag color="green">You</Tag>
                    ) : "staff" ? (
                        <Tag color="magenta">Staff</Tag>
                    ) : (
                        <Tag color="blue">RoleTypeHere</Tag>
                    )}
                </div>
            )}
        </div>
    ) : (
        <>
            {notFoundText || (
                <span style={{ color }} className={Styles.NotFoundText}>
                    [User not found]
                </span>
            )}
        </>
    );
};

export default UserDisplay;
