import moment from "moment";
import { ISCheduleCategoryAttrs } from "../Schedule/Schedule";
import { TUserAttr } from "../User/User";
import { AppModel } from "../_Utils/AppModel";
import { Watchable } from "../_Utils/Watchable";
import { OrgMember } from "./OrgMember";
import {
    TContactServiceAttr,
    TContactServiceLevelAttr,
    TDealerGroupAttr,
    TDealerGroupRequestAttr,
    TOrganizationAttr,
    TOrgInvitation,
    TOrgMemberAttr,
    TOrgRoleAttr,
} from "./Types";

export type OrganizationEventMap = {
    ATTRIBUTES_UPDATED: TOrganizationAttr;
    MEMBERS_UPDATED: OrgMember[];
    CONTACT_SERVICE_LEVELS_UPDATED: TContactServiceLevelAttr[];
    CONTACT_SCHEDULES_CATEGORIES_UPDATED: ISCheduleCategoryAttrs[];
    CONTACT_SERVICES_UPDATED: TContactServiceAttr[];
    AUTHORIZED_DEALER_GROUPS_UPDATED: TDealerGroupAttr[];
    INVITATIONS_UPDATED: TOrgInvitation[];
    DEALER_GROUP_REQUESTS_UPDATED: TDealerGroupRequestAttr[];
    ORG_ROLES_UPDATED: TOrgRoleAttr[];
};

class OrganizationData extends Watchable<OrganizationEventMap> {
    constructor(attrs: TOrganizationAttr) {
        super();
        this.cAttrs = attrs;
        this.attrs = attrs;
    }

    private cMembers: OrgMember[] = [];
    private cServiceLevels: TContactServiceLevelAttr[] = [];
    private cScheduleCategories: ISCheduleCategoryAttrs[] = [];
    private cAuthorizedDealerGroups: TDealerGroupAttr[] = [];
    private cDealerGroupRequests: TDealerGroupRequestAttr[] = [];
    private cServices: TContactServiceAttr[] = [];
    private cInvitations: TOrgInvitation[] = [];
    private cRoles: TOrgRoleAttr[] = [];
    private cAttrs: TOrganizationAttr;

    set members(m: OrgMember[]) {
        this.cMembers = m;
        this.trigger("MEMBERS_UPDATED", m);
    }

    get members() {
        return this.cMembers;
    }

    set invitations(m: TOrgInvitation[]) {
        this.cInvitations = m;
        this.trigger("INVITATIONS_UPDATED", m);
    }

    get invitations() {
        return this.cInvitations;
    }

    set contactServiceLevels(sl: TContactServiceLevelAttr[]) {
        this.cServiceLevels = sl;
        this.trigger("CONTACT_SERVICE_LEVELS_UPDATED", sl);
    }

    get contactServiceLevels() {
        return this.cServiceLevels;
    }

    get schedulesCategories() {
        return this.cScheduleCategories;
    }

    set schedulesCategories(catg: ISCheduleCategoryAttrs[]) {
        this.cScheduleCategories = catg;
        this.trigger("CONTACT_SCHEDULES_CATEGORIES_UPDATED", catg);
    }

    set contactServices(sv: TContactServiceLevelAttr[]) {
        this.cServices = sv;
        this.trigger("CONTACT_SERVICES_UPDATED", sv);
    }

    get contactServices() {
        return this.cServices;
    }

    get authorizedDealerGroups() {
        return this.cAuthorizedDealerGroups;
    }

    set authorizedDealerGroups(dg: TDealerGroupAttr[]) {
        this.cAuthorizedDealerGroups = dg;
        this.trigger("AUTHORIZED_DEALER_GROUPS_UPDATED", dg);
    }

    set dealerGroupRequests(m: TDealerGroupRequestAttr[]) {
        this.cDealerGroupRequests = m;
        this.trigger("DEALER_GROUP_REQUESTS_UPDATED", m);
    }

    get dealerGroupRequests() {
        return this.cDealerGroupRequests;
    }

    set orgRoles(atr: TOrgRoleAttr[]) {
        this.cRoles = atr;
        this.trigger("ORG_ROLES_UPDATED", atr);
    }

    get orgRoles() {
        return this.cRoles;
    }

    set attrs(atr: TOrganizationAttr) {
        atr.createdAt = moment(atr.createdAt);
        atr.updatedAt = moment(atr.updatedAt);
        this.cAttrs = atr;
        this.trigger("ATTRIBUTES_UPDATED", atr);
    }

    get attrs() {
        return this.cAttrs;
    }
}

export class Organization extends AppModel<OrganizationData> {
    public data: OrganizationData;
    public readonly id: string;

    constructor(attributes: TOrganizationAttr) {
        super();
        this.data = new OrganizationData(attributes);
        this.id = attributes._id;
    }

    updateContactServiceLevels(serviceLevels: TContactServiceLevelAttr[]) {
        this.data.contactServiceLevels = serviceLevels;
        return this;
    }

    updateScheduleCategories(categories: ISCheduleCategoryAttrs[]) {
        this.data.schedulesCategories = categories;
        return this;
    }

    addContactService(service: TContactServiceAttr) {
        this.data.contactServices = [...this.data.contactServices, service];
        return this;
    }

    updateContactServices(services: TContactServiceAttr[]) {
        this.data.contactServices = services;
        return this;
    }

    updateInvitations(invitations: TOrgInvitation[]) {
        this.data.invitations = invitations;
        return this;
    }

    updateRoles(roles: TOrgRoleAttr[]) {
        this.data.orgRoles = roles;
        return this;
    }

    updateMemberUserAttr(userData: TUserAttr) {
        this.data.members = this.data.members.map((m) => (m.attrs.user._id === userData._id ? m.updateMemberUserAttr(userData) : m));
        return this;
    }

    updateMembers(membersAttr: TOrgMemberAttr[]) {
        this.data.members = membersAttr.map((attr) => {
            const member = this.data.members.find((t) => t.id == attr._id);
            if (member) {
                return member.update(attr);
            } else return new OrgMember(attr);
        });
        return this;
    }

    addAuthorizedDealerGroup(dGroup: TDealerGroupAttr) {
        this.data.authorizedDealerGroups = [...this.data.authorizedDealerGroups, dGroup];
        return this;
    }

    updateAuthorizedDealerGroups(dGroups: TDealerGroupAttr[]) {
        this.data.authorizedDealerGroups = dGroups;
        return this;
    }

    updateDealerGroupRequests(requests: TDealerGroupRequestAttr[]) {
        this.data.dealerGroupRequests = requests;
        return this;
    }

    updateAttributes(orgAttr: TOrganizationAttr) {
        this.data.attrs = { ...this.data.attrs, ...orgAttr };
        return this;
    }

    updateOrganization(org: Organization) {
        this.data.attrs = org.data.attrs;
        return this;
    }

    getMember(userId: string): OrgMember | undefined {
        return this.data.members.find((t) => t.attrs.user._id === userId);
    }
}
