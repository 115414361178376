import ActionTypes from "./actionTypes";
import { getAppStore } from "../../store";
import { Task } from "../../../models/Task/Task";
import { TTaskAttr, TTaskCatg } from "../../../models/Task/Types";
import { UserStoreActions } from "../user/actions";

export class TaskStoreActions {
    static loadingTasks(loading: TTaskCatg | null) {
        getAppStore().dispatch({ type: ActionTypes.LOADING_TASKS, payload: loading });
    }
    static setTasksLoaded(state: TTaskCatg) {
        getAppStore().dispatch({ type: ActionTypes.LOADED_TASKS, payload: state });
    }

    static saveTasks(tasks: Partial<{ [k in TTaskCatg]: Task[] }>) {
        getAppStore().dispatch({ type: ActionTypes.SAVE_TASKS, payload: tasks });
    }
    static loadingTemplates(loading: boolean) {
        getAppStore().dispatch({ type: ActionTypes.LOADING_TEMPLATES, payload: loading });
    }
    static setTemplatesLoaded(state: boolean) {
        getAppStore().dispatch({ type: ActionTypes.LOADED_TEMPLATES, payload: state });
    }

    static saveTaskTemplate(tasks: any[]) {
        getAppStore().dispatch({ type: ActionTypes.SAVE_TEMPLATES, payload: tasks });
    }

    static removeTask(task: Task) {
        getAppStore().dispatch({ type: ActionTypes.REMOVE_TASK, payload: task });
    }
    static getTasks() {
        return getAppStore().getState().tasks.tasks;
    }
    static getTemplates() {
        return getAppStore().getState().tasks.tasks;
    }
    static detectCateg(taskAttr: TTaskAttr): TTaskCatg {
        const { assignee, createdBy } = taskAttr;
        const userId = UserStoreActions.getUser()?.id;
        return assignee === userId && createdBy !== userId ? "assigned" : assignee !== userId && createdBy !== userId ? "active" : "user";
    }
    static addOrUpdateToTasks(task: Task, catg?: TTaskCatg) {
        const tasks = TaskStoreActions.getTasks();
        const detectedCatg = TaskStoreActions.detectCateg(task.data.attrs);
        if (catg && catg !== detectedCatg) {
            tasks[catg] = tasks[catg].filter((r) => r.id !== task.id);
        }
        catg = detectedCatg;
        if (tasks[catg].some((t) => t.data.attrs._id === task.id)) {
            tasks[catg] = tasks[catg].map((t) => {
                if (t.data.attrs._id === task.id) {
                    return t.updateTask(task);
                }
                return t;
            });
        } else if (catg == "assigned" || !task.data.attrs.projectRef) {
            tasks[catg] = [task, ...tasks[catg]];
        }
        TaskStoreActions.saveTasks({ ...tasks });
    }

    static addOrUpdateToTaskTemplates(task: Task, catg?: TTaskCatg) {
        let templates = TaskStoreActions.getTemplates();
        if (templates.some((t) => t.data.attrs._id === task.id)) {
            templates = templates.map((t) => {
                if (t.data.attrs._id === task.id) {
                    return t.updateTemplate(task);
                }
                return t;
            });
        } else {
            templates = [task, ...templates];
        }
        TaskStoreActions.saveTaskTemplate(templates);
    }
}
