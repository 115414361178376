import { Button, Card } from "antd";
import Avatar from "antd/lib/avatar/avatar";
import React, { useEffect, useState } from "react";
import Loadable from "react-loadable";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import {
    APPS_ROUTE,
    CONTACTS_ROUTE,
    DASHBOARD_ROUTE,
    DIARY_ROUTE,
    DOCUMENTS_ROUTE,
    PROJECTS_ROUTE,
    SERVICES_FEES_ROUTE,
    SETTINGS_ROUTE,
    TASKS_ROUTE,
} from "../../constants/appRoutes";
import MainLayout from "../../layouts/MainLayout/MainLayout";
import { AppManager } from "../../manager";
import { Organization } from "../../models/Organization/Organization";
import { RootState } from "../../redux/reducers";
import AppLoadingPage from "../AppLoadingPage/AppLoadingPage";
import AppSimplePage from "../AppSimplePage/AppSimplePage";
import Styles from "./index.scss";
import Loading from "../../components/Loading/Loading";
import AusplanAppsPage from "./Apps/Apps";
import WelcomePage from "./Welcome/WelcomePage";
import { useModelEventWatcher } from "../../hooks/modelHook";
import { TFullOrganizationAttr } from "../../models/Organization/Types";
import { SubscriptionIssuePage, MultipleSessionIssuePage } from "./IssuePages";
import OutlookCalProvider from "../../containers/CalendarSync/OutlookCalendar/OutlookCalendarContext";
import { MsalProvider } from "@azure/msal-react";
import msalInstance from "../../containers/CalendarSync/OutlookCalendar/msalInstance";
import { AppStateT } from "../../redux/services/app/reducer";

const PageInitializing: React.FC<{ text?: string }> = ({ text }) => {
    return <MainLayout Content={<Loading text={text || "Initializing"} />} />;
};

const DashboardPage = Loadable({
    loader: () => import("./Dashboard/Dashboard"),
    loading: () => <PageInitializing text="Initializing Dashboard Page" />,
});

const ContactsPage = Loadable({
    loader: () => import("./Contacts/ContactsPage"),
    loading: () => <PageInitializing text="Initializing Contacts Page" />,
});

const DocumentsPage = Loadable({
    loader: () => import("./Documents/DocumentsPage"),
    loading: () => <PageInitializing text="Initializing Documents Page" />,
});

const StatementOfAdvicePage = Loadable({
    loader: () => import("./StatementOfAdvice/StatementOfAdvicePage"),
    loading: () => <PageInitializing text="Initializing SOA Page" />,
});

const ServicesFeesPage = Loadable({
    loader: () => import("./ServicesFees/ServicesFeesPage"),
    loading: () => <PageInitializing text="Initializing Services Fees Page" />,
});

const DiaryPage = Loadable({
    loader: () => import("./Diary/DiaryPage"),
    loading: () => <PageInitializing text="Initializing Diary Page" />,
});

const TasksPage = Loadable({
    loader: () => import("./Tasks/TasksPage"),
    loading: () => <PageInitializing text="Initializing Tasks Page" />,
});

const SettingsPage = Loadable({
    loader: () => import("./Settings/SettingsPage"),
    loading: () => <PageInitializing text="Initializing Settings Page" />,
});

const ProtectedRoutes = () => {
    const activeOrg = useSelector<RootState, Organization>((r) => r.orgs.activeOrg!);
    const appInitStatus = useSelector<RootState, AppStateT["initState"]["status"]>((r) => r.app.initState.status);
    const [orgAttr] = useModelEventWatcher(activeOrg, "ATTRIBUTES_UPDATED", activeOrg.data.attrs as TFullOrganizationAttr);
    const [initializing, setInitializing] = useState(true);
    const activeUser = AppManager.user.getActiveUser();
    const location = useLocation();
    const initializeApp = async () => {
        await Promise.all([
            AppManager.contact.apiFetchAllContacts(),
            AppManager.document.main.apiFetchAllDocuments(() => {}),
            AppManager.document.template.apiFetchAllTemplates(() => {}),
            AppManager.task.apiFetchMyTasks(() => {}),
            AppManager.taskTemplate.apiFetchAllTaskTemplates("templates", () => {}),
            AppManager.task.apiFetchAllTasks("user", () => {}),
            AppManager.diary.apiFetchAllMySchedules(() => {}),
            AppManager.task.apiFetchAllTasks("assigned", () => {}),
            AppManager.project.apiFetchAllProjects("user", () => {}),
            AppManager.project.apiFetchAllProjects("org", () => {}),
            AppManager.project.apiFetchMyProjects(() => {}),
            AppManager.project.template.apiFetchAllTemplates(() => {}),
        ]);
        setInitializing(false);
    };
    useEffect(() => {
        // Apps will take care of initializing their data
        if (!location.pathname.startsWith(APPS_ROUTE)) {
            if (appInitStatus == "READY") initializeApp();
        } else setInitializing(false);
    }, [appInitStatus]);

    return appInitStatus == "MULTIPLE_SESSIONS" ? (
        <MultipleSessionIssuePage />
    ) : initializing ? (
        <AppLoadingPage loadingText="Loading App..." />
    ) : orgAttr.subscription && !["live", "active"].includes(orgAttr?.subscription?.status || "") ? (
        <SubscriptionIssuePage />
    ) : (orgAttr as TFullOrganizationAttr).initialSetupStatus !== "done" &&
      (orgAttr as TFullOrganizationAttr).initialSetupStatus !== "canceled" &&
      orgAttr.owner._id === activeUser?.id ? (
        <WelcomePage />
    ) : (
        <Routes>
            <Route path="/contacts*" element={<ContactsPage />} />
            <Route path="/documents*" element={<DocumentsPage />} />
            <Route path="/soa*" element={<StatementOfAdvicePage />} />
            <Route path="/dashboard*" element={<DashboardPage />} />
            <Route path="/diary*" element={<DiaryPage />} />
            <Route path="/servicesFees*" element={<ServicesFeesPage />} />
            <Route path="/tasks*" element={<TasksPage />} />
            <Route path="/projects*" element={<TasksPage />} />
            <Route path="/settings*" element={<SettingsPage />} />
            <Route path="/apps*" element={<AusplanAppsPage />} />
            <Route path="*" element={<Navigate to={DASHBOARD_ROUTE} replace />} />
        </Routes>
    );
};

const ErrorDisplay: React.FC<{ text: string }> = ({ text }) => {
    const handleReloadPage = () => {
        window.location.reload();
    };
    return (
        <div className={Styles.ErrorContainer}>
            <div className={Styles.ErrorTitle}>Error!</div>
            <div className={Styles.ErrorText}>{text}</div>
            <div>
                Try{" "}
                <Button size="small" onClick={handleReloadPage}>
                    Reloading
                </Button>{" "}
                the page. If that does not help, please contact technical support with a screenshot of this error
            </div>
        </div>
    );
};

const NoOrgDisplay = () => {
    return (
        <div className={Styles.ErrorContainer}>
            <div className={Styles.ErrorTitle}>Sorry!</div>
            <div className={Styles.ErrorText}>You currently do not belong to any organization and hence cannot use Ausplan.</div>
            <div>If you think there is something wrong, please contact technical support</div>
        </div>
    );
};

const SelectOrganizationView: React.FC<{ onSelectOrg: (org: Organization) => void }> = ({ onSelectOrg }) => {
    const userOrgs = useSelector<RootState, Organization[]>((state) => state.orgs.orgs);
    return (
        <div className={Styles.OrgsViewContainer}>
            <div className={Styles.SelectOrgTitle}>Select Organization</div>
            <div className={Styles.SelectOrgDesc}>You currently belong to these organizations. Please select one to proceed.</div>
            <div className={Styles.OrgListContainer}>
                {userOrgs.map((t) => (
                    <div key={t.id} className={Styles.OrgItemContainer} onClick={() => onSelectOrg(t)}>
                        <Avatar shape="square">{t.data.attrs.name[0]}</Avatar>
                        <div className={Styles.Text}>{t.data.attrs.name}</div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default () => {
    const userDataLoaded = useSelector<RootState, boolean>((state) => state.user.userLoaded);
    const activeOrg = useSelector<RootState, Organization | undefined>((state) => state.orgs.activeOrg);
    const userOrgs = useSelector<RootState, Organization[]>((state) => state.orgs.orgs);
    const [loading, setLoading] = useState(!userDataLoaded);
    const location = useLocation();
    const [loadError, setLoadError] = useState<string>();
    const handleSetActiveOrg = (org: Organization) => {
        setLoading(true);
        AppManager.org.activateOrganization(org, (err) => {
            setLoading(false);
            if (err) setLoadError(err);
        });
    };
    useEffect(() => {
        if (!userDataLoaded) {
            AppManager.user.apiFetchUserData((err) => {
                setLoading(false);
                if (err) setLoadError(err);
                else {
                    const persistedOrg = AppManager.org.getPersistedActiveOrg();
                    if (persistedOrg) handleSetActiveOrg(persistedOrg);
                    else if (AppManager.org.getUserOrgs().length == 1) {
                        handleSetActiveOrg(AppManager.org.getUserOrgs()[0]);
                    }
                }
            });
        }
    }, [userDataLoaded]);

    useEffect(() => {
        if (activeOrg) AppManager.notifications.apiFetchUserNotifications(() => {});
    }, [activeOrg]);

    return loading ? (
        <AppLoadingPage simple={location.pathname.startsWith(APPS_ROUTE)} />
    ) : loadError ? (
        <AppSimplePage content={<ErrorDisplay text={loadError} />} />
    ) : !userOrgs.length ? (
        <AppSimplePage content={<NoOrgDisplay />} />
    ) : !activeOrg ? (
        <AppSimplePage content={<SelectOrganizationView onSelectOrg={handleSetActiveOrg} />} />
    ) : (
        <MsalProvider instance={msalInstance}>
            <OutlookCalProvider>
                <ProtectedRoutes />
            </OutlookCalProvider>
        </MsalProvider>
    );
};
