enum ActionTypes {
    SAVE_DOCUMENT_TEMPLATES = "SAVE_DOCUMENT_TEMPLATES",
    LOADING_DOCUMENT_TEMPLATES = "LOADING_DOCUMENT_TEMPLATES",
    LOADED_DOCUMENT_TEMPLATES = "LOADED_DOCUMENT_TEMPLATES",
    REMOVE_DOCUMENT_TEMPLATE = "REMOVE_DOCUMENT_TEMPLATE",

    SAVE_DOCUMENTS = "SAVE_DOCUMENT",
    LOADING_DOCUMENTS = "LOADING_DOCUMENT",
    LOADED_DOCUMENTS = "LOADED_DOCUMENT",
    REMOVE_DOCUMENT = "REMOVE_DOCUMENT",

    LOGOUT_ACTION = "LOGOUT_ACTION",
}

export default ActionTypes;
