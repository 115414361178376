import moment from "moment";
import { Watchable } from "../_Utils/Watchable";
import { TProjectTemplateAttr } from "./Types";

type ProjectTemplateEventMap = {
    ATTRIBUTES_UPDATED: TProjectTemplateAttr;
    PROJECT_TEMPLATE_DELETED: null;
};

class ProjectTemplateData extends Watchable<ProjectTemplateEventMap> {
    constructor(attrs: TProjectTemplateAttr) {
        super();
        this.cAttrs = attrs;
        this.attrs = attrs;
    }

    private cAttrs: TProjectTemplateAttr;

    triggerTemplateDeleted() {
        this.trigger("PROJECT_TEMPLATE_DELETED", null);
    }

    set attrs(atr: TProjectTemplateAttr) {
        this.cAttrs = atr;
        this.cAttrs.createdAt = moment(this.cAttrs.createdAt);
        this.cAttrs.updatedAt = moment(this.cAttrs.updatedAt);

        this.trigger("ATTRIBUTES_UPDATED", atr);
    }

    get attrs() {
        return this.cAttrs;
    }
}

export class ProjectTemplate {
    public data: ProjectTemplateData;
    public readonly id: string;

    constructor(attributes: TProjectTemplateAttr) {
        this.data = new ProjectTemplateData(attributes);
        this.id = attributes._id;
    }

    markDeleted() {
        this.data.triggerTemplateDeleted();
    }

    updateTemplate(template: ProjectTemplate) {
        this.updateTemplateAttrs(template.data.attrs);
        return this;
    }

    updateTemplateAttrs(attrs: TProjectTemplateAttr) {
        this.data.attrs = attrs;
        return this;
    }

    remove() {
        this.data.triggerTemplateDeleted();
    }
}
