import { TASKS_ROUTE } from "../../constants/appRoutes";
import { AppManager } from "..";
import { IOEventTypes } from "../RealtimeManager";

export class TaskNetworkHelper {
    static networkDataReceived = <K extends keyof IOEventTypes>(evt: K, resp: any): void => {
        switch (evt) {
            // Masterlist events
            case "TASK_CREATED":
                {
                    const task = (resp as IOEventTypes["TASK_CREATED"]).data;
                    AppManager.task.updateTaskAttrOrAddNew(task);
                }
                break;

            case "TASK_UPDATED":
                {
                    const task = (resp as IOEventTypes["TASK_CREATED"]).data;
                    AppManager.task.updateTaskAttrOrAddNew(task);
                }
                break;
            case "TASK_DELETED":
                AppManager.task.removeTask((resp as IOEventTypes["TASK_DELETED"]).data._id);
                break;
        }
    };

    static onConnectionRestoredSync(currentRoute: string, currentPathName: string) {
        switch (currentRoute) {
            case TASKS_ROUTE:
                AppManager.task.apiFetchAllTasks("user", () => {});
                AppManager.task.apiFetchAllTasks("assigned", () => {});
                AppManager.task.apiFetchAllTasks("active", () => {});
                AppManager.project.apiFetchAllProjects("user", () => {});
                AppManager.project.apiFetchAllProjects("org", () => {});
                AppManager.projectTemplate.apiFetchAllTemplates(() => {});
                break;
        }
    }
}
