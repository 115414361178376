import moment from "moment";
import { TOrganizationAttr } from "../Organization/Types";
import { Watchable } from "../_Utils/Watchable";

type UserEventMap = {
    ATTRIBUTES_UPDATED: TUserAttr;
    USER_DELETED: null;
};

export type TUserNotfSettingsAttr = {
    pauseAll?: boolean;
    email?: {
        taskUpdates?: boolean;
        contactActivity?: boolean;
    };
    browser?: {
        taskUpdates?: boolean;
        contactActivity?: boolean;
    };
};

export type TUserAttr = {
    _id: string;
    firstName: string;
    lastName: string;
    email: string;
    telephone: string;
    notificationSettings?: TUserNotfSettingsAttr;
    bio: string;
    createdAt: moment.Moment;
    updatedAt: moment.Moment;
};

class UserData extends Watchable<UserEventMap> {
    constructor(attrs: TUserAttr) {
        super();
        this.cAttrs = attrs;
        this.attrs = attrs;
    }

    private cAttrs: TUserAttr;

    triggerUserDeleted() {
        this.trigger("USER_DELETED", null);
    }

    set attrs(atr: TUserAttr) {
        this.cAttrs = { ...this.cAttrs, ...atr };
        this.cAttrs.createdAt = moment(this.cAttrs.createdAt);
        this.cAttrs.updatedAt = moment(this.cAttrs.updatedAt);

        this.trigger("ATTRIBUTES_UPDATED", atr);
    }

    get attrs() {
        return this.cAttrs;
    }
}

export type TUserData = {
    userInfo: TUserAttr;
    orgs: {
        owned: TOrganizationAttr[];
        membership: TOrganizationAttr[];
    };
};

export class User {
    public data: UserData;
    public readonly id: string;

    constructor(attributes: TUserAttr) {
        this.data = new UserData(attributes);
        this.id = attributes._id;
    }

    updateUser(user: User) {
        this.updateUserAttrs(user.data.attrs);
        return this;
    }

    updateUserAttrs(attrs: TUserAttr) {
        this.data.attrs = attrs;
        return this;
    }

    remove() {
        this.data.triggerUserDeleted();
    }
}
