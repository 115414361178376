/* eslint-disable react/no-unescaped-entities */
import React, { useRef, useState, useEffect, useMemo } from "react";
import { Alert, Checkbox, Collapse, Divider, Drawer, Form, Input, InputNumber, Modal, Select, Spin, Table } from "antd";
import Button from "antd-button-color";
import { Provider } from "react-redux";
import CloseIcon from "@ant-design/icons/CloseOutlined";
import ConnectIcon from "@ant-design/icons/ApiOutlined";
import MailIcon from "@ant-design/icons/MailFilled";
import Styles from "./EmailConnector.scss";
import AppContent from "../../../../../../containers/AppContent/AppContent";
import { getAppStore } from "../../../../../../redux/store";
import { useForm } from "antd/lib/form/Form";
import { AppManager } from "../../../../../../manager";
import { useModelEventWatcher } from "../../../../../../hooks/modelHook";
import PagePlaceholder from "../../../../../../components/PagePlaceholder/PagePlaceholder";
import { TConnectedEmailInfo } from "../../../../../../models/Organization/Types";
import { StarFilled, StarOutlined } from "@ant-design/icons";

const SUPPORTED_EMAIL_PROVIDERS = {
    "gmail.com": { host: "imap.gmail.com", port: 993 },
    "outlook.com": { host: "imap-mail.outlook.com", port: 993 },
    "aol.com": { host: "imap.aol.com", port: 993 },
    "zoho.com": { host: "imap.zoho.com", port: 993 },
    "yahoo.com": { host: "imap.mail.yahoo.com", port: 993 },
} as { [k: string]: { host: string; port: number } | undefined };

const CustomAccountForm: React.FC<{ onSubmit: (d: any) => void; submitting?: boolean; initialValues?: any }> = ({
    onSubmit,
    submitting,
    initialValues,
}) => {
    const smtpPortOptions = useMemo(() => {
        return [
            { label: "25", value: 25 },
            { label: "465", value: 465 },
            { label: "587", value: 587 },
            { label: "Custom...", value: 0 },
        ];
    }, []);

    const imapPortOptions = useMemo(() => {
        return [
            { label: "143", value: 143 },
            { label: "993", value: 993 },
            { label: "Custom...", value: 0 },
        ];
    }, []);

    return (
        <Form layout="vertical" onFinish={onSubmit} initialValues={initialValues}>
            <Divider className={Styles.Divider}>Incoming Mail (IMAP)</Divider>
            <div style={{ display: "flex", gap: 10 }}>
                <Form.Item name="imapUser" label="Username/Email" hasFeedback rules={[{ required: true }]} style={{ flex: 1 }}>
                    <Input placeholder="Enter your username or email" />
                </Form.Item>
                <Form.Item name="imapPassword" label="Password" style={{ flex: 1 }}>
                    <Input.Password placeholder="Enter your password" />
                </Form.Item>
            </div>

            <Form.Item
                hasFeedback
                label="Server Hostname"
                name="imapHost"
                rules={[{ required: true }]}
                tooltip="This is the hostname of your email provider's IMAP Server."
            >
                <Input placeholder="e.g. smtp.myserverdomain.com" />
            </Form.Item>

            <Form.Item shouldUpdate noStyle>
                {({ getFieldValue, setFieldValue }) => (
                    <Form.Item
                        hasFeedback
                        label="IMAP Port"
                        name="imapPort"
                        rules={[{ required: true }]}
                        tooltip="This is the IMAP port used by the host of your email provider"
                    >
                        <div style={{ display: "flex", gap: 10 }}>
                            <Select
                                options={imapPortOptions}
                                value={getFieldValue("imapPort")}
                                onChange={(val) => setFieldValue("imapPort", val)}
                                style={{ flex: 1 }}
                            />
                            {getFieldValue("imapPort") == 0 && (
                                <InputNumber
                                    placeholder="Enter custom port number"
                                    style={{ flex: 2 }}
                                    onChange={(val) => setFieldValue("imapPort", val as number)}
                                    value={getFieldValue("imapPort")}
                                />
                            )}
                        </div>
                    </Form.Item>
                )}
            </Form.Item>
            <Form.Item noStyle shouldUpdate>
                {({ setFieldsValue, getFieldValue }) => (
                    <Form.Item name="tls" label="Security">
                        <Checkbox checked={getFieldValue("tls")} onChange={(e) => setFieldsValue({ tls: e.target.checked })}>
                            Use TLS
                        </Checkbox>
                    </Form.Item>
                )}
            </Form.Item>
            <Divider className={Styles.Divider}>Outgoing Mail (SMTP)</Divider>
            <div style={{ display: "flex", gap: 10 }}>
                <Form.Item name="smtpUser" label="Username/Email" hasFeedback rules={[{ required: true }]} style={{ flex: 1 }}>
                    <Input placeholder="Enter your username or email" />
                </Form.Item>
                <Form.Item name="smtpPassword" label="Password" style={{ flex: 1 }}>
                    <Input.Password placeholder="Enter your password" />
                </Form.Item>
            </div>

            <Form.Item
                hasFeedback
                label="Server Hostname"
                name="smtpHost"
                rules={[{ required: true }]}
                tooltip="This is the hostname of your email provider's SMTP Server."
            >
                <Input placeholder="e.g. smtp.myserverdomain.com" />
            </Form.Item>

            <Form.Item shouldUpdate noStyle>
                {({ getFieldValue, setFieldValue }) => (
                    <Form.Item
                        hasFeedback
                        label="SMTP Port"
                        name="smtpPort"
                        rules={[{ required: true }]}
                        tooltip="This is the SMTP port used by the host of your email provider"
                    >
                        <div style={{ display: "flex", gap: 10 }}>
                            <Select
                                options={smtpPortOptions}
                                value={getFieldValue("smtpPort")}
                                onChange={(val) => setFieldValue("smtpPort", val)}
                                style={{ flex: 1 }}
                            />
                            {getFieldValue("smtpPort") == 0 && (
                                <InputNumber
                                    placeholder="Enter custom port number"
                                    style={{ flex: 2 }}
                                    onChange={(val) => setFieldValue("smtpPort", val as number)}
                                    value={getFieldValue("smtpPort")}
                                />
                            )}
                        </div>
                    </Form.Item>
                )}
            </Form.Item>
            <Form.Item noStyle shouldUpdate>
                {({ setFieldsValue, getFieldValue }) => (
                    <Form.Item name="smtpSecure" label="">
                        <Checkbox checked={getFieldValue("smtpSecure")} onChange={(e) => setFieldsValue({ tls: e.target.checked })}>
                            Enforce SSL
                        </Checkbox>
                    </Form.Item>
                )}
            </Form.Item>
            <Form.Item name="organization" initialValue="org" style={{ display: "none" }} />
            <Form.Item>
                <div style={{ display: "flex", justifyContent: "center", marginTop: 10 }}>
                    <Button block icon={<ConnectIcon />} loading={submitting} disabled={submitting} htmlType="submit" size="large" type="primary">
                        Connect
                    </Button>
                </div>
            </Form.Item>
        </Form>
    );
};

const KnownAccountTypeForm: React.FC<{ onSubmit: (d: any) => void; submitting?: boolean; initialValues?: any; name: string }> = ({
    onSubmit,
    submitting,
    initialValues,
    name,
}) => {
    return (
        <Form layout="vertical" onFinish={onSubmit} initialValues={initialValues}>
            <Divider className={Styles.Divider}>{name} Setting</Divider>
            <Form.Item name="email" label="Email" hasFeedback rules={[{ required: true }]}>
                <Input placeholder="Enter your email" />
            </Form.Item>
            <Form.Item name="password" label="Password">
                <Input.Password placeholder="Enter your password" />
            </Form.Item>

            <Form.Item>
                <div style={{ display: "flex", justifyContent: "center", marginTop: 10 }}>
                    <Button block icon={<ConnectIcon />} loading={submitting} disabled={submitting} htmlType="submit" size="large" type="primary">
                        Connect
                    </Button>
                </div>
            </Form.Item>
        </Form>
    );
};

const EmailConnectorView: React.FC<{ onClose: () => void }> = ({ onClose }) => {
    const [openDrawer, setOpenDrawer] = useState(false);
    const [connecting, setConnecting] = useState(false);
    const [disconnectingEmail, setDisconnectingEmail] = useState<string>();
    const [loading, setLoading] = useState(false);
    const [connectedEmails, setConnectedEmails] = useState<TConnectedEmailInfo[]>([]);
    const [errors, setErrors] = useState<string>();
    const containerRef = useRef<HTMLDivElement>(null);
    const [accountType, setAccountType] = useState<string>("");

    const handleConnectEmail = async (data: any) => {
        try {
            setConnecting(true);
            setErrors(undefined);
            data.accountType = accountType;
            if (accountType) {
                data.imapUser = data.email;
                data.smtpUser = data.email;
                data.imapPassword = data.password;
                data.smtpPassword = data.password;
            }
            const res = await AppManager.org.emailSystem.apiConnectEmail(data);
            setConnectedEmails((r) => [...r, res]);

            AppManager.alert.msgSuccess("Email connected");
            setOpenDrawer(false);
        } catch (error) {
            setErrors(`${error}`);
        } finally {
            setConnecting(false);
        }
    };

    const handleDisconnectEmail = async (connectedEmailId: string) => {
        AppManager.alert.confirmAction("Confirm Email Disconnect", "Are you sure you want to disconnect this email?", async (res) => {
            if (res === "OK") {
                try {
                    setDisconnectingEmail(connectedEmailId);
                    setErrors(undefined);
                    await AppManager.org.emailSystem.apiDisconnectEmail(connectedEmailId);
                    setConnectedEmails((r) => r.filter((t) => t._id !== connectedEmailId));
                    AppManager.alert.msgSuccess("Email disconnected");
                    setOpenDrawer(false);
                } catch (error) {
                    AppManager.alert.toastError(`${error}`);
                } finally {
                    setDisconnectingEmail(undefined);
                }
            }
        });
    };

    const handleSetDefaultConnectedEmail = async (connectedEmailId: string) => {
        try {
            await AppManager.org.emailSystem.apiSetDefault(connectedEmailId);
            setConnectedEmails((r) => r.map((t) => ({ ...t, isDefault: t._id === connectedEmailId })));
            AppManager.alert.msgSuccess("Email set as default");
        } catch (error) {
            AppManager.alert.toastError(`${error}`);
        }
    };

    useEffect(() => {
        setLoading(true);
        AppManager.org.emailSystem
            .apiGetConnectedEmails()
            .then(setConnectedEmails)
            .catch(AppManager.alert.toastError)
            .finally(() => setLoading(false));
    }, []);

    const accountTypeOptions = useMemo(() => {
        return [
            { label: "Office365", value: "office365" },
            { label: "Yahoo!", value: "yahoo" },
            { label: "Outlook/Hotmail", value: "outlook" },
            { label: "Custom...", value: "" },
        ];
    }, []);

    return (
        <AppContent
            header="Connected Emails Manager"
            showNetworkState={false}
            containerRef={containerRef}
            headerActions={
                <>
                    <Button style={{ marginRight: 20 }} type="primary" onClick={() => setOpenDrawer(true)}>
                        Connect New Email
                    </Button>
                    <Button danger type="link" style={{ marginRight: 15 }} onClick={onClose} icon={<CloseIcon />} size="small">
                        Close
                    </Button>
                </>
            }
        >
            <div className={Styles.ViewContainer}>
                <div className={Styles.InnerWrapper}>
                    {!!connectedEmails?.length ? (
                        <Spin spinning={loading} tip="Loading...">
                            <div className={Styles.ConnectedEmails}>
                                <div className={Styles.Header}>All Connected Emails</div>
                                {connectedEmails?.map((item) => (
                                    <div className={Styles.EmailItem} key={item.smtpUser}>
                                        <div className={Styles.Name}>
                                            <MailIcon style={{ marginRight: 10 }} /> {item.smtpUser}
                                            {item.isDefault && (
                                                <span className={Styles.DefaultTag}>
                                                    <StarFilled /> Default
                                                </span>
                                            )}
                                            {!item.isDefault && (
                                                <span className={Styles.DefaultTrigger} onClick={() => handleSetDefaultConnectedEmail(item._id)}>
                                                    <StarOutlined /> Set Default
                                                </span>
                                            )}
                                        </div>
                                        <div>
                                            <Button
                                                danger
                                                size="small"
                                                loading={disconnectingEmail === item._id}
                                                disabled={!!disconnectingEmail}
                                                onClick={() => handleDisconnectEmail(item._id)}
                                            >
                                                Disconnect
                                            </Button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </Spin>
                    ) : (
                        <PagePlaceholder image="" title="" desc="You haven't connected any emails yet" />
                    )}
                </div>
            </div>
            <Drawer closable={false} open={openDrawer} onClose={() => setOpenDrawer(false)} width={650}>
                {errors && (
                    <div style={{ marginBottom: 20 }}>
                        <Alert message={errors} type="error" showIcon closable />
                    </div>
                )}
                <Divider className={Styles.Divider}>Email Account Type</Divider>
                <Select options={accountTypeOptions} value={accountType} onChange={(val) => setAccountType(val)} style={{ marginBottom: 20 }} />

                {!accountType ? (
                    <CustomAccountForm
                        onSubmit={handleConnectEmail}
                        submitting={connecting}
                        initialValues={{ tls: true, smtpSecure: true, imapPort: 993, smtpPort: 465 }}
                    />
                ) : (
                    <KnownAccountTypeForm
                        onSubmit={handleConnectEmail}
                        submitting={connecting}
                        initialValues={{}}
                        name={accountTypeOptions.find((t) => t.value == accountType)?.label || ""}
                    />
                )}
            </Drawer>
        </AppContent>
    );
};

export const openEmailConnector = (onDone?: () => void) => {
    const modal = Modal.info({
        content: (
            <Provider store={getAppStore()}>
                <div style={{ height: window.innerHeight - 130 }}>
                    <EmailConnectorView
                        onClose={() => {
                            modal.destroy();
                            onDone?.();
                        }}
                    />
                </div>
            </Provider>
        ),
        icon: null,
        okButtonProps: { style: { display: "none" } },
        width: window.innerWidth - 50,
        centered: true,
    });

    return modal;
};
