import { Project } from "../../../models/Task/Project";
import { ProjectTemplate } from "../../../models/Task/ProjectTemplate";
import { TProjectCatg } from "../../../models/Task/Types";
import createReducer from "../../utils/createReducer";
import ActionTypes from "./actionTypes";

export type SubscriptionStateT = {
    currentPlan: any;
    loadingCurrentPlan: boolean;

    customerInfo: any;
    loadingCustomerInfo: boolean;

    paymentMethods: any[];
    loadingPaymentMethods: boolean;

    billingHistory: any[];
    loadingBillingHistory: boolean;
};

export const initialState: SubscriptionStateT = {
    currentPlan: null,
    loadingCurrentPlan: false,

    customerInfo: null,
    loadingCustomerInfo: false,

    paymentMethods: [],
    loadingPaymentMethods: false,

    billingHistory: [],
    loadingBillingHistory: false,
};

// App Reducer
export default createReducer<SubscriptionStateT>(initialState, {
    [ActionTypes.SAVE_CURRENT_PLAN]: (state, payload: any) => ({
        ...state,
        currentPlan: payload,
    }),
    [ActionTypes.LOADING_CURRENT_PLAN]: (state, payload: boolean) => ({
        ...state,
        loadingCurrentPlan: payload,
    }),

    [ActionTypes.SAVE_CUSTOMER_INFO]: (state, payload: any) => ({
        ...state,
        customerInfo: payload,
    }),
    [ActionTypes.LOADING_CUSTOMER_INFO]: (state, payload: boolean) => ({
        ...state,
        loadingCustomerInfo: payload,
    }),

    [ActionTypes.SAVE_PAYMENT_METHODS]: (state, payload: any) => ({
        ...state,
        paymentMethods: payload,
    }),
    [ActionTypes.LOADING_PAYMENT_METHODS]: (state, payload: boolean) => ({
        ...state,
        loadingPaymentMethods: payload,
    }),

    [ActionTypes.LOADING_BILLING_HISTORY]: (state, payload: boolean) => ({
        ...state,
        loadingBillingHistory: payload,
    }),
    [ActionTypes.SAVE_BILLING_HISTORY]: (state, payload: any) => ({
        ...state,
        billingHistory: payload,
    }),

    [ActionTypes.LOGOUT_ACTION]: (state) => ({
        ...state,
        ...initialState,
    }),
});
