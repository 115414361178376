import React, { useEffect, useState } from "react";
import Menu from "antd/lib/menu";
import Badge from "antd/lib/badge";
import classNames from "classnames";
import DashboardIcon from "@ant-design/icons/HomeOutlined";
import ContactIcon from "@ant-design/icons/ContactsOutlined";
import ProjectIcon from "@ant-design/icons/ProjectOutlined";
import DiaryIcon from "@ant-design/icons/FieldTimeOutlined";
import DocumentIcon from "@ant-design/icons/FileAddOutlined";
import ServiceFeesIcon from "@ant-design/icons/WalletOutlined";
import SettingsIcon from "@ant-design/icons/SettingOutlined";

import Styles from "./Sidebar.scss";
import * as Routes from "../../constants/appRoutes";
import { AppManager } from "../../manager";
import { useModelEventWatcher } from "../../hooks/modelHook";
import { useLocation } from "react-router-dom";
import { FileProtectOutlined } from "@ant-design/icons";

type TMenuKey = "dashboard" | "contacts" | "diary" | "tasks_projects" | "documents" | "services_fees" | "settings" | "soa";

type TMenuItem = {
    label: string;
    icon?: React.ReactNode;
    route: string;
    key: TMenuKey;
    hidden?: boolean;
    disabled?: boolean;
};

/**
 * Props for the {@link Sidebar} component
 */
export type SidebarProps = {
    /**
     * Sidebar menu items
     */
    menuItems: TMenuItem[];

    /**
     * Menu items to be hidden
     */
    hiddenItems?: TMenuKey[];

    /**
     * Menu items to disable
     */
    disabledItems?: TMenuKey[];

    /**
     * Badge counts to be displayed at the side of each menu item. (e.g number of messages etc)
     */
    itemBadeCounts?: { menu: TMenuKey; count: number }[];

    /**
     * Whether or not the sidebar is in a `collapsed` or `expanded` state
     */
    isCollapsed: boolean;

    dummySidebar?: boolean;

    /**
     * Called when an item is clicked. Returning `false` from this callback prevents
     * the default action of routing to the clicked menu screen.
     */
    onItemClick?: (menuItem: TMenuItem) => boolean;
};

/**
 * Props for the {@link DefaultAppSidebar} component
 */
export type DefaultAppSidebarProps = {
    hiddenItems?: TMenuKey[];
    disabledItems?: TMenuKey[];
    itemBadeCounts?: { menu: TMenuKey; count: number }[];
    isCollapsed: boolean;
    dummySidebar?: boolean;
    onItemClick?: (menuItem: TMenuItem) => boolean;
};

const DEFAULT_SIDEBAR_ITEMS: TMenuItem[] = [
    {
        key: "dashboard",
        label: "Dashboard",
        icon: <DashboardIcon />,
        route: Routes.DASHBOARD_ROUTE,
    },
    {
        key: "contacts",
        label: "Contacts",
        icon: <ContactIcon />,
        route: Routes.CONTACTS_ROUTE,
    },
    {
        key: "diary",
        label: "Diary",
        icon: <DiaryIcon />,
        route: Routes.DIARY_ROUTE,
    },
    {
        key: "tasks_projects",
        label: "Tasks & Projects",
        icon: <ProjectIcon />,
        route: Routes.TASKS_ROUTE,
    },
    {
        key: "documents",
        label: "Documents",
        icon: <DocumentIcon />,
        route: Routes.DOCUMENTS_ROUTE,
    },
    {
        key: "soa",
        label: "St..mt of Advice",
        icon: <FileProtectOutlined />,
        route: Routes.STATEMENT_OF_ADVICE_ROUTE,
    },
    {
        key: "services_fees",
        label: "Services Fees",
        icon: <ServiceFeesIcon />,
        route: Routes.SERVICES_FEES_ROUTE,
    },
    {
        key: "settings",
        label: "Settings",
        icon: <SettingsIcon />,
        route: Routes.SETTINGS_ROUTE,
    },
];

/**
 * Renders the sidebar using the provided menu items.
 *
 * ### Usage
 * ```js
 * <Sidebar
 *   menuItems={[...]}
 *   hiddenItems={[...]}
 *   disabledItems={[...]}
 *   itemBadeCounts={[...]}
 *   onItemClick={(item)=>{...}}
 *   />
 * ```
 *
 * ### Props
 * {@link SidebarProps}
 */
export const Sidebar: React.FC<SidebarProps> = ({
    menuItems,
    hiddenItems = [],
    itemBadeCounts = [],
    disabledItems = [],
    isCollapsed,
    dummySidebar,
    onItemClick,
}) => {
    const [activeItemKey, setActiveItemKey] = useState<TMenuKey>();
    const org = AppManager.org.getCurrentActiveOrg();
    const location = useLocation();
    const [orgAttr] = useModelEventWatcher(org, "ATTRIBUTES_UPDATED", org?.data.attrs);
    const handleItemClick = (item: TMenuItem) => {
        if (item.key === activeItemKey) return;
        if (onItemClick) {
            // returning false in onItemClick function should prevent default behaviour
            // of pushing to the provided route.
            const shouldContinueAction = onItemClick(item);
            if (shouldContinueAction && item.route) {
                AppManager.route.goto(item.route);
            }
        } else if (item.route) AppManager.route.goto(item.route);
    };
    useEffect(() => {
        const activeRoute = AppManager.route.CURRENT_ROUTE;
        const item = menuItems.find((t) => activeRoute.startsWith(t.route));
        if (item) setActiveItemKey(item.key);
    }, [location.pathname]);
    return (
        <div className={Styles.Container}>
            <div className={Styles.MenuContainer}>
                {!isCollapsed && (
                    <div title={`Current Organization: ${orgAttr?.name}`} className={Styles.OrgName}>
                        {orgAttr?.name || ""}
                    </div>
                )}
                <Menu theme="dark" selectable activeKey={activeItemKey} selectedKeys={dummySidebar ? undefined : [activeItemKey || ""]}>
                    {menuItems
                        .filter((t) => !t.hidden)
                        .filter((t) => !hiddenItems.includes(t.key))
                        .map((item) =>
                            dummySidebar ? (
                                <Menu.Item className={Styles.DummyMenuItem} key={item.key}>
                                    <div className={Styles.Inner} />
                                </Menu.Item>
                            ) : (
                                <Menu.Item
                                    key={item.key}
                                    icon={
                                        <span
                                            className={classNames([
                                                Styles.ItemIcon,
                                                {
                                                    [Styles.HasBadge]: isCollapsed && !!itemBadeCounts.find((t) => t.menu === item.key)?.count,
                                                },
                                            ])}
                                        >
                                            {item.icon}
                                        </span>
                                    }
                                    onClick={() => handleItemClick(item)}
                                    disabled={!!item.disabled || disabledItems.includes(item.key)}
                                >
                                    {item.label}
                                    {!!itemBadeCounts.find((t) => t.menu === item.key)?.count && (
                                        <div className={Styles.MenuItemBadge}>
                                            <Badge count={itemBadeCounts.find((t) => t.menu === item.key)?.count} size="small" />
                                        </div>
                                    )}
                                </Menu.Item>
                            ),
                        )}
                </Menu>
            </div>
            <div className={Styles.BottomMenuContainer}></div>
        </div>
    );
};

/**
 * Renders the sidebar with a default set of menu options.
 * These are the menu options that most routes need by default.
 *
 * ### Usage
 * ```js
 * <DefaultAppSidebar
 *   isCollapsed={false}
 *   hiddenItems={[...]}
 *   disabledItems={[...]}
 *   itemBadeCounts={[...]}
 *   onItemClick={(item)=>{...}}
 * />
 * ```
 *
 * ### Props
 * {@link DefaultAppSidebarProps}
 */
export const AppSidebar: React.FC<DefaultAppSidebarProps> = ({
    isCollapsed,
    hiddenItems,
    itemBadeCounts,
    disabledItems,
    dummySidebar,
    onItemClick,
}) => {
    return (
        <Sidebar
            menuItems={DEFAULT_SIDEBAR_ITEMS}
            isCollapsed={isCollapsed}
            hiddenItems={hiddenItems}
            disabledItems={disabledItems}
            itemBadeCounts={itemBadeCounts}
            dummySidebar={dummySidebar}
            onItemClick={onItemClick}
        />
    );
};

export default Sidebar;
