import ArrowRightIcon from "@ant-design/icons/ArrowRightOutlined";
import LockIcon from "@ant-design/icons/LockOutlined";
import { Alert, Button, Form, Input, Select, Spin } from "antd";
import React, { useState } from "react";
import { AppManager } from "../../../../manager";
import Styles from "./Home.scss";

const LoginStep: React.FC<{ onDone: (creds: any) => void; iressURI?: string }> = ({ onDone, iressURI }) => {
    const [startingMigration, setStartingMigration] = useState(false);
    const [error, setError] = useState("");
    const handleStartMigration = (data: any) => {
        setStartingMigration(true);
        data.iressURI = "https://modeloffice.xplan.iress.com.au";
        AppManager.ausplanApps.xplanMigrator.apiStartMigration(data, (err, resp) => {
            setStartingMigration(false);
            if (err) {
                AppManager.alert.toastError(`${err}`);
                setError(`${err}`);
            } else if (resp?.isCredentialsWrong) {
                AppManager.alert.msgError("Invalid credentials");
                setError("Could not login at this time. Please verify your credentials and try again");
            } else {
                AppManager.alert.msgSuccess("Login success! The migration has begun");
                onDone(resp);
            }
        });
    };
    return (
        <div className={Styles.LoginContainer}>
            <div className={Styles.LoginBox}>
                <div className={Styles.LockIndicator}>
                    <div className={Styles.Wrapper}>
                        <LockIcon />
                    </div>
                </div>
                {error && <Alert type="error" message={error} closable onClose={() => setError("")} style={{ marginBottom: 15 }} />}
                <Spin spinning={startingMigration} tip="Initiating Migration..This might take a while...">
                    <Form layout="vertical" onFinish={handleStartMigration} initialValues={{ iressURI }}>
                        <Form.Item name="username" label="Iress Username" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item name="password" label="Password" rules={[{ required: true }]}>
                            <Input.Password />
                        </Form.Item>
                        <div style={{ textAlign: "center", marginTop: 20 }}>
                            <Button size="large" type="primary" htmlType="submit" loading={startingMigration} disabled={startingMigration}>
                                Start Migration
                                <ArrowRightIcon />
                            </Button>
                        </div>
                    </Form>
                </Spin>
            </div>
        </div>
    );
};

export default LoginStep;
