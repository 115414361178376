import { SETTINGS_ROUTE } from "../../constants/appRoutes";
import { AppManager } from "..";
import { IOEventTypes } from "../RealtimeManager";

export class OrgNetworkHelper {
    static networkDataReceived = <K extends keyof IOEventTypes>(evt: K, resp: any): void => {
        switch (evt) {
            case "USER_UPDATED":
                {
                    const user = (resp as IOEventTypes["USER_UPDATED"]).data;
                    AppManager.org.updateActiveOrgMemberUserAttr(user);
                }
                break;
            case "ORGANIZATION_UPDATED":
                {
                    const organization = (resp as IOEventTypes["ORGANIZATION_UPDATED"]).data;
                    AppManager.org.hydrateFullOrganization(organization, false);
                }
                break;
            case "ORG_INVITE_CREATED":
            case "ORG_INVITE_UPDATED":
            case "ORG_INVITE_DELETED":
                const invitation = (resp as IOEventTypes["ORG_INVITE_CREATED"]).data;
                AppManager.org.apiFetchOrgInvitations(invitation.organization, () => {});
                break;
        }
    };

    static onConnectionRestoredSync(currentRoute: string, currentPathName: string) {
        switch (currentRoute) {
            case SETTINGS_ROUTE:
                AppManager.org.refreshActiveOrganization();
                break;
        }
    }
}
