import { Moment } from "moment";
import { AppManager } from "..";
import { formatUrl } from "../../helpers/misc";
import { QueryResultCallback } from "../../models/Callback/Callback";
import { Company } from "../../models/Contact/Company/Company";
import { ContactFileAttrs } from "../../models/Contact/ContactFile";
import { ContactNoteAttrs } from "../../models/Contact/ContactNote";
import { ContactServiceAttrs } from "../../models/Contact/ContactService";
import { TCompanyAttrs, TContactDeletableProps } from "../../models/Contact/Types";
import { CompanyStoreActions } from "../../redux/services/contacts/actions";
import { AzureBlobStorage } from "../../redux/utils/AzureBlobStorage";
import { APIManager } from "../APIManager";
import { CustomField, CustomFieldsData } from "../../models/Contact/CustomFields";

export class CompanyManager {
    static newContactObj(data: TCompanyAttrs) {
        return new Company(data);
    }

    static newCustomFieldObj(data: CustomFieldsData) {
        return new CustomField(data);
    }

    getContact(companyId: string) {
        return CompanyStoreActions.getCompanies().find((t) => t.id === companyId);
    }

    getCustomFields(id: string) {
        return CompanyStoreActions.getCustomFieldCompany().find((t) => t.id === id);
    }

    getAllCustomFields() {
        return CompanyStoreActions.getCustomFieldCompany();
    }

    async apiFetchAllContacts(cb: QueryResultCallback<Company[]>, includeDeleted = false, includeProspects = false) {
        try {
            CompanyStoreActions.loadingCompanies(true);
            const resp = await APIManager.get(
                formatUrl(APIManager.CONTACT.COMPANY.LIST_API, {
                    includeDeleted: includeDeleted ? "1" : undefined,
                    includeProspects: includeProspects ? "1" : undefined,
                }),
            );
            const companies: Company[] = resp.data.map((t: TCompanyAttrs) => this.updateContactAttrOrAddNew(t, false));
            if (!includeDeleted && !includeProspects) {
                CompanyStoreActions.saveCompanies(companies);
            }
            CompanyStoreActions.setCompanyLoaded(true);
            cb(null, companies);

            return companies;
        } catch (err) {
            cb(`${err}`);
        } finally {
            CompanyStoreActions.loadingCompanies(false);
        }
    }

    async apiFetchContactServiceFees(
        cb: QueryResultCallback,
        contactId: string,
        startDate: Moment | null | undefined,
        endDate: Moment | null | undefined,
    ) {
        try {
            CompanyStoreActions.loadingCompanies(true);
            const resp = await APIManager.get(
                APIManager.CONTACT.COMPANY.SERVICE_FEE_API(contactId, startDate?.toISOString(), endDate?.toISOString()),
            );
            cb(null, resp?.data);
        } catch (err) {
            cb(`${err}`);
        } finally {
            CompanyStoreActions.loadingCompanies(false);
        }
    }

    async apiFetchDeletedContacts(cb: QueryResultCallback<Company[]>) {
        try {
            CompanyStoreActions.loadingCompanies(true);
            const resp = await APIManager.get(formatUrl(APIManager.CONTACT.COMPANY.LIST_API, { deletedOnly: "1" }));
            const companies: Company[] = resp.data.map((t: TCompanyAttrs) => this.updateContactAttrOrAddNew(t, false));
            CompanyStoreActions.setCompanyLoaded(true);
            cb(null, companies);

            return companies;
        } catch (err) {
            cb(`${err}`);
        } finally {
            CompanyStoreActions.loadingCompanies(false);
        }
    }

    async apiFetchProspectContacts(cb: QueryResultCallback<Company[]>) {
        try {
            CompanyStoreActions.loadingCompanies(true);
            const resp = await APIManager.get(formatUrl(APIManager.CONTACT.COMPANY.LIST_API, { prospectOnly: "1" }));
            const companies: Company[] = resp.data.map((t: TCompanyAttrs) => this.updateContactAttrOrAddNew(t, false));
            CompanyStoreActions.setCompanyLoaded(true);
            cb(null, companies);

            return companies;
        } catch (err) {
            cb(`${err}`);
        } finally {
            CompanyStoreActions.loadingCompanies(false);
        }
    }

    async apiFetchCustomFieldsContacts(cb: QueryResultCallback<CustomField[]>) {
        try {
            CompanyStoreActions.loadingCompanies(true);
            const resp = await APIManager.get(formatUrl(APIManager.CONTACT.COMPANY.LIST_CUSTOM_FIELDS_API));
            const companies: CustomField[] = resp.data.map((t: CustomFieldsData) => this.updateContactCustomFieldsAttrOrAddNew(t));
            CompanyStoreActions.setCompanyLoaded(true);
            cb(null, companies);

            return companies;
        } catch (err) {
            cb(`${err}`);
        } finally {
            CompanyStoreActions.loadingCompanies(false);
        }
    }

    async apiFetchContactAttrs(companyId: string, cb: QueryResultCallback<Company>) {
        try {
            const resp = await APIManager.get(APIManager.CONTACT.COMPANY.ITEM_API(companyId));
            const company = this.updateContactAttrOrAddNew(resp.data);
            cb(null, company);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiFetchContactNotes(companyId: string, cb: QueryResultCallback<ContactNoteAttrs[]>) {
        try {
            const resp = await APIManager.get(APIManager.CONTACT.COMPANY.NOTES_API(companyId));
            const company = this.getContact(companyId);
            if (company) {
                company.updateNotes(resp.data);
            }
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiFetchContactServices(companyId: string, cb: QueryResultCallback<ContactServiceAttrs[]>) {
        try {
            const resp = await APIManager.get(APIManager.CONTACT.COMPANY.SERVICES_API(companyId));
            const company = this.getContact(companyId);
            if (company) {
                company.updateServices(resp.data);
            }
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiFetchContactFiles(companyId: string, cb: QueryResultCallback<ContactNoteAttrs[]>) {
        try {
            const resp = await APIManager.get(APIManager.CONTACT.COMPANY.FILES_API(companyId));
            const company = this.getContact(companyId);
            if (company) {
                company.updateFiles(resp.data);
            }
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async rehydrateContact(companyId: string, cb: QueryResultCallback<Company[]>) {
        const company = this.getContact(companyId);
        const errors: string[] = [];
        const callback = (err: string | null) => {
            if (err) errors.push(err);
        };
        if (company) {
            await this.apiFetchContactAttrs(companyId, callback);
            await this.apiFetchContactNotes(companyId, callback);
            await this.apiFetchContactFiles(companyId, callback);
            await this.apiFetchContactServices(companyId, callback);
        }
        cb(errors.length ? errors.join("\n") : null);
    }

    async apiSaveContactAuto(indvData: Partial<TCompanyAttrs>, templates: string[], cb: QueryResultCallback<Company>) {
        try {
            const resp = await APIManager.post(APIManager.CONTACT.COMPANY.AUTO_REGISTER_API, { contactData: indvData, templates });
            const company: Company = CompanyManager.newContactObj(resp.data);
            CompanyStoreActions.addOrUpdateToCompanies(company);
            cb(null, company);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiSaveContact(indvData: Partial<TCompanyAttrs>, cb: QueryResultCallback<Company>) {
        try {
            const resp = await APIManager.post(APIManager.CONTACT.COMPANY.LIST_API, indvData);
            const company: Company = CompanyManager.newContactObj(resp.data);
            CompanyStoreActions.addOrUpdateToCompanies(company);
            cb(null, company);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiSaveContactNote(
        companyId: string,
        noteData: { title: string; note: string; type: string },
        cb: QueryResultCallback<ContactNoteAttrs[]>,
    ) {
        try {
            const resp = await APIManager.post(APIManager.CONTACT.COMPANY.NOTES_API(companyId), noteData);
            const company = this.getContact(companyId);
            if (company) {
                company.addOrUpdateNote(resp.data);
            }
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiSaveContactService(
        companyId: string,
        serviceData: { serviceId: string; serviceDate: string },
        cb: QueryResultCallback<ContactServiceAttrs[]>,
    ) {
        try {
            const resp = await APIManager.post(APIManager.CONTACT.COMPANY.SERVICES_API(companyId), serviceData);
            const company = this.getContact(companyId);
            if (company) {
                company.addOrUpdateService(resp.data);
            }
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiSaveContactFile(companyId: string, contactNames: string, file: File, source: string, cb: QueryResultCallback<ContactFileAttrs>) {
        const azure = AzureBlobStorage.newInstance();
        const activeOrg = AppManager.org.getCurrentActiveOrg()!;
        const blobName = `${activeOrg.id}/contactdocs/companies/${companyId}/${contactNames}`;
        azure.uploadToBlobStorage(file, blobName, async (err, respData) => {
            if (err) cb(`${err}`);
            else {
                const fileData = {
                    name: file.name,
                    type: file.type,
                    size: file.size,
                    source,
                    blobName: respData!.blobName,
                    blobUrl: respData!.blobUrl,
                };
                try {
                    const resp = await APIManager.post(APIManager.CONTACT.COMPANY.FILES_API(companyId), fileData);
                    const company = this.getContact(companyId);
                    if (company) {
                        company.addOrUpdateFile(resp.data);
                    }
                    cb(null, resp.data);
                } catch (err) {
                    azure.deleteBlobFromStorage(respData!.blobName);
                    cb(`${err}`);
                }
            }
        });
    }

    updateContactAttrOrAddNew(contactAttr: TCompanyAttrs, saveToStore = true) {
        let company = this.getContact(contactAttr._id);
        if (company) {
            company.updateCompanyAttrs(contactAttr);
        } else {
            company = CompanyManager.newContactObj(contactAttr);
        }
        if (saveToStore) CompanyStoreActions.addOrUpdateToCompanies(company);
        return company;
    }

    updateContactCustomFieldsAttrOrAddNew(contactAttr: CustomFieldsData, saveToStore = true) {
        let customField = this.getCustomFields(contactAttr._id);
        if (customField) {
            customField.updateCustomField(contactAttr);
        } else {
            customField = CompanyManager.newCustomFieldObj(contactAttr);
        }
        if (saveToStore) CompanyStoreActions.addOrUpdateCustomFields(customField);
        return customField;
    }

    async apiSaveCustomField(data: Partial<CustomFieldsData>, cb: QueryResultCallback<CustomField>) {
        try {
            const resp = await APIManager.post(APIManager.CONTACT.INDIVIDUAL.CUSTOM_FIELDS_INDIVIDUAL_API, data);
            const individual: CustomField = CompanyManager.newCustomFieldObj(resp.data);
            CompanyStoreActions.addOrUpdateCustomFields(individual);
            cb(null, individual);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiUpdateCustomField(customFieldId: string, data: Partial<CustomFieldsData>, cb: QueryResultCallback<CustomField>) {
        try {
            const resp = await APIManager.put(APIManager.CONTACT.INDIVIDUAL.CUSTOM_FIELD_API(customFieldId), data);
            const individual: CustomField = CompanyManager.newCustomFieldObj(resp.data);
            CompanyStoreActions.addOrUpdateCustomFields(individual);
            cb(null, individual);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiDeleteCustomField(customFieldId: string, cb: QueryResultCallback<CustomFieldsData>) {
        try {
            const resp = await APIManager.delete(APIManager.CONTACT.INDIVIDUAL.CUSTOM_FIELD_API(customFieldId));
            const company = this.getCustomFields(customFieldId);

            CompanyStoreActions.removeCustomFieldsCompany(company!);
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiUpdateContactNote(
        companyId: string,
        noteId: string,
        noteData: { title?: string; note?: string; type?: string },
        cb: QueryResultCallback<ContactNoteAttrs[]>,
    ) {
        try {
            const resp = await APIManager.put(APIManager.CONTACT.COMPANY.NOTE_ITEM_API(companyId, noteId), noteData);
            const company = this.getContact(companyId);
            if (company) {
                console.log("NOTE UPDATED ", resp.data);
                company.addOrUpdateNote(resp.data);
            }
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiUpdateContact(companyId: string, contactData: Partial<TCompanyAttrs>, cb: QueryResultCallback<Company>) {
        try {
            const resp = await APIManager.put(APIManager.CONTACT.COMPANY.ITEM_API(companyId), contactData);
            const company = this.updateContactAttrOrAddNew(resp.data);
            cb(null, company);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiLoadFullContactNote(companyId: string, noteId: string, cb: QueryResultCallback<ContactNoteAttrs[]>) {
        try {
            const resp = await APIManager.get(APIManager.CONTACT.COMPANY.NOTE_ITEM_API(companyId, noteId));
            const company = this.getContact(companyId);
            if (company) company.addOrUpdateNote(resp.data);
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiDeleteContactNote(companyId: string, noteId: string, cb: QueryResultCallback<ContactNoteAttrs[]>) {
        try {
            const note = await APIManager.get(APIManager.CONTACT.COMPANY.NOTE_ITEM_API(companyId, noteId));
            const noteAttrs: ContactNoteAttrs = note.data;
            for (const attachment of noteAttrs.attachments) {
                await new Promise((resolve, reject) => {
                    this.apiDeleteContactFile(companyId, typeof attachment == "string" ? attachment : attachment._id, (err, d) => {
                        if (err) reject(`${err}`);
                        else resolve(d!);
                    });
                });
            }
            const resp = await APIManager.delete(APIManager.CONTACT.COMPANY.NOTE_ITEM_API(companyId, noteId));
            const company = this.getContact(companyId);
            if (company) {
                company.removeNoteWithId(noteId);
            }
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiDeleteContactService(companyId: string, serviceId: string, cb: QueryResultCallback<ContactServiceAttrs[]>) {
        try {
            const resp = await APIManager.delete(APIManager.CONTACT.COMPANY.SERVICE_ITEM_API(companyId, serviceId));
            const company = this.getContact(companyId);
            if (company) {
                company.removeServiceWithId(serviceId);
            }
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiDeleteContactFile(companyId: string, fileId: string, cb: QueryResultCallback<ContactFileAttrs>) {
        const azure = AzureBlobStorage.newInstance();
        try {
            const fileItem = await APIManager.get(APIManager.CONTACT.COMPANY.FILE_ITEM_API(companyId, fileId));
            const { blobName } = fileItem.data;
            azure.deleteBlobFromStorage(blobName, async (err) => {
                if (err) cb(`${err}`);
                else {
                    try {
                        const resp = await APIManager.delete(APIManager.CONTACT.COMPANY.FILE_ITEM_API(companyId, fileId));
                        const company = this.getContact(companyId);
                        if (company) {
                            company.removeFileWithId(fileId);
                        }
                        cb(null, resp.data);
                    } catch (err) {
                        azure.undeleteBlobFromStorage(blobName);
                        cb(`${err}`);
                    }
                }
            });
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiDeleteContact(companyId: string, cb: QueryResultCallback) {
        try {
            const resp = await APIManager.delete(APIManager.CONTACT.COMPANY.ITEM_API(companyId));
            this.removeContact(companyId);
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiRestoreContact(companyId: string, cb: QueryResultCallback) {
        try {
            const resp = await APIManager.patch(APIManager.CONTACT.COMPANY.ITEM_RESTORE_API(companyId));
            this.restoreContact(companyId);
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    restoreContact(companyId: string) {
        const company = this.getContact(companyId);
        if (company) {
            CompanyStoreActions.removeCompany(company);
        } else console.log("CONTACT WITH ID ", companyId, " NOT FOUND");
    }

    removeContact(companyId: string) {
        const company = this.getContact(companyId);
        if (company) {
            company.remove();
            CompanyStoreActions.removeCompany(company);
        } else console.log("CONTACT WITH ID ", companyId, " NOT FOUND");
    }

    removeContactCustomFieldWithId(id: string) {
        const companies = CompanyStoreActions.getCompanies();

        const newCompanies: Company[] = companies.map((comp) => {
            comp.data.attrs.customField = comp.data.attrs.customField?.filter((c) => c.template._id !== id) || [];

            return comp;
        });

        CompanyStoreActions.saveCompanies(newCompanies);
    }

    addNewContactCustomField(customField: { id: string; template: CustomFieldsData; value: string }) {
        const companies = CompanyStoreActions.getCompanies();

        const newCompanies: Company[] = companies.map((ind) => {
            ind.data.attrs.customField = [...(ind.data.attrs.customField || []), customField];

            return ind;
        });

        CompanyStoreActions.saveCompanies(newCompanies);
    }

    async apiCheckContactDeletable(companyId: string, cb: QueryResultCallback<TContactDeletableProps>) {
        try {
            const resp = await APIManager.get(APIManager.CONTACT.COMPANY.CHECK_CONTACT_DELETABLE_API(companyId));
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiSendEmail(individualId: string, emailData: { email: string; subject: string; body: string }, cb: QueryResultCallback<any>) {
        try {
            const resp = await APIManager.post(APIManager.CONTACT.COMPANY.SEND_EMAIL_API(individualId), emailData);
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }
}
