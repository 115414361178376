import { CheckOutlined } from "@ant-design/icons";
import ArrowRightIcon from "@ant-design/icons/ArrowRightOutlined";
import CheckIcon from "@ant-design/icons/CheckCircleFilled";
import DeleteIcon from "@ant-design/icons/DeleteFilled";
import LockIcon from "@ant-design/icons/LockOutlined";
import { Alert, Button, Collapse, Drawer, Form, Input, Modal, Select, Spin, Table, Tag } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import OrgMemberSelect from "../../../../components/CustomDisplays/UserDisplay/OrgMemberSelect";
import Loading from "../../../../components/Loading/Loading";
import { AppManager } from "../../../../manager";
import { ICompanyInfo, ICustomerInfo, IIndividualInfo, ITrustInfo } from "../../../../models/Apps/Migration/XPlanTypes";
import { TFullOrganizationAttr } from "../../../../models/Organization/Types";
import Styles from "./Home.scss";

const ManageIndividuals: React.FC<{ data: IIndividualInfo[]; deletingItem: string; onDelete: (item: IIndividualInfo) => void }> = ({
    data,
    onDelete,
    deletingItem,
}) => {
    return (
        <div>
            <Table
                dataSource={data}
                rowKey={(rec) => rec.pageId}
                rowClassName={(rec) => (rec.pageId === deletingItem ? Styles.DeletingRecordItem : "")}
                columns={[
                    { title: "#Id", render: (_, rec) => <b>{rec.pageId}</b> },
                    { title: "First Name", render: (_, rec) => rec.keyDetails?.firstName || "--" },
                    { title: "Surname", render: (_, rec) => rec.keyDetails?.surname || "--" },
                    { title: "Preferred Name", render: (_, rec) => rec.keyDetails?.preferredName || "--" },
                    { title: "State", render: (_, rec) => rec.contactDetails?.addressObject?.state || "--" },
                    { title: "Address", render: (_, rec) => rec.contactDetails?.addressObject?.street || "--" },
                    { title: "Category", render: (_, rec) => rec.keyDetails?.category || "--" },
                    {
                        title: "",
                        width: 75,
                        render: (_, rec) =>
                            rec.migrated ? (
                                <Tag color="green">migrated</Tag>
                            ) : !rec.fullyScrapped ? (
                                <Tag color="red">uncompleted</Tag>
                            ) : (
                                <Button
                                    icon={<DeleteIcon />}
                                    type="primary"
                                    danger
                                    size="small"
                                    onClick={() => onDelete(rec)}
                                    disabled={deletingItem == rec.pageId}
                                    loading={deletingItem == rec.pageId}
                                />
                            ),
                    },
                ]}
                size="small"
                pagination={{ defaultPageSize: 15 }}
            />
        </div>
    );
};

const ManageCompanies: React.FC<{ data: ICompanyInfo[]; deletingItem: string; onDelete: (item: ICompanyInfo) => void }> = ({
    data,
    deletingItem,
    onDelete,
}) => {
    return (
        <div>
            <Table
                dataSource={data}
                rowKey={(rec) => rec.pageId}
                rowClassName={(rec) => (rec.pageId === deletingItem ? Styles.DeletingRecordItem : "")}
                columns={[
                    { title: "#Id", render: (_, rec) => <b>{rec.pageId}</b> },
                    { title: "Company Name", render: (_, rec) => rec.keyDetails?.keyDetailsSection?.companyName || "--" },
                    { title: "State", render: (_, rec) => rec.contactDetails?.addressObject?.state || "--" },
                    { title: "Address", render: (_, rec) => rec.contactDetails?.addressObject?.street || "--" },
                    {
                        title: "",
                        width: 50,
                        render: (_, rec) =>
                            rec.migrated ? (
                                <Tag color="green">migrated</Tag>
                            ) : !rec.fullyScrapped ? (
                                <Tag color="red">uncompleted</Tag>
                            ) : (
                                <Button
                                    icon={<DeleteIcon />}
                                    type="primary"
                                    danger
                                    size="small"
                                    onClick={() => onDelete(rec)}
                                    disabled={deletingItem == rec.pageId}
                                    loading={deletingItem == rec.pageId}
                                />
                            ),
                    },
                ]}
                size="small"
                pagination={{ defaultPageSize: 15 }}
            />
        </div>
    );
};

const ManageTrusts: React.FC<{ data: ITrustInfo[]; deletingItem: string; onDelete: (item: ITrustInfo) => void }> = ({
    data,
    onDelete,
    deletingItem,
}) => {
    return (
        <div>
            <Table
                dataSource={data}
                rowKey={(rec) => rec.pageId}
                rowClassName={(rec) => (rec.pageId === deletingItem ? Styles.DeletingRecordItem : "")}
                columns={[
                    { title: "#Id", render: (_, rec) => <b>{rec.pageId}</b> },
                    { title: "Trust Name", render: (_, rec) => rec.keyDetails?.keyDetailsSection?.trustName || "--" },
                    { title: "State", render: (_, rec) => rec.contactDetails?.addressObject?.state || "--" },
                    { title: "Address", render: (_, rec) => rec.contactDetails?.addressObject?.street || "--" },
                    {
                        title: "",
                        width: 50,
                        render: (_, rec) =>
                            rec.migrated ? (
                                <Tag color="green">migrated</Tag>
                            ) : !rec.fullyScrapped ? (
                                <Tag color="red">uncompleted</Tag>
                            ) : (
                                <Button
                                    icon={<DeleteIcon />}
                                    type="primary"
                                    danger
                                    size="small"
                                    onClick={() => onDelete(rec)}
                                    disabled={deletingItem == rec.pageId}
                                    loading={deletingItem == rec.pageId}
                                />
                            ),
                    },
                ]}
                size="small"
                pagination={{ defaultPageSize: 15 }}
            />
        </div>
    );
};

const ManageReferenceMappings: React.FC<{
    individuals: IIndividualInfo[];
    customer: ICustomerInfo;
    onAdviserMapping: (mappings: any) => void;
    onSRVLevelMapping: (mapping: any) => void;
}> = ({ individuals, customer, onAdviserMapping, onSRVLevelMapping }) => {
    const [foundAdvisers, setFoundAdvisers] = useState<string[]>([]);
    const [foundServiceLevels, setFoundServiceLevels] = useState<string[]>([]);
    const [adviserMappings, setAdviserMappings] = useState<{ [k: string]: string }>(customer.postMigrationMappings?.advisers || {});
    const [srvLevelMappings, setSrvLevelMappings] = useState<{ [k: string]: string }>(customer.postMigrationMappings?.serviceLevels || {});
    const [organizationInfo, setOrganizationInfo] = useState<TFullOrganizationAttr>();
    const [fetchingOrgInfo, setFetchingOrgInfo] = useState<boolean>(false);

    useEffect(() => {
        console.log("INDIVIDUALS ", individuals);
        setFoundAdvisers([...new Set(individuals.filter((r) => r.keyDetails?.adviser).map((r) => r.keyDetails?.adviser))] as string[]);
        setFoundServiceLevels([...new Set(individuals.filter((r) => r.keyDetails?.category).map((r) => r.keyDetails?.category))] as string[]);

        setFetchingOrgInfo(true);

        AppManager.org.apiFetchAllOrgInfo(customer.ausplanUserId, (err, data) => {
            setFetchingOrgInfo(false);
            if (err) {
                AppManager.alert.error("Error", `${err}`);
            } else setOrganizationInfo(data);
        });
    }, [individuals]);

    useEffect(() => {
        onAdviserMapping(adviserMappings);
    }, [adviserMappings]);

    useEffect(() => {
        onSRVLevelMapping(srvLevelMappings);
    }, [srvLevelMappings]);

    useEffect(() => {
        setAdviserMappings((r) => ({ ...(customer.postMigrationMappings?.advisers || {}), ...r }));
        setSrvLevelMappings((r) => ({ ...(customer.postMigrationMappings?.serviceLevels || {}), ...r }));
    }, [customer]);

    const alreadyMappedAdvisers = Object.keys(customer.postMigrationMappings?.advisers || {}).filter(
        (t) => customer.postMigrationMappings?.advisers?.[t],
    );
    const alreadyMappedSrvLevels = Object.keys(customer.postMigrationMappings?.serviceLevels || {}).filter(
        (t) => customer.postMigrationMappings?.serviceLevels?.[t],
    );

    return (
        <div className={Styles.ReferenceMappingContainer}>
            {fetchingOrgInfo && <Loading />}
            {organizationInfo && (
                <div className={Styles.ItemContainer}>
                    <div className={Styles.Heading}>Advisers</div>
                    <div className={Styles.Content}>
                        {foundAdvisers
                            .filter((r) => !alreadyMappedAdvisers.includes(r))
                            .map((item, ind) => (
                                <div key={ind} className={Styles.MapItemWrapper}>
                                    <div className={Styles.Name}>{item}</div>
                                    <ArrowRightIcon className={Styles.ArrowRight} />
                                    <div className={Styles.SelectContainer}>
                                        <OrgMemberSelect
                                            showType
                                            onChange={(val) => setAdviserMappings((r) => ({ ...r, [item]: val }))}
                                            placeholder="Select a matching adviser"
                                        />
                                    </div>
                                </div>
                            ))}
                        {!foundAdvisers.filter((r) => !alreadyMappedAdvisers.includes(r)).length && "No advisers to be mapped"}
                    </div>
                </div>
            )}
            {organizationInfo && !!foundServiceLevels.length && (
                <div className={Styles.ItemContainer}>
                    <div className={Styles.Heading}>Service Levels</div>
                    <div className={Styles.Content}>
                        {foundServiceLevels
                            .filter((r) => !alreadyMappedSrvLevels.includes(r))
                            .map((item, ind) => (
                                <div key={ind} className={Styles.MapItemWrapper}>
                                    <div className={Styles.Name}>{item}</div>
                                    <ArrowRightIcon className={Styles.ArrowRight} />
                                    <div className={Styles.SelectContainer}>
                                        <Select<string>
                                            options={organizationInfo.contactServiceLevels.map((item) => ({ label: item.name, value: item.name }))}
                                            onChange={(val) => setSrvLevelMappings((r) => ({ ...r, [item]: val }))}
                                            placeholder="Select a matching service level"
                                        />
                                    </div>
                                </div>
                            ))}
                        {!foundServiceLevels.filter((r) => !alreadyMappedSrvLevels.includes(r)).length && "No service levels to be mapped"}
                    </div>
                </div>
            )}
        </div>
    );
};

export const PerformMappingsDrawer: React.FC<{ customer?: ICustomerInfo; show: boolean; onToggleShow: (s: boolean) => void }> = ({
    customer,
    show,
    onToggleShow,
}) => {
    const [individuals, setIndividuals] = useState<IIndividualInfo[]>([]);
    const [loading, setLoading] = useState(false);
    const [finalizing, setFinalizing] = useState(false);
    const [adviserMappings, setAdviserMappings] = useState(false);
    const [serviceLevelMappings, setSrvLevelMappings] = useState(false);
    const handleSaveAndContinue = () => {
        setFinalizing(true);
        AppManager.ausplanApps.xplanMigrator.apiFinalizeMigration(customer!.ausplanUserId, { adviserMappings, serviceLevelMappings }, (err, resp) => {
            setFinalizing(false);
            if (err) {
                Modal.error({ title: "Failed to finalize migration", content: `${err}` });
            } else {
                AppManager.alert.msgSuccess("Migration Successful!");
                onToggleShow(false);
            }
        });
    };
    useEffect(() => {
        if (show && customer) {
            setLoading(true);
            AppManager.ausplanApps.xplanMigrator.apiFetchIndividuals(customer.ausplanUserId, null, (err, res) => {
                setLoading(false);
                if (err) AppManager.alert.toastError(`${err}`);
                else setIndividuals(res!);
            });
        }
    }, [show]);
    return (
        <Drawer
            title="Mapping Actions"
            onClose={() => onToggleShow(false)}
            visible={show}
            width={600}
            footer={
                <div style={{ textAlign: "center" }}>
                    <Button
                        disabled={loading || finalizing}
                        loading={finalizing}
                        type="primary"
                        onClick={handleSaveAndContinue}
                        icon={<CheckOutlined />}
                    >
                        Save and Continue
                    </Button>
                </div>
            }
        >
            {(loading || finalizing) && <Loading text="..." />}
            {customer && !loading && (
                <ManageReferenceMappings
                    customer={customer}
                    individuals={individuals}
                    onAdviserMapping={setAdviserMappings}
                    onSRVLevelMapping={setSrvLevelMappings}
                />
            )}
        </Drawer>
    );
};

const FinalizeMigrationStep: React.FC<{ onDone: () => void; customer: ICustomerInfo }> = ({ onDone, customer }) => {
    const [finalizing, setFinalizing] = useState(false);
    const [loadingIndividuals, setLoadingIndividuals] = useState(false);
    const [loadingCompanies, setLoadingCompanies] = useState(false);
    const [loadingTrusts, setLoadingTrusts] = useState(false);
    const [deletingIndividual, setDeletingIndividual] = useState("");
    const [deletingCompany, setDeletingCompany] = useState("");
    const [deletingTrust, setDeletingTrust] = useState("");
    const [individuals, setIndividuals] = useState<IIndividualInfo[]>([]);
    const [companies, setCompanies] = useState<ICompanyInfo[]>([]);
    const [trusts, setTrusts] = useState<ITrustInfo[]>([]);
    const [error, setError] = useState("");
    const [adviserMappings, setAdviserMappings] = useState<{ [k: string]: string }>({});
    const [serviceLevelMappings, setSrvLevelMappings] = useState<{ [k: string]: string }>({});

    const migrationComplete = useMemo(() => {
        const items = [...trusts, ...companies, ...individuals];
        return !!items.length && items.every((r) => r.migrated);
    }, [trusts, companies, individuals]);

    const handleFinalizeMigration = () => {
        setFinalizing(true);
        AppManager.ausplanApps.xplanMigrator.apiFinalizeMigration(customer.ausplanUserId, { adviserMappings, serviceLevelMappings }, (err, resp) => {
            setFinalizing(false);
            if (err) {
                AppManager.alert.error("Error", `${err}`);
                setError(`${err}`);
            } else {
                AppManager.alert.msgSuccess("Migration Successful!");
                onDone();
            }
        });
    };

    const handleDeleteIndividual = (item: IIndividualInfo) => {
        AppManager.alert.confirmAction(
            "Confirm Delete Individual",
            `Do you want to remove the individual item "#${item.pageId}" from the migration list?`,
            (res) => {
                if (res === "OK") {
                    setDeletingIndividual(item.pageId);
                    AppManager.ausplanApps.xplanMigrator.apiDeleteIndividual(customer.ausplanUserId, item.pageId, (err) => {
                        setDeletingIndividual("");
                        if (err) AppManager.alert.toastError(`${err}`);
                        else {
                            setIndividuals((indv) => indv.filter((r) => r.pageId !== item.pageId));
                            AppManager.alert.msgSuccess("Individual item deleted");
                        }
                    });
                }
            },
        );
    };

    const handleDeleteCompany = (item: ICompanyInfo) => {
        AppManager.alert.confirmAction(
            "Confirm Delete Company",
            `Do you want to remove the company item "#${item.pageId}" from the migration list?`,
            (res) => {
                if (res === "OK") {
                    setDeletingCompany(item.pageId);
                    AppManager.ausplanApps.xplanMigrator.apiDeleteCompany(customer.ausplanUserId, item.pageId, (err) => {
                        setDeletingCompany("");
                        if (err) AppManager.alert.toastError(`${err}`);
                        else {
                            setCompanies((comps) => comps.filter((r) => r.pageId !== item.pageId));
                            AppManager.alert.msgSuccess("Company item deleted");
                        }
                    });
                }
            },
        );
    };

    const handleDeleteTrust = (item: ITrustInfo) => {
        AppManager.alert.confirmAction(
            "Confirm Delete Trust",
            `Do you want to remove the trust item "#${item.pageId}" from the migration list?`,
            (res) => {
                if (res === "OK") {
                    setDeletingTrust(item.pageId);
                    AppManager.ausplanApps.xplanMigrator.apiDeleteTrust(customer.ausplanUserId, item.pageId, (err) => {
                        setDeletingTrust("");
                        if (err) AppManager.alert.toastError(`${err}`);
                        else {
                            setTrusts((trusts) => trusts.filter((r) => r.pageId !== item.pageId));
                            AppManager.alert.msgSuccess("Trust item deleted");
                        }
                    });
                }
            },
        );
    };

    useEffect(() => {
        setLoadingIndividuals(true);
        AppManager.ausplanApps.xplanMigrator.apiFetchIndividuals(customer.ausplanUserId, null, (err, res) => {
            setLoadingIndividuals(false);
            if (err) AppManager.alert.toastError(`${err}`);
            else setIndividuals(res!);
        });

        setLoadingCompanies(true);
        AppManager.ausplanApps.xplanMigrator.apiFetchCompanies(customer.ausplanUserId, null, (err, res) => {
            setLoadingCompanies(false);
            if (err) AppManager.alert.toastError(`${err}`);
            else setCompanies(res!);
        });

        setLoadingTrusts(true);
        AppManager.ausplanApps.xplanMigrator.apiFetchTrusts(customer.ausplanUserId, null, (err, res) => {
            setLoadingTrusts(false);
            if (err) AppManager.alert.toastError(`${err}`);
            else setTrusts(res!);
        });
    }, []);
    const loadingItems = loadingIndividuals || loadingCompanies || loadingTrusts;
    return (
        <Spin spinning={finalizing} tip="Finalizing migration...">
            <div className={Styles.FinalizeContainer}>
                <div className={Styles.Title}>
                    <div className={Styles.ActionContainer}>
                        <div className={Styles.Info}>
                            {loadingItems ? "Loading" : migrationComplete ? "Everything went well" : "Some items are not fully migrated"}
                        </div>
                        <Button
                            disabled={loadingItems}
                            size="large"
                            icon={<CheckIcon />}
                            type="primary"
                            onClick={handleFinalizeMigration}
                            loading={finalizing}
                        >
                            Complete Migration
                        </Button>
                    </div>
                    {/* <div className={Styles.Description}>
                        <Alert type="info" showIcon closable message={<span>All your data have been moved to ausplan.</span>} />
                    </div> */}
                </div>
                {/* <Collapse defaultActiveKey={["mappings"]} style={{ marginBottom: 30 }}>
                    <Collapse.Panel
                        key="mappings"
                        header={<h3 style={{ display: "inline-block" }}>Reference Mappings</h3>}
                        style={{ position: "relative" }}
                    >
                        <div style={{ minHeight: 150 }}>
                            {loadingIndividuals ? (
                                <Loading text=" " />
                            ) : (
                                <ManageReferenceMappings
                                    individuals={individuals}
                                    customer={customer}
                                    onAdviserMapping={setAdviserMappings}
                                    onSRVLevelMapping={setSrvLevelMappings}
                                />
                            )}
                        </div>
                    </Collapse.Panel>
                </Collapse> */}
                <Collapse defaultActiveKey={["individuals"]} style={{ marginBottom: 30 }}>
                    <Collapse.Panel
                        key="individuals"
                        header={<h3 style={{ display: "inline-block" }}>Fetched Individuals {!loadingIndividuals && `(${individuals.length})`}</h3>}
                        style={{ position: "relative" }}
                    >
                        <div style={{ minHeight: 150 }}>
                            {loadingIndividuals ? (
                                <Loading text=" " />
                            ) : (
                                <ManageIndividuals data={individuals} onDelete={handleDeleteIndividual} deletingItem={deletingIndividual} />
                            )}
                        </div>
                    </Collapse.Panel>
                </Collapse>
                <Collapse defaultActiveKey={["companies"]} style={{ marginBottom: 30 }}>
                    <Collapse.Panel
                        key="companies"
                        header={<h3 style={{ display: "inline-block" }}>Fetched Companies {!loadingCompanies && `(${companies.length})`}</h3>}
                        style={{ position: "relative" }}
                    >
                        <div style={{ minHeight: 150 }}>
                            {loadingCompanies ? (
                                <Loading text=" " />
                            ) : (
                                <ManageCompanies data={companies} onDelete={handleDeleteCompany} deletingItem={deletingCompany} />
                            )}
                        </div>
                    </Collapse.Panel>
                </Collapse>
                <Collapse defaultActiveKey={["trusts"]}>
                    <Collapse.Panel
                        key="trusts"
                        header={<h3 style={{ display: "inline-block" }}>Fetched Trusts {!loadingTrusts && `(${trusts.length})`}</h3>}
                        style={{ position: "relative" }}
                    >
                        <div style={{ minHeight: 150 }}>
                            {loadingTrusts ? (
                                <Loading text=" " />
                            ) : (
                                <ManageTrusts data={trusts} onDelete={handleDeleteTrust} deletingItem={deletingTrust} />
                            )}
                        </div>
                    </Collapse.Panel>
                </Collapse>
            </div>
        </Spin>
    );
};

export default FinalizeMigrationStep;
