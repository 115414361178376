import React, { useState } from "react";
import { Card, Input, Form, Button, Tooltip } from "antd";
import ArrowRightIcon from "@ant-design/icons/ArrowRightOutlined";
import Styles from "./ConnectEmailSetup.scss";
import { AppManager } from "../../../../../manager";
import emailImg from "../../../../../assets/img/connect_email_image.png";
import outlookImg from "../../../../../assets/img/o365_calendar_image.png";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/reducers";
import { Organization } from "../../../../../models/Organization/Organization";
import CheckIcon from "@ant-design/icons/CheckCircleFilled";
import { useModelEventWatcher } from "../../../../../hooks/modelHook";
import { useProvideOutlookCalContext } from "../../../../../containers/CalendarSync/OutlookCalendar/OutlookCalendarContext";
import { openEmailConnector } from "../../../Settings/GeneralSettingsPage/AppsTab/EmailConnector/EmailConnector";

const ConnectEmailSetup: React.FC<{ onNext: () => void }> = ({ onNext }) => {
    const activeOrg = useSelector<RootState, Organization>((state) => state.orgs.activeOrg!);
    const [skipping, setSkipping] = useState(false);
    // const activeUser = AppManager.user.getActiveUser();
    const o365Cal = useProvideOutlookCalContext();
    // const [orgAttr] = useModelEventWatcher(activeOrg, "ATTRIBUTES_UPDATED", activeOrg?.data.attrs);

    const canNext = /*!!orgAttr?.connectedEmails?.length ||*/ !!o365Cal.user;

    const handleSkip = () => {
        setSkipping(true);
        AppManager.org.apiAddCompletedInitialSetupItem(activeOrg.id, "connect_email", (err) => {
            setSkipping(false);
            if (err) AppManager.alert.error("Error", `${err}`);
            else onNext();
        });
    };

    const handleConnectCal = () => {
        if (!o365Cal.user) {
            o365Cal.signIn();
        } else AppManager.alert.msgInfo("You have already connected your O365 Calendar");
    };

    return (
        <Card className="match-parent animate__animated animate__zoomIn animate__faster">
            <div className={Styles.Container}>
                <div className={Styles.Header}>Connect Email and Calendar</div>
                <div className={Styles.Description}>This functionality is coming soon</div>
                <div>
                    <div className={Styles.Button} onClick={openEmailConnector}>
                        <img src={emailImg} />
                        <div className={Styles.Name}>Connect Email</div>
                        {/* {!!orgAttr?.connectedEmails?.length && <CheckIcon style={{ color: "green", fontSize: 20 }} />} */}
                    </div>
                    <div className={Styles.Button} onClick={handleConnectCal}>
                        <img src={outlookImg} style={{ width: 40, marginLeft: 7 }} />
                        <div className={Styles.Name}>Connect O365 Calendar</div>
                        {!!o365Cal.user && <CheckIcon style={{ color: "green", fontSize: 20 }} />}
                    </div>
                </div>
                <div className={Styles.Actions}>
                    <Button size="large" type="primary" onClick={onNext} disabled={!canNext}>
                        Next <ArrowRightIcon />
                    </Button>
                    {!canNext && (
                        <Button onClick={handleSkip} style={{ margin: 10 }} type="link" danger disabled={skipping} loading={skipping}>
                            Skip this setup
                        </Button>
                    )}
                </div>
            </div>
        </Card>
    );
};

export default ConnectEmailSetup;
