import { Watchable } from "../_Utils/Watchable";

export type TServiceFeeProviderAttrs = {
    _id: string;
    name: string;
    csvRowsBelow: number;
    annotatedColumns: {
        clientname?: { annotation: string[] };
        reference?: { annotation: string };
        revenuetype?: { annotation: string };
        fee?: { annotation: string };
        gst?: { annotation: string };
        adviser?: { annotation: string };
        productname?: { annotation: string };
        transaction?: { annotation: string, dateFormat: string };
    };
    adviserRefs: {
        user: string;
        ref: string;
    }[];
    calculateGst?: boolean;
    calculateFeeInclGst?: boolean;
    calculateFeeExclGst?: boolean
};

type ProviderEventMap = {
    ATTRIBUTES_UPDATED: TServiceFeeProviderAttrs;
    PROVIDER_DELETED: null;
};

class ProviderData extends Watchable<ProviderEventMap> {
    private cAttrs: TServiceFeeProviderAttrs;

    constructor(attrs: TServiceFeeProviderAttrs) {
        super();
        this.cAttrs = attrs;
        this.attrs = attrs;
    }

    triggerProviderDeleted() {
        this.trigger("PROVIDER_DELETED", null);
    }

    set attrs(atr: TServiceFeeProviderAttrs) {
        this.cAttrs = atr;

        this.trigger("ATTRIBUTES_UPDATED", atr);
    }

    get attrs() {
        return this.cAttrs;
    }
}

export class ServiceFeeProvider {
    public data: ProviderData;
    public readonly id: string;

    constructor(attributes: TServiceFeeProviderAttrs) {
        this.data = new ProviderData(attributes);
        this.id = attributes._id;
    }

    updateProvider(provider: ServiceFeeProvider) {
        this.updateProviderAttrs(provider.data.attrs);
        return this;
    }

    updateProviderAttrs(attrs: TServiceFeeProviderAttrs) {
        this.data.attrs = attrs;
        return this;
    }

    remove() {
        this.data.triggerProviderDeleted();
    }
}
