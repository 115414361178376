import { Path as PathParser } from "path-parser";
import { CONTACTS_ROUTE, SINGLE_CONTACT_COMPANY_ROUTE, SINGLE_CONTACT_INDIVIDUAL_ROUTE, SINGLE_CONTACT_TRUST_ROUTE } from "../../constants/appRoutes";
import { AppManager } from "../index";
import { IOEventTypes } from "../RealtimeManager";

export class ContactNetworkHelper {
    static networkDataReceived = <K extends keyof IOEventTypes>(evt: K, resp: any): void => {
        switch (evt) {
            //Individual events
            case "CONTACT_UPDATED":
            case "CONTACT_CREATED":
                {
                    const contact = (resp as IOEventTypes["CONTACT_CREATED"]).data;
                    AppManager.contact[contact.contactType].updateContactAttrOrAddNew(contact as any);
                }
                break;
            case "CONTACT_DELETED":
                {
                    const contact = (resp as IOEventTypes["CONTACT_DELETED"]).data;
                    AppManager.contact[contact.contactType].removeContact(contact._id);
                }
                break;

            // Contact Note events
            case "CONTACT_NOTE_ADDED":
            case "CONTACT_NOTE_UPDATED":
                {
                    const noteAttrs = (resp as IOEventTypes["CONTACT_NOTE_ADDED"]).data;
                    const contact = AppManager.contact.getContact(noteAttrs.contact);
                    if (contact) contact.addOrUpdateNote(noteAttrs);
                }
                break;
            case "CONTACT_NOTE_DELETED":
                {
                    const noteAttrs = (resp as IOEventTypes["CONTACT_NOTE_ADDED"]).data;
                    const contact = AppManager.contact.getContact(noteAttrs.contact);
                    if (contact) contact.removeNoteWithId(noteAttrs._id);
                }
                break;

            // Contact File events
            case "CONTACT_FILE_ADDED":
            case "CONTACT_FILE_UPDATED":
                {
                    const fileAttrs = (resp as IOEventTypes["CONTACT_FILE_ADDED"]).data;
                    const contact = AppManager.contact.getContact(fileAttrs.contact);
                    if (contact) contact.addOrUpdateFile(fileAttrs);
                }
                break;
            case "CONTACT_FILE_DELETED":
                {
                    const fileAttrs = (resp as IOEventTypes["CONTACT_FILE_ADDED"]).data;
                    const contact = AppManager.contact.getContact(fileAttrs.contact);
                    if (contact) contact.removeFileWithId(fileAttrs._id);
                }
                break;

            // Contact Service events
            case "CONTACT_SERVICE_ADDED":
            case "CONTACT_SERVICE_UPDATED":
                {
                    const srvAttrs = (resp as IOEventTypes["CONTACT_SERVICE_ADDED"]).data;
                    const contact = AppManager.contact.getContact(srvAttrs.contact);
                    if (contact) contact.addOrUpdateService(srvAttrs);
                }
                break;
            case "CONTACT_SERVICE_DELETED":
                {
                    const srvAttrs = (resp as IOEventTypes["CONTACT_SERVICE_DELETED"]).data;
                    const contact = AppManager.contact.getContact(srvAttrs.contact);
                    if (contact) contact.removeServiceWithId(srvAttrs._id);
                }
                break;
            case "CONTACT_MANY_SERVICES_DELETED":
                {
                    const data = (resp as IOEventTypes["CONTACT_MANY_SERVICES_DELETED"]).data;
                    data.forEach((srvAttrs) => {
                        const contact = AppManager.contact.getContact(srvAttrs.contact);
                        if (contact) contact.removeServiceWithId(srvAttrs._id);
                    });
                }
                break;
            case "CONTACT_AUTO_REGISTRATION_INITIATED":
                {
                    const data = (resp as IOEventTypes["CONTACT_AUTO_REGISTRATION_INITIATED"]).data;
                    AppManager.contact[data.contact.contactType].updateContactAttrOrAddNew(data.contact as any);

                    data.documents.forEach((doc) => AppManager.document.main.updateDocumentAttrOrAddNew(doc));
                }
                break;
            // case "INDIVIDUAL_DEPENDENT_ADDED":
            // case "INDIVIDUAL_DEPENDENT_UPDATED":
            //     {
            //         const data = (resp as IOEventTypes["INDIVIDUAL_DEPENDENT_ADDED"]).data;
            //         const contact = AppManager.contact["Individual"].getContact(data.contact as any);
            //         if(contact) contact.updateDependents()
            //     }
            //     break;
            case "CONTACT_AUDIT":
                {
                    const data = (resp as IOEventTypes["CONTACT_AUDIT"]).data;
                    if (data?.contact[0] !== null) {
                        const contact = AppManager.contact[data.contactType].getContact(data?.contact);
                        if (contact) contact.newAuditItem(data);
                    }
                }
                break;

            case "CONTACT_CUSTOM_FIELD_CREATED":
                {
                    const data = (resp as IOEventTypes["CONTACT_CUSTOM_FIELD_CREATED"]).data;
                    if (data && data.contactType === "Individual") {
                        AppManager.contact.Individual.updateContactCustomFieldAttrOrAddNew(data);
                        AppManager.contact.Individual.addNewContactCustomField({ id: data._id, template: data, value: "" });
                    }
                    if (data && data.contactType === "Company") {
                        AppManager.contact.Company.updateContactCustomFieldsAttrOrAddNew(data);
                        AppManager.contact.Company.addNewContactCustomField({ id: data._id, template: data, value: "" });
                    }
                    if (data && data.contactType === "Trust") {
                        AppManager.contact.Trust.updateContactCustomFieldsAttrOrAddNew(data);
                        AppManager.contact.Trust.addNewContactCustomField({ id: data._id, template: data, value: "" });
                    }
                }
                break;

            case "CONTACT_CUSTOM_FIELD_UPDATED":
                {
                    const data = (resp as IOEventTypes["CONTACT_CUSTOM_FIELD_UPDATED"]).data;
                    if (data && data.contactType === "Individual") AppManager.contact.Individual.updateContactCustomFieldAttrOrAddNew(data);
                    if (data && data.contactType === "Company") AppManager.contact.Company.updateContactCustomFieldsAttrOrAddNew(data);
                    if (data && data.contactType === "Trust") AppManager.contact.Trust.updateContactCustomFieldsAttrOrAddNew(data);
                }
                break;

            case "CONTACT_CUSTOM_FIELD_DELETED":
                {
                    const data = (resp as IOEventTypes["CONTACT_CUSTOM_FIELD_DELETED"]).data;
                    if (data && data.contactType === "Individual") AppManager.contact.Individual.removeContactCustomFieldWithId(data._id);
                    if (data && data.contactType === "Company") AppManager.contact.Company.removeContactCustomFieldWithId(data._id);
                    if (data && data.contactType === "Trust") AppManager.contact.Trust.removeContactCustomFieldWithId(data._id);
                }
                break;
        }
    };

    static onConnectionRestoredSync(currentRoute: string, currentPathName: string) {
        switch (currentRoute) {
            case CONTACTS_ROUTE:
                AppManager.contact.Individual.apiFetchAllContacts(() => {});
                break;

            case SINGLE_CONTACT_INDIVIDUAL_ROUTE:
                {
                    const match = new PathParser(currentRoute).test(currentPathName);
                    if (match) AppManager.contact.Individual.rehydrateContact(match.contactId, () => {});
                    else console.error("Route paths do not match. State of this page could not be syncronized");
                }
                break;
            case SINGLE_CONTACT_COMPANY_ROUTE:
                {
                    const match = new PathParser(currentRoute).test(currentPathName);
                    if (match) AppManager.contact.Company.rehydrateContact(match.contactId, () => {});
                    else console.error("Route paths do not match. State of this page could not be syncronized");
                }
                break;
            case SINGLE_CONTACT_TRUST_ROUTE:
                {
                    const match = new PathParser(currentRoute).test(currentPathName);
                    if (match) AppManager.contact.Trust.rehydrateContact(match.contactId, () => {});
                    else console.error("Route paths do not match. State of this page could not be syncronized");
                }
                break;
        }
    }
}
