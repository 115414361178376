import React, { useEffect, useRef, useState } from "react";
import Logo from "../../../../components/Logo/Logo";
import Styles from "./Home.scss";
import iressLogo from "../../../../assets/img/Iress_logo.png";
import ArrowRightIcon from "@ant-design/icons/ArrowRightOutlined";
import RetryIcon from "@ant-design/icons/ReloadOutlined";
import { Button, Card, Steps } from "antd";
import UserAccountButton from "../../../../containers/StatefulComponents/UserAccountButton/UserAccountButton";
import Loading from "../../../../components/Loading/Loading";
import LoginStep from "./LoginStep";
import { AppManager } from "../../../../manager";
import BasicErrorView from "../../../../components/ErrorView/BasicErrorView/BasicErrorView";
import { ICustomerInfo } from "../../../../models/Apps/Migration/XPlanTypes";
import InitializationStep from "./InitializationStep";
import MigrateIndividualsView from "./MigrateIndividuals";
import MigrateCompaniesView from "./MigrateCompanies";
import MigrateTrustsView from "./MigrateTrusts";
import FinalizeMigrationStep from "./FinalizeMigration";
import MigrationDoneStep from "./MigrationDoneStep";

type TCredentials = {
    iressURI: string;
    username: string;
    password: string;
};

const AUTH_STEP = 0;
const INIT_STEP = 1;
const INDIVIDUALS_STEP = 2;
const COMPANIES_STEP = 3;
const TRUSTS_STEP = 4;
const FINAL_STEP = 5;
const COMPLETED_STEP = 6;

const STATUS_UPDATE_INTV = 20 * 1000;

const MigrationHomePage: React.FC<{ hideUserAccBtn?: boolean; iressURI?: string }> = ({ hideUserAccBtn, iressURI }) => {
    const [currentStep, setCurrentStep] = useState(-1);
    const [userCredentials, setCredentials] = useState<TCredentials>();
    const [customerInfo, setCustomerInfo] = useState<ICustomerInfo>();
    const [retryingMigration, setRetryingMigration] = useState(false);
    const [migrationFailed, setMigrationFailed] = useState(false);
    const userOrgs = AppManager.org.getUserOrgs();
    const userId = AppManager.user.getActiveUser()!.id;
    const [ownedOrgs] = useState(userOrgs.filter((r) => r.data.attrs.owner?._id === userId));
    const [migratedOrgs] = useState(userOrgs.filter((r) => r.data.attrs.completedMigrations?.find((m) => m.source === "xplan_central")));
    const [unMigratedOrgs] = useState(userOrgs.filter((r) => !r.data.attrs.completedMigrations?.find((m) => m.source === "xplan_central")));
    const [loading, setLoading] = useState(true);
    const [pageLoadError, setPageLoadError] = useState("");
    const runningJobId = useRef<any>();

    const handleMoveToNextStep = () => {
        setCurrentStep((s) => s + 1);
    };
    const handleLoginSuccess = (customerInfo: ICustomerInfo) => {
        const { iressURI, username, password } = customerInfo;
        setCredentials({ iressURI, username, password });
        handleMoveToNextStep();
        handleMigrationStarted(customerInfo);
    };

    const handleRetryMigration = () => {
        setRetryingMigration(true);
        AppManager.ausplanApps.xplanMigrator.apiResetMigration(customerInfo!.ausplanUserId, (err) => {
            setRetryingMigration(false);
            if (err) AppManager.alert.error("Failed to continue migration", `${err}`);
            else {
                AppManager.alert.msgSuccess("Migration restarted");
                handleMigrationStarted();
            }
        });
    };

    const fetchCustomerInfo = async (customerId: string) => {
        const result: ICustomerInfo = await AppManager.ausplanApps.xplanMigrator.apiFetchCustomerInfo(customerId, () => {});
        if (result) loadAppMigrationState(result);
    };

    const handleMigrationStarted = (cInfo?: ICustomerInfo) => {
        setCurrentStep((currentStep) => {
            const customer = cInfo || customerInfo!;
            if (runningJobId.current) clearInterval(runningJobId.current);

            loadAppMigrationState(customer);
            if (!cInfo) fetchCustomerInfo(customer.ausplanUserId);
            if (currentStep < FINAL_STEP) {
                runningJobId.current = setInterval(() => {
                    fetchCustomerInfo(customer.ausplanUserId);
                }, STATUS_UPDATE_INTV);
            }

            return currentStep;
        });
    };

    const loadAppMigrationState = (customerInfo: ICustomerInfo) => {
        setCustomerInfo(customerInfo);
        if (customerInfo.fullyScraped) {
            setCurrentStep(FINAL_STEP);
        } else if (!customerInfo.isIndividualsIdsScraped || !customerInfo.isCompaniesIdsScraped || !customerInfo.isTrustsIdsScraped) {
            setCurrentStep(INIT_STEP);
        } else if (!customerInfo.isIndividualsScraped) {
            setCurrentStep(INDIVIDUALS_STEP);
        } else if (!customerInfo.isCompaniesScraped) {
            setCurrentStep(COMPANIES_STEP);
        } else if (!customerInfo.isTrustsScraped) {
            setCurrentStep(TRUSTS_STEP);
        } else setPageLoadError("Could not determine current step. please report this error");
        setMigrationFailed(customerInfo.status == "failed");
    };

    const fetchAppMigrationStateData = async () => {
        if (ownedOrgs.length > 0) {
            for (const unMigratedOrg of unMigratedOrgs) {
                const result: ICustomerInfo = await AppManager.ausplanApps.xplanMigrator.apiFetchCustomerInfo(unMigratedOrg.id, () => {});
                if (result) {
                    handleMigrationStarted(result);
                    return;
                }
            }
            handleMoveToNextStep();
        } else {
            setPageLoadError("This feature is only available for organization owners at this time.");
            setLoading(false);
        }
    };

    useEffect(() => {
        if (currentStep >= FINAL_STEP) {
            clearInterval(runningJobId.current);
        }
    }, [currentStep]);

    useEffect(() => {
        fetchAppMigrationStateData();
    }, []);

    return (
        <div className={Styles.HomeContainer}>
            <div className={Styles.Header}>
                <div className={Styles.LogoView}>
                    <img src={iressLogo} style={{ marginRight: 10, width: 55 }} />
                    <ArrowRightIcon className={Styles.ArrowRight} />
                    <Logo type="simple" imgStyle={{ width: 55, height: 55 }} />
                </div>
                <div className={Styles.CurrentAction}>
                    {currentStep == AUTH_STEP && "Authentication"}
                    {currentStep == INIT_STEP && "Initialize Migration"}
                    {currentStep == INDIVIDUALS_STEP && "Migrating Individuals"}
                    {currentStep == COMPANIES_STEP && "Migrating Companies"}
                    {currentStep == TRUSTS_STEP && "Migrating Trusts"}
                    {currentStep == FINAL_STEP && "Finalize Migration"}
                </div>
                {!hideUserAccBtn && (
                    <div>
                        <UserAccountButton disableOptions />
                    </div>
                )}
            </div>
            <div className={Styles.ContentContainer}>
                <div className={Styles.StepsView}>
                    <Steps direction="vertical" style={{ minHeight: 500 }} current={currentStep}>
                        <Steps.Step
                            title="Authenticate"
                            description={currentStep == AUTH_STEP ? "In Progress" : currentStep > AUTH_STEP ? "Done" : ""}
                        />
                        <Steps.Step
                            title="Initialize Migration"
                            description={currentStep == INIT_STEP ? "In Progress" : currentStep > INIT_STEP ? "Done" : ""}
                        />
                        <Steps.Step
                            title="Migrate Individuals"
                            description={currentStep == INDIVIDUALS_STEP ? "In Progress" : currentStep > INDIVIDUALS_STEP ? "Done" : ""}
                        />
                        <Steps.Step
                            title="Migrate Companies"
                            description={currentStep == COMPANIES_STEP ? "In Progress" : currentStep > COMPANIES_STEP ? "Done" : ""}
                        />
                        <Steps.Step
                            title="Migrate Trusts"
                            description={currentStep == TRUSTS_STEP ? "In Progress" : currentStep > TRUSTS_STEP ? "Done" : ""}
                        />
                        <Steps.Step
                            title="Finalize"
                            description={currentStep == FINAL_STEP ? "In Progress" : currentStep > FINAL_STEP ? "Done" : ""}
                        />
                    </Steps>
                </div>
                <div className={Styles.MainContent}>
                    <div className={Styles.MainContentWrapper}>
                        <div className={Styles.Card}>
                            {migrationFailed ? (
                                <div style={{ textAlign: "center" }}>
                                    <BasicErrorView error="Migration did not complete successfully." />
                                    <Button
                                        icon={<RetryIcon />}
                                        size="large"
                                        style={{ marginTop: 20 }}
                                        onClick={handleRetryMigration}
                                        loading={retryingMigration}
                                        disabled={retryingMigration}
                                    >
                                        Retry
                                    </Button>
                                </div>
                            ) : currentStep == -1 ? (
                                loading ? (
                                    <Loading text="Initializing" />
                                ) : pageLoadError ? (
                                    <BasicErrorView error={pageLoadError} />
                                ) : (
                                    "something went wrong"
                                )
                            ) : currentStep == AUTH_STEP ? (
                                <LoginStep onDone={handleLoginSuccess} iressURI={iressURI} />
                            ) : currentStep == INIT_STEP ? (
                                <InitializationStep credentials={userCredentials!} customer={customerInfo} ownedOrgs={ownedOrgs} />
                            ) : currentStep == INDIVIDUALS_STEP ? (
                                <MigrateIndividualsView customer={customerInfo!} />
                            ) : currentStep == COMPANIES_STEP ? (
                                <MigrateCompaniesView customer={customerInfo!} />
                            ) : currentStep == TRUSTS_STEP ? (
                                <MigrateTrustsView customer={customerInfo!} />
                            ) : currentStep == FINAL_STEP ? (
                                <FinalizeMigrationStep customer={customerInfo!} onDone={handleMoveToNextStep} />
                            ) : currentStep == COMPLETED_STEP ? (
                                <MigrationDoneStep />
                            ) : (
                                "Could not determine current step. Please report this issue"
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MigrationHomePage;
