import React, { useState } from "react";
import Styles from "./Home.scss";
import InfoIcon from "@ant-design/icons/InfoCircleFilled";
import animationGif from "../../../../assets/img/transferringData.gif";
import Logo from "../../../../components/Logo/Logo";
import IressLogoImg from "../../../../assets/img/Iress_logo.png";
import { Alert } from "antd";

const AnimatedStatusHeader: React.FC<{ tip?: string }> = ({ tip }) => {
    return (
        <div>
            <div className={Styles.AnimatedStatusHeader}>
                <div className={Styles.IressLogo}>
                    <img src={IressLogoImg} style={{ width: 80 }} />
                </div>
                <div className={Styles.Animation}>
                    <img src={animationGif} />
                </div>
                <div className={Styles.AusplanLogo}>
                    <Logo type="with-text-btn" imgStyle={{ width: 80 }} />
                </div>
            </div>
            <Alert
                type="info"
                message={
                    tip || "The migration will take some time to complete. You might close this tab/window and check on the status of the job later"
                }
                showIcon
                closable
            />
        </div>
    );
};

export default AnimatedStatusHeader;
