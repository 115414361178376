import { Task } from "../../../models/Task/Task";
import { TTaskCatg } from "../../../models/Task/Types";
import createReducer from "../../utils/createReducer";
import ActionTypes from "./actionTypes";

export type TaskStateT = {
    tasks: { [k in TTaskCatg]: Task[] };
    loadingTasks: TTaskCatg | null;
    tasksLoaded: TTaskCatg[];
    templates: Task[];
    loadingTemplates: boolean;
    templatesLoaded: boolean;
};

export const initialState: TaskStateT = {
    tasks: {
        user: [],
        active: [],
        assigned: [],
        myTasks: [],
        completed: [],
    },
    loadingTasks: null,
    tasksLoaded: [],
    loadingTemplates: false,
    templatesLoaded: false,
    templates: [],
};

// App Reducer
export default createReducer<TaskStateT>(initialState, {
    [ActionTypes.SAVE_TASKS]: (state, payload: { [k in Partial<TTaskCatg>]: Task[] }) => ({
        ...state,
        tasks: { ...state.tasks, ...payload },
    }),
    [ActionTypes.LOADED_TASKS]: (state, payload: TTaskCatg) => ({
        ...state,
        tasksLoaded: [...state.tasksLoaded.filter((t) => t !== payload), payload],
    }),
    [ActionTypes.LOADING_TASKS]: (state, payload: TTaskCatg) => ({
        ...state,
        loadingTasks: payload,
    }),

    [ActionTypes.REMOVE_TASK]: (state, payload: Task) => ({
        ...state,
        tasks: Object.keys(state.tasks).reduce((curr, key) => {
            const filtered = state.tasks[key as TTaskCatg].filter((t) => t.id !== payload.id);
            curr[key as TTaskCatg] = filtered;
            return curr;
        }, {} as { [k in TTaskCatg]: Task[] }),
    }),
    [ActionTypes.SAVE_TEMPLATES]: (state, payload: Task[]) => ({
        ...state,
        templates: payload,
    }),
    [ActionTypes.LOADED_TEMPLATES]: (state, payload: boolean) => ({
        ...state,
        templatesLoaded: payload,
    }),
    [ActionTypes.LOADING_TEMPLATES]: (state, payload: boolean) => ({
        ...state,
        loadingTemplates: payload,
    }),
    [ActionTypes.LOGOUT_ACTION]: (state) => ({
        ...state,
        ...initialState,
    }),
});
