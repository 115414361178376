import { Button } from "antd";
import React, { useState } from "react";
import { Checkmark } from "react-checkmark";
import Styles from "./Home.scss";

const MigrationDoneStep: React.FC = () => {
    return (
        <div className={Styles.LoginContainer}>
            <div className={Styles.LoginBox}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                    <Checkmark size={100} />
                    <h2 style={{ marginTop: 20 }}>Hurray!! Everything went well 🙂</h2>
                    <div style={{ color: "#777", textAlign: "center" }}>
                        You can now{" "}
                        <Button size="small" onClick={close}>
                            Close
                        </Button>{" "}
                        this tab and access your data from the app
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MigrationDoneStep;
