import { Card } from "antd";
import React from "react";
import AppContent from "../../containers/AppContent/AppContent";
import BasicHeaderLayout from "../../layouts/BasicHeaderLayout/BasicHeaderLayout";
import page404Img from "../../assets/img/page_404.png";

const Page404: React.FC = () => {
    return (
        <BasicHeaderLayout title="Not found">
            <AppContent header="" showNetworkState={false}>
                <Card className="match-parent">
                    <div
                        className="match-parent"
                        style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}
                    >
                        <img src={page404Img} style={{ width: 350 }} />
                        <h2 style={{ fontSize: 50, fontWeight: "bold" }}>Oops!</h2>
                        <h2 style={{ fontSize: 20, margin: 0, position: "relative", top: -10 }}>Hmm... That page does not exist</h2>
                    </div>
                </Card>
            </AppContent>
        </BasicHeaderLayout>
    );
};

export default Page404;
