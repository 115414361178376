import { QueryResultCallback } from "../../models/Callback/Callback";
import { IScheduleAttrs } from "../../models/Schedule/Schedule";
import { DiaryStoreActions } from "../../redux/services/diary/actions";
import { APIManager } from "../APIManager";
import { DiaryNetworkHelper } from "./NetworkHelper";

export class DiaryManager {
    networkHelper = DiaryNetworkHelper;
    async apiFetchAllMySchedules(cb: QueryResultCallback<IScheduleAttrs[]>) {
        try {
            DiaryStoreActions.loadingSchedules(true);
            const resp = await APIManager.get(APIManager.SCHEDULE.DIARY_MY_SCHEDULES_API);
            DiaryStoreActions.saveSchedules(resp.data);
            DiaryStoreActions.setSchedulesLoaded(true);
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        } finally {
            DiaryStoreActions.loadingSchedules(false);
        }
    }

    async apiFetchAllOrgSchedules(cb: QueryResultCallback<IScheduleAttrs[]>) {
        try {
            DiaryStoreActions.loadingSchedules(true);
            const resp = await APIManager.get(APIManager.SCHEDULE.DIARY_ALL_SCHEDULES_API);
            DiaryStoreActions.saveSchedules(resp.data);
            DiaryStoreActions.setSchedulesLoaded(true);
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        } finally {
            DiaryStoreActions.loadingSchedules(false);
        }
    }

    async apiAddNewSchedule(newSchedule: Omit<IScheduleAttrs, "_id">, cb: QueryResultCallback<IScheduleAttrs>) {
        try {
            const resp = await APIManager.post(APIManager.SCHEDULE.DIARY_ALL_SCHEDULES_API, newSchedule);
            this.updateOrAddSchedule(resp.data);
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiUpdateSchedule(scheduleId: string, scheduleData: Partial<IScheduleAttrs>, cb: QueryResultCallback<IScheduleAttrs>) {
        try {
            const resp = await APIManager.put(APIManager.SCHEDULE.DIARY_SINGLE_SCHEDULE_API(scheduleId), scheduleData);
            this.updateOrAddSchedule(resp.data);
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiDeleteSchedule(scheduleId: string, cb: QueryResultCallback<IScheduleAttrs>) {
        try {
            const resp = await APIManager.delete(APIManager.SCHEDULE.DIARY_SINGLE_SCHEDULE_API(scheduleId));
            DiaryStoreActions.removeSchedule(scheduleId);
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    updateOrAddSchedule(scheduleData: IScheduleAttrs) {
        let updated = false;
        const allSchedules = DiaryStoreActions.getSchedules();
        for (let i = 0; i < allSchedules.length; i++) {
            if (allSchedules[i]._id === scheduleData._id) {
                allSchedules[i] = scheduleData;
                updated = true;
                break;
            }
        }
        DiaryStoreActions.saveSchedules([...(updated ? [] : [scheduleData]), ...allSchedules]);
    }

    removeSchedule(scheduleData: IScheduleAttrs) {
        const allSchedules = DiaryStoreActions.getSchedules().filter((t) => t._id !== scheduleData._id);
        DiaryStoreActions.saveSchedules(allSchedules);
    }
}
