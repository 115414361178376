import { Company } from "../../../models/Contact/Company/Company";
import { CustomField } from "../../../models/Contact/CustomFields";
import { Individual } from "../../../models/Contact/Individual/Individual";
import { Trust } from "../../../models/Contact/Trust/Trust";
import createReducer from "../../utils/createReducer";
import ActionTypes from "./actionTypes";

export type ContactStateT = {
    individuals: Individual[];
    companies: Company[];
    trusts: Trust[];
    customFieldIndividual: CustomField[];
    customFieldCompany: CustomField[];
    customFieldTrust: CustomField[];
    loadingIndividuals: boolean;
    individualsLoaded: boolean;
    loadingCompanies: boolean;
    companiesLoaded: boolean;
    loadingTrusts: boolean;
    trustsLoaded: boolean;
};

export const initialState: ContactStateT = {
    individuals: [],
    companies: [],
    trusts: [],
    customFieldIndividual: [],
    customFieldCompany: [],
    customFieldTrust: [],
    loadingIndividuals: false,
    individualsLoaded: false,
    loadingCompanies: false,
    companiesLoaded: false,
    loadingTrusts: false,
    trustsLoaded: false,
};

// App Reducer
export default createReducer<ContactStateT>(initialState, {
    [ActionTypes.SAVE_INDIVIDUALS]: (state, payload: Individual[]) => ({
        ...state,
        individuals: payload,
    }),
    [ActionTypes.LOADED_INDIVIDUALS]: (state, payload: boolean) => ({
        ...state,
        individualsLoaded: payload,
    }),
    [ActionTypes.LOADING_INDIVIDUALS]: (state, payload: boolean) => ({
        ...state,
        loadingIndividuals: payload,
    }),
    [ActionTypes.REMOVE_INDIVIDUAL]: (state, payload: Individual) => ({
        ...state,
        individuals: state.individuals.filter((t) => t.id !== payload.id),
    }),

    [ActionTypes.SAVE_COMPANIES]: (state, payload: Company[]) => ({
        ...state,
        companies: payload,
    }),
    [ActionTypes.LOADED_COMPANIES]: (state, payload: boolean) => ({
        ...state,
        companiesLoaded: payload,
    }),
    [ActionTypes.LOADING_COMPANIES]: (state, payload: boolean) => ({
        ...state,
        loadingCompanies: payload,
    }),
    [ActionTypes.REMOVE_COMPANY]: (state, payload: Company) => ({
        ...state,
        companies: state.companies.filter((t) => t.id !== payload.id),
    }),

    [ActionTypes.SAVE_TRUSTS]: (state, payload: Trust[]) => ({
        ...state,
        trusts: payload,
    }),
    [ActionTypes.LOADED_TRUSTS]: (state, payload: boolean) => ({
        ...state,
        trustsLoaded: payload,
    }),
    [ActionTypes.LOADING_TRUSTS]: (state, payload: boolean) => ({
        ...state,
        loadingTrusts: payload,
    }),
    [ActionTypes.REMOVE_TRUST]: (state, payload: Trust) => ({
        ...state,
        trusts: state.trusts.filter((t) => t.id !== payload.id),
    }),

    [ActionTypes.LOGOUT_ACTION]: (state) => ({
        ...state,
        ...initialState,
    }),

    [ActionTypes.SAVE_CUSTOM_FIELDS_INDIVIDUAL]: (state, payload: CustomField[]) => ({
        ...state,
        customFieldIndividual: payload,
    }),

    [ActionTypes.SAVE_CUSTOM_FIELDS_COMPANY]: (state, payload: CustomField[]) => ({
        ...state,
        customFieldCompany: payload,
    }),

    [ActionTypes.SAVE_CUSTOM_FIELDS_TRUST]: (state, payload: CustomField[]) => ({
        ...state,
        customFieldTrust: payload,
    }),
});
