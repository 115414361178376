import SocketIO, { Socket } from "socket.io-client";
import { AppManager } from ".";
import { BASE_URL } from "../constants/apiEndpoints";
import { ContactFileAttrs } from "../models/Contact/ContactFile";
import { ContactNoteAttrs } from "../models/Contact/ContactNote";
import { ContactServiceAttrs } from "../models/Contact/ContactService";
import { IndividualDependentAttrs } from "../models/Contact/Individual/IndividualDependent";
import { TContactAttrs, TContactAuditAttr } from "../models/Contact/Types";
import { TDocumentAttr, TDocumentTemplateAttr } from "../models/Document/Types";
import { INotification } from "../models/Notification/Notification";
import { TFullOrganizationAttr, TOrganizationAttr, TOrgInvitation } from "../models/Organization/Types";
import { IScheduleAttrs } from "../models/Schedule/Schedule";
import { IMasterlistRecordAttrs } from "../models/ServicesFees/MasterlistRecord";
import { TServiceFeeProviderAttrs } from "../models/ServicesFees/Provider";
import { TProjectAttr, TProjectTemplateAttr, TTaskAttr } from "../models/Task/Types";
import { ISimpleUser } from "../models/User/SimpleUserType";
import { TUserAttr } from "../models/User/User";
import { AppStoreActions } from "../redux/services/app/actions";
import type { CustomFieldsData } from "../models/Contact/CustomFields";

type NetworkData<T> = { data: T; source: ISimpleUser };

export type IOEventTypes = {
    NOTIFICATION: NetworkData<INotification>;

    CONTACT_CREATED: NetworkData<TContactAttrs>;
    CONTACT_UPDATED: NetworkData<TContactAttrs>;
    CONTACT_DELETED: NetworkData<TContactAttrs>;

    CONTACT_NOTE_ADDED: NetworkData<ContactNoteAttrs>;
    CONTACT_NOTE_UPDATED: NetworkData<ContactNoteAttrs>;
    CONTACT_NOTE_DELETED: NetworkData<ContactNoteAttrs>;
    CONTACT_NOTE_RESTORED: NetworkData<ContactNoteAttrs>;

    CONTACT_FILE_ADDED: NetworkData<ContactFileAttrs>;
    CONTACT_FILE_UPDATED: NetworkData<ContactFileAttrs>;
    CONTACT_FILE_DELETED: NetworkData<ContactFileAttrs>;

    CONTACT_SERVICE_ADDED: NetworkData<ContactServiceAttrs>;
    CONTACT_SERVICE_UPDATED: NetworkData<ContactServiceAttrs>;
    CONTACT_SERVICE_DELETED: NetworkData<ContactServiceAttrs>;

    CONTACT_AUTO_REGISTRATION_INITIATED: NetworkData<{ contact: TContactAttrs; documents: TDocumentAttr[] }>;
    CONTACT_MANY_SERVICES_DELETED: NetworkData<ContactServiceAttrs[]>;

    CONTACT_AUDIT: NetworkData<TContactAuditAttr>;

    INDIVIDUAL_DEPENDENT_ADDED: NetworkData<IndividualDependentAttrs>;
    INDIVIDUAL_DEPENDENT_UPDATED: NetworkData<IndividualDependentAttrs>;
    INDIVIDUAL_DEPENDENT_DELETED: NetworkData<IndividualDependentAttrs>;

    DOCUMENT_CREATED: NetworkData<TDocumentAttr>;
    DOCUMENT_UPDATED: NetworkData<TDocumentAttr>;
    DOCUMENT_DELETED: NetworkData<TDocumentAttr>;
    DOCUMENT_SIGN_REQUESTED: NetworkData<TDocumentAttr>;
    DOCUMENT_TEMPLATE_CREATED: NetworkData<TDocumentTemplateAttr>;
    DOCUMENT_TEMPLATE_UPDATED: NetworkData<TDocumentTemplateAttr>;
    DOCUMENT_TEMPLATE_DELETED: NetworkData<TDocumentTemplateAttr>;

    MASTERLIST_RECORDS_CREATED: NetworkData<IMasterlistRecordAttrs[]>;
    MASTERLIST_RECORD_UPDATED: NetworkData<IMasterlistRecordAttrs>;
    MASTERLIST_RECORD_DELETED: NetworkData<IMasterlistRecordAttrs>;

    SERVICE_FEE_PROVIDER_CREATED: NetworkData<TServiceFeeProviderAttrs>;
    SERVICE_FEE_PROVIDER_UPDATED: NetworkData<TServiceFeeProviderAttrs>;
    SERVICE_FEE_PROVIDER_DELETED: NetworkData<TServiceFeeProviderAttrs>;

    SCHEDULE_CREATED: NetworkData<IScheduleAttrs>;
    SCHEDULE_UPDATED: NetworkData<IScheduleAttrs>;
    SCHEDULE_DELETED: NetworkData<IScheduleAttrs>;

    TASK_CREATED: NetworkData<TTaskAttr>;
    TASK_UPDATED: NetworkData<TTaskAttr>;
    TASK_DELETED: NetworkData<TTaskAttr>;

    PROJECT_CREATED: NetworkData<TProjectAttr>;
    PROJECT_UPDATED: NetworkData<TProjectAttr>;
    PROJECT_DELETED: NetworkData<TProjectAttr>;

    PROJECT_TEMPLATE_CREATED: NetworkData<TProjectTemplateAttr>;
    PROJECT_TEMPLATE_UPDATED: NetworkData<TProjectTemplateAttr>;
    PROJECT_TEMPLATE_DELETED: NetworkData<TProjectTemplateAttr>;

    USER_UPDATED: NetworkData<TUserAttr>;

    ORGANIZATION_UPDATED: NetworkData<TFullOrganizationAttr>;
    ORG_INVITE_CREATED: NetworkData<TOrgInvitation>;
    ORG_INVITE_UPDATED: NetworkData<TOrgInvitation>;
    ORG_INVITE_DELETED: NetworkData<TOrgInvitation>;

    MULTIPLE_SESSIONS_FORCE_LOGOUT: any;

    CONTACT_CUSTOM_FIELD_UPDATED: NetworkData<CustomFieldsData>;
    CONTACT_CUSTOM_FIELD_CREATED: NetworkData<CustomFieldsData>;
    CONTACT_CUSTOM_FIELD_DELETED: NetworkData<CustomFieldsData>;
};

const ALL_EVENTS: (keyof IOEventTypes)[] = [
    "NOTIFICATION",

    // contact events
    "CONTACT_CREATED",
    "CONTACT_UPDATED",
    "CONTACT_DELETED",
    "CONTACT_AUTO_REGISTRATION_INITIATED",
    "CONTACT_AUDIT",

    "CONTACT_NOTE_ADDED",
    "CONTACT_NOTE_DELETED",
    "CONTACT_NOTE_UPDATED",
    "CONTACT_NOTE_RESTORED",
    "CONTACT_FILE_ADDED",
    "CONTACT_FILE_UPDATED",
    "CONTACT_FILE_DELETED",
    "CONTACT_SERVICE_ADDED",
    "CONTACT_SERVICE_UPDATED",
    "CONTACT_SERVICE_DELETED",
    "CONTACT_MANY_SERVICES_DELETED",

    "INDIVIDUAL_DEPENDENT_ADDED",
    "INDIVIDUAL_DEPENDENT_UPDATED",
    "INDIVIDUAL_DEPENDENT_DELETED",

    // doc events
    "DOCUMENT_CREATED",
    "DOCUMENT_UPDATED",
    "DOCUMENT_DELETED",
    "DOCUMENT_SIGN_REQUESTED",
    "DOCUMENT_TEMPLATE_CREATED",
    "DOCUMENT_TEMPLATE_UPDATED",
    "DOCUMENT_TEMPLATE_DELETED",

    // services fees events
    "MASTERLIST_RECORDS_CREATED",
    "MASTERLIST_RECORD_UPDATED",
    "MASTERLIST_RECORD_DELETED",
    "SERVICE_FEE_PROVIDER_CREATED",
    "SERVICE_FEE_PROVIDER_UPDATED",
    "SERVICE_FEE_PROVIDER_DELETED",

    // Diary events
    "SCHEDULE_CREATED",
    "SCHEDULE_UPDATED",
    "SCHEDULE_DELETED",

    // Tasks events
    "TASK_CREATED",
    "TASK_UPDATED",
    "TASK_DELETED",

    // Project events
    "PROJECT_CREATED",
    "PROJECT_UPDATED",
    "PROJECT_DELETED",

    // Project template events
    "PROJECT_TEMPLATE_CREATED",
    "PROJECT_TEMPLATE_UPDATED",
    "PROJECT_TEMPLATE_DELETED",

    // User events
    "USER_UPDATED",

    // Org events
    "ORGANIZATION_UPDATED",
    "ORG_INVITE_CREATED",
    "ORG_INVITE_UPDATED",
    "ORG_INVITE_DELETED",

    // others
    "MULTIPLE_SESSIONS_FORCE_LOGOUT",

    // CustomField events
    "CONTACT_CUSTOM_FIELD_UPDATED",
    "CONTACT_CUSTOM_FIELD_CREATED",
    "CONTACT_CUSTOM_FIELD_DELETED",
];

type InitConfig = {
    onReAuthenticate: () => void;
    onReconnected: () => void;
    onDataReceived: <K extends keyof IOEventTypes>(evt: K, data: IOEventTypes[K]) => void;
};

export class RealtimeRequestManager {
    socket?: Socket;

    private config?: InitConfig;

    private socketListenersRegistered = false;

    init(config: InitConfig, force = false) {
        this.config = config;
        if (this.socket && this.socket.connected) {
            this.destroy();
        }
        AppStoreActions.updateInitState({ status: "INITIALIZING", statusErrorReason: undefined });
        this.socket = SocketIO(BASE_URL, {
            transports: ["websocket"],
            jsonp: false,
            reconnection: true,
            query: {
                token: AppManager.auth.getAccessToken() || "",
                ...(force ? { force: "1" } : {}),
            },
        });
        this.socket.on("connect_error", (reason) => {
            console.error("CONNECTION ERROR ", reason, " MSG '", reason.message, "'");
            if (reason.message === "UnAuthorizedSocketError") {
                this.socket!.disconnect();
                this.config!.onReAuthenticate();
            } else if (reason.message === "MULTIPLE_SESSIONS") {
                this.socket?.disconnect();
                AppStoreActions.updateInitState({ status: "MULTIPLE_SESSIONS" });
            }
        });
        this.socket.io.on("reconnect_attempt", (attemptNumber) => {
            AppStoreActions.updateNetworState("reconnecting");
        });
        this.socket.io.on("reconnect_failed", () => {
            AppStoreActions.updateNetworState("offline");
        });
        this.socket.io.on("reconnect_error", () => {
            AppStoreActions.updateNetworState("offline");
        });
        this.socket.on("disconnect", (reason) => {
            console.log("DISCONNECTED! ", reason);
            AppStoreActions.updateNetworState("offline");
        });
        this.socket.on("connect", () => {
            console.log("CONNECTED!");
            this.initSocketListeners();
            if (AppStoreActions.getNetworkState() === "reconnecting") {
                this.config!.onReconnected();
            }
            AppStoreActions.updateNetworState("online");
            AppStoreActions.updateInitState({ status: "READY", statusErrorReason: undefined });
        });
    }

    reInitWithForcedAuth() {
        this.init(this.config!, true);
    }

    private initSocketListeners() {
        if (this.socketListenersRegistered) return;
        this.socketListenersRegistered = true;
        ALL_EVENTS.forEach((EVENT_NAME) => {
            console.log("REGISTERING ", EVENT_NAME);
            this.socket?.on(EVENT_NAME, (data) => {
                console.log(EVENT_NAME, " => ", data);
                if (EVENT_NAME == "MULTIPLE_SESSIONS_FORCE_LOGOUT") {
                    this.destroy();
                    this.config?.onReAuthenticate();
                } else this.config!.onDataReceived(EVENT_NAME, data);
            });
        });
    }

    destroy() {
        if (!this.socket?.disconnected) {
            this.socketListenersRegistered = false;
            this.socket?.disconnect();
        }
    }

    emitEvent(event: string, data: any) {
        if (this.socket?.connected) {
            this.socket?.emit(event, data);
        }
    }
}
