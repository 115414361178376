import moment from "moment";
import { Watchable } from "../_Utils/Watchable";
import { Task } from "./Task";
import { TProjectAttr, TProjectDataAttr } from "./Types";

type ProjectEventMap = {
    ATTRIBUTES_UPDATED: TProjectDataAttr;
    PROJECT_DELETED: null;
};

class ProjectData extends Watchable<ProjectEventMap> {
    private cAttrs: TProjectDataAttr;
    private allProjectTasks: Task[] = [];
    constructor(attrs: TProjectAttr) {
        super();
        this.cAttrs = this.projectDataFromAttr(attrs);
        this.attrs = attrs;
    }

    triggerProjectDeleted() {
        this.trigger("PROJECT_DELETED", null);
    }

    private projectDataFromAttr(atr: TProjectAttr) {
        const projectDataAttr: TProjectDataAttr = {
            ...atr,
            dueDate: atr.dueDate ? moment(atr.dueDate) : undefined,
            createdAt: moment(atr.createdAt),
            updatedAt: moment(atr.updatedAt),
            phases: [],
        };
        projectDataAttr.phases = atr.phases.map((phase) => {
            const tasks = phase.tasks
                .map((task) => {
                    if (typeof task !== "string") {
                        return new Task(task);
                    }
                })
                .filter((t) => !!t) as Task[];
            return { ...phase, tasks };
        });
        return projectDataAttr;
    }

    set attrs(atr: TProjectAttr | TProjectDataAttr) {
        const attrInfo = atr;
        const projectDataAttr: TProjectDataAttr = {
            ...attrInfo,
            dueDate: attrInfo.dueDate ? moment(attrInfo.dueDate) : undefined,
            createdAt: moment(this.cAttrs.createdAt),
            updatedAt: moment(this.cAttrs.updatedAt),
            phases: [],
        };
        projectDataAttr.phases = attrInfo.phases.map((phase) => {
            const tasks = phase.tasks
                .map((task) => {
                    let taskInstance: Task | null = null;
                    if (task instanceof Task) {
                        taskInstance = task;
                    } else if (typeof task !== "string") {
                        taskInstance = new Task(task);
                    }
                    if (taskInstance) {
                        const oldInstance = this.allProjectTasks.find((r) => r.id === taskInstance?.id);
                        if (oldInstance) {
                            return oldInstance?.updateTask(taskInstance);
                        } else {
                            this.allProjectTasks.push(taskInstance);
                            return taskInstance;
                        }
                    }
                })
                .filter((t) => !!t) as Task[];
            return { ...phase, tasks };
        });
        this.cAttrs = projectDataAttr;
        this.cAttrs.dueDate = this.cAttrs.dueDate ? moment(this.cAttrs.dueDate) : undefined;
        this.cAttrs.createdAt = moment(this.cAttrs.createdAt);
        this.cAttrs.updatedAt = moment(this.cAttrs.updatedAt);

        this.trigger("ATTRIBUTES_UPDATED", projectDataAttr);
    }

    get attrs(): TProjectDataAttr {
        return this.cAttrs;
    }
}

export class Project {
    public data: ProjectData;
    public readonly id: string;

    constructor(attributes: TProjectAttr) {
        this.data = new ProjectData(attributes);
        this.id = attributes._id;
    }

    markDeleted() {
        this.data.triggerProjectDeleted();
    }

    updateProject(project: Project) {
        this.updateProjectAttrs(project.data.attrs);
        return this;
    }

    updateProjectAttrs(attrs: TProjectAttr | TProjectDataAttr) {
        this.data.attrs = attrs;
        return this;
    }

    remove() {
        this.data.triggerProjectDeleted();
    }
}
