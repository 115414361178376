import { IMasterlistRecordAttrs } from "../../../models/ServicesFees/MasterlistRecord";
import { ServiceFeeProvider } from "../../../models/ServicesFees/Provider";
import createReducer from "../../utils/createReducer";
import ActionTypes from "./actionTypes";

export type ServiceFeeStateT = {
    masterlistRecords: IMasterlistRecordAttrs[];
    providers: ServiceFeeProvider[];
    allAdviserRefs: { [k: string]: { user: string; ref: string }[] };
    loadingRecords: boolean;
    recordsLoaded: boolean;
    loadingProviders: boolean;
    providersLoaded: boolean;
};

export const initialState: ServiceFeeStateT = {
    masterlistRecords: [],
    providers: [],
    loadingRecords: false,
    recordsLoaded: false,
    loadingProviders: false,
    providersLoaded: false,
    allAdviserRefs: {},
};

// App Reducer
export default createReducer<ServiceFeeStateT>(initialState, {
    [ActionTypes.SAVE_RECORDS]: (state, payload: IMasterlistRecordAttrs[]) => ({
        ...state,
        masterlistRecords: payload,
    }),
    [ActionTypes.LOADED_RECORDS]: (state, payload: boolean) => ({
        ...state,
        recordsLoaded: payload,
    }),
    [ActionTypes.LOADING_RECORDS]: (state, payload: boolean) => ({
        ...state,
        loadingRecords: payload,
    }),
    [ActionTypes.DELETE_RECORD]: (state, payload: string) => ({
        ...state,
        masterlistRecords: state.masterlistRecords.filter((t) => t._id !== payload),
    }),
    [ActionTypes.ADD_NEW_RECORDS]: (state, payload: IMasterlistRecordAttrs[]) => ({
        ...state,
        masterlistRecords: [...payload, ...state.masterlistRecords],
    }),

    [ActionTypes.SAVE_ALL_PROVIDERS_ADVISERREFS]: (state, payload: { [k: string]: { user: string; ref: string }[] }) => ({
        ...state,
        allAdviserRefs: payload,
    }),
    [ActionTypes.SAVE_PROVIDERS]: (state, payload: ServiceFeeProvider[]) => ({
        ...state,
        providers: payload,
    }),
    [ActionTypes.LOADED_PROVIDERS]: (state, payload: boolean) => ({
        ...state,
        providersLoaded: payload,
    }),
    [ActionTypes.LOADING_PROVIDERS]: (state, payload: boolean) => ({
        ...state,
        providersLoaded: payload,
    }),
    [ActionTypes.DELETE_PROVIDER]: (state, payload: ServiceFeeProvider) => ({
        ...state,
        providers: state.providers.filter((t) => t.id !== payload.id),
    }),

    [ActionTypes.LOGOUT_ACTION]: (state) => ({
        ...state,
        ...initialState,
    }),
});
