import ActionTypes from "./actionTypes";
import { getAppStore } from "../../store";
import { IMasterlistRecordAttrs } from "../../../models/ServicesFees/MasterlistRecord";
import { ServiceFeeProvider } from "../../../models/ServicesFees/Provider";

export class ServiceFeeStoreActions {
    static loadingRecords(loading: boolean) {
        getAppStore().dispatch({ type: ActionTypes.LOADING_RECORDS, payload: loading });
    }
    static setRecordsLoaded(state: boolean) {
        getAppStore().dispatch({ type: ActionTypes.LOADED_RECORDS, payload: state });
    }
    static saveRecords(records: IMasterlistRecordAttrs[]) {
        getAppStore().dispatch({ type: ActionTypes.SAVE_RECORDS, payload: records });
    }
    static addNewRecords(records: IMasterlistRecordAttrs[]) {
        getAppStore().dispatch({ type: ActionTypes.ADD_NEW_RECORDS, payload: records });
    }
    static removeRecord(recordId: string) {
        getAppStore().dispatch({ type: ActionTypes.DELETE_RECORD, payload: recordId });
    }
    static getRecords() {
        return getAppStore().getState().servicefee.masterlistRecords;
    }
    static addOrUpdateToRecords(record: IMasterlistRecordAttrs) {
        let records = ServiceFeeStoreActions.getRecords();
        if (records.some((t) => t._id === record._id)) {
            records = records.map((t) => {
                if (t._id === record._id) return record;
                return t;
            });
        } else {
            records = [record, ...records];
        }
        ServiceFeeStoreActions.saveRecords(records);
    }

    static loadingProviders(loading: boolean) {
        getAppStore().dispatch({ type: ActionTypes.LOADING_PROVIDERS, payload: loading });
    }
    static setProvidersLoaded(state: boolean) {
        getAppStore().dispatch({ type: ActionTypes.LOADED_PROVIDERS, payload: state });
    }
    static saveProviders(providers: ServiceFeeProvider[]) {
        getAppStore().dispatch({ type: ActionTypes.SAVE_PROVIDERS, payload: providers });
    }
    static saveAllProvidersAdviserRefs(adviserRefsMap: { [k: string]: { user: string; ref: string }[] }) {
        getAppStore().dispatch({ type: ActionTypes.SAVE_ALL_PROVIDERS_ADVISERREFS, payload: adviserRefsMap });
    }
    static removeProvider(provider: ServiceFeeProvider) {
        getAppStore().dispatch({ type: ActionTypes.DELETE_PROVIDER, payload: provider });
    }
    static getProviders() {
        return getAppStore().getState().servicefee.providers;
    }
    static addOrUpdateToProviders(provider: ServiceFeeProvider) {
        let providers = ServiceFeeStoreActions.getProviders();
        if (providers.some((t) => t.id === provider.id)) {
            providers = providers.map((t) => {
                if (t.id === provider.id) {
                    return t.updateProvider(provider);
                }
                return t;
            });
        } else {
            providers = [provider, ...providers];
        }
        ServiceFeeStoreActions.saveProviders(providers);
    }
}
