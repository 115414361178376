export const LOCAL_STORAGE_KEYS = {
    TOKEN: "783.LLL",
    ACTIVE_ORG: "2200.3263",
};

export const TEMPLATE_EDITOR = {
    EDIT_FIELD: "EDIT_FIELD",
    EDIT_NODE_SIZES: {
        TEXT: { width: 100, height: 38 },
        INITIALS: { width: 100, height: 42 },
        CHECKBOX: { width: 25, height: 25 },
        DATE: { width: 170, height: 30 },
        DROP: { width: 100, height: 35 },
        RADIO: { width: 25, height: 25 },
        SIGNATURE: { width: 200, height: 80 },
    },
};

export const RESTORE_NOTE_MAX_DURATION = 7; // in days

export const APP_ENV = {
    BUILD_TARGET: process.env.BUILD_TARGET || "",
    AUTH_ORIGIN: process.env.AUTH_ORIGIN || "",
};

export const DELETED_ARCHIEVED = "Archived Deleted";
export const ARCHIVED = "Archived";
