import { DIARY_ROUTE, SERVICES_FEES_MASTERLIST_ROUTE, SERVICES_FEES_NEW_RECORD_ROUTE } from "../../constants/appRoutes";
import { AppManager } from "..";
import { IOEventTypes } from "../RealtimeManager";

export class DiaryNetworkHelper {
    static networkDataReceived = <K extends keyof IOEventTypes>(evt: K, resp: any): void => {
        switch (evt) {
            // Masterlist events
            case "SCHEDULE_CREATED":
            case "SCHEDULE_UPDATED":
                {
                    const schedule = (resp as IOEventTypes["SCHEDULE_CREATED"]).data;
                    AppManager.diary.updateOrAddSchedule(schedule);
                }
                break;
            case "SCHEDULE_DELETED":
                AppManager.diary.removeSchedule((resp as IOEventTypes["SCHEDULE_DELETED"]).data);
                break;
        }
    };

    static onConnectionRestoredSync(currentRoute: string, currentPathName: string) {
        switch (currentRoute) {
            case DIARY_ROUTE:
                AppManager.diary.apiFetchAllMySchedules(() => {});
                break;
        }
    }
}
