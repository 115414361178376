export type CustomFieldsData = {
    options: string[];
    _id: string;
    type: string;
    contactType: string;
    documentTag: string;
    name: string;
    createdBy: string;
    organization: string;
    createdAt: string;
    updatedAt: string;
};

export class CustomField {
    public readonly id: string;
    public data: CustomField;

    constructor(public attrs: CustomFieldsData) {
        this.data = this.updateCustomField(attrs);
        this.id = attrs._id;
    }

    updateCustomField(customFieldAttrs: CustomFieldsData) {
        this.attrs = customFieldAttrs;
        return this;
    }

    static fromArray(customFields: CustomFieldsData[]) {
        return customFields.map((t) => new CustomField(t));
    }
}
