import React, { useEffect, useRef, useState } from "react";
import Logo from "../../../../components/Logo/Logo";
import Styles from "./Home.scss";
import iressLogo from "../../../../assets/img/Iress_logo.png";
import ArrowRightIcon from "@ant-design/icons/ArrowRightOutlined";
import LoadIcon from "@ant-design/icons/Loading3QuartersOutlined";
import RetryIcon from "@ant-design/icons/ReloadOutlined";
import { Alert, Button, Card, Steps } from "antd";
import UserAccountButton from "../../../../containers/StatefulComponents/UserAccountButton/UserAccountButton";
import Loading from "../../../../components/Loading/Loading";
import LoginStep from "./LoginStep";
import { AppManager } from "../../../../manager";
import BasicErrorView from "../../../../components/ErrorView/BasicErrorView/BasicErrorView";
import { ICustomerInfo } from "../../../../models/Apps/Migration/XPlanTypes";
import InitializationStep from "./InitializationStep";
import MigrateIndividualsView from "./MigrateIndividuals";
import MigrateCompaniesView from "./MigrateCompanies";
import MigrateTrustsView from "./MigrateTrusts";
import FinalizeMigrationStep, { PerformMappingsDrawer } from "./FinalizeMigration";
import MigrationDoneStep from "./MigrationDoneStep";

type TCredentials = {
    iressURI: string;
    username: string;
    password: string;
};

const AUTH_STEP = 0;
const FINAL_STEP = 50;
const COMPLETED_STEP = FINAL_STEP + 1;

const INIT_SUBSTEP = 0;
const INDIVIDUALS_SUBSTEP = 1;
const COMPANIES_SUBSTEP = 2;
const TRUSTS_SUBSTEP = 3;
const FINAL_SUBSTEP = 4;

const STATUS_UPDATE_INTV = 20 * 1000;

const MigrationHomePage: React.FC<{ hideUserAccBtn?: boolean; iressURI?: string }> = ({ hideUserAccBtn, iressURI }) => {
    const [currentStep, setCurrentStep] = useState(-1);
    const [currentGroup, setCurrentGroup] = useState("");
    const [currentSubStep, setCurrentSubStep] = useState(-1);
    const [migrationGroups, setMigrationGroups] = useState<string[]>([]);
    const [userCredentials, setCredentials] = useState<TCredentials>();
    const [customerInfo, setCustomerInfo] = useState<ICustomerInfo>();
    const [retryingMigration, setRetryingMigration] = useState(false);
    const [migrationFailed, setMigrationFailed] = useState(false);
    const userOrgs = AppManager.org.getUserOrgs();
    const userId = AppManager.user.getActiveUser()!.id;
    const [ownedOrgs] = useState(userOrgs.filter((r) => r.data.attrs.owner?._id === userId));
    const [migratedOrgs] = useState(userOrgs.filter((r) => r.data.attrs.completedMigrations?.find((m) => m.source === "xplan_central")));
    const [unMigratedOrgs] = useState(userOrgs.filter((r) => !r.data.attrs.completedMigrations?.find((m) => m.source === "xplan_central")));
    const [loading, setLoading] = useState(true);
    const [pageLoadError, setPageLoadError] = useState("");
    const runningJobId = useRef<any>();
    const [showMapperDrawer, setShowMapperDrawer] = useState(false);

    const handleMoveToNextStep = () => {
        setCurrentStep((s) => s + 1);
    };

    const handleLoginSuccess = (customerInfo: ICustomerInfo) => {
        const { iressURI, username, password } = customerInfo;
        setCredentials({ iressURI, username, password });
        handleMoveToNextStep();
        handleMigrationStarted(customerInfo);
    };

    const handleRetryMigration = () => {
        setRetryingMigration(true);
        AppManager.ausplanApps.xplanMigrator.apiResetMigration(customerInfo!.ausplanUserId, (err) => {
            setRetryingMigration(false);
            if (err) AppManager.alert.error("Failed to continue migration", `${err}`);
            else {
                AppManager.alert.msgSuccess("Migration restarted");
                handleMigrationStarted();
            }
        });
    };

    const fetchCustomerInfo = async (customerId: string) => {
        const result: ICustomerInfo = await AppManager.ausplanApps.xplanMigrator.apiFetchCustomerInfo(customerId, () => {});
        if (result) loadAppMigrationState(result);
    };

    const handleMigrationStarted = (cInfo?: ICustomerInfo) => {
        setCurrentStep((currentStep) => {
            const customer = cInfo || customerInfo!;
            if (runningJobId.current) clearInterval(runningJobId.current);

            loadAppMigrationState(customer);
            if (!cInfo) fetchCustomerInfo(customer.ausplanUserId);
            if (currentStep < FINAL_STEP) {
                let fetching = false;
                runningJobId.current = setInterval(() => {
                    if (!fetching) {
                        fetching = true;
                        fetchCustomerInfo(customer.ausplanUserId).finally(() => (fetching = false));
                    }
                }, STATUS_UPDATE_INTV);
            }

            return currentStep;
        });
    };

    const loadAppMigrationState = (customerInfo: ICustomerInfo) => {
        setCustomerInfo(customerInfo);
        console.log("CUSTOMER INFO ", customerInfo);
        setMigrationGroups(customerInfo.migrationScrapeGroups || []);
        setMigrationFailed(customerInfo.status == "failed");
        if (customerInfo.fullyScraped) {
            setCurrentStep(FINAL_STEP);
            setLoading(false);
        } else {
            const activeGroup = customerInfo.currentGroup;
            if (!activeGroup && customerInfo.status == "under-migration") return setLoading(true);
            setLoading(false);

            setCurrentGroup(activeGroup);
            const currentGroupIndex = customerInfo.migrationScrapeGroups.indexOf(activeGroup);
            if (currentGroupIndex >= 0) {
                setCurrentStep(currentGroupIndex + AUTH_STEP + 1);
                console.log("CURRENT STEP? ", currentGroupIndex + AUTH_STEP + 1);
                const idsScraped =
                    customerInfo.scrapedIndividualIdsGroups.includes(activeGroup) &&
                    customerInfo.scrapedCompanyIdsGroups.includes(activeGroup) &&
                    customerInfo.scrapedTrustIdsGroups.includes(activeGroup);
                if (!idsScraped) {
                    setCurrentSubStep(INIT_SUBSTEP);
                } else if (!customerInfo.scrapedIndividualGroups.includes(activeGroup)) {
                    setCurrentSubStep(INDIVIDUALS_SUBSTEP);
                } else if (!customerInfo.scrapedCompanyGroups.includes(activeGroup)) {
                    setCurrentSubStep(COMPANIES_SUBSTEP);
                } else if (!customerInfo.scrapedTrustGroups.includes(activeGroup)) {
                    setCurrentSubStep(TRUSTS_SUBSTEP);
                } else {
                    setCurrentSubStep(FINAL_SUBSTEP);
                    // setPageLoadError("Could not determine current step. please report this error");
                }
            }
        }
    };

    const fetchAppMigrationStateData = async () => {
        if (ownedOrgs.length > 0) {
            for (const unMigratedOrg of unMigratedOrgs) {
                const result: ICustomerInfo = await AppManager.ausplanApps.xplanMigrator.apiFetchCustomerInfo(unMigratedOrg.id, () => {});
                if (result) {
                    handleMigrationStarted(result);
                    return;
                }
            }
            handleMoveToNextStep();
        } else {
            setPageLoadError("This feature is only available for organization owners at this time.");
            setLoading(false);
        }
    };

    useEffect(() => {
        if (currentStep >= FINAL_STEP) {
            clearInterval(runningJobId.current);
        }
    }, [currentStep]);

    useEffect(() => {
        fetchAppMigrationStateData();

        return () => clearInterval(runningJobId.current);
    }, []);

    return (
        <div className={Styles.HomeContainer}>
            <div className={Styles.Header}>
                <div className={Styles.LogoView}>
                    <img src={iressLogo} style={{ marginRight: 10, width: 55 }} />
                    <ArrowRightIcon className={Styles.ArrowRight} />
                    <Logo type="simple" imgStyle={{ width: 55, height: 55 }} />
                </div>
                <div className={Styles.CurrentAction}>
                    {currentStep == AUTH_STEP && "Authentication"}
                    {currentSubStep == INIT_SUBSTEP && `Initializing Migration for ${currentGroup || "--"}`}
                    {currentSubStep == INDIVIDUALS_SUBSTEP && `Migrating Individuals for ${currentGroup || "--"}`}
                    {currentSubStep == COMPANIES_SUBSTEP && `Migrating Companies for ${currentGroup || "--"}`}
                    {currentSubStep == TRUSTS_SUBSTEP && `Migrating Trusts for ${currentGroup || "--"}`}
                    {currentSubStep == FINAL_SUBSTEP && `Finalizing Migration for ${currentGroup || "--"}`}
                </div>
                {!hideUserAccBtn && (
                    <div>
                        <UserAccountButton disableOptions />
                    </div>
                )}
            </div>
            {!!(customerInfo?.unmappedAdvisers?.length || customerInfo?.unmappedServiceLevels?.length) && (
                <Alert
                    showIcon
                    type="warning"
                    message="Action required"
                    description="Please provide mappings for some advisers and services levels"
                    style={{ margin: 5 }}
                    action={
                        <Button size="large" type="primary" onClick={() => setShowMapperDrawer(true)}>
                            Perform Mappings <ArrowRightIcon />
                        </Button>
                    }
                />
            )}
            <div className={Styles.ContentContainer}>
                <div className={Styles.StepsView}>
                    <div className={Styles.StepsContent}>
                        <Steps direction="vertical" style={{ minHeight: 500 }} current={currentStep}>
                            <Steps.Step
                                title="Authenticate"
                                description={currentStep == AUTH_STEP ? "In Progress" : currentStep > AUTH_STEP ? "Done" : ""}
                            />
                            {!migrationGroups.length && <Steps.Step title="To be determined" />}
                            {migrationGroups.map((item, ind) => (
                                <Steps.Step
                                    key={item}
                                    title={`Migrate ${item}`}
                                    description={
                                        currentStep === ind + 1 ? (
                                            <>
                                                <Steps
                                                    className={Styles.InnerSteps}
                                                    direction="vertical"
                                                    size="small"
                                                    current={currentSubStep}
                                                    progressDot
                                                    style={{ paddingLeft: 5 }}
                                                >
                                                    <Steps.Step
                                                        title="Initialize Migration"
                                                        description={
                                                            currentSubStep == INIT_SUBSTEP
                                                                ? "In Progress"
                                                                : currentSubStep > INIT_SUBSTEP
                                                                ? "Done"
                                                                : ""
                                                        }
                                                    />
                                                    <Steps.Step
                                                        title="Migrate Individuals"
                                                        description={
                                                            currentSubStep == INDIVIDUALS_SUBSTEP
                                                                ? "In Progress"
                                                                : currentSubStep > INDIVIDUALS_SUBSTEP
                                                                ? "Done"
                                                                : ""
                                                        }
                                                    />
                                                    <Steps.Step
                                                        title="Migrate Companies"
                                                        description={
                                                            currentSubStep == COMPANIES_SUBSTEP
                                                                ? "In Progress"
                                                                : currentSubStep > COMPANIES_SUBSTEP
                                                                ? "Done"
                                                                : ""
                                                        }
                                                    />
                                                    <Steps.Step
                                                        title="Migrate Trusts"
                                                        description={
                                                            currentSubStep == TRUSTS_SUBSTEP
                                                                ? "In Progress"
                                                                : currentSubStep > TRUSTS_SUBSTEP
                                                                ? "Done"
                                                                : ""
                                                        }
                                                    />
                                                    <Steps.Step
                                                        title="Finalize"
                                                        description={
                                                            currentSubStep == FINAL_SUBSTEP
                                                                ? "In Progress"
                                                                : currentSubStep > FINAL_SUBSTEP
                                                                ? "Done"
                                                                : ""
                                                        }
                                                    />
                                                </Steps>
                                            </>
                                        ) : currentStep > ind + 1 ? (
                                            "Done"
                                        ) : null
                                    }
                                />
                            ))}
                        </Steps>
                    </div>
                </div>
                <div className={Styles.MainContent}>
                    <div className={Styles.MainContentWrapper}>
                        <div className={Styles.Card}>
                            {migrationFailed ? (
                                <div style={{ textAlign: "center" }}>
                                    <BasicErrorView error="Migration did not complete successfully." />
                                    <Button
                                        icon={<RetryIcon />}
                                        size="large"
                                        style={{ marginTop: 20 }}
                                        onClick={handleRetryMigration}
                                        loading={retryingMigration}
                                        disabled={retryingMigration}
                                    >
                                        Retry
                                    </Button>
                                </div>
                            ) : currentStep == -1 ? (
                                loading ? (
                                    <Loading text="Initializing" />
                                ) : pageLoadError ? (
                                    <BasicErrorView error={pageLoadError} />
                                ) : (
                                    <span>
                                        Determining the migration step...
                                        <LoadIcon spin />
                                    </span>
                                )
                            ) : currentStep == AUTH_STEP ? (
                                <LoginStep onDone={handleLoginSuccess} iressURI={iressURI} />
                            ) : loading ? (
                                <Loading text="Loading migration state" />
                            ) : currentSubStep == INIT_SUBSTEP ? (
                                <InitializationStep credentials={userCredentials!} customer={customerInfo} ownedOrgs={ownedOrgs} />
                            ) : currentSubStep == INDIVIDUALS_SUBSTEP ? (
                                <MigrateIndividualsView customer={customerInfo!} />
                            ) : currentSubStep == COMPANIES_SUBSTEP ? (
                                <MigrateCompaniesView customer={customerInfo!} />
                            ) : currentSubStep == TRUSTS_SUBSTEP || currentSubStep == FINAL_SUBSTEP ? (
                                <MigrateTrustsView customer={customerInfo!} />
                            ) : currentStep == FINAL_STEP ? (
                                <FinalizeMigrationStep customer={customerInfo!} onDone={handleMoveToNextStep} />
                            ) : currentStep == COMPLETED_STEP ? (
                                <MigrationDoneStep />
                            ) : (
                                <span>
                                    Determining the migration step...
                                    <LoadIcon spin />
                                </span>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <PerformMappingsDrawer
                show={showMapperDrawer}
                onToggleShow={(state) => {
                    setShowMapperDrawer(state);
                    if (!state) fetchAppMigrationStateData();
                }}
                customer={customerInfo}
            />
        </div>
    );
};

export default MigrationHomePage;
