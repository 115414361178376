import React, { useEffect } from "react";
import { AppManager } from "../../../manager";
import AppLoadingPage from "../../AppLoadingPage/AppLoadingPage";

const LogoutPage = () => {
    useEffect(() => {
        AppManager.auth.dispatchLogout();
        setTimeout(AppManager.route.gotoLogin, 2000);
    }, []);
    return <AppLoadingPage loadingText="Logging out" />;
};

export default LogoutPage;
