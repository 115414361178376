const API_BASE_URL =
    process.env.APP_BACKEND_URL || `${window.location.protocol}//${window.location.hostname}${window.location.port ? ":" + 8080 : ""}`;

export const BASE_URL = API_BASE_URL;
export const BASE_API = `${BASE_URL}/api/v1`;
export const LOGIN_API = `${BASE_API}/auth/login`;
export const GET_AUTH_INFO_API = `${BASE_API}/auth/authInfo`;
export const GET_AZURE_CREDENTIALS_API = `${BASE_API}/auth/azureCredentials`;
export const LOGIN_AUTH0_API = `${BASE_API}/auth/login/auth0`;
export const USER_TOKEN_REFRESH_API = `${BASE_API}/auth/refreshToken`;
export const USER_AUTH_CREDENTIALS_API = `${BASE_API}/users/me/updateAuthCredentials`;
export const USER_NOTIFICATION_SETTINGS_API = `${BASE_API}/users/me/notificationSettings`;
export const USER_DATA_API = `${BASE_API}/users/me/data`;

// User notification APIs
export const ALL_NOTIFICATIONS_API = `${BASE_API}/notifications/{organizationId}/me/all`;
export const SINGLE_NOTIFICATION_API = `${BASE_API}/notifications/{organizationId}/me/{notificationId}`;
export const READ_ALL_NOTIFICATIONS_API = `${BASE_API}/notifications/{organizationId}/me/read-all`;

// Organization APIs
export const ORG_MEMBERS_API = `${BASE_API}/org/{organizationId}/members`;
export const ORG_ALL_INFO_API = `${BASE_API}/org/{organizationId}/allInfo`;
export const ORG_BASIC_INFO_API = `${BASE_API}/org/{organizationId}/basicInfo`;
export const ORG_CONTACT_SERVICES_API = `${BASE_API}/org/{organizationId}/contactServices`;
export const ORG_INVITATIONS_API = `${BASE_API}/org/{organizationId}/invitations`;
export const ORG_INVITATION_ITEM_API = `${BASE_API}/org/{organizationId}/invitations/{invitationId}`;
export const ORG_ROLES_API = `${BASE_API}/org/{organizationId}/roles`;
export const ORG_ROLE_ITEM_API = `${BASE_API}/org/{organizationId}/roles/{roleId}`;
export const ORG_SET_DEFAULT_ROLE_API = `${BASE_API}/org/{organizationId}/roles/{roleId}/setAsDefault`;
export const ORG_UPDATE_MEMBER_ROLE_API = `${BASE_API}/org/{organizationId}/members/updateMemberRole`;
export const ORG_MEMBER_ITEM_API = `${BASE_API}/org/{organizationId}/members/{memberId}`;
export const ORG_SERVICE_LEVELS_API = `${BASE_API}/org/{organizationId}/serviceLevels`;
export const ORG_SERVICE_LEVEL_ITEM_API = `${BASE_API}/org/{organizationId}/serviceLevels/{serviceLevelId}`;
export const ORG_SERVICE_LEVEL_REORDER_API = `${BASE_API}/org/{organizationId}/serviceLevels/reorder`;
export const ORG_SCHEDULES_CATEGORIES_API = `${BASE_API}/org/{organizationId}/scheduleCategory`;
export const ORG_SCHEDULES_CATEGORY_ITEM_API = `${BASE_API}/org/{organizationId}/scheduleCategory/{categoryId}`;
export const ORG_DEALER_GROUPS_API = `${BASE_API}/org/{organizationId}/dealerGroups`;
export const ORG_DEALER_GROUP_REQUESTS_API = `${BASE_API}/org/{organizationId}/dealerGroups/requests`;
export const ORG_DEALER_GROUP_REQUEST_ITEM_API = `${BASE_API}/org/{organizationId}/dealerGroups/requests/{requestId}`;
export const ORG_DEALER_GROUP_ITEM_API = `${BASE_API}/org/{organizationId}/dealerGroups/{dealerGroupId}`;
export const ORG_INITIAL_SETUP_ITEM_API = `${BASE_API}/org/{organizationId}/initialSetup/items`;
export const ORG_INITIAL_SETUP_STATUS_API = `${BASE_API}/org/{organizationId}/initialSetup/status`;

// Contact APIs
// Individuals APIs
export const CONTACT_INDIVIDUAL_LIST_API = `${BASE_API}/contacts/individuals/{organizationId}`;
export const CONTACT_INDIVIDUAL_SERVICE_FEE_API = `${BASE_API}/servicesFees/{organizationId}/mlRecords/contacts/{contactId}/Individual`;
export const CONTACT_INDIVIDUAL_BULK_EMAIL_API = `${BASE_API}/contacts/individuals/{organizationId}/bulkEmail`;
export const CONTACT_INDIVIDUAL_AUTO_REGISTER_API = `${BASE_API}/contacts/individuals/{organizationId}/autoRegister`;
export const CONTACT_INDIVIDUAL_ITEM_API = `${BASE_API}/contacts/individuals/{organizationId}/{contactId}`;
export const CONTACT_INDIVIDUAL_ITEM_RESTORE_API = `${BASE_API}/contacts/individuals/{organizationId}/{contactId}/restore`;
export const CONTACT_INDIVIDUAL_NOTES_API = `${CONTACT_INDIVIDUAL_ITEM_API}/notes`;
export const CONTACT_INDIVIDUAL_NOTE_ITEM_API = `${CONTACT_INDIVIDUAL_ITEM_API}/notes/{noteId}`;
export const CONTACT_INDIVIDUAL_NOTE_ITEM_RESTORE_API = `${CONTACT_INDIVIDUAL_ITEM_API}/notes/{noteId}/restore`;
export const CONTACT_INDIVIDUAL_FILES_API = `${CONTACT_INDIVIDUAL_ITEM_API}/files`;
export const CONTACT_INDIVIDUAL_FILE_ITEM_API = `${CONTACT_INDIVIDUAL_ITEM_API}/files/{fileId}`;
export const CONTACT_INDIVIDUAL_FILE_NOTE_ITEM_API = `${CONTACT_INDIVIDUAL_ITEM_API}/{noteId}/files/{fileId}`;
export const CONTACT_INDIVIDUAL_SERVICES_API = `${CONTACT_INDIVIDUAL_ITEM_API}/services`;
export const CONTACT_INDIVIDUAL_SERVICE_ITEM_API = `${CONTACT_INDIVIDUAL_ITEM_API}/services/{serviceId}`;
export const CONTACT_INDIVIDUAL_DEPENDENTS_API = `${CONTACT_INDIVIDUAL_ITEM_API}/dependents`;
export const CONTACT_INDIVIDUAL_DEPENDENT_ITEM_API = `${CONTACT_INDIVIDUAL_ITEM_API}/dependents/{dependentId}`;
export const CONTACT_INDIVIDUAL_ACTIVE_DOCUMENTS_API = `${CONTACT_INDIVIDUAL_ITEM_API}/activeDocuments`;
export const CONTACT_INDIVIDUAL_DELETABLE_CHECK_API = `${CONTACT_INDIVIDUAL_ITEM_API}/checkDeletable`;
export const CONTACT_INDIVIDUAL_SEND_EMAIL_API = `${CONTACT_INDIVIDUAL_ITEM_API}/sendEmail`;
export const CONTACT_INDIVIDUAL_LINK_COMPANY_API = `${CONTACT_INDIVIDUAL_ITEM_API}/linkCompany`;
export const CONTACT_INDIVIDUAL_UNLINK_COMPANY_API = `${CONTACT_INDIVIDUAL_ITEM_API}/unlinkCompany`;
export const CONTACT_INDIVIDUAL_LINK_TRUST_API = `${CONTACT_INDIVIDUAL_ITEM_API}/linkTrust`;
export const CONTACT_INDIVIDUAL_UNLINK_TRUST_API = `${CONTACT_INDIVIDUAL_ITEM_API}/unlinkTrust`;
export const CONTACT_CUSTOM_FIELD_API = `${BASE_API}/contacts/customFields/{organizationId}/{customFieldId}`;
export const CONTACT_INDIVIDUAL_CUSTOM_FIELDS_API = `${BASE_API}/contacts/customFields/{organizationId}?contactType=individual`;
export const CONTACT_COMPANY_CUSTOM_FIELDS_API = `${BASE_API}/contacts/customFields/{organizationId}?contactType=company`;
export const CONTACT_TRUST_CUSTOM_FIELDS_API = `${BASE_API}/contacts/customFields/{organizationId}?contactType=trust`;

// Companies APIs
export const CONTACT_COMPANY_LIST_API = `${BASE_API}/contacts/companies/{organizationId}`;
export const CONTACT_COMPANY_SERVICE_FEE_API = `${BASE_API}/servicesFees/{organizationId}/mlRecords/contacts/{contactId}/Company`;
export const CONTACT_COMPANY_AUTO_REGISTER_API = `${BASE_API}/contacts/companies/{organizationId}/autoRegister`;
export const CONTACT_COMPANY_ITEM_API = `${BASE_API}/contacts/companies/{organizationId}/{contactId}`;
export const CONTACT_COMPANY_ITEM_RESTORE_API = `${BASE_API}/contacts/companies/{organizationId}/{contactId}/restore`;
export const CONTACT_COMPANY_NOTES_API = `${CONTACT_COMPANY_ITEM_API}/notes`;
export const CONTACT_COMPANY_NOTE_ITEM_API = `${CONTACT_COMPANY_ITEM_API}/notes/{noteId}`;
export const CONTACT_COMPANY_FILES_API = `${CONTACT_COMPANY_ITEM_API}/files`;
export const CONTACT_COMPANY_FILE_ITEM_API = `${CONTACT_COMPANY_ITEM_API}/files/{fileId}`;
export const CONTACT_COMPANY_SERVICES_API = `${CONTACT_COMPANY_ITEM_API}/services`;
export const CONTACT_COMPANY_SERVICE_ITEM_API = `${CONTACT_COMPANY_ITEM_API}/services/{serviceId}`;
export const CONTACT_COMPANY_ACTIVE_DOCUMENTS_API = `${CONTACT_COMPANY_ITEM_API}/activeDocuments`;
export const CONTACT_COMPANY_DELETABLE_CHECK_API = `${CONTACT_COMPANY_ITEM_API}/checkDeletable`;
export const CONTACT_COMPANY_SEND_EMAIL_API = `${CONTACT_COMPANY_ITEM_API}/sendEmail`;

// Trusts APIs
export const CONTACT_TRUST_LIST_API = `${BASE_API}/contacts/trusts/{organizationId}`;
export const CONTACT_TRUST_SERVICE_FEE_API = `${BASE_API}/servicesFees/{organizationId}/mlRecords/contacts/{contactId}/Trust`;
export const CONTACT_TRUST_AUTO_REGISTER_API = `${BASE_API}/contacts/trusts/{organizationId}/autoRegister`;
export const CONTACT_TRUST_ITEM_API = `${BASE_API}/contacts/trusts/{organizationId}/{contactId}`;
export const CONTACT_TRUST_ITEM_RESTORE_API = `${BASE_API}/contacts/trusts/{organizationId}/{contactId}/restore`;
export const CONTACT_TRUST_NOTES_API = `${CONTACT_TRUST_ITEM_API}/notes`;
export const CONTACT_TRUST_NOTE_ITEM_API = `${CONTACT_TRUST_ITEM_API}/notes/{noteId}`;
export const CONTACT_TRUST_FILES_API = `${CONTACT_TRUST_ITEM_API}/files`;
export const CONTACT_TRUST_FILE_ITEM_API = `${CONTACT_TRUST_ITEM_API}/files/{fileId}`;
export const CONTACT_TRUST_SERVICES_API = `${CONTACT_TRUST_ITEM_API}/services`;
export const CONTACT_TRUST_SERVICE_ITEM_API = `${CONTACT_TRUST_ITEM_API}/services/{serviceId}`;
export const CONTACT_TRUST_ACTIVE_DOCUMENTS_API = `${CONTACT_TRUST_ITEM_API}/activeDocuments`;
export const CONTACT_TRUST_DELETABLE_CHECK_API = `${CONTACT_TRUST_ITEM_API}/checkDeletable`;
export const CONTACT_TRUST_SEND_EMAIL_API = `${CONTACT_TRUST_ITEM_API}/sendEmail`;

// Contact Audit APIs
export const CONTACT_AUDITS_API = `${BASE_API}/contacts/audits/{organizationId}/{contactId}`;
export const CONTACT_NOTE_AUDITS_API = `${BASE_API}/contacts/audits/{organizationId}/{contactId}/{noteId}`;

// Document APIs
export const DOCUMENT_TEMPLATE_LIST_API = `${BASE_API}/documents/{organizationId}/templates`;
export const DOCUMENT_TEMPLATE_ITEM_API = `${BASE_API}/documents/{organizationId}/templates/{templateId}`;
export const DOCUMENT_TEMPLATE_ITEM_CONTENTTAGS_API = `${BASE_API}/documents/{organizationId}/templates/{templateId}/content-tags`;
export const DOCUMENT_TEMPLATE_VERIFY_API = `${BASE_API}/documents/{organizationId}/templates/verify-item`;
export const DOCUMENT_TEMPLATE_ITEM_BUILD_API = `${BASE_API}/documents/{organizationId}/templates/{templateId}/build`;
export const DOCUMENT_TEMPLATE_INDIVIDUAL_SIGN_API = `${BASE_API}/documents/{organizationId}/templates/signRequest/individual`;
export const DOCUMENT_TEMPLATE_COMPANY_SIGN_API = `${BASE_API}/documents/{organizationId}/templates/signRequest/company`;
export const DOCUMENT_TEMPLATE_TRUST_SIGN_API = `${BASE_API}/documents/{organizationId}/templates/signRequest/trust`;
export const DISCOVERY_FORM_INDIVIDUAL_SIGN_API = `${BASE_API}/documents/{organizationId}/templates/e-discovery/individual`;
export const DISCOVERY_GROUPS_API = `${BASE_API}/documents/{organizationId}/soa-groups/listing`;
export const DISCOVERY_GROUP_ITEM_API = `${BASE_API}/documents/{organizationId}/soa-groups/{soaGroupId}`;
export const DISCOVERY_GROUP_DOCUMENT_ITEM_API = `${BASE_API}/documents/{organizationId}/soa-groups/{soaGroupId}/document/{documentId}`;
export const STATEMENTS_OF_ADVICE_API = `${BASE_API}/documents/{organizationId}/soa-docs/listing`;
export const STATEMENT_OF_ADVICE_ITEM_API = `${BASE_API}/documents/{organizationId}/soa-docs/{soaId}`;
export const STATEMENT_OF_ADVICE_DRAFTS_API = `${BASE_API}/documents/{organizationId}/soa-docs/{soaId}/drafts`;

export const DOCUMENT_LIST_API = `${BASE_API}/documents/{organizationId}`;
export const DOCUMENT_ITEM_API = `${BASE_API}/documents/{organizationId}/{documentId}`;
export const DOCUMENT_MULTIFILE_DOWNLOAD_API = `${BASE_API}/documents/{organizationId}/{documentId}/multi-file-download`;

// Hook APIs
export const BASE_HOOK_API = `${BASE_API}/hooks/{organizationId}`;
export const HOOK_ORGANIZATION_INFO_API = `${BASE_HOOK_API}/{tokenId}/organizationInfo`;
export const HOOK_CONTACT_INFO_API = `${BASE_HOOK_API}/{tokenId}/contactInfo`;
export const HOOK_ORG_INVITE_INFO_API = `${BASE_HOOK_API}/{tokenId}/inviteInfo`;
export const HOOK_ACCEPT_ORG_INVITE_NEW_USER_API = `${BASE_HOOK_API}/{tokenId}/acceptInviteWithNewUser`;
export const HOOK_ACCEPT_ORG_INVITE_API = `${BASE_HOOK_API}/{tokenId}/acceptInvite`;
export const HOOK_DECLINE_ORG_INVITE_API = `${BASE_HOOK_API}/{tokenId}/declineInvite`;
export const HOOK_RELATED_TOKENMAPS_INFO_API = `${BASE_HOOK_API}/{tokenId}/relatedTokens`;
export const HOOK_UPDATE_CONTACT_API = `${BASE_HOOK_API}/{tokenId}/updateContact`;
export const HOOK_OPEN_DOCUMENT_API = `${BASE_HOOK_API}/{tokenId}/document/open`;
export const HOOK_SIGN_DOCUMENT_API = `${BASE_HOOK_API}/{tokenId}/document/sign`;
export const HOOK_DISCOVERY_DOC_ITEM_API = `${BASE_HOOK_API}/{tokenId}/soa-discovery-item`;
export const HOOK_COMPLETE_DOC_DISCOVERY_API = `${BASE_HOOK_API}/{tokenId}/soa-discovery-item/complete`;
export const HOOK_UPDATE_DOC_DISCOVERY_API = `${BASE_HOOK_API}/{tokenId}/soa-discovery-item/update`;
export const HOOK_SIGN_CLEANUP_REGISTER_CONTACT_API = `${BASE_HOOK_API}/{tokenId}/cleanup`;

// Services fees APIs
export const MASTERLIST_RECORDS_API = `${BASE_API}/servicesFees/{organizationId}/mlRecords`;
export const MASTERLIST_SINGLE_RECORD_API = `${BASE_API}/servicesFees/{organizationId}/mlRecords/{recordId}`;
export const MASTERLIST_PROVIDERS_API = `${BASE_API}/servicesFees/{organizationId}/providers`;
export const MASTERLIST_SINGLE_PROVIDER_API = `${BASE_API}/servicesFees/{organizationId}/providers/{providerId}`;
export const MASTERLIST_PROVIDER_AUTODETECT_API = `${BASE_API}/servicesFees/{organizationId}/providers/autodetect`;
export const MASTERLIST_PROVIDER_CSVCODE_API = `${BASE_API}/servicesFees/{organizationId}/providers/{providerId}/updateCsvCode`;
export const MASTERLIST_PROVIDERS_ADVISERREF_API = `${BASE_API}/servicesFees/{organizationId}/providers/adviserRefs/all`;
export const MASTERLIST_SERVICE_FEE_AUDIT_LIST_API = `${BASE_API}/servicesFees/{organizationId}/originalServiceFeeFiles/{originalServiceFeeFileId}`;
export const MASTERLIST_SERVICE_FEE_FILE_DELETE_API = `${BASE_API}/servicesFees/{organizationId}/originalServiceFeeFiles/{originalServiceFeeFileId}`;
export const MASTERLIST_SERVICE_FEE_FILE_DELETE_ASSOCIATED_ENTRIES_API = `${BASE_API}/servicesFees/{organizationId}/originalServiceFeeFiles/{originalServiceFeeFileId}/entries`;

// Diary(schedules) APIs
export const DIARY_ALL_SCHEDULES_API = `${BASE_API}/schedules/{organizationId}`;
export const DIARY_MY_SCHEDULES_API = `${BASE_API}/schedules/{organizationId}/mySchedules`;
export const DIARY_COLLEAGUES_SCHEDULES_API = `${BASE_API}/schedules/{organizationId}/colleaguesSchedules`;
export const DIARY_SINGLE_SCHEDULE_API = `${BASE_API}/schedules/{organizationId}/{scheduleId}`;

// Tasks and Projects APIs
export const TASKS_API = `${BASE_API}/tasks/{organizationId}`;
export const TASKS_MY_API = `${BASE_API}/tasks/{organizationId}/mytasks`;
export const TASK_SINGLE_API = `${BASE_API}/tasks/{organizationId}/{taskId}`;
export const TASK_SINGLE_TEMPLATE_API = `${BASE_API}/taskTemplates/{organizationId}`;
export const TASK_COMPLETED_API = `${BASE_API}/tasks/{organizationId}/{taskId}/markCompleted`;
export const TASK_SUBITEM_API = `${BASE_API}/tasks/{organizationId}/{taskId}/taskItem`;
export const TASK_SINGLE_SUBITEM_API = `${BASE_API}/tasks/{organizationId}/{taskId}/taskItem/{taskItemId}`;
export const TASK_SUBITEM_COMPLETED_API = `${BASE_API}/tasks/{organizationId}/{taskId}/taskItem/{taskItemId}/markCompleted`;

export const PROJECTS_API = `${BASE_API}/projects/{organizationId}`;
export const PROJECT_MY_API = `${BASE_API}/projects/{organizationId}/myprojects`;
export const PROJECT_SINGLE_API = `${BASE_API}/projects/{organizationId}/{projectId}`;

export const PROJECT_TEMPLATES_API = `${BASE_API}/projectTemplates/{organizationId}`;
export const PROJECT_TEMPLATE_SINGLE_API = `${BASE_API}/projectTemplates/{organizationId}/{templateId}`;

// azure blob storage
export const AZURE_UPLOAD_API = `${BASE_API}/azure/{organizationId}/upload`;
export const AZURE_DELETE_API = `${BASE_API}/azure/{organizationId}/delete`;
export const AZURE_UNDELETE_API = `${BASE_API}/azure/{organizationId}/undelete`;
export const AZURE_DOWNLOAD_API = `${BASE_API}/azure/{organizationId}/download`;
export const AZURE_LIST_API = `${BASE_API}/azure/{organizationId}/list`;

// subscription
export const SUBSCRIPTION_CUSTOMER_INFO_API = `${BASE_API}/subscriptions/{organizationId}/customerInfo`;
export const SUBSCRIPTION_CURRENT_PLAN_API = `${BASE_API}/subscriptions/{organizationId}/currentPlan`;
export const SUBSCRIPTION_PAYMENT_METHODS_API = `${BASE_API}/subscriptions/{organizationId}/paymentMethods`;
export const SUBSCRIPTION_BILLING_HISTORY_API = `${BASE_API}/subscriptions/{organizationId}/billingHistory`;

// misc
export const SEARCH_ORGANIZATION_API = `${BASE_API}/misc/search/{organizationId}/searchOrg`;
export const XPLAN_MGT_PROXY_BASE_API = `${BASE_API}/misc/migration/{organizationId}/xplan`;
export const XPLAN_MGT_CUSTOMER_API = `${XPLAN_MGT_PROXY_BASE_API}/customers`;
export const XPLAN_MGT_CUSTOMER_ITEM_API = `${XPLAN_MGT_PROXY_BASE_API}/customers/{customerId}`;
export const XPLAN_MGT_CUSTOMER_RESET_API = `${XPLAN_MGT_PROXY_BASE_API}/customers/{customerId}/reset`;
// export const XPLAN_MGT_CUSTOMER_TEST_CREDS_API = `${XPLAN_MGT_PROXY_BASE_API}/customers/testCredentials`;
export const XPLAN_MGT_INDIVIDUALS_API = `${XPLAN_MGT_PROXY_BASE_API}/individuals/{customerId}`;
export const XPLAN_MGT_INDIVIDUAL_ITEM_API = `${XPLAN_MGT_PROXY_BASE_API}/individuals/{customerId}/{individualId}`;
export const XPLAN_MGT_COMPANIES_API = `${XPLAN_MGT_PROXY_BASE_API}/companies/{customerId}`;
export const XPLAN_MGT_COMPANY_ITEM_API = `${XPLAN_MGT_PROXY_BASE_API}/companies/{customerId}/{companyId}`;
export const XPLAN_MGT_TRUSTS_API = `${XPLAN_MGT_PROXY_BASE_API}/trusts/{customerId}`;
export const XPLAN_MGT_TRUST_ITEM_API = `${XPLAN_MGT_PROXY_BASE_API}/trusts/{customerId}/{trustId}`;
export const XPLAN_MGT_CONTACT_NOTES_API = `${XPLAN_MGT_PROXY_BASE_API}/contactFileNotes/{customerId}/{contactId}`;
export const XPLAN_MGT_CONTACT_NOTE_ATTACHMENTS_API = `${XPLAN_MGT_PROXY_BASE_API}/contactFileNotes/{customerId}/{contactId}/attachments/{fileNoteId}`;

export const XPLAN_MGT_COMPLETE_MIGRATION_API = `${XPLAN_MGT_PROXY_BASE_API}/finalize/{customerId}`;
export const CONNECTED_EMAIL_API = `${BASE_API}/orgMailSystem/{organizationId}/connectedEmail`;
export const CONNECTED_EMAIL_ITEM_API = `${BASE_API}/orgMailSystem/{organizationId}/connectedEmail/{connectedEmailId}`;
export const EMAIL_TEMPLATES_API = `${BASE_API}/orgMailSystem/{organizationId}/emailTemplates`;
export const EMAIL_TEMPLATE_ITEM_API = `${BASE_API}/orgMailSystem/{organizationId}/emailTemplates/{templateId}`;
export const EMAIL_SIGNATURE_API = `${BASE_API}/orgMailSystem/{organizationId}/emailSignatures`;
export const EMAIL_SIGNATURE_ITEM_API = `${BASE_API}/orgMailSystem/{organizationId}/emailSignatures/{signatureId}`;
export const EMAIL_SEND_API = `${BASE_API}/orgMailSystem/{organizationId}/bulkEmail`;

// analytics
export const BASE_ANALYTICS_API = `${BASE_API}/analytics/{organizationId}`;
export const ANALYTICS_CONTACT_WORTH_API = `${BASE_ANALYTICS_API}/contacts/worthPerEach`;
export const ANALYTICS_DATE_RANGE_AGGREGATION_API = `${BASE_ANALYTICS_API}/org/dateRangeAggregatedStat`;
export const ANALYTICS_DATE_RANGE_REVENUE_API = `${BASE_ANALYTICS_API}/org/dateRangeRevenueStat`;
export const ANALYTICS_ENTITY_COUNTS_API = `${BASE_ANALYTICS_API}/org/generalStatsCount`;
export const ANALYTICS_MONTH_REVENUE_API = `${BASE_ANALYTICS_API}/org/monthRevenueStat`;
export const ANALYTICS_USER_TRANSACTIONS_API = `${BASE_ANALYTICS_API}/users/transactions/{userId}`;
export const ANALYTICS_UPCOMING_BIRTHDAYS_API = `${BASE_ANALYTICS_API}/contacts/upcomingBirthdays`;
export const ANALYTICS_UPCOMING_EVENTS_API = `${BASE_ANALYTICS_API}/users/upcomingEvents`;
export const ANALYTICS_USER_EARNINGS_API = `${BASE_ANALYTICS_API}/users/earnings`;

export const SUBMIT_APP_ISSUE_API = `${BASE_API}/misc/issues/{organizationId}/submit`;
export const SERVICE_FEE_FILES_API = `${BASE_API}/servicesFees/{organizationId}/originalServiceFeeFiles`;
export const OriginalServiceFeeFiles_LIST = `${BASE_API}/servicesFees/{organizationId}/originalServiceFeeFiles`;
export const OriginalServiceFeeFilesAudit_LIST = `${BASE_API}/servicesFees/{organizationId}/originalServiceFeeFilesAudit`;
