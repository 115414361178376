import { Project } from "../../../models/Task/Project";
import { ProjectTemplate } from "../../../models/Task/ProjectTemplate";
import { TProjectCatg } from "../../../models/Task/Types";
import createReducer from "../../utils/createReducer";
import ActionTypes from "./actionTypes";

export type ProjectStateT = {
    projects: { [k in TProjectCatg]: Project[] };
    templates: ProjectTemplate[];
    loadingProjects: TProjectCatg | null;
    projectsLoaded: TProjectCatg[];
    loadingTemplates: boolean;
    templatesLoaded: boolean;
};

export const initialState: ProjectStateT = {
    projects: {
        user: [],
        org: [],
    },
    loadingProjects: null,
    projectsLoaded: [],
    loadingTemplates: false,
    templatesLoaded: false,
    templates: [],
};

// App Reducer
export default createReducer<ProjectStateT>(initialState, {
    [ActionTypes.SAVE_PROJECTS]: (state, payload: { [k in Partial<TProjectCatg>]: Project[] }) => ({
        ...state,
        projects: { ...state.projects, ...payload },
    }),
    [ActionTypes.LOADED_PROJECTS]: (state, payload: TProjectCatg) => ({
        ...state,
        projectsLoaded: [...state.projectsLoaded.filter((t) => t !== payload), payload],
    }),
    [ActionTypes.LOADING_PROJECTS]: (state, payload: TProjectCatg) => ({
        ...state,
        loadingProjects: payload,
    }),

    [ActionTypes.REMOVE_PROJECT]: (state, payload: Project) => ({
        ...state,
        projects: Object.keys(state.projects).reduce((curr, key) => {
            const projectsForKey = state.projects[key as TProjectCatg];

            if (Array.isArray(projectsForKey)) {
                const filtered = projectsForKey.filter((t) => t.id !== payload.id);
                curr[key as TProjectCatg] = filtered;
            } else {
                console.warn(`Projects for key ${key} is not an array.`);
                curr[key as TProjectCatg] = projectsForKey;
            }

            return curr;
        }, {} as { [k in TProjectCatg]: Project[] }),
    }),

    [ActionTypes.SAVE_TEMPLATES]: (state, payload: ProjectTemplate[]) => ({
        ...state,
        templates: payload,
    }),
    [ActionTypes.LOADED_TEMPLATES]: (state, payload: boolean) => ({
        ...state,
        templatesLoaded: payload,
    }),
    [ActionTypes.LOADING_TEMPLATES]: (state, payload: boolean) => ({
        ...state,
        loadingTemplates: payload,
    }),

    [ActionTypes.REMOVE_TEMPLATE]: (state, payload: ProjectTemplate) => ({
        ...state,
        templates: state.templates.filter((r) => r.id !== payload.id),
    }),

    [ActionTypes.LOGOUT_ACTION]: (state) => ({
        ...state,
        ...initialState,
    }),
});
