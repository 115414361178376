import { Alert, Button, Card, Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";
import ArrowRightIcon from "@ant-design/icons/ArrowRightOutlined";
import { Auth0Lock } from "auth0-lock";
import SimpleCenteredLayout from "../../../layouts/SimpleCentered/SimpleCenteredLayout";
import Styles from "./Login.scss";
import { AppManager } from "../../../manager";
import { useLocation } from "react-router-dom";
import Logo from "../../../components/Logo/Logo";

const AUTH0_DEFAULT_OPTIONS = {
    auth: {
        responseType: "token id_token",
        sso: true,
        redirect: true,
        redirectUrl: `${window.origin}/#/auth/login`,
        params: {
            scope: "openid profile email",
        },
    },
    theme: {
        logo: "https://portal.ausplan.net.au/logo.png",
        primaryColor: "#4752ac",
    },
    languageDictionary: {
        title: "Login to Ausplan",
    },
    allowSignUp: false,
    autoclose: true,
    autofocus: true,
};

type LoginBoxProps = {
    authDomain?: string | null;
    authID?: string | null;
    redirectTo?: string;
};

const LoginBox: React.FC<LoginBoxProps> = ({ authDomain, authID, redirectTo }) => {
    const [loading, setLoading] = useState(false);
    const lockRef = useRef<Auth0LockStatic>();

    const handleAuth0Login = () => lockRef.current?.show();

    const handleAuth0Response = (authResult: AuthResult) => {
        console.log("AUTH RESULT ", authResult);
        setLoading(true);
        AppManager.auth.loginUserFromAuth0(authResult.idToken, authResult.idTokenPayload.nonce, (err) => {
            setLoading(false);
            if (err) AppManager.alert.toastError(err);
            else {
                if (redirectTo) AppManager.route.goto(redirectTo);
                else AppManager.route.gotoHome();
            }
        });
    };

    useEffect(() => {
        if (authID && authDomain) {
            lockRef.current = new Auth0Lock(authID, authDomain, AUTH0_DEFAULT_OPTIONS);
            console.log("LOCK INIT...");
            lockRef.current.on("authenticated", handleAuth0Response);
            lockRef.current.on("show", () => setLoading(true));
            lockRef.current.on("hide", () => setLoading(false));
        }
    }, [authID, authDomain]);
    return (
        <Spin spinning={loading} tip="Processing...">
            <Card className={Styles.LoginBoxContainer}>
                <div className={Styles.LoginBoxHeader}>
                    <div className={Styles.WelcomeText}>Welcome!</div>
                    <div className={Styles.SigninText}>Please signin to continue</div>
                    <div className={Styles.LogoWrapper}>
                        <Logo />
                    </div>
                </div>
                <div className={Styles.ActionWrapper}>
                    <Button type="primary" size="large" disabled={!authDomain || !authID || loading} onClick={handleAuth0Login}>
                        Login to continue <ArrowRightIcon />
                    </Button>
                </div>
            </Card>
        </Spin>
    );
};

const LoginPage = () => {
    const [loadingText, setLoadingText] = useState<string>();
    const [authDomain, setAuthDomain] = useState<string | null>();
    const [authID, setAuthID] = useState<string | null>();
    const [error, setError] = useState<string | null>();
    const [redirectTo, setRedirectTo] = useState<string>();
    const location = useLocation();

    const handleInitialize = () => {
        setLoadingText("Initializing...");
        setError(null);
        AppManager.auth.fetchAuthInfo((err, resp) => {
            setLoadingText("");
            if (err) {
                AppManager.alert.toastError(err);
                setError(err);
            } else {
                setAuthDomain(resp!.authDomain);
                setAuthID(resp!.authID);
            }
        });
    };

    const gotoSignup = () => AppManager.route.gotoSignup();

    useEffect(() => {
        handleInitialize();
        setRedirectTo(location.state);
    }, []);
    return (
        <SimpleCenteredLayout>
            <Spin spinning={!!loadingText} tip={loadingText}>
                <div className={Styles.Container}>
                    {error && (
                        <Alert
                            message={
                                <div className={Styles.ErrorAlert}>
                                    {error}
                                    <Button type="link" onClick={handleInitialize}>
                                        Reload
                                    </Button>
                                </div>
                            }
                            showIcon
                            type="error"
                            style={{ marginBottom: 15 }}
                        />
                    )}
                    <LoginBox authDomain={authDomain} authID={authID} redirectTo={redirectTo} />
                    <div className={Styles.SignupPrompt}>
                        <div className={Styles.Text}>Don&apos;t have an account?</div>{" "}
                        <Button size="small" onClick={gotoSignup}>
                            Signup Here
                        </Button>
                    </div>
                </div>
            </Spin>
        </SimpleCenteredLayout>
    );
};

export default LoginPage;
