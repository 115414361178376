import { combineReducers } from "redux";
import app, { AppStateT } from "./services/app/reducer";
import contacts, { ContactStateT } from "./services/contacts/reducer";
import auth, { AuthStateT } from "./services/auth/reducer";
import user, { UserStateT } from "./services/user/reducer";
import orgs, { OrgStateT } from "./services/organizations/reducer";
import documents, { DocumentStateT } from "./services/documents/reducer";
import servicefee, { ServiceFeeStateT } from "./services/servicesfees/reducer";
import diary, { DiaryStateT } from "./services/diary/reducer";
import tasks, { TaskStateT } from "./services/task/reducer";
import projects, { ProjectStateT } from "./services/project/reducer";
import subscriptions, { SubscriptionStateT } from "./services/subscription/reducer";
import notifications, { NotificationStateT } from "./services/notifications/reducer";

// Root state of the store
export type RootState = {
    app: AppStateT;
    auth: AuthStateT;
    contacts: ContactStateT;
    documents: DocumentStateT;
    user: UserStateT;
    orgs: OrgStateT;
    servicefee: ServiceFeeStateT;
    diary: DiaryStateT;
    tasks: TaskStateT;
    projects: ProjectStateT;
    subscriptions: SubscriptionStateT;
    notifications: NotificationStateT;
};

// Root reducer configuration
const rootReducer = combineReducers<RootState>({
    app,
    auth,
    contacts,
    documents,
    user,
    orgs,
    servicefee,
    diary,
    tasks,
    projects,
    subscriptions,
    notifications,
});

export default rootReducer;
