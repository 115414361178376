import { Select, SelectProps } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AppManager } from "../../../manager";
import { Organization } from "../../../models/Organization/Organization";
import { OrgMember } from "../../../models/Organization/OrgMember";
import { RootState } from "../../../redux/reducers";
import UserDisplay from "./OrgMemberDisplay";

const OrgMemberSelect: React.FC<SelectProps<string> & { filterType?: "Planner" | "Role"; showType?: boolean }> = ({ value, ...props }) => {
    const activeOrg = useSelector<RootState, Organization>((state) => state.orgs.activeOrg!);
    const [orgMembers, setOrgMembers] = useState<OrgMember[]>(activeOrg.data.members || []);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        if (!orgMembers) {
            setLoading(true);
            AppManager.org.apiFetchOrgMembers(activeOrg, () => setLoading(false));
        }

        activeOrg.data.watch("MEMBERS_UPDATED", setOrgMembers);
        return () => activeOrg.data.unwatch("MEMBERS_UPDATED", setOrgMembers);
    }, []);

    return (
        <Select {...props} value={typeof value === "string" ? value : (value as any)?._id} loading={loading} optionLabelProp="label">
            {orgMembers.map((member) => (
                <Select.Option key={member.id} value={member.attrs.user._id} label={`${member.attrs.user.firstName} ${member.attrs.user.lastName}`}>
                    <UserDisplay user={member} link={false} showType={props.showType} />
                </Select.Option>
            ))}
        </Select>
    );
};

export default OrgMemberSelect;
