import { ISimpleUser } from "../User/SimpleUserType";
import { TContactType } from "./Types";

export type ContactFileAttrs = {
    _id: string;
    name: string;
    type: string;
    size: number;
    source: "note-attachment" | "uploaded" | "signed-document" | string;
    blobUrl: string;
    blobName: string;
    contact: string;
    contactType: TContactType;
    createdBy: string | ISimpleUser;
    createdAt: Date;
    updatedAt: Date;
};

export class ContactFile {
    public readonly id: string;
    constructor(public attrs: ContactFileAttrs) {
        this.id = attrs._id;
    }

    updateFile(fileAttrs: ContactFileAttrs) {
        this.attrs = fileAttrs;
        return this;
    }

    static fromArray(files: ContactFileAttrs[]) {
        return files.map((t) => new ContactFile(t));
    }
}
