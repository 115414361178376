import moment from "moment";
import { Watchable } from "../_Utils/Watchable";
import { TTaskAttr } from "./Types";

type TaskEventMap = {
    ATTRIBUTES_UPDATED: TTaskAttr;
    TASK_DELETED: null;
};

class TaskData extends Watchable<TaskEventMap> {
    constructor(attrs: TTaskAttr) {
        super();
        this.cAttrs = attrs;
        this.attrs = attrs;
    }

    private cAttrs: TTaskAttr;

    triggerTaskDeleted() {
        this.trigger("TASK_DELETED", null);
    }

    set attrs(atr: TTaskAttr) {
        this.cAttrs = atr;
        this.cAttrs.dueDate = this.cAttrs.dueDate ? moment(this.cAttrs.dueDate) : undefined;
        this.cAttrs.createdAt = moment(this.cAttrs.createdAt);
        this.cAttrs.updatedAt = moment(this.cAttrs.updatedAt);

        this.trigger("ATTRIBUTES_UPDATED", atr);
    }

    get attrs() {
        return this.cAttrs;
    }
}

export class Task {
    [x: string]: any;
    public data: TaskData;
    public readonly id: string;

    constructor(attributes: TTaskAttr) {
        this.data = new TaskData(attributes);
        this.id = attributes._id;
    }

    markDeleted() {
        this.data.triggerTaskDeleted();
    }

    updateTask(task: Task) {
        this.updateTaskAttrs(task.data.attrs);
        return this;
    }

    updateTaskAttrs(attrs: TTaskAttr) {
        this.data.attrs = attrs;
        return this;
    }

    remove() {
        this.data.triggerTaskDeleted();
    }
}
