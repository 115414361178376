import React, { useState } from "react";
import { Card, Input, Form, Button, Modal } from "antd";
import ArrowRightIcon from "@ant-design/icons/ArrowRightOutlined";
import Styles from "./MigrationSetup.scss";
import iressLogoImg from "../../../../../assets/img/Iress_logo.png";
import CheckIcon from "@ant-design/icons/CheckCircleFilled";
import classNames from "classnames";
import { AppManager } from "../../../../../manager";
import XplanModelOfficeHomePage from "../../../Apps/IresXplanModelOfficeMigrator/HomePage";
import XplanPlannerHomePage from "../../../Apps/IresXplanMigrator/HomePage";
import { Provider } from "react-redux";
import CloseIcon from "@ant-design/icons/CloseOutlined";
import { getAppStore } from "../../../../../redux/store";
import { Organization } from "../../../../../models/Organization/Organization";
import { RootState } from "../../../../../redux/reducers";
import { useSelector } from "react-redux";

const openMigrationModal = (onClose = () => {}, provider: string | undefined = undefined) => {
    const modal = Modal.info({
        width: "97%",
        icon: null,
        centered: true,
        content: (
            <Provider store={getAppStore()}>
                <div style={{ width: "100%", height: window.innerHeight - 130 }}>
                    {provider?.includes("plannercentral") ? <XplanPlannerHomePage hideUserAccBtn /> : <XplanModelOfficeHomePage hideUserAccBtn />}
                </div>
            </Provider>
        ),
        okText: "Close",
        okButtonProps: {
            type: "default",
            size: "small",
            style: { position: "absolute", top: 15, right: 15 },
            icon: <CloseIcon />,
            onClick: () =>
                AppManager.alert.confirmAction(
                    "Confirm Action",
                    "You can always continue or check the status of your migration from the settings page. Close migration wizard?",
                    (res) => {
                        if (res == "OK") {
                            modal.destroy();
                            onClose();
                        }
                    },
                    { okText: "Close Wizard" },
                ),
        },
    });
};

const MigrationSetup: React.FC<{ onNext: () => void }> = ({ onNext }) => {
    const activeOrg = useSelector<RootState, Organization>((state) => state.orgs.activeOrg!);
    const [preparingNext, setPreparingNext] = useState(false);
    const [skipping, setSkipping] = useState(false);
    const [selected, setSelectedMigration] = useState<"xplan_central" | "xplan_office">();

    const handleSkip = () => {
        AppManager.alert.confirmAction(
            "Confirm Action",
            "Skip migration? You can always do this from your settings page",
            (res) => {
                if (res === "OK") {
                    setSkipping(true);
                    AppManager.org.apiAddCompletedInitialSetupItem(activeOrg.id, "migration_setup", (err) => {
                        setSkipping(false);
                        if (err) AppManager.alert.error("Error", `${err}`);
                        else onNext();
                    });
                }
            },
            { okText: "YES, SKIP" },
        );
    };

    const handleNext = () => {
        setPreparingNext(true);
        AppManager.org.apiAddCompletedInitialSetupItem(activeOrg.id, "migration_setup", (err) => {
            setPreparingNext(false);
            if (err) AppManager.alert.error("Error", `${err}`);
            else onNext();
        });
    };

    return (
        <Card className="match-parent animate__animated animate__zoomIn animate__faster">
            <div className={Styles.Container}>
                <div className={Styles.Header}>Migrate from other providers</div>
                <div className={Styles.Description}>Please select a provider to migrate all of your data to Ausplan</div>
                <div className={Styles.Content}>
                    <div
                        className={classNames(Styles.MigrationSource, { [Styles.Active]: selected === "xplan_central" })}
                        onClick={() => setSelectedMigration("xplan_central")}
                    >
                        <img src={iressLogoImg} width="90" />
                        <div className={Styles.Name}>Iress Xplan Planner Central</div>
                        <CheckIcon className={Styles.SelectedIndicatorIcon} />
                    </div>
                    <div
                        className={classNames(Styles.MigrationSource, { [Styles.Active]: selected === "xplan_office" })}
                        onClick={() => setSelectedMigration("xplan_office")}
                    >
                        <img src={iressLogoImg} width="90" />
                        <div className={Styles.Name}>Iress Xplan Model Office</div>
                        <CheckIcon className={Styles.SelectedIndicatorIcon} />
                    </div>
                </div>
                <div className={Styles.Actions}>
                    <Button
                        size="large"
                        type="primary"
                        onClick={() =>
                            openMigrationModal(
                                handleNext,
                                {
                                    xplan_office: "https://modeloffice.xplan.iress.com.au",
                                    xplan_central: "https://plannercentral.xplan.iress.com.au",
                                }[selected as NonNullable<typeof selected>],
                            )
                        }
                        disabled={!selected || skipping || preparingNext}
                    >
                        Launch Migration Wizard
                    </Button>
                    <Button onClick={handleSkip} style={{ margin: 10 }} type="link" danger loading={skipping} disabled={skipping || preparingNext}>
                        Skip this setup
                    </Button>
                </div>
            </div>
        </Card>
    );
};

export default MigrationSetup;
