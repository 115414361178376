import { AppManager } from "..";
import { QueryResultCallback } from "../../models/Callback/Callback";
import { ContactUtils } from "../../models/Contact/Utils";
import { INotification } from "../../models/Notification/Notification";
import { NotificationStoreActions } from "../../redux/services/notifications/actions";
import { APIManager } from "../APIManager";
import { NotificationNetworkHelper } from "./NetworkHelper";

export class NotificationManager {
    static networkHelper = NotificationNetworkHelper;
    static async apiFetchUserNotifications(cb: QueryResultCallback<INotification[]>) {
        try {
            NotificationStoreActions.loadingNotifications(true);
            const resp = await APIManager.get(APIManager.NOTIFICATION.LIST_API);
            NotificationStoreActions.saveNotifications(resp.data);
            NotificationStoreActions.setNotificationsLoaded(true);
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        } finally {
            NotificationStoreActions.loadingNotifications(false);
        }
    }

    static async apiDeleteAllNotifications(cb: QueryResultCallback<INotification[]>) {
        try {
            const resp = await APIManager.delete(APIManager.NOTIFICATION.LIST_API);
            NotificationStoreActions.saveNotifications([]);
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    static async apiDeleteSingleNotification(notfId: string, cb: QueryResultCallback<INotification[]>) {
        try {
            const resp = await APIManager.delete(APIManager.NOTIFICATION.ITEM_API(notfId));
            NotificationStoreActions.removeNotification(notfId);
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    static async apiMarkAllNotificationsAsRead(cb: QueryResultCallback<INotification[]>) {
        try {
            const resp = await APIManager.put(APIManager.NOTIFICATION.READ_ALL_API, null);
            NotificationStoreActions.readAll();
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    static onNewNotification(notf: INotification) {
        NotificationStoreActions.addNewNotification(notf);
        const user = AppManager.user.getActiveUser();
        if (user) {
            const notfSettings = user.data.attrs.notificationSettings;
            if (!notfSettings || !notfSettings.pauseAll) {
                switch (notf.type) {
                    case "CONTACT_ASSIGNED":
                        {
                            const data = (notf as INotification<"CONTACT_ASSIGNED">).data;
                            AppManager.alert.notify(
                                "New Contact Assigned",
                                `${ContactUtils.getDisplayName(data.contact)} assigned to you by ${data.createdBy.firstName} ${
                                    data.createdBy.lastName
                                }`,
                            );
                        }
                        break;
                    case "CONTACT_UNASSIGNED":
                        {
                            const data = (notf as INotification<"CONTACT_UNASSIGNED">).data;
                            AppManager.alert.notify(
                                "Contact Unassigned",
                                `${ContactUtils.getDisplayName(data.contact)} unassigned from you by ${data.createdBy.firstName} ${
                                    data.createdBy.lastName
                                }`,
                            );
                        }
                        break;
                    case "CONTACT_REGISTRATION_COMPLETED":
                        if (notfSettings?.browser?.contactActivity) {
                            const data = (notf as INotification<"CONTACT_REGISTRATION_COMPLETED">).data;
                            AppManager.alert.notify(
                                "Contact Registration Complete",
                                `Your contact ${ContactUtils.getDisplayName(data.contact)} has completed their registration.`,
                                "success",
                            );
                        }
                        break;
                    case "DOCUMENT_SIGNED":
                        if (notfSettings?.browser?.contactActivity) {
                            const data = (notf as INotification<"DOCUMENT_SIGNED">).data;
                            AppManager.alert.notify(
                                "Document Sign Complete",
                                `Your assigned contact ${ContactUtils.getDisplayName(data.contact)} has signed and returned the document ${
                                    data.document.name
                                }.`,
                                "success",
                            );
                        }
                        break;
                    case "ORG_MEMBER_REMOVED":
                        {
                            const data = (notf as INotification<"ORG_MEMBER_REMOVED">).data;
                            AppManager.alert.notify(
                                "Removed From Organization",
                                `You have been removed from ${data.organization.name} by ${data.createdBy.firstName} ${data.createdBy.lastName}.`,
                            );
                        }
                        break;
                    case "TASK_ASSIGNED":
                        if (notfSettings?.browser?.taskUpdates) {
                            const data = (notf as INotification<"TASK_ASSIGNED">).data;
                            AppManager.alert.notify(
                                "New Task Assigned",
                                `The task "${data.task.title}" has been assigned to you by ${data.createdBy.firstName} ${data.createdBy.lastName}.`,
                            );
                        }
                        break;
                    case "TASK_UNASSIGNED":
                        if (notfSettings?.browser?.taskUpdates) {
                            const data = (notf as INotification<"TASK_UNASSIGNED">).data;
                            AppManager.alert.notify(
                                "Task Unassigned",
                                `You have been unassigned from the task "${data.task.title}" by ${data.createdBy.firstName} ${data.createdBy.lastName}.`,
                            );
                        }
                        break;
                    case "TASK_COMPLETED":
                        if (notfSettings?.browser?.taskUpdates) {
                            const data = (notf as INotification<"TASK_COMPLETED">).data;
                            AppManager.alert.notify(
                                "Task Completed",
                                `${data.createdBy.firstName} ${data.createdBy.lastName} has completed the task "${data.task.title}" which you earlier assigned to them`,
                                "success",
                            );
                        }
                        break;
                    case "DEALER_GROUP_ADDED_TO_ORG":
                        const data = (notf as INotification<"DEALER_GROUP_ADDED_TO_ORG">).data;
                        AppManager.alert.notify(
                            "Dealer Group Access Granted",
                            `The dealer group ${data.dGroup.name}, now has access to your organization's data`,
                            "success",
                        );
                        break;
                    case "USER_MAIL_LOOP_ERROR":
                        const message = (notf as INotification<"USER_MAIL_LOOP_ERROR">).data.message;
                        AppManager.alert.notify("Connected Email Error", `${message}`, "error");
                        break;
                    case "DEFAULT":
                        {
                            const data = (notf as INotification<"DEFAULT">).data;
                            AppManager.alert.notify("Notification", `${data}`);
                        }
                        break;
                }
            }
            // handle browser notf here
            console.log("\n\n WILL PUSH BROWSER NOTF HERE ", notf, "\n\n", notfSettings);
        }
    }
}
