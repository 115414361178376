import { AppManager } from "..";
import { QueryResultCallback } from "../../models/Callback/Callback";
import { TContactType } from "../../models/Contact/Types";
import { DocumentTemplate } from "../../models/Document/DocumentTemplate";
import { TDocumentTemplateAttr } from "../../models/Document/Types";
import { DocTemplateStoreActions } from "../../redux/services/documents/actions";
import { AzureBlobStorage } from "../../redux/utils/AzureBlobStorage";
import { APIManager } from "../APIManager";

export class TemplateManager {
    static newTemplateObj(data: TDocumentTemplateAttr) {
        return new DocumentTemplate(data);
    }

    getTemplate(templateId: string) {
        return DocTemplateStoreActions.getDocumentTemplates().find((t) => t.id === templateId);
    }

    async apiFetchAllTemplates(cb: QueryResultCallback<DocumentTemplate[]>) {
        try {
            DocTemplateStoreActions.loadingTemplate(true);
            const resp = await APIManager.get(APIManager.DOCUMENT.TEMPLATES_API);
            const templates: DocumentTemplate[] = resp.data.map((t: TDocumentTemplateAttr) => this.updateTemplateAttrOrAddNew(t, false));
            DocTemplateStoreActions.saveTemplates(templates);
            DocTemplateStoreActions.setTemplatesLoaded(true);
            cb(null, templates);
        } catch (err) {
            cb(`${err}`);
        } finally {
            DocTemplateStoreActions.loadingTemplate(false);
        }
    }

    async apiFetchTemplateAttrs(templateId: string, cb: QueryResultCallback<DocumentTemplate>) {
        try {
            const resp = await APIManager.get(APIManager.DOCUMENT.TEMPLATE_ITEM_API(templateId));
            const template = this.updateTemplateAttrOrAddNew(resp.data);
            cb(null, template);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiSaveDocumentTemplate(templatefile: File, category: string | null, cb: QueryResultCallback<DocumentTemplate>) {
        AzureBlobStorage.newInstanceAsync((err, azure) => {
            if (!err && azure) {
                const activeOrg = AppManager.org.getCurrentActiveOrg()!;
                const tmplBlobName = `${activeOrg.id}/doctemplates/${templatefile.name}`;
                azure.uploadToBlobStorage(templatefile, tmplBlobName, async (err, respData) => {
                    if (err) cb(`${err}`);
                    else {
                        const templateData = {
                            name: templatefile.name,
                            description: undefined, // just for test. can be removed
                            fileType: templatefile.type,
                            fileSize: templatefile.size,
                            blobName: respData!.blobName,
                            fileUrl: respData!.blobUrl,
                            category: category,
                        };
                        try {
                            const resp = await APIManager.post(APIManager.DOCUMENT.TEMPLATES_API, templateData);
                            const template: DocumentTemplate = TemplateManager.newTemplateObj(resp.data);
                            DocTemplateStoreActions.addOrUpdateToTemplates(template);
                            cb(null, template);
                        } catch (err) {
                            azure.deleteBlobFromStorage(respData!.blobName);
                            cb(`${err}`);
                        }
                    }
                });
            } else cb(`Failed to initialize upload ${err}`);
        });
    }

    async apiDeleteDocumentTemplate(templateId: string, cb: QueryResultCallback<TDocumentTemplateAttr>) {
        try {
            AzureBlobStorage.newInstanceAsync(async (err, azure) => {
                if (!azure) return cb(`Failed to initialize delete operation: ${err}`);
                try {
                    const fileItem = await APIManager.get(APIManager.DOCUMENT.TEMPLATE_ITEM_API(templateId));
                    const { blobName } = fileItem.data;
                    azure.deleteBlobFromStorage(blobName, async (err) => {
                        if (err) {
                            cb(`${err}`);
                        } else {
                            try {
                                const resp = await APIManager.delete(APIManager.DOCUMENT.TEMPLATE_ITEM_API(templateId));
                                const template = this.getTemplate(templateId);
                                if (template) {
                                    template.markDeleted();
                                    DocTemplateStoreActions.removeTemplate(template);
                                }
                                cb(null, resp.data);
                            } catch (err2) {
                                azure.undeleteBlobFromStorage(blobName);
                                cb(`${err2}`);
                            }
                        }
                    });
                } catch (err3) {
                    cb(`${err3}`);
                }
            });
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiSendTemplateToSign(templateId: string, contactId: string, contactType: TContactType, cb: QueryResultCallback<DocumentTemplate[]>) {
        const SIGN_API =
            contactType === "Individual"
                ? APIManager.DOCUMENT.TEMPLATE_INDIVIDUAL_SIGN_API
                : contactType === "Company"
                ? APIManager.DOCUMENT.TEMPLATE_COMPANY_SIGN_API
                : contactType === "Trust"
                ? APIManager.DOCUMENT.TEMPLATE_TRUST_SIGN_API
                : "";

        try {
            const resp = await APIManager.post(SIGN_API, { templateId, contactId });
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        } finally {
            DocTemplateStoreActions.loadingTemplate(false);
        }
    }

    async apiUpdateTemplate(templateId: string, templateData: Partial<TDocumentTemplateAttr>, cb: QueryResultCallback<TDocumentTemplateAttr>) {
        try {
            const resp = await APIManager.put(APIManager.DOCUMENT.TEMPLATE_ITEM_API(templateId), templateData);
            const template = this.getTemplate(templateId);
            if (template) {
                template.updateTemplateAttrs(resp.data);
            }
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiVerifyTemplate(templateFile: File, cb: QueryResultCallback<TDocumentTemplateAttr>) {
        try {
            const data = new FormData();
            data.append("file", templateFile, templateFile.name);
            const resp = await APIManager.post(APIManager.DOCUMENT.TEMPLATE_ITEM_VERIFY_API, data);
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiGetTemplateContentTags(templateId: string, cb: QueryResultCallback<TDocumentTemplateAttr>) {
        try {
            const resp = await APIManager.get(APIManager.DOCUMENT.TEMPLATE_ITEM_CONTENTTAGS_API(templateId));
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiBuildDocTemplate(templateId: string, data: any, cb: QueryResultCallback<any>) {
        try {
            const resp = await APIManager.post(APIManager.DOCUMENT.TEMPLATE_ITEM_BUILD_API(templateId), data);
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiDownloadTemplateDocData(templateBlobName: string, cb: QueryResultCallback<Blob>) {
        AzureBlobStorage.newInstanceAsync(async (err, azure) => {
            if (err || !azure) cb(`Failed to initialize template download ${err}`);
            else {
                try {
                    await azure.downloadBlobFromStorage(templateBlobName, (err, data) => {
                        if (err || !data) cb(err ? err : "No data received for this template. Please report this issue for further actions.");
                        else cb(null, data);
                    });
                } catch (err2) {
                    cb(err);
                }
            }
        });
    }

    async apiFetchTemplate(templateId: string, cb: QueryResultCallback<DocumentTemplate>) {
        try {
            const resp = await APIManager.get(APIManager.DOCUMENT.TEMPLATE_ITEM_API(templateId));
            let template = this.getTemplate(templateId);
            if (template) {
                template = template.updateTemplateAttrs(resp.data);
            } else {
                template = TemplateManager.newTemplateObj(resp.data);
                DocTemplateStoreActions.addOrUpdateToTemplates(template);
            }

            if (template.data.docData) {
                // We shouldn't download the template document if it was already done
                cb(null, template);
            } else {
                await this.apiDownloadTemplateDocData(template!.data.attrs.blobName, (err, data) => {
                    if (err) cb(err);
                    else {
                        template!.updateDocData(data!);
                        cb(null, template);
                    }
                });
                // cb(null, template);
            }
        } catch (err) {
            cb(`${err}`);
        }
    }

    updateTemplateAttrOrAddNew(templateAttr: TDocumentTemplateAttr, saveToStore = true) {
        let template = this.getTemplate(templateAttr._id);
        if (template) {
            template.updateTemplateAttrs(templateAttr);
        } else {
            template = TemplateManager.newTemplateObj(templateAttr);
        }
        if (saveToStore) DocTemplateStoreActions.addOrUpdateToTemplates(template);
        return template;
    }

    removeTemplate(templateId: string) {
        const template = this.getTemplate(templateId);
        if (template) {
            template.remove();
            DocTemplateStoreActions.removeTemplate(template);
        }
    }
}
