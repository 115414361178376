/* eslint-disable prettier/prettier */
import responsiveObserve from "antd/lib/_util/responsiveObserve";
import { QueryResultCallback } from "../../models/Callback/Callback";
import { Task } from "../../models/Task/Task";
import { TTaskAttr, TTaskCatg, TTaskListItemAttr } from "../../models/Task/Types";
import { TaskStoreActions } from "../../redux/services/task/actions";
import { APIManager } from "../APIManager";
// import { TaskNetworkHelper } from "./TaskNetworkHelper";
import { AppManager } from "..";
import { TaskManager } from "./TaskManager";

export class TaskTemplateManager {
    // // networkHelper = TaskNetworkHelper;
    static newTaskObj(data: TTaskAttr) {
        return new Task(data);
    }

    getMyTaskTemplates() {
        const taskMap = TaskStoreActions.getTemplates();
        return taskMap.templates;
    }

    async apiFetchAllTaskTemplates(taskCatg: TTaskCatg, cb: QueryResultCallback<Task>) {
        try {
            TaskStoreActions.loadingTasks("templates");
            const resp = await APIManager.get(APIManager.TASKS.TASKS_TEMPLATES_API());

            TaskStoreActions.saveTasks({ [taskCatg]: resp.data });
            cb(null, resp?.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiAddTaskTemplate(
        taskData: Partial<Omit<TTaskAttr, "dueDate" | "tasklist"> & { dueDate?: string | undefined; tasklist: Partial<TTaskListItemAttr>[] }>,
        cb: QueryResultCallback<Task>,
    ) {
        try {
            const resp = await APIManager.post(APIManager.TASKS.TASK_SINGLE_TEMPLATE_API(), taskData);

            const taskTemplates = AppManager.task.getMyTaskTemplates();
            TaskStoreActions.saveTasks({ templates: [...taskTemplates, resp.data] });

            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    updateTemplateAttrOrAddNew(templateAttr: any, saveToStore = true) {
        const template = TaskManager.newTaskObj(templateAttr);
        if (saveToStore) TaskStoreActions.addOrUpdateToTaskTemplates(template);
        return template;
    }
}
