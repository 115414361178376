import { Moment } from "moment";
import { QueryResultCallback } from "../../models/Callback/Callback";
import { Trust } from "../../models/Contact/Trust/Trust";
import { ContactFileAttrs } from "../../models/Contact/ContactFile";
import { ContactNoteAttrs } from "../../models/Contact/ContactNote";
import { ContactServiceAttrs } from "../../models/Contact/ContactService";
import { TTrustAttrs, TContactDeletableProps } from "../../models/Contact/Types";
import { TrustStoreActions } from "../../redux/services/contacts/actions";
import { AzureBlobStorage } from "../../redux/utils/AzureBlobStorage";
import { APIManager } from "../APIManager";
import { formatUrl } from "../../helpers/misc";
import { AppManager } from "..";
import { CustomField, CustomFieldsData } from "../../models/Contact/CustomFields";

export class TrustManager {
    static newContactObj(data: TTrustAttrs) {
        return new Trust(data);
    }

    static newCustomFieldObj(data: CustomFieldsData) {
        return new CustomField(data);
    }

    getContact(trustId: string) {
        return TrustStoreActions.getTrusts().find((t) => t.id === trustId);
    }

    getCustomField(id: string) {
        return TrustStoreActions.getCustomFieldTrust().find((t) => t.id === id);
    }

    getAllCustomFields() {
        return TrustStoreActions.getCustomFieldTrust();
    }

    async apiFetchAllContacts(cb: QueryResultCallback<Trust[]>, includeDeleted = false, includeProspects = false) {
        try {
            TrustStoreActions.loadingTrusts(true);
            const resp = await APIManager.get(
                formatUrl(APIManager.CONTACT.TRUST.LIST_API, {
                    includeDeleted: includeDeleted ? "1" : undefined,
                    includeProspects: includeProspects ? "1" : undefined,
                }),
            );
            const trusts: Trust[] = resp.data.map((t: TTrustAttrs) => this.updateContactAttrOrAddNew(t, false));
            if (!includeDeleted) {
                TrustStoreActions.saveTrusts(trusts);
            }
            TrustStoreActions.setTrustLoaded(true);
            cb(null, trusts);

            return trusts;
        } catch (err) {
            cb(`${err}`);
        } finally {
            TrustStoreActions.loadingTrusts(false);
        }
    }

    async apiFetchContactServiceFees(
        cb: QueryResultCallback,
        contactId: string,
        startDate: Moment | null | undefined,
        endDate: Moment | null | undefined,
    ) {
        try {
            TrustStoreActions.loadingTrusts(true);
            const resp = await APIManager.get(APIManager.CONTACT.TRUST.SERVICE_FEE_API(contactId, startDate?.toISOString(), endDate?.toISOString()));
            cb(null, resp?.data);
        } catch (err) {
            cb(`${err}`);
        } finally {
            TrustStoreActions.loadingTrusts(false);
        }
    }

    async apiFetchDeletedContacts(cb: QueryResultCallback<Trust[]>) {
        try {
            TrustStoreActions.loadingTrusts(true);
            const resp = await APIManager.get(formatUrl(APIManager.CONTACT.TRUST.LIST_API, { deletedOnly: "1" }));
            const trusts: Trust[] = resp.data.map((t: TTrustAttrs) => this.updateContactAttrOrAddNew(t, false));
            TrustStoreActions.setTrustLoaded(true);
            cb(null, trusts);

            return trusts;
        } catch (err) {
            cb(`${err}`);
        } finally {
            TrustStoreActions.loadingTrusts(false);
        }
    }

    async apiFetchProspectContacts(cb: QueryResultCallback<Trust[]>) {
        try {
            TrustStoreActions.loadingTrusts(true);
            const resp = await APIManager.get(formatUrl(APIManager.CONTACT.TRUST.LIST_API, { prospectOnly: "1" }));
            const trusts: Trust[] = resp.data.map((t: TTrustAttrs) => this.updateContactAttrOrAddNew(t, false));
            TrustStoreActions.setTrustLoaded(true);
            cb(null, trusts);

            return trusts;
        } catch (err) {
            cb(`${err}`);
        } finally {
            TrustStoreActions.loadingTrusts(false);
        }
    }

    async apiFetchCustomFieldsContacts(cb: QueryResultCallback<CustomField[]>) {
        try {
            TrustStoreActions.loadingTrusts(true);
            const resp = await APIManager.get(formatUrl(APIManager.CONTACT.TRUST.LIST_CUSTOM_FIELDS_API));
            const trusts: CustomField[] = resp.data.map((t: CustomFieldsData) => this.updateContactCustomFieldsAttrOrAddNew(t));
            TrustStoreActions.setTrustLoaded(true);
            cb(null, trusts);

            return trusts;
        } catch (err) {
            cb(`${err}`);
        } finally {
            TrustStoreActions.loadingTrusts(false);
        }
    }

    async apiDeleteCustomField(customFieldId: string, cb: QueryResultCallback<CustomFieldsData>) {
        try {
            const resp = await APIManager.delete(APIManager.CONTACT.INDIVIDUAL.CUSTOM_FIELD_API(customFieldId));
            const trust = this.getCustomField(customFieldId);

            TrustStoreActions.removeCustomFieldsTrust(trust!);
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiSaveCustomField(data: Partial<CustomFieldsData>, cb: QueryResultCallback<CustomField>) {
        try {
            const resp = await APIManager.post(APIManager.CONTACT.INDIVIDUAL.CUSTOM_FIELDS_INDIVIDUAL_API, data);
            const individual: CustomField = TrustManager.newCustomFieldObj(resp.data);
            TrustStoreActions.addOrUpdateCustomFields(individual);
            cb(null, individual);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiUpdateCustomField(customFieldId: string, data: Partial<CustomFieldsData>, cb: QueryResultCallback<CustomField>) {
        try {
            const resp = await APIManager.put(APIManager.CONTACT.INDIVIDUAL.CUSTOM_FIELD_API(customFieldId), data);
            const individual: CustomField = TrustManager.newCustomFieldObj(resp.data);
            TrustStoreActions.addOrUpdateCustomFields(individual);
            cb(null, individual);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiFetchContactAttrs(trustId: string, cb: QueryResultCallback<Trust>) {
        try {
            const resp = await APIManager.get(APIManager.CONTACT.TRUST.ITEM_API(trustId));
            const trust = this.updateContactAttrOrAddNew(resp.data);
            cb(null, trust);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiFetchContactNotes(trustId: string, cb: QueryResultCallback<ContactNoteAttrs[]>) {
        try {
            const resp = await APIManager.get(APIManager.CONTACT.TRUST.NOTES_API(trustId));
            const trust = this.getContact(trustId);
            if (trust) {
                trust.updateNotes(resp.data);
            }
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiFetchContactServices(trustId: string, cb: QueryResultCallback<ContactServiceAttrs[]>) {
        try {
            const resp = await APIManager.get(APIManager.CONTACT.TRUST.SERVICES_API(trustId));
            const trust = this.getContact(trustId);
            if (trust) {
                trust.updateServices(resp.data);
            }
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiFetchContactFiles(trustId: string, cb: QueryResultCallback<ContactNoteAttrs[]>) {
        try {
            const resp = await APIManager.get(APIManager.CONTACT.TRUST.FILES_API(trustId));
            const trust = this.getContact(trustId);
            if (trust) {
                trust.updateFiles(resp.data);
            }
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async rehydrateContact(trustId: string, cb: QueryResultCallback<Trust[]>) {
        const trust = this.getContact(trustId);
        const errors: string[] = [];
        const callback = (err: string | null) => {
            if (err) errors.push(err);
        };
        if (trust) {
            await this.apiFetchContactAttrs(trustId, callback);
            await this.apiFetchContactNotes(trustId, callback);
            await this.apiFetchContactFiles(trustId, callback);
            await this.apiFetchContactServices(trustId, callback);
        }
        cb(errors.length ? errors.join("\n") : null);
    }

    async apiSaveContactAuto(indvData: Partial<TTrustAttrs>, templates: string[], cb: QueryResultCallback<Trust>) {
        try {
            const resp = await APIManager.post(APIManager.CONTACT.TRUST.AUTO_REGISTER_API, { contactData: indvData, templates });
            const trust: Trust = TrustManager.newContactObj(resp.data);
            TrustStoreActions.addOrUpdateToTrusts(trust);
            cb(null, trust);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiSaveContact(indvData: Partial<TTrustAttrs>, cb: QueryResultCallback<Trust>) {
        try {
            const resp = await APIManager.post(APIManager.CONTACT.TRUST.LIST_API, indvData);
            const trust: Trust = TrustManager.newContactObj(resp.data);
            TrustStoreActions.addOrUpdateToTrusts(trust);
            cb(null, trust);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiSaveContactNote(trustId: string, noteData: { title: string; note: string; type: string }, cb: QueryResultCallback<ContactNoteAttrs[]>) {
        try {
            const resp = await APIManager.post(APIManager.CONTACT.TRUST.NOTES_API(trustId), noteData);
            const trust = this.getContact(trustId);
            if (trust) {
                trust.addOrUpdateNote(resp.data);
            }
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiSaveContactService(
        trustId: string,
        serviceData: { serviceId: string; serviceDate: string },
        cb: QueryResultCallback<ContactServiceAttrs[]>,
    ) {
        try {
            const resp = await APIManager.post(APIManager.CONTACT.TRUST.SERVICES_API(trustId), serviceData);
            const trust = this.getContact(trustId);
            if (trust) {
                trust.addOrUpdateService(resp.data);
            }
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiSaveContactFile(trustId: string, contactNames: string, file: File, source: string, cb: QueryResultCallback<ContactFileAttrs>) {
        const azure = AzureBlobStorage.newInstance();
        const activeOrg = AppManager.org.getCurrentActiveOrg()!;
        const blobName = `${activeOrg.id}/contactdocs/trusts/${trustId}/${contactNames}`;
        azure.uploadToBlobStorage(file, blobName, async (err, respData) => {
            if (err) cb(`${err}`);
            else {
                const fileData = {
                    name: file.name,
                    type: file.type,
                    size: file.size,
                    source,
                    blobName: respData!.blobName,
                    blobUrl: respData!.blobUrl,
                };
                try {
                    const resp = await APIManager.post(APIManager.CONTACT.TRUST.FILES_API(trustId), fileData);
                    const trust = this.getContact(trustId);
                    if (trust) {
                        trust.addOrUpdateFile(resp.data);
                    }
                    cb(null, resp.data);
                } catch (err) {
                    azure.deleteBlobFromStorage(respData!.blobName);
                    cb(`${err}`);
                }
            }
        });
    }

    updateContactAttrOrAddNew(contactAttr: TTrustAttrs, saveToStore = true) {
        let trust = this.getContact(contactAttr._id);
        if (trust) {
            trust.updateTrustAttrs(contactAttr);
        } else {
            trust = TrustManager.newContactObj(contactAttr);
        }
        if (saveToStore) TrustStoreActions.addOrUpdateToTrusts(trust);
        return trust;
    }

    updateContactCustomFieldsAttrOrAddNew(contactAttr: CustomFieldsData, saveToStore = true) {
        let trust = this.getCustomField(contactAttr._id);
        if (trust) {
            trust.updateCustomField(contactAttr);
        } else {
            trust = TrustManager.newCustomFieldObj(contactAttr);
        }
        if (saveToStore) TrustStoreActions.addOrUpdateCustomFields(trust);
        return trust;
    }

    async apiUpdateContactNote(
        trustId: string,
        noteId: string,
        noteData: { title?: string; note?: string; type?: string },
        cb: QueryResultCallback<ContactNoteAttrs[]>,
    ) {
        try {
            const resp = await APIManager.put(APIManager.CONTACT.TRUST.NOTE_ITEM_API(trustId, noteId), noteData);
            const trust = this.getContact(trustId);
            if (trust) {
                console.log("NOTE UPDATED ", resp.data);
                trust.addOrUpdateNote(resp.data);
            }
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiLoadFullContactNote(trustId: string, noteId: string, cb: QueryResultCallback<ContactNoteAttrs[]>) {
        try {
            const resp = await APIManager.get(APIManager.CONTACT.TRUST.NOTE_ITEM_API(trustId, noteId));
            const trust = this.getContact(trustId);
            if (trust) trust.addOrUpdateNote(resp.data);
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiUpdateContact(trustId: string, contactData: Partial<TTrustAttrs>, cb: QueryResultCallback<Trust>) {
        try {
            const resp = await APIManager.put(APIManager.CONTACT.TRUST.ITEM_API(trustId), contactData);
            const trust = this.updateContactAttrOrAddNew(resp.data);
            cb(null, trust);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiDeleteContactNote(trustId: string, noteId: string, cb: QueryResultCallback<ContactNoteAttrs[]>) {
        try {
            const note = await APIManager.get(APIManager.CONTACT.TRUST.NOTE_ITEM_API(trustId, noteId));
            const noteAttrs: ContactNoteAttrs = note.data;
            for (const attachment of noteAttrs.attachments) {
                await new Promise((resolve, reject) => {
                    this.apiDeleteContactFile(trustId, typeof attachment == "string" ? attachment : attachment._id, (err, d) => {
                        if (err) reject(`${err}`);
                        else resolve(d!);
                    });
                });
            }
            const resp = await APIManager.delete(APIManager.CONTACT.TRUST.NOTE_ITEM_API(trustId, noteId));
            const trust = this.getContact(trustId);
            if (trust) {
                trust.removeNoteWithId(noteId);
            }
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiDeleteContactService(trustId: string, serviceId: string, cb: QueryResultCallback<ContactServiceAttrs[]>) {
        try {
            const resp = await APIManager.delete(APIManager.CONTACT.TRUST.SERVICE_ITEM_API(trustId, serviceId));
            const trust = this.getContact(trustId);
            if (trust) {
                trust.removeServiceWithId(serviceId);
            }
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiDeleteContactFile(trustId: string, fileId: string, cb: QueryResultCallback<ContactFileAttrs>) {
        const azure = AzureBlobStorage.newInstance();
        try {
            const fileItem = await APIManager.get(APIManager.CONTACT.TRUST.FILE_ITEM_API(trustId, fileId));
            const { blobName } = fileItem.data;
            azure.deleteBlobFromStorage(blobName, async (err) => {
                if (err) cb(`${err}`);
                else {
                    try {
                        const resp = await APIManager.delete(APIManager.CONTACT.TRUST.FILE_ITEM_API(trustId, fileId));
                        const trust = this.getContact(trustId);
                        if (trust) {
                            trust.removeFileWithId(fileId);
                        }
                        cb(null, resp.data);
                    } catch (err) {
                        azure.undeleteBlobFromStorage(blobName);
                        cb(`${err}`);
                    }
                }
            });
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiDeleteContact(trustId: string, cb: QueryResultCallback) {
        try {
            const resp = await APIManager.delete(APIManager.CONTACT.TRUST.ITEM_API(trustId));
            this.removeContact(trustId);
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiRestoreContact(trustId: string, cb: QueryResultCallback) {
        try {
            const resp = await APIManager.patch(APIManager.CONTACT.TRUST.ITEM_RESTORE_API(trustId));
            this.restoreContact(trustId);
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    restoreContact(trustId: string) {
        const trust = this.getContact(trustId);
        if (trust) {
        } else console.log("CONTACT WITH ID ", trustId, " NOT FOUND");
    }

    removeContact(trustId: string) {
        const trust = this.getContact(trustId);
        if (trust) {
            trust.remove();
            TrustStoreActions.removeTrust(trust);
        } else console.log("CONTACT WITH ID ", trustId, " NOT FOUND");
    }

    removeContactCustomFieldWithId(id: string) {
        const trusts = TrustStoreActions.getTrusts();

        const newTrusts: Trust[] = trusts.map((trust) => {
            trust.data.attrs.customField = trust.data.attrs.customField?.filter((c) => c.template._id !== id) || [];

            return trust;
        });

        TrustStoreActions.saveTrusts(newTrusts);
    }

    addNewContactCustomField(customField: { id: string; template: CustomFieldsData; value: string }) {
        const trusts = TrustStoreActions.getTrusts();

        const newCompanies: Trust[] = trusts.map((ind) => {
            ind.data.attrs.customField = [...(ind.data.attrs.customField || []), customField];

            return ind;
        });

        TrustStoreActions.saveTrusts(newCompanies);
    }

    async apiCheckContactDeletable(trustId: string, cb: QueryResultCallback<TContactDeletableProps>) {
        try {
            const resp = await APIManager.get(APIManager.CONTACT.TRUST.CHECK_CONTACT_DELETABLE_API(trustId));
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiSendEmail(individualId: string, emailData: { email: string; subject: string; body: string }, cb: QueryResultCallback<any>) {
        try {
            const resp = await APIManager.post(APIManager.CONTACT.TRUST.SEND_EMAIL_API(individualId), emailData);
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }
}
