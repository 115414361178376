import { QueryResultCallback } from "../../models/Callback/Callback";
import { TUserAttr, TUserData, TUserNotfSettingsAttr, User } from "../../models/User/User";
import { UserStoreActions } from "../../redux/services/user/actions";
import { APIManager } from "../APIManager";
import { OrgManager } from "../OrgManager/OrgManager";
import { UserNetworkHelper } from "./NetworkHelper";

export class UserManager {
    networkHelper = UserNetworkHelper;
    private newUserObj(data: TUserAttr) {
        return new User(data);
    }
    async apiFetchUserData(cb: QueryResultCallback<TUserData>) {
        try {
            UserStoreActions.loadingUser(true);
            const resp = await APIManager.get(APIManager.USER_DATA_API);
            const userData: TUserData = resp.data;
            UserStoreActions.saveUser(this.newUserObj(userData.userInfo));
            new OrgManager().saveUserOrganizations([...userData.orgs.owned, ...userData.orgs.membership]);
            cb(null, userData);
        } catch (err) {
            cb(`${err}`);
        } finally {
            UserStoreActions.loadingUser(false);
        }
    }
    async apiUpdateUserData(updateData: Partial<TUserAttr>, cb: QueryResultCallback<TUserAttr>) {
        try {
            const resp = await APIManager.put(APIManager.USER_DATA_API, updateData);
            this.updateActiveUserInfo(resp.data);
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }
    async apiUpdateUserNotfSettings(updateData: Partial<TUserNotfSettingsAttr>, cb: QueryResultCallback<TUserAttr>) {
        try {
            const resp = await APIManager.put(APIManager.USER_NOTIFICATION_SETTINGS_API, updateData);
            this.updateActiveUserInfo(resp.data);
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    updateActiveUserInfo(userInfo: TUserAttr) {
        const user = UserStoreActions.getUser();
        if (user && user.id === userInfo._id) {
            UserStoreActions.saveUser(user.updateUserAttrs(userInfo));
        }
    }
    getActiveUser() {
        return UserStoreActions.getUser();
    }
}
