import { BlobServiceClient, StorageSharedKeyCredential } from "@azure/storage-blob";
import { AppManager } from "../../manager";
import { QueryResultCallback } from "../../models/Callback/Callback";
import { getAppStore } from "../store";
// const { ACCOUNT_KEY, ACCOUNT_ID, AZURE_BLOB_STORAGE_CONTAINER, SHARED_ACCESS_SIGNATURE_TOKEN } = AZURE;

// const AZURE_BLOB_STORAGE_URL = `https://${ACCOUNT_ID}.blob.core.windows.net${SHARED_ACCESS_SIGNATURE_TOKEN}`;

type TUploadSuccess = { blobName: string; blobUrl: string };
type TUploadCallback = (err: string | null, data?: TUploadSuccess) => void;
type TDownloadCallback = (err: string | null, data?: Blob) => void;

export class AzureBlobStorage {
    constructor(/*private blobStorageUrl: string, private storageContainerName: string*/) {}
    getBlobStringName = (name: string, extension: string) => {
        return `${name}_${new Date().toISOString().replace(/:/g, "-").replace(/\.\d+/g, "").replace("T", "_").replace("Z", "")}${extension}`;
    };

    uploadToBlobStorage = async (file: File, bName: string, callback: TUploadCallback = () => {}) => {
        if (!(file instanceof File)) return callback("Invalid file instance");
        const formData = new FormData();
        formData.append("azure-blob", file);
        formData.append("blobName", bName);
        try {
            const resp = await AppManager.request.post(AppManager.request.STORAGE.AZURE_UPLOAD_API, formData);
            callback(null, resp.data);
        } catch (err) {
            console.log("ERROR ", err);
            callback(`${err}`);
        }
    };

    listBlobStorage = async (blobName: string, callback: TDownloadCallback = () => {}) => {
        try {
            const resp = await AppManager.request.request({
                method: "GET",
                responseType: "blob",
                url: AppManager.request.STORAGE.AZURE_LIST_API,
                data: { blobName },
            });
            callback(null, resp.data);
        } catch (err) {
            console.log("ERROR ", err);
            callback(`${err}`);
        }
    };

    deleteBlobFromStorage = async (blobName: string, callback: TUploadCallback = () => {}) => {
        try {
            const resp = await AppManager.request.post(AppManager.request.STORAGE.AZURE_DELETE_API, { blobName });
            callback(null, resp.data);
        } catch (err) {
            console.log("ERROR ", err);
            callback(`${err}`);
        }
    };

    undeleteBlobFromStorage = async (blobName: string, callback: TUploadCallback = () => {}) => {
        try {
            const resp = await AppManager.request.post(AppManager.request.STORAGE.AZURE_UNDELETE_API, { blobName });
            callback(null, resp.data);
        } catch (err) {
            console.log("ERROR ", err);
            callback(`${err}`);
        }
    };

    downloadBlobFromStorage = async (blobName: string, callback: TDownloadCallback = () => {}) => {
        try {
            const resp = await AppManager.request.request({
                method: "POST",
                responseType: "blob",
                url: AppManager.request.STORAGE.AZURE_DOWNLOAD_API,
                data: { blobName },
            });
            callback(null, resp.data);
        } catch (err) {
            console.log("ERROR ", err);
            callback(`${err}`);
        }
    };

    // static fromStoreCreds() {
    //     const azureCreds = getAppStore().getState().auth.azureCreds;
    //     if (azureCreds) return new AzureBlobStorage(azureCreds.blobStorageUrl, azureCreds.blobStorageContainer);
    //     return null;
    // }

    static newInstanceAsync(cb: QueryResultCallback<AzureBlobStorage>) {
        // const azureCreds = getAppStore().getState().auth.azureCreds;
        // if (azureCreds) cb(null, new AzureBlobStorage(azureCreds.blobStorageUrl, azureCreds.blobStorageContainer));
        // else {
        //     AppManager.auth.fetchAzureCredentials((err, creds) => {
        //         if (err) {
        //             cb(`${err}`);
        //         } else {
        //             cb(null, new AzureBlobStorage(creds!.blobStorageUrl, creds!.blobStorageContainer));
        //         }
        //     });
        // }
        cb(null, new AzureBlobStorage());
    }

    static newInstance() {
        return new AzureBlobStorage();
    }
}
