import { PublicClientApplication, AuthenticationResult, EventMessage, EventType } from "@azure/msal-browser";
import config from "./config";

const msalInstance = new PublicClientApplication({
    auth: {
        clientId: config.appId,
        redirectUri: config.redirectUri,
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: true,
    },
});

const accounts = msalInstance.getAllAccounts();
if (accounts && accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((evt: EventMessage) => {
    if (evt.eventType === EventType.LOGIN_SUCCESS && evt.payload) {
        const authResult = evt.payload as AuthenticationResult;
        msalInstance.setActiveAccount(authResult.account);
    }
});

export default msalInstance;
