import { AppManager } from ".";
import { QueryResultCallback } from "../models/Callback/Callback";
import { TIndividualAttrs } from "../models/Contact/Types";
import { TDocTokenMapAttr, TDocumentAttr } from "../models/Document/Types";
import { Organization } from "../models/Organization/Organization";
import { TOrganizationAttr, TOrgInvitation } from "../models/Organization/Types";
import { AuthStoreActions } from "../redux/services/auth/actions";
import { APIManager } from "./APIManager";

export class EphemeralGroupManager {
    async apiLoadOrganization(contactToken: string, organizationId: string, cb: QueryResultCallback<Organization>) {
        try {
            const resp = await APIManager.get(APIManager.HOOK_ORGANIZATION_INFO_API(organizationId, contactToken));
            const org = AppManager.org.hydrateFullOrganization(resp.data, true);

            cb(null, org);
        } catch (err) {
            cb(`${err}`);
        }
    }
    async setAccessToken(token: string) {
        AuthStoreActions.saveUserToken(token, false);
    }
    async apiLoadContactTokenMapAttrs(
        contactToken: string,
        organizationId: string,
        cb: QueryResultCallback<{ contact: TIndividualAttrs; organization: TOrganizationAttr; docTokenMaps: TDocTokenMapAttr[] }>,
    ) {
        try {
            const resp = await APIManager.get(APIManager.HOOK_CONTACT_INFO_API(organizationId, contactToken));
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiLoadOrgInvitationAttrs(authToken: string, organizationId: string, cb: QueryResultCallback<TOrgInvitation>) {
        try {
            const resp = await APIManager.get(APIManager.HOOK_INVITE_INFO_API(organizationId, authToken));
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiLoadRelatedTokenMaps(contactToken: string, organizationId: string, cb: QueryResultCallback<TDocTokenMapAttr[]>) {
        try {
            const resp = await APIManager.get(APIManager.HOOK_RELATED_TOKENMAPS_INFO_API(organizationId, contactToken));
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiUpdateContactAttrs(
        contactToken: string,
        organizationId: string,
        contactData: Partial<TIndividualAttrs>,
        cb: QueryResultCallback<{ contact: TIndividualAttrs; organization: TOrganizationAttr; docTokenMaps: TDocTokenMapAttr[] }>,
    ) {
        try {
            const resp = await APIManager.put(APIManager.HOOK_UPDATE_CONTACT_API(organizationId, contactToken), contactData);
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiOpenDocumentForSigning(docToken: string, organizationId: string, cb: QueryResultCallback<TDocumentAttr>) {
        try {
            const resp = await APIManager.get(APIManager.HOOK_OPEN_DOCUMENT_API(organizationId, docToken));
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiSignDocument(
        docToken: string,
        organizationId: string,
        fields: any[],
        fileInfoId: string | null,
        cb: QueryResultCallback<TDocumentAttr>,
    ) {
        try {
            const resp = await APIManager.put(APIManager.HOOK_SIGN_DOCUMENT_API(organizationId, docToken), { fields, fileInfoId });
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiUpdateSOADiscovery(docToken: string, organizationId: string, fields: any[], cb: QueryResultCallback<TDocumentAttr>) {
        try {
            const resp = await APIManager.put(APIManager.HOOK_UPDATE_DOC_DISCOVERY_API(organizationId, docToken), { fields });
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiCompleteSOADiscovery(docToken: string, organizationId: string, fields: any[], cb: QueryResultCallback<TDocumentAttr>) {
        try {
            const resp = await APIManager.put(APIManager.HOOK_COMPLETE_DOC_DISCOVERY_API(organizationId, docToken), { fields });
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiGetSOADiscoveryItem(docToken: string, organizationId: string) {
        const res = await APIManager.get(APIManager.HOOK_DISCOVERY_DOC_ITEM_API(organizationId, docToken));
        return res.data;
    }

    async apiDownloadDocTemplate(templateBlobName: string, cb: QueryResultCallback<Blob>) {
        try {
            AppManager.document.template.apiDownloadTemplateDocData(templateBlobName, cb);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiCleanupSigningAndRegistration(contactToken: string, organizationId: string, cb: QueryResultCallback<TDocumentAttr>) {
        try {
            const resp = await APIManager.delete(APIManager.HOOK_SIGN_CLEANUP_CONTACT_REGISTRATION_API(organizationId, contactToken));
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiAcceptOrgInvitationWithNewUser(authToken: string, organizationId: string, data: any, cb: QueryResultCallback<TDocumentAttr>) {
        try {
            const resp = await APIManager.post(APIManager.HOOK_ACCEPT_ORG_INVITE_NEW_USER_API(organizationId, authToken), data);
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiAcceptOrgInvitation(authToken: string, organizationId: string, userId: string, cb: QueryResultCallback<TDocumentAttr>) {
        try {
            const resp = await APIManager.post(APIManager.HOOK_ACCEPT_ORG_INVITE_API(organizationId, authToken), { userId });
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiDeclineOrgInvitation(authToken: string, organizationId: string, cb: QueryResultCallback<TDocumentAttr>) {
        try {
            const resp = await APIManager.post(APIManager.HOOK_DECLINE_ORG_INVITE_API(organizationId, authToken), null);
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }
}
