import { AppManager } from "..";
import { IOEventTypes } from "../RealtimeManager";

export class NotificationNetworkHelper {
    static networkDataReceived = <K extends keyof IOEventTypes>(evt: K, resp: any): void => {
        switch (evt) {
            case "NOTIFICATION":
                const data = (resp as IOEventTypes["NOTIFICATION"]).data;
                AppManager.notifications.onNewNotification(data);
                break;
        }
    };

    static onConnectionRestoredSync(currentRoute: string, currentPathName: string) {
        AppManager.notifications.apiFetchUserNotifications(() => {});
    }
}
