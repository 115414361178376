import { AppManager } from ".";
import { IOEventTypes } from "./RealtimeManager";

export class AppStateSyncManager {
    static networkDataReceived<K extends keyof IOEventTypes>(evt: K, data: any): void {
        AppManager.contact.networkHelper.networkDataReceived(evt, data);
        AppManager.document.networkHelper.networkDataReceived(evt, data);
        AppManager.servicefee.networkHelper.networkDataReceived(evt, data);
        AppManager.diary.networkHelper.networkDataReceived(evt, data);
        AppManager.task.networkHelper.networkDataReceived(evt, data);
        AppManager.project.networkHelper.networkDataReceived(evt, data);
        AppManager.project.template.networkHelper.networkDataReceived(evt, data);
        AppManager.user.networkHelper.networkDataReceived(evt, data);
        AppManager.org.networkHelper.networkDataReceived(evt, data);
        AppManager.notifications.networkHelper.networkDataReceived(evt, data);
    }

    static networkConnectionRestored() {
        const currentRoute = AppManager.route.CURRENT_ROUTE;
        const currentPathName = AppManager.route.CURRENT_PATHNAME;

        AppManager.contact.networkHelper.onConnectionRestoredSync(currentRoute, currentPathName);
        AppManager.document.networkHelper.onConnectionRestoredSync(currentRoute, currentPathName);
        AppManager.servicefee.networkHelper.onConnectionRestoredSync(currentRoute, currentPathName);
        AppManager.diary.networkHelper.onConnectionRestoredSync(currentRoute, currentPathName);
        AppManager.task.networkHelper.onConnectionRestoredSync(currentRoute, currentPathName);
        AppManager.project.networkHelper.onConnectionRestoredSync(currentRoute, currentPathName);
        AppManager.project.template.networkHelper.onConnectionRestoredSync(currentRoute, currentPathName);
        AppManager.user.networkHelper.onConnectionRestoredSync(currentRoute, currentPathName);
        AppManager.org.networkHelper.onConnectionRestoredSync(currentRoute, currentPathName);
        AppManager.notifications.networkHelper.onConnectionRestoredSync(currentRoute, currentPathName);
    }
}
