import { TLoginData } from "../models/Auth/Auth";
import AlertManager from "./AlertManager";
import { AppStateSyncManager } from "./AppStateSyncManager";
import { AuthManager } from "./AuthManager";
import { DocumentManager } from "./DocumentManager";
import { EphemeralGroupManager } from "./EphemeralGroupManager";
import { OrgManager } from "./OrgManager/OrgManager";
import { IOEventTypes, RealtimeRequestManager } from "./RealtimeManager";
import { RouteManager } from "./RouteManager";
import { UserManager } from "./UserManager/UserManager";
import { ServiceFeeManager } from "./ServiceFeeManager";
import { DiaryManager } from "./DiaryManager/DiaryManager";
import { TaskManager } from "./TaskManager/TaskManager";
import { ProjectManager } from "./TaskManager/ProjectManager";
import { ProjectTemplateManager } from "./TaskManager/ProjectTemplateManager";
import { APIManager } from "./APIManager";
import { AzureBlobStorage } from "../redux/utils/AzureBlobStorage";
import { SubscriptionManager } from "./SubscriptionManager/SubscriptionManager";
import { MiscManager } from "./MiscManager";
import { NotificationManager } from "./NotificationManager/NotificationManager";
import { AusplanAppsManager } from "./AppsManager/AppsManager";
import { AnalyticsManager } from "./AnalyticsManager/AnalyticsManager";
import { ContactManager } from "./ContactManager";
import { SOAManager } from "./DocumentManager/SOAManager";
import { TaskTemplateManager } from "./TaskManager/TaskTemplateManager";

export class AppManager {
    static contact = new ContactManager();
    static auth = new AuthManager({
        postLoginCallback: AppManager.postLoginHook,
        postLogoutCallback: AppManager.postLogoutHook,
    });
    static alert = AlertManager;
    static user = new UserManager();
    static org = new OrgManager();
    static realtime = new RealtimeRequestManager();
    static document = new DocumentManager();
    static ephemeral = new EphemeralGroupManager();
    static servicefee = new ServiceFeeManager();
    static diary = new DiaryManager();
    static task = new TaskManager();
    static taskTemplate = new TaskTemplateManager();
    static project = new ProjectManager();
    static projectTemplate = new ProjectTemplateManager();
    static blobStorage = new AzureBlobStorage();
    static subscription = new SubscriptionManager();
    static analytics = new AnalyticsManager();
    static route = RouteManager;
    static sync = AppStateSyncManager;
    static request = APIManager;
    static misc = MiscManager;
    static notifications = NotificationManager;
    static ausplanApps = AusplanAppsManager;
    static soa = new SOAManager();

    static reAuthenticateUser() {
        console.log("REAUTHENTICATION REQUIRED");
        RouteManager.gotoLogin(RouteManager.CURRENT_ROUTE);
    }

    private static onRealtimeData<K extends keyof IOEventTypes>(evt: K, data: IOEventTypes[K]) {}

    private static postLoginHook(data: TLoginData) {
        AppManager.realtime.init({
            onReAuthenticate: AppManager.reAuthenticateUser,
            onDataReceived: AppStateSyncManager.networkDataReceived,
            onReconnected: AppManager.postConnectionRestoredHook,
        });
    }
    private static postLogoutHook() {
        AppManager.realtime.destroy();
    }

    private static postConnectionRestoredHook() {
        AppStateSyncManager.networkConnectionRestored();
    }
}
