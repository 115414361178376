enum ActionTypes {
    SAVE_PROJECTS = "SAVE_PROJECTS",
    SAVE_TEMPLATES = "SAVE_TEMPLATES",
    LOADING_PROJECTS = "LOADING_PROJECTS",
    LOADING_TEMPLATES = "LOADING_TEMPLATES",
    LOADED_PROJECTS = "LOADED_PROJECTS",
    LOADED_TEMPLATES = "LOADED_TEMPLATES",
    REMOVE_PROJECT = "REMOVE_PROJECT",
    REMOVE_TEMPLATE = "REMOVE_TEMPLATE",
    LOGOUT_ACTION = "LOGOUT_ACTION",
}

export default ActionTypes;
