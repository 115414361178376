import ActionTypes from "./actionTypes";
import { Individual } from "../../../models/Contact/Individual/Individual";
import { getAppStore } from "../../store";
import { Company } from "../../../models/Contact/Company/Company";
import { Trust } from "../../../models/Contact/Trust/Trust";
import { CustomField } from "../../../models/Contact/CustomFields";

export class IndividualStoreActions {
    static loadingIndividuals(loading: boolean) {
        getAppStore().dispatch({ type: ActionTypes.LOADING_INDIVIDUALS, payload: loading });
    }
    static setIndividualLoaded(state: boolean) {
        getAppStore().dispatch({ type: ActionTypes.LOADED_INDIVIDUALS, payload: state });
    }
    static saveIndividuals(indv: Individual[]) {
        getAppStore().dispatch({ type: ActionTypes.SAVE_INDIVIDUALS, payload: indv });
    }
    static removeIndividual(individual: Individual) {
        getAppStore().dispatch({ type: ActionTypes.REMOVE_INDIVIDUAL, payload: individual });
    }
    static getCustomFieldsIndividual() {
        return getAppStore().getState().contacts.customFieldIndividual;
    }
    static saveCustomFieldsIndividual(customFieldIndividual: CustomField[]) {
        getAppStore().dispatch({ type: ActionTypes.SAVE_CUSTOM_FIELDS_INDIVIDUAL, payload: customFieldIndividual });
    }
    static getIndividuals() {
        return getAppStore().getState().contacts.individuals;
    }

    static removeCustomFieldsIndividual(customFieldIndividual: CustomField) {
        let customField = IndividualStoreActions.getCustomFieldsIndividual();
        customField = customField.filter((t) => t.data.attrs._id !== customFieldIndividual.id);
        IndividualStoreActions.saveCustomFieldsIndividual(customField);
    }

    static addOrUpdateToIndividuals(individual: Individual) {
        let individuals = IndividualStoreActions.getIndividuals();
        if (individuals.some((t) => t.data.attrs._id === individual.id)) {
            individuals = individuals.map((t) => {
                if (t.data.attrs._id === individual.id) {
                    return t.updateIndividual(individual);
                }
                return t;
            });
        } else {
            individuals = [individual, ...individuals];
        }
        IndividualStoreActions.saveIndividuals(individuals);
    }

    static addOrUpdateCustomFields(individual: CustomField) {
        let individuals = IndividualStoreActions.getCustomFieldsIndividual();
        if (individuals.some((t) => t.data.attrs._id === individual.id)) {
            individuals = individuals.map((t) => {
                if (t.data.attrs._id === individual.id) {
                    return t.updateCustomField(individual.attrs);
                }
                return t;
            });
        } else {
            individuals = [individual, ...individuals];
        }
        IndividualStoreActions.saveCustomFieldsIndividual(individuals);
    }
}

export class CompanyStoreActions {
    static loadingCompanies(loading: boolean) {
        getAppStore().dispatch({ type: ActionTypes.LOADING_COMPANIES, payload: loading });
    }
    static setCompanyLoaded(state: boolean) {
        getAppStore().dispatch({ type: ActionTypes.LOADED_COMPANIES, payload: state });
    }
    static saveCompanies(company: Company[]) {
        getAppStore().dispatch({ type: ActionTypes.SAVE_COMPANIES, payload: company });
    }
    static removeCompany(company: Company) {
        getAppStore().dispatch({ type: ActionTypes.REMOVE_COMPANY, payload: company });
    }
    static getCompanies() {
        return getAppStore().getState().contacts.companies;
    }
    static getCustomFieldCompany() {
        return getAppStore().getState().contacts.customFieldCompany;
    }
    static saveCustomFieldsCompany(customFieldCompany: CustomField[]) {
        getAppStore().dispatch({ type: ActionTypes.SAVE_CUSTOM_FIELDS_COMPANY, payload: customFieldCompany });
    }
    static addOrUpdateToCompanies(company: Company) {
        let companies = CompanyStoreActions.getCompanies();
        if (companies.some((t) => t.data.attrs._id === company.id)) {
            companies = companies.map((t) => {
                if (t.data.attrs._id === company.id) {
                    return t.updateCompany(company);
                }
                return t;
            });
        } else {
            companies = [company, ...companies];
        }
        CompanyStoreActions.saveCompanies(companies);
    }
    static addOrUpdateCustomFields(customFieldCompany: CustomField) {
        let company = CompanyStoreActions.getCustomFieldCompany();
        if (company.some((t) => t.data.attrs._id === customFieldCompany.id)) {
            company = company.map((t) => {
                if (t.data.attrs._id === customFieldCompany.id) {
                    return t.updateCustomField(customFieldCompany.attrs);
                }
                return t;
            });
        } else {
            company = [customFieldCompany, ...company];
        }
        CompanyStoreActions.saveCustomFieldsCompany(company);
    }

    static removeCustomFieldsCompany(customFieldCompany: CustomField) {
        let customField = CompanyStoreActions.getCustomFieldCompany();
        customField = customField.filter((t) => t.data.attrs._id !== customFieldCompany.id);
        CompanyStoreActions.saveCustomFieldsCompany(customField);
    }
}

export class TrustStoreActions {
    static loadingTrusts(loading: boolean) {
        getAppStore().dispatch({ type: ActionTypes.LOADING_TRUSTS, payload: loading });
    }
    static setTrustLoaded(state: boolean) {
        getAppStore().dispatch({ type: ActionTypes.LOADED_TRUSTS, payload: state });
    }
    static saveTrusts(trust: Trust[]) {
        getAppStore().dispatch({ type: ActionTypes.SAVE_TRUSTS, payload: trust });
    }
    static removeTrust(trust: Trust) {
        getAppStore().dispatch({ type: ActionTypes.REMOVE_TRUST, payload: trust });
    }
    static getCustomFieldTrust() {
        return getAppStore().getState().contacts.customFieldTrust;
    }
    static saveCustomFieldsTrust(customFieldTrust: CustomField[]) {
        getAppStore().dispatch({ type: ActionTypes.SAVE_CUSTOM_FIELDS_TRUST, payload: customFieldTrust });
    }
    static getTrusts() {
        return getAppStore().getState().contacts.trusts;
    }
    static addOrUpdateToTrusts(trust: Trust) {
        let trusts = TrustStoreActions.getTrusts();
        if (trusts.some((t) => t.data.attrs._id === trust.id)) {
            trusts = trusts.map((t) => {
                if (t.data.attrs._id === trust.id) {
                    return t.updateTrust(trust);
                }
                return t;
            });
        } else {
            trusts = [trust, ...trusts];
        }
        TrustStoreActions.saveTrusts(trusts);
    }
    static addOrUpdateCustomFields(customFieldTrust: CustomField) {
        let trust = TrustStoreActions.getCustomFieldTrust();
        if (trust.some((t) => t.data.attrs._id === customFieldTrust.id)) {
            trust = trust.map((t) => {
                if (t.data.attrs._id === customFieldTrust.id) {
                    return t.updateCustomField(customFieldTrust.attrs);
                }
                return t;
            });
        } else {
            trust = [customFieldTrust, ...trust];
        }
        TrustStoreActions.saveCustomFieldsTrust(trust);
    }

    static removeCustomFieldsTrust(customFieldTrust: CustomField) {
        let customField = TrustStoreActions.getCustomFieldTrust();
        customField = customField.filter((t) => t.data.attrs._id !== customFieldTrust.id);
        TrustStoreActions.saveCustomFieldsTrust(customField);
    }
}
