export const downloadBlobToFile = (data: Blob, filename: string, elemId?: string, mime?: string) => {
    const isJSON = mime == "application/json";
    const blob =
        data instanceof Blob ? data : new Blob([isJSON ? JSON.stringify(data, null, 2) : data], { type: mime || "application/octet-stream" });

    if (typeof window.navigator.msSaveBlob !== "undefined") {
        window.navigator.msSaveBlob(blob, filename);
    } else {
        const blobURL = window.URL.createObjectURL(blob);
        const tempLink = document.createElement("a");
        tempLink.style.display = "none";
        tempLink.href = blobURL;
        tempLink.setAttribute("download", filename);

        if (typeof tempLink.download === "undefined") {
            tempLink.setAttribute("target", "_blank");
        }
        const elemIDSelector = document.getElementById(elemId || "") || document.body;
        elemIDSelector.appendChild(tempLink);
        tempLink.click();
        elemIDSelector.removeChild(tempLink);
        window.URL.revokeObjectURL(blobURL);
    }
};
