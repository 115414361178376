import React from "react";

import { Route, Routes } from "react-router-dom";
import { XPLAN_PLANNER_MIGRATION_ROUTE, XPLAN_MODELOFFICE_MIGRATION_ROUTE } from "../../../constants/appRoutes";
import SimpleLayout from "../../../layouts/SimpleLayout/SimpleLayout";
import XplanModelOfficeHomePage from "./IresXplanModelOfficeMigrator/HomePage";
import XPlanPlannerHomePage from "./IresXplanModelOfficeMigrator/HomePage";

const AusplanAppsPage = () => {
    return (
        <Routes>
            <Route
                path="/migration/xplan-modeloffice*"
                element={
                    <SimpleLayout>
                        <XplanModelOfficeHomePage />
                    </SimpleLayout>
                }
            />
            <Route
                path="/migration/xplan-plannercentral*"
                element={
                    <SimpleLayout>
                        <XPlanPlannerHomePage />
                    </SimpleLayout>
                }
            />
        </Routes>
    );
};

export default AusplanAppsPage;
