import { AppManager } from "..";
import { QueryResultCallback } from "../../models/Callback/Callback";
import { IMasterlistRecordAttrs } from "../../models/ServicesFees/MasterlistRecord";
import { ServiceFeeStoreActions } from "../../redux/services/servicesfees/actions";
import { APIManager } from "../APIManager";
import { BASE_API } from "../../constants/apiEndpoints";
import { AzureBlobStorage } from "../../redux/utils/AzureBlobStorage";
import { IAuditExpandedRecordAttrs, IAuditRecordAttrs } from "../../models/AuditRecord/AuditRecord";

type TUploadSuccess = { blobName: string; blobUrl: string; _id?: string };
type TUploadCallback = (err: string | null, data?: TUploadSuccess) => void;

export class MasterlistManager {
    async apiFetchAllRecords(cb: QueryResultCallback<IMasterlistRecordAttrs[]>) {
        try {
            ServiceFeeStoreActions.loadingRecords(true);
            const resp = await APIManager.get(APIManager.MASTERLIST.MASTERLIST_RECORDS_API);
            ServiceFeeStoreActions.saveRecords(resp.data);
            ServiceFeeStoreActions.setRecordsLoaded(true);
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        } finally {
            ServiceFeeStoreActions.loadingRecords(false);
        }
    }

    async apiAddNewRecords(newRecords: IMasterlistRecordAttrs[], cb: QueryResultCallback<IMasterlistRecordAttrs[]>) {
        try {
            const resp = await APIManager.post(APIManager.MASTERLIST.MASTERLIST_RECORDS_API, newRecords);
            this.updateOrAddRecords(resp.data);
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiUpdateRecord(recordId: string, recordData: Partial<IMasterlistRecordAttrs>, cb: QueryResultCallback<IMasterlistRecordAttrs>) {
        try {
            const resp = await APIManager.put(APIManager.MASTERLIST.MASTERLIST_SINGLE_RECORD_API(recordId), recordData);
            ServiceFeeStoreActions.addOrUpdateToRecords(resp.data);
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiDeleteRecord(recordId: string, cb: QueryResultCallback<IMasterlistRecordAttrs>) {
        try {
            const resp = await APIManager.delete(APIManager.MASTERLIST.MASTERLIST_SINGLE_RECORD_API(recordId));
            ServiceFeeStoreActions.removeRecord(recordId);
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    updateOrAddRecords(recordData: IMasterlistRecordAttrs[], areNewRecords = false) {
        console.log("RECORD DATA IS ", recordData);
        const allRecords = ServiceFeeStoreActions.getRecords();
        for (let i = 0; i < allRecords.length; i++) {
            const record = allRecords[i];
            const updatedRecordInd = recordData.findIndex((t) => t._id === record._id);
            if (updatedRecordInd >= 0) {
                allRecords[i] = recordData[updatedRecordInd];
                recordData.splice(updatedRecordInd, 1);
            } else if (areNewRecords) {
                allRecords[i].isNewRecord = false;
            }
            if (!recordData.length && !areNewRecords) break;
        }
        AppManager.servicefee.provider.apiFetchAllProviderAdviserRefs(() => {});
        AppManager.servicefee.provider.apiFetchAllProviders(() => {});
        ServiceFeeStoreActions.saveRecords([...recordData, ...allRecords]);
    }

    removeRecord(recordData: IMasterlistRecordAttrs) {
        const allRecords = ServiceFeeStoreActions.getRecords().filter((t) => t._id !== recordData._id);
        ServiceFeeStoreActions.saveRecords(allRecords);
    }

    async apiSaveMasterListCSVFile(providerId: string, file: File, source: string, callback: TUploadCallback = () => {}) {
        const azure = AzureBlobStorage.newInstance();
        const activeOrg = AppManager.org.getCurrentActiveOrg()!;
        const blobName = `StorageAccount/blob-store/${activeOrg.id}/${providerId}/FDS`;
        await azure.uploadToBlobStorage(file, blobName, async (err, respData) => {
            if (err) return err;
            else {
                const fileData = {
                    name: file.name,
                    fileSize: file.size,
                    fileType: source,
                    blobName: respData!.blobName,
                    fileUrl: respData!.blobUrl,
                };
                try {
                    const resp = await AppManager.request.post(AppManager.request.STORAGE.SERVICE_FEE_FILES_API, fileData);
                    callback(null, resp.data);
                } catch (err) {
                    callback(`${err}`);
                }
                return fileData;
            }
        });
    }

    async apiFetchOriginalServiceFeeFiles(providerId: string, cb: QueryResultCallback<IMasterlistRecordAttrs[]>) {
        try {
            const resp = await APIManager.get(APIManager.STORAGE.OriginalServiceFeeFiles_LIST);
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        } finally {
            ServiceFeeStoreActions.loadingRecords(false);
        }
    }

    async apiFetchAuditList(providerId: string, cb: QueryResultCallback<IAuditRecordAttrs[]>) {
        try {
            const resp = await APIManager.get(APIManager.STORAGE.OriginalServiceFeeFilesAudit_LIST);
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        } finally {
            ServiceFeeStoreActions.loadingRecords(false);
        }
    }

    async apiDeleteServiceFeeFile(originalServiceFeeFileId: string, cb: QueryResultCallback<IAuditRecordAttrs[]>) {
        try {
            const resp = await APIManager.delete(APIManager.MASTERLIST.MASTERLIST_SERVICE_FEE_FILE_DELETE_API(originalServiceFeeFileId));
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        } finally {
            ServiceFeeStoreActions.loadingRecords(false);
        }
    }

    async apiDeleteServiceFeeFileAssociatedEntries(originalServiceFeeFileId: string, cb: QueryResultCallback<IAuditRecordAttrs[]>) {
        try {
            const resp = await APIManager.delete(APIManager.MASTERLIST.MASTERLIST_SERVICE_FEE_FILE_DELETE_ASSOCIATED_ENTRIES_API(originalServiceFeeFileId));
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        } finally {
            ServiceFeeStoreActions.loadingRecords(false);
        }
    }

    async apiFetchServiceFeeAuditList(originalServiceFeeFileId: string, cb: QueryResultCallback<IAuditExpandedRecordAttrs>) {
        try {
            const resp = await APIManager.get(APIManager.MASTERLIST.MASTERLIST_SERVICE_FEE_AUDIT_LIST_API(originalServiceFeeFileId));
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        } finally {
            ServiceFeeStoreActions.loadingRecords(false);
        }
    }
}
