import { Button, Card, Form, Input, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import CheckIcon from "@ant-design/icons/CheckOutlined";
import Loading from "../../../components/Loading/Loading";
import BasicHeaderLayout from "../../../layouts/BasicHeaderLayout/BasicHeaderLayout";
import { AppManager } from "../../../manager";
import Styles from "./CompleteSignup.scss";

type SignupFormProps = {
    name: string;
    email: string;
};

const SignupForm: React.FC<SignupFormProps> = ({ name, email }) => {
    const [isUpdating, setUpdating] = useState(false);
    const handleUpdateCredentials = (values: { email: string; password: string }) => {
        setUpdating(true);
        AppManager.auth.updateCredentials(values, true, (err) => {
            if (err) AppManager.alert.error("Error", err);
            else AppManager.route.gotoHome();
        });
    };
    return (
        <Spin spinning={isUpdating} tip="Please wait...">
            <div className={Styles.ContentContainer}>
                <Card className={Styles.UpdatePasswordBox}>
                    <div className={Styles.HeaderText}>
                        <div className={Styles.UserName}>Hello {name}!</div>
                        <div className={Styles.Desc}>You&apos;re just one step away to your new dealer group account</div>
                    </div>
                    <div className={Styles.FormContainer}>
                        <Form labelCol={{ span: 10 }} labelAlign="left" initialValues={{ email }} onFinish={handleUpdateCredentials}>
                            <Form.Item label="Email" name="email">
                                <Input disabled />
                            </Form.Item>
                            <Form.Item label="New Password" name="password" required rules={[{ required: true, min: 6, max: 20 }]}>
                                <Input.Password />
                            </Form.Item>
                            <Form.Item
                                label="Confirm Password"
                                name="confirmPassword"
                                dependencies={["password"]}
                                rules={[
                                    { required: true },
                                    ({ getFieldValue }) => ({
                                        validator: (_, value) => {
                                            if (getFieldValue("password") !== value)
                                                return Promise.reject(new Error("Confirmation password does not match"));
                                            return Promise.resolve();
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password />
                            </Form.Item>
                            <div style={{ textAlign: "center", marginTop: 35 }}>
                                <Button size="large" type="primary" htmlType="submit">
                                    <CheckIcon />
                                    Complete
                                </Button>
                            </div>
                        </Form>
                    </div>
                </Card>
            </div>
        </Spin>
    );
};

const CompleteDealerGroupSignup = () => {
    const [verifying, setVerifying] = useState(false);
    const [error, setError] = useState<string>();
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");

    const location = useLocation();

    useEffect(() => {
        const { pathname } = location;
        const authString = pathname.replace("/auth-setup/d/", "");
        try {
            setVerifying(true);
            const decoded = atob(authString);
            const [dEmail, dPassword, dName] = decoded.split("\n");
            setEmail(dEmail);
            setName(dName);
            AppManager.auth.loginUser(dEmail, dPassword, (err, isInvalid) => {
                setVerifying(false);
                if (err && isInvalid) AppManager.route.gotoLogin();
                else if (err) {
                    setError(err);
                    AppManager.alert.error("Error", err);
                }
            });
        } catch (err) {
            AppManager.route.gotoLogin();
        }
    }, []);
    return (
        <BasicHeaderLayout
            title="Complete Sign Up"
            content={verifying ? <Loading /> : error ? <div style={{ marginTop: 30 }}>{error}</div> : <SignupForm name={name} email={email} />}
        />
    );
};

export default CompleteDealerGroupSignup;
