import { formatUrl } from "../helpers/misc";
import { TAuthInfo } from "../models/Auth/Auth";
import { QueryResultCallback } from "../models/Callback/Callback";
import { APIManager } from "./APIManager";

export class MiscManager {
    static async apiSearchOrganization(searchText: string, options: { notes?: string; files?: string }, cb: QueryResultCallback<any[]>) {
        try {
            const resp = await APIManager.get(APIManager.SEARCH_ORGANIZATION_API(searchText, options));
            cb(null, resp.data);
        } catch (err) {
            cb(err);
        }
    }
    static async apiSubmitIssue(issue: any, cb: QueryResultCallback<any>) {
        try {
            const resp = await APIManager.post(APIManager.SUBMIT_APP_ISSUE_API, issue);
            cb(null, resp.data);
        } catch (err) {
            cb(err);
        }
    }
}
