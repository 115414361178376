import { AppManager } from ".";
import { formatUrl } from "../helpers/misc";
import { apiHttpGET, apiHttpPOST, tokenConfig } from "../helpers/request_helper";
import { TAuthInfo, TAzureCredentials, TLoginData } from "../models/Auth/Auth";
import { QueryResultCallback } from "../models/Callback/Callback";
import { AuthStoreActions } from "../redux/services/auth/actions";
import { APIManager } from "./APIManager";
import { OrgManager } from "./OrgManager/OrgManager";

type AuthManagerConfig = {
    postLoginCallback: (data: TLoginData) => void;
    postLogoutCallback: () => void;
};

export class AuthManager {
    constructor(private config: AuthManagerConfig) {}
    async fetchAuthInfo(cb: QueryResultCallback<TAuthInfo>, loadSetupIntent = OrgManager.getActiveOrg()?.id) {
        try {
            const resp = await APIManager.get(formatUrl(APIManager.AUTH_INFO_API, { setup_intent: loadSetupIntent }));
            cb(null, resp.data);
        } catch (err: any) {
            cb(err);
        }
    }
    async fetchAzureCredentials(cb: QueryResultCallback<TAzureCredentials>) {
        try {
            const resp = await APIManager.get(APIManager.AZURE_CREDENTIALS_API);
            AuthStoreActions.saveAzureCredentials(resp.data);
            cb(null, resp.data);
        } catch (err) {
            cb(err);
        }
    }
    async refreshToken(cb: QueryResultCallback<TLoginData | boolean | undefined>, token?: string | null) {
        try {
            token = token || AuthStoreActions.getUserToken();
            const wasAuthenticated = AuthStoreActions.isUserAuthenticated();
            if (token) {
                const resp = await apiHttpGET(APIManager.REFRESH_TOKEN_API, tokenConfig(token));
                const data: TLoginData = resp.data;
                AuthStoreActions.saveUserToken(data.access_token);
                cb(null, resp.data);
                if (!wasAuthenticated) this.config.postLoginCallback(resp.data);
            } else {
                cb("Not Authorized", true);
            }
        } catch (err) {
            const isFailedAuth = Boolean(err.response && err.response.status === 401);
            const errMsg = await APIManager.getResponseErrorMsg(err);
            cb(errMsg, isFailedAuth);
        }
    }
    attemptUserReAuthentication(cb: QueryResultCallback<TLoginData | boolean | undefined>, token?: string | null) {
        if (AuthStoreActions.isUserAuthenticated()) {
            cb(null, true);
        } else {
            this.refreshToken((err, resp) => {
                if (err) cb(err, resp);
                else {
                    cb(null, false);
                }
            }, token);
        }
    }
    async loginUserFromAuth0(accessToken: string, nonce: string, cb: QueryResultCallback) {
        try {
            const wasAuthenticated = AuthStoreActions.isUserAuthenticated();
            const resp = await APIManager.post(APIManager.AUTH0_LOGIN_API, { accessToken, nonce });
            const respData: TLoginData = resp.data;
            AuthStoreActions.saveUserToken(respData.access_token);
            if (!wasAuthenticated) this.config.postLoginCallback(resp.data);
            cb(null, resp.data);
        } catch (err) {
            cb(err);
        }
    }
    async loginUser(email: string, password: string, cb: QueryResultCallback) {
        try {
            const wasAuthenticated = AuthStoreActions.isUserAuthenticated();
            const resp = await apiHttpPOST(APIManager.LOGIN_API, undefined, { email, password });
            const respData: TLoginData = resp.data;
            AuthStoreActions.saveUserToken(respData.access_token);
            AuthStoreActions.saveUserAuthCredentials(email, password);
            if (!wasAuthenticated) this.config.postLoginCallback(resp.data);
            cb(null, resp.data);
        } catch (err) {
            cb(err, err.response && err.response.status === 401 ? true : false);
        }
    }
    async updateCredentials(creds: { email: string; password: string; oldPassword?: string }, autoLogin: boolean, cb: QueryResultCallback) {
        try {
            const resp = await APIManager.put(APIManager.USER_AUTH_CREDS_API(), creds);
            if (autoLogin) {
                await this.loginUser(creds.email, creds.password, cb);
            } else cb(null, resp.data);
        } catch (err) {
            cb(err, err.response && err.response.status === 401 ? true : false);
        }
    }

    getAuth0LockInstance = () => {};

    getAccessToken() {
        return AuthStoreActions.getUserToken();
    }
    dispatchLogout() {
        AuthStoreActions.logoutUser();
        this.config.postLogoutCallback();
    }
}
