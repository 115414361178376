import LoadingItem from "react-loading";
import { Alert, Progress, Table, Steps } from "antd";
import React, { useEffect, useState } from "react";
import { Checkmark } from "react-checkmark";
import ClockIcon from "@ant-design/icons/ClockCircleOutlined";
import LoadingIcon from "@ant-design/icons/LoadingOutlined";
import { AppManager } from "../../../../manager";
import { ICustomerInfo, ITrustInfo } from "../../../../models/Apps/Migration/XPlanTypes";
import AnimatedStatusHeader from "./AnimatedStatusHeader";
import Styles from "./Home.scss";
import { AttachmentStepDetails, FileNotesStepDetails } from "./MigrateIndividuals";

const ExpandedDetails: React.FC<{ record: ITrustInfo }> = ({ record }) => {
    const currentStep = !record.keyDetailsPageScraped
        ? 0
        : !record.contactDetailsPageScraped
        ? 1
        : !record.fileNotesDataScraped
        ? 2
        : !record.isAllNotesAttachmentsUploaded
        ? 3
        : 4;
    return (
        <div className={Styles.MigrationStepView}>
            <div className={Styles.Steps}>
                <Steps current={currentStep} size="small">
                    <Steps.Step
                        title={currentStep == 0 ? "Loading Key Details" : "Key Details"}
                        description={currentStep == 0 ? "In-Progress" : currentStep > 0 ? "Done" : ""}
                        icon={currentStep == 0 ? <LoadingIcon /> : undefined}
                    />
                    <Steps.Step
                        title={currentStep == 1 ? "Loading Contact Details" : "Contact Details"}
                        description={currentStep == 1 ? "In-Progress" : currentStep > 1 ? "Done" : ""}
                        icon={currentStep == 1 ? <LoadingIcon /> : undefined}
                    />
                    <Steps.Step
                        title={currentStep == 2 ? "Loading File Notes" : "File Notes"}
                        description={
                            currentStep == 2 ? (record.fileNotesIdsScraped ? "Loading file notes" : "Migrating data") : currentStep > 2 ? "Done" : ""
                        }
                        icon={currentStep == 2 ? <LoadingIcon /> : undefined}
                    />
                    <Steps.Step
                        title={currentStep == 3 ? "Loading Attachments" : "Attachments"}
                        description={currentStep == 3 ? "In-Progress" : currentStep > 3 ? "Done" : ""}
                        icon={currentStep == 3 ? <LoadingIcon /> : undefined}
                    />
                </Steps>
            </div>
            <div style={{ paddingLeft: 15, paddingTop: 15 }}>
                {currentStep == 2 && <FileNotesStepDetails individual={record} />}
                {currentStep == 3 && <AttachmentStepDetails individual={record} />}
            </div>
        </div>
    );
};

const MigrateTrustsView: React.FC<{ customer: ICustomerInfo }> = ({ customer }) => {
    const [loading, setLoading] = useState(false);
    const [trusts, setTrusts] = useState<ITrustInfo[]>([]);
    const [completedCount, setCompletedCount] = useState(0);
    const [totalCount, setTotalCount] = useState(1);
    const [error, setError] = useState("");
    const loadTrusts = () => {
        setLoading(true);
        AppManager.ausplanApps.xplanMigrator.apiFetchTrusts(customer.ausplanUserId, customer.currentGroup, (err, data) => {
            setLoading(false);
            if (err) setError(`${err}`);
            else if (data) {
                setTrusts(data);
            }
        });
    };
    const getCurrentRow = () => {
        for (let i = 0; i < trusts.length; i++) {
            if (!trusts[i].fullyScrapped && (i == 0 || trusts[i - 1].fullyScrapped)) {
                return trusts[i];
            }
        }

        return undefined;
    };
    useEffect(() => {
        loadTrusts();
    }, [customer]);
    useEffect(() => {
        setTotalCount(trusts.length);
        setCompletedCount(trusts.filter((r) => r.fullyScrapped).length);
    }, [trusts]);

    const currentRow = getCurrentRow();

    return (
        <div className={Styles.MigrationView}>
            <AnimatedStatusHeader />
            <div className={Styles.MigrationItems}>
                <div className={Styles.StatusHeader}>
                    <div>
                        Trusts Migration Status ({completedCount}/{totalCount})
                    </div>
                    <div className={Styles.ProgressContainer}>
                        <Progress strokeWidth={12} type="line" percent={+((completedCount * 100) / totalCount).toFixed(1)} />
                    </div>
                </div>
                {error && <Alert type="error" message={error} closable onClose={() => setError("")} style={{ marginBottom: 15 }} />}
                <Table
                    dataSource={trusts}
                    rowKey={(rec) => rec.pageId}
                    columns={[
                        { title: "#Id", render: (_, rec) => <b>{rec.pageId}</b> },
                        { title: "Trust Name", render: (_, rec) => rec.keyDetails?.keyDetailsSection?.trustName || "--" },
                        { title: "State", render: (_, rec) => rec.contactDetails?.addressObject?.state || "--" },
                        { title: "Address", render: (_, rec) => rec.contactDetails?.addressObject?.street || "--" },
                        {
                            title: "",
                            width: 50,
                            render: (_, rec) =>
                                rec.fullyScrapped ? (
                                    <Checkmark size={20} />
                                ) : currentRow?.pageId == rec.pageId ? (
                                    <LoadingItem type="cubes" color="#555" height={15} width={30} />
                                ) : (
                                    <ClockIcon style={{ fontSize: 18 }} />
                                ),
                        },
                    ]}
                    loading={!trusts.length && loading}
                    size="small"
                    pagination={false}
                    expandable={{
                        expandedRowRender: (record) => <ExpandedDetails record={record} />,
                        expandedRowKeys: [currentRow?.pageId || ""],
                        rowExpandable: (record) => currentRow?.pageId === record.pageId,
                    }}
                />
            </div>
        </div>
    );
};

export default MigrateTrustsView;
