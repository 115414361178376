enum ActionTypes {
    SAVE_INDIVIDUALS = "SAVE_INDIVIDUALS",
    LOADING_INDIVIDUALS = "LOADING_INDIVIDUALS",
    LOADED_INDIVIDUALS = "LOADED_INDIVIDUALS",
    REMOVE_INDIVIDUAL = "REMOVE_INDIVIDUAL",

    SAVE_COMPANIES = "SAVE_COMPANIES",
    LOADING_COMPANIES = "LOADING_COMPANIES",
    LOADED_COMPANIES = "LOADED_COMPANIES",
    REMOVE_COMPANY = "REMOVE_COMPANY",

    SAVE_TRUSTS = "SAVE_TRUSTS",
    LOADING_TRUSTS = "LOADING_TRUSTS",
    LOADED_TRUSTS = "LOADED_TRUSTS",
    REMOVE_TRUST = "REMOVE_TRUST",

    LOGOUT_ACTION = "LOGOUT_ACTION",

    SAVE_CUSTOM_FIELDS_INDIVIDUAL = "SAVE_CUSTOM_FIELDS_INDIVIDUAL",
    SAVE_CUSTOM_FIELDS_COMPANY = "SAVE_CUSTOM_FIELDS_COMPANY",
    SAVE_CUSTOM_FIELDS_TRUST = "SAVE_CUSTOM_FIELDS_TRUST",

    REMOVE_CUSTOM_FIELDS_INDIVIDUAL = "REMOVE_CUSTOM_FIELDS_INDIVIDUAL",
    REMOVE_CUSTOM_FIELDS_COMPANY = "REMOVE_CUSTOM_FIELDS_COMPANY",
    REMOVE_CUSTOM_FIELDS_TRUST = "REMOVE_CUSTOM_FIELDS_TRUST",
}

export default ActionTypes;
