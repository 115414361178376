import LoadingItem from "react-loading";
import { Alert, Progress, Table, Steps, notification } from "antd";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Checkmark } from "react-checkmark";
import LoadingIcon from "@ant-design/icons/LoadingOutlined";
import ClockIcon from "@ant-design/icons/ClockCircleOutlined";
import { AppManager } from "../../../../manager";
import {
    ICompanyInfo,
    IContactAttachment,
    IContactFileNote,
    ICustomerInfo,
    IIndividualInfo,
    ITrustInfo,
} from "../../../../models/Apps/Migration/XPlanTypes";
import AnimatedStatusHeader from "./AnimatedStatusHeader";
import Styles from "./Home.scss";
import prettyBytes from "pretty-bytes";

export const AttachmentStepDetails: React.FC<{ individual: IIndividualInfo | ICompanyInfo | ITrustInfo }> = ({ individual }) => {
    const [attachments, setAttachments] = useState<IContactAttachment[]>([]);
    const [fileNotes, setFileNotes] = useState<IContactFileNote[]>([]);
    const fieldLocal = !individual.isAllNotesAttachmentsDownloaded ? "isDownloaded" : "isUploaded";
    const fieldGlobal = !individual.isAllNotesAttachmentsDownloaded ? "isAllAttachmentsDownloaded" : "isAllAttachmentsUploaded";
    const totalAttachments = useRef(0),
        downloaded = useRef(0),
        uploaded = useRef(0);
    const currentFileNote = useMemo(() => {
        totalAttachments.current = 0;
        downloaded.current = 0;
        uploaded.current = 0;
        let res = undefined;
        for (let i = 0; i < fileNotes.length; i++) {
            totalAttachments.current += fileNotes[i].attachments?.length || 0;
            downloaded.current += attachments?.filter((r) => r.isDownloaded).length || 0;
            uploaded.current += attachments?.filter((r) => r.isUploaded).length || 0;
            if (!res && !fileNotes[i][fieldGlobal] && (i == 0 || fileNotes[i - 1][fieldGlobal])) {
                res = fileNotes[i];
            }
        }
        return res;
    }, [fileNotes, attachments]);

    const currentAttachmentRow = useMemo(() => {
        for (let i = 0; i < attachments.length; i++) {
            if (!attachments[i][fieldLocal] && (i == 0 || attachments[i - 1][fieldLocal])) {
                return attachments[i];
            }
        }

        return undefined;
    }, [currentFileNote, attachments]);

    const currentDownloaded = attachments?.filter((r) => r.isDownloaded).length;
    const currentUploaded = attachments?.filter((r) => r.isUploaded).length;
    const currentTotal = attachments?.length;

    useEffect(() => {
        if (currentFileNote?.fileNoteId) {
            const { ausplanUserId: customerId, pageId: contactId } = individual;
            const { fileNoteId } = currentFileNote;
            AppManager.ausplanApps.xplanMigrator.apiFetchContactFileNoteAttachments(customerId, contactId, fileNoteId, (err, data) => {
                if (err) return notification.error({ message: "Error loading contact files", description: `${err}` });
                setAttachments(data || []);
            });
        }
    }, [individual, currentFileNote?.fileNoteId]);

    useEffect(() => {
        const { ausplanUserId: customerId, pageId: contactId } = individual;
        AppManager.ausplanApps.xplanMigrator.apiFetchContactFileNotes(customerId, contactId, (err, data) => {
            if (err) return notification.error({ message: "Error loading contact files", description: `${err}` });
            setFileNotes(data || []);
        });
    }, [individual]);

    return (
        <>
            <h4 style={{ fontWeight: "bold" }}>
                {fieldLocal == "isDownloaded"
                    ? `Downloading Attachments: ${currentDownloaded}/${currentTotal}`
                    : `Uploading Attachments: ${currentUploaded}/${currentTotal}`}
            </h4>
            <h4 style={{ margin: "5px 0" }}>
                <b>Current Note</b>: {currentFileNote?.subject || "--"}
                <div style={{ display: "flex", alignItems: "center" }}>
                    <span style={{ marginRight: 35 }}>
                        <b>Total Attachments</b>: {totalAttachments.current || "--"}
                    </span>
                    <span style={{ marginRight: 35 }}>
                        <b>Downloaded</b>: {downloaded.current || "--"}
                    </span>
                    <span>
                        <b>Uploaded</b>: {uploaded.current || "--"}
                    </span>
                </div>
            </h4>
            <Table
                dataSource={attachments || []}
                rowKey={(rec) => `${rec.attachmentId}`}
                columns={[
                    { title: "#Att. Id", dataIndex: "attachmentId" },
                    { title: "Attachment Name", render: (_, rec) => rec.name || "--" },
                    { title: "Type", render: (_, rec) => rec.fileType || "--" },
                    { title: "Size", render: (_, rec) => (rec.fileSize ? prettyBytes(rec.fileSize) : "--") },
                    {
                        title: "",
                        width: 50,
                        render: (_, rec) =>
                            rec[fieldLocal] ? (
                                <Checkmark size={20} />
                            ) : currentAttachmentRow?.attachmentId == rec.attachmentId ? (
                                <LoadingItem type="cubes" color="#555" height={15} width={30} />
                            ) : (
                                <ClockIcon style={{ fontSize: 18 }} />
                            ),
                    },
                ]}
                size="small"
                pagination={false}
                style={{ marginBottom: 50 }}
            />
        </>
    );
};

export const FileNotesStepDetails: React.FC<{ individual: IIndividualInfo | ICompanyInfo | ITrustInfo }> = ({ individual }) => {
    const [fileNotes, setFileNotes] = useState<IContactFileNote[]>([]);
    const currentRow = useMemo(() => {
        for (let i = 0; i < fileNotes.length; i++) {
            if (!fileNotes[i].isDataScraped && (i == 0 || fileNotes[i - 1].isDataScraped)) {
                return fileNotes[i];
            }
        }

        return undefined;
    }, [individual]);

    useEffect(() => {
        const { ausplanUserId: customerId, pageId: contactId } = individual;
        AppManager.ausplanApps.xplanMigrator.apiFetchContactFileNotes(customerId, contactId, (err, data) => {
            if (err) return notification.error({ message: "Error loading contact files", description: `${err}` });
            setFileNotes(data || []);
        });
    }, [individual]);

    return (
        <Table
            dataSource={fileNotes || []}
            rowKey={(rec) => `${rec.fileNoteId}`}
            columns={[
                { title: "Note #Id", dataIndex: "fileNoteId" },
                { title: "Subject", render: (_, rec) => rec.subject || "--" },
                { title: "Total Attachments", render: (_, rec) => rec.attachments?.length || "--" },
                { title: "Date Created", render: (_, rec) => rec.createDate || "--" },
                {
                    title: "",
                    width: 50,
                    render: (_, rec) =>
                        rec.isDataScraped ? (
                            <Checkmark size={20} />
                        ) : currentRow?.fileNoteId == rec.fileNoteId ? (
                            <LoadingItem type="cubes" color="#555" height={15} width={30} />
                        ) : (
                            <ClockIcon style={{ fontSize: 18 }} />
                        ),
                },
            ]}
            size="small"
            pagination={false}
            style={{ marginBottom: 50 }}
        />
    );
};

const ExpandedDetails: React.FC<{ record: IIndividualInfo | ICompanyInfo | ITrustInfo }> = ({ record }) => {
    const currentStep = !record.keyDetailsPageScraped
        ? 0
        : !record.contactDetailsPageScraped
        ? 1
        : !record.fileNotesDataScraped
        ? 2
        : !record.isAllNotesAttachmentsUploaded
        ? 3
        : 4;
    return (
        <div className={Styles.MigrationStepView}>
            <div className={Styles.Steps}>
                <Steps current={currentStep} size="small">
                    <Steps.Step
                        title={currentStep == 0 ? "Loading Key Details" : "Key Details"}
                        description={currentStep == 0 ? "In-Progress" : currentStep > 0 ? "Done" : ""}
                        icon={currentStep == 0 ? <LoadingIcon /> : undefined}
                    />
                    <Steps.Step
                        title={currentStep == 1 ? "Loading Contact Details" : "Contact Details"}
                        description={currentStep == 1 ? "In-Progress" : currentStep > 1 ? "Done" : ""}
                        icon={currentStep == 1 ? <LoadingIcon /> : undefined}
                    />
                    <Steps.Step
                        title={currentStep == 2 ? "Loading File Notes" : "File Notes"}
                        description={
                            currentStep == 2 ? (record.fileNotesIdsScraped ? "Loading file notes" : "Migrating data") : currentStep > 2 ? "Done" : ""
                        }
                        icon={currentStep == 2 ? <LoadingIcon /> : undefined}
                    />
                    <Steps.Step
                        title={currentStep == 3 ? "Loading Attachments" : "Attachments"}
                        description={currentStep == 3 ? "In-Progress" : currentStep > 3 ? "Done" : ""}
                        icon={currentStep == 3 ? <LoadingIcon /> : undefined}
                    />
                </Steps>
            </div>
            <div style={{ paddingLeft: 15, paddingTop: 15 }}>
                {currentStep == 2 && <FileNotesStepDetails individual={record} />}
                {currentStep == 3 && <AttachmentStepDetails individual={record} />}
            </div>
        </div>
    );
};

const MigrateIndividualsView: React.FC<{ customer: ICustomerInfo }> = ({ customer }) => {
    const [loading, setLoading] = useState(false);
    const [individuals, setIndividuals] = useState<IIndividualInfo[]>([]);
    const [completedCount, setCompletedCount] = useState(0);
    const [totalCount, setTotalCount] = useState(1);
    const [error, setError] = useState("");
    const currentRow = useMemo(() => {
        for (let i = 0; i < individuals.length; i++) {
            if (!individuals[i].fullyScrapped && (i == 0 || individuals[i - 1].fullyScrapped)) {
                return individuals[i];
            }
        }

        return undefined;
    }, [individuals]);

    const loadIndividuals = () => {
        if (loading) return;
        setLoading(true);
        AppManager.ausplanApps.xplanMigrator.apiFetchIndividuals(customer.ausplanUserId, customer.currentGroup, (err, data) => {
            setLoading(false);
            if (err) setError(`${err}`);
            else if (data) {
                setIndividuals(data);
            }
        });
    };

    useEffect(() => {
        loadIndividuals();
    }, [customer]);

    useEffect(() => {
        setTotalCount(individuals.length);
        setCompletedCount(individuals.filter((r) => r.fullyScrapped).length);
    }, [individuals]);

    return (
        <div className={Styles.MigrationView}>
            <AnimatedStatusHeader />
            <div className={Styles.MigrationItems}>
                <div className={Styles.StatusHeader}>
                    <div>
                        Individuals Migration Status ({completedCount}/{totalCount})
                    </div>
                    <div className={Styles.ProgressContainer}>
                        <Progress strokeWidth={12} type="line" percent={+((completedCount * 100) / totalCount).toFixed(1)} />
                    </div>
                </div>
                {error && <Alert type="error" message={error} closable onClose={() => setError("")} style={{ marginBottom: 15 }} />}
                <Table
                    dataSource={individuals}
                    rowKey={(rec) => rec.pageId}
                    columns={[
                        { title: "#Id", dataIndex: "pageId" },
                        { title: "First Name", render: (_, rec) => rec.keyDetails?.firstName || "--" },
                        { title: "Surname", render: (_, rec) => rec.keyDetails?.surname || "--" },
                        { title: "Preferred Name", render: (_, rec) => rec.keyDetails?.preferredName || "--" },
                        { title: "State", render: (_, rec) => rec.contactDetails?.addressObject?.state || "--" },
                        { title: "Address", render: (_, rec) => rec.contactDetails?.addressObject?.street || "--" },
                        { title: "Category", render: (_, rec) => rec.keyDetails?.category || "--" },
                        {
                            title: "",
                            width: 50,
                            render: (_, rec) =>
                                rec.fullyScrapped ? (
                                    <Checkmark size={20} />
                                ) : currentRow?.pageId == rec.pageId ? (
                                    <LoadingItem type="cubes" color="#555" height={15} width={30} />
                                ) : (
                                    <ClockIcon style={{ fontSize: 18 }} />
                                ),
                        },
                    ]}
                    loading={!individuals.length && loading}
                    size="small"
                    pagination={false}
                    expandable={{
                        expandedRowRender: (record) => <ExpandedDetails record={record} />,
                        expandedRowKeys: [currentRow?.pageId || ""],
                        rowExpandable: (record) => currentRow?.pageId === record.pageId,
                    }}
                />
            </div>
        </div>
    );
};

export default MigrateIndividualsView;
