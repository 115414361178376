import { Organization } from "../../../models/Organization/Organization";
import createReducer from "../../utils/createReducer";
import ActionTypes from "./actionTypes";

export type OrgStateT = {
    orgs: Organization[];
    activeOrg?: Organization;
    loadingOrgs: boolean;
    orgsLoaded: boolean;
};

export const initialState: OrgStateT = {
    orgs: [],
    loadingOrgs: false,
    orgsLoaded: false,
    activeOrg: undefined,
};

// App Reducer
export default createReducer<OrgStateT>(initialState, {
    [ActionTypes.SAVE_ORGANIZATIONS]: (state, payload: Organization[]) => ({
        ...state,
        orgs: payload,
        orgsLoaded: true,
    }),
    [ActionTypes.LOADING_ORGANIZATIONS]: (state, payload: boolean) => ({
        ...state,
        loadingOrgs: payload,
    }),
    [ActionTypes.SET_ACTIVE_ORGANIZATION]: (state, payload: Organization) => ({
        ...state,
        activeOrg: payload,
    }),
    [ActionTypes.LOGOUT_ACTION]: (state) => ({
        ...state,
        ...initialState,
    }),
});
