import { QueryResultCallback } from "../../models/Callback/Callback";
import { APIManager } from "../APIManager";

export class SOAManager {
    async apiSendIndividualsDiscoveryFormToSign(data: { contacts: string[]; name: string; mode: string }, cb: QueryResultCallback<any>) {
        try {
            const resp = await APIManager.post(APIManager.DOCUMENT.DISCOVERY_FORM_INDIVIDUAL_SIGN_API, data);
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiGetSoaGroups() {
        const res = await APIManager.get(APIManager.DOCUMENT.DISCOVERY_GROUPS_API);
        return res.data;
    }

    async apiGetSoaGroupItem(groupId: string) {
        const res = await APIManager.get(APIManager.DOCUMENT.DISCOVERY_GROUP_ITEM_API(groupId));
        return res.data;
    }

    async apiGetStatmentsOfAdvice() {
        const res = await APIManager.get(APIManager.DOCUMENT.STATEMENTS_OF_ADVICE_API);
        return res.data;
    }

    async apiGetStatmentOfAdviceItem(soaId: string) {
        const res = await APIManager.get(APIManager.DOCUMENT.STATEMENT_OF_ADVICE_ITEM_API(soaId));
        return res.data;
    }

    async apiUpdateStatmentOfAdviceInput(soaId: string, data: any) {
        const res = await APIManager.put(APIManager.DOCUMENT.STATEMENT_OF_ADVICE_ITEM_API(soaId), data);
        return res.data;
    }

    async apiUpdateSoaGroupDocument(fields: any[], groupId: string, docId: string) {
        const res = await APIManager.put(APIManager.DOCUMENT.DISCOVERY_GROUP_DOCUMENT_ITEM_API(groupId, docId), { fields });
        return res.data;
    }

    async apiGenerateStatmentOfAdviceDraft(soaId: string) {
        const resp = await APIManager.request({
            url: APIManager.DOCUMENT.STATEMENT_OF_ADVICE_DRAFTS_API(soaId),
            method: "post",
            responseType: "blob",
        });
        return resp.data;
    }

    async apiMarkSoaGroupDocumentComplete(groupId: string, docId: string) {
        const res = await APIManager.patch(APIManager.DOCUMENT.DISCOVERY_GROUP_DOCUMENT_ITEM_COMPLETE_API(groupId, docId));
        return res.data;
    }
}
