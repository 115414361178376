import ActionTypes from "./actionTypes";
import { getAppStore } from "../../store";
import { TProjectAttr, TProjectCatg, TProjectDataAttr } from "../../../models/Task/Types";
import { UserStoreActions } from "../user/actions";
import { Project } from "../../../models/Task/Project";
import { ProjectTemplate } from "../../../models/Task/ProjectTemplate";

export class ProjectStoreActions {
    static loadingProjects(loading: TProjectCatg | null) {
        getAppStore().dispatch({ type: ActionTypes.LOADING_PROJECTS, payload: loading });
    }
    static setProjectsLoaded(state: TProjectCatg) {
        getAppStore().dispatch({ type: ActionTypes.LOADED_PROJECTS, payload: state });
    }

    static saveProjects(projects: Partial<{ [k in TProjectCatg]: Project[] }>) {
        getAppStore().dispatch({ type: ActionTypes.SAVE_PROJECTS, payload: projects });
    }

    static removeProject(project: Project) {
        getAppStore().dispatch({ type: ActionTypes.REMOVE_PROJECT, payload: project });
    }
    static getProjects() {
        return getAppStore().getState().projects.projects;
    }
    static detectCateg(projectAttr: TProjectAttr | TProjectDataAttr): TProjectCatg {
        const { createdBy } = projectAttr;
        const userId = UserStoreActions.getUser()?.id;
        return createdBy === userId ? "user" : "org";
    }
    static addOrUpdateToProjects(project: Project, catg?: TProjectCatg) {
        const projects = ProjectStoreActions.getProjects();
        catg = catg || ProjectStoreActions.detectCateg(project.data.attrs);
        if (projects[catg].some((t) => t.data.attrs._id === project.id)) {
            projects[catg] = projects[catg].map((t) => {
                if (t.data.attrs._id === project.id) {
                    return t.updateProject(project);
                }
                return t;
            });
        } else {
            projects[catg] = [project, ...projects[catg]];
        }
        ProjectStoreActions.saveProjects(projects);
    }
}

export class ProjectTemplateStoreActions {
    static loadingTemplates(loading: boolean) {
        getAppStore().dispatch({ type: ActionTypes.LOADING_TEMPLATES, payload: loading });
    }
    static setTemplatesLoaded(state: boolean) {
        getAppStore().dispatch({ type: ActionTypes.LOADED_TEMPLATES, payload: state });
    }

    static saveTemplates(templates: ProjectTemplate[]) {
        getAppStore().dispatch({ type: ActionTypes.SAVE_TEMPLATES, payload: templates });
    }

    static removeTemplate(template: ProjectTemplate) {
        getAppStore().dispatch({ type: ActionTypes.REMOVE_TEMPLATE, payload: template });
    }
    static getTemplates() {
        return getAppStore().getState().projects.templates;
    }
    static addOrUpdateToTemplates(template: ProjectTemplate) {
        let templates = ProjectTemplateStoreActions.getTemplates();
        if (templates.some((t) => t.data.attrs._id === template.id)) {
            templates = templates.map((t) => {
                if (t.data.attrs._id === template.id) {
                    return t.updateTemplate(template);
                }
                return t;
            });
        } else {
            templates = [template, ...templates];
        }
        ProjectTemplateStoreActions.saveTemplates(templates);
    }
}
