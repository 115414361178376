enum ActionTypes {
    SAVE_SCHEDULES = "SAVE_SCHEDULES",
    UPDATE_SCHEDULE = "UPDATE_SCHEDULE",
    LOADED_SCHEDULES = "LOADED_SCHEDULES",
    LOADING_SCHEDULES = "LOADING_SCHEDULES",
    ADD_NEW_SCHEDULE = "ADD_NEW_SCHEDULE",
    DELETE_SCHEDULE = "DELETE_SCHEDULE",
    SET_HIDDEN_CATEGORIES = "SET_HIDDEN_CATEGORIES",

    LOGOUT_ACTION = "LOGOUT_ACTION",
}

export default ActionTypes;
