import * as allRoutes from "../constants/appRoutes";
import { Path as PathParser } from "path-parser";
import { AppStoreActions } from "../redux/services/app/actions";
import QueryString from "qs";
import { APP_ENV } from "../constants/appVars";

/*
 * Volatile paths; path's that shouldn't be remembered when the back
 * action is triggered
 */
const volatilePaths = [
    allRoutes.SERVICES_FEES_NEW_RECORD_ROUTE,
    allRoutes.NEW_PROJECT_ROUTE,
    allRoutes.PROJECT_EDIT_ROUTE,
    allRoutes.NEW_PROJECT_TEMPLATE_ROUTE,
    allRoutes.PROJECT_TEMPLATE_EDIT_ROUTE,
    allRoutes.TEMPLATE_PREVIEW_ROUTE,
];

const activeWindows: { [k: string]: Window | null } = {};

export class RouteManager {
    private static getRouteQueryString(queryStrings: { [k: string]: string }) {
        let qs = "";

        Object.keys(queryStrings).forEach((key) => {
            if (queryStrings[key]) {
                const value = encodeURIComponent(queryStrings[key]);
                qs = `${qs}${qs.length > 0 ? "&" : ""}${key}=${value}`;
            }
        });

        if (qs.length > 0) {
            return `?${qs}`;
        }
        return "";
    }

    static formatRoutePath(pathName: string, pathParams?: { [k: string]: string }) {
        pathParams = pathParams || {};

        Object.keys(pathParams).forEach((key) => {
            if (pathParams![key]) {
                const re = RegExp(`/:${key}`);
                pathName = pathName.replace(re, `/${pathParams![key]}`);
            }
        });

        return pathName;
    }

    static gotoHome(): void {
        RouteManager.goto(allRoutes.HOME_ROUTE);
    }

    static gotoLogin(redirectTo?: string): void {
        const formattedQs = RouteManager.getRouteQueryString({ purge: "1" });
        window.location.assign(`${APP_ENV.AUTH_ORIGIN}/#${formattedQs}`);
    }

    static gotoSignup(): void {
        RouteManager.goto(allRoutes.SIGNUP_ROUTE);
    }

    static gotoLogout(): void {
        RouteManager.goto(allRoutes.LOGOUT_ROUTE);
    }

    static gotoSingleIndividual(contactId: string, options?: { tab?: "files" | "services" | "notes"; item?: string }): void {
        RouteManager.goto(allRoutes.SINGLE_CONTACT_INDIVIDUAL_ROUTE, { contactId }, null, options);
    }

    static gotoSingleCompany(contactId: string, options?: { tab?: "files" | "services" | "notes"; item?: string }): void {
        RouteManager.goto(allRoutes.SINGLE_CONTACT_COMPANY_ROUTE, { contactId }, null, options);
    }

    static gotoSingleTrust(contactId: string, options?: { tab?: "files" | "services" | "notes"; item?: string }): void {
        RouteManager.goto(allRoutes.SINGLE_CONTACT_TRUST_ROUTE, { contactId }, null, options);
    }

    static gotoContactAudits(): void {
        RouteManager.goto(allRoutes.CONTACT_AUDIT_ROUTE);
    }

    static getSingleIndividualRoute(contactId: string): string {
        return RouteManager.formatRoutePath(allRoutes.SINGLE_CONTACT_INDIVIDUAL_ROUTE, { contactId });
    }

    static gotoAllContacts(): void {
        RouteManager.goto(allRoutes.CONTACTS_ROUTE);
    }

    static gotoAllTasksAndProjects(options?: { tab?: "tasks" | "projects"; item?: string }): void {
        const navQs: { [k: string]: string } = options && options.tab ? { tab: options.tab } : {};
        RouteManager.goto(allRoutes.TASKS_ROUTE, undefined, options, navQs);
    }

    static gotoSingleProject(projectId: string, options?: { tab?: "list" | "board" }, state?: { task?: string }): void {
        RouteManager.goto(allRoutes.SINGLE_PROJECT_ROUTE, { projectId }, state, options);
    }

    static gotoProjectEditor(projectId: string): void {
        RouteManager.goto(allRoutes.PROJECT_EDIT_ROUTE, { projectId });
    }

    static gotoAllDocsAndTemplates(options?: { tab?: "templates" | "docs" }): void {
        const navQs: { [k: string]: string } = options && options.tab ? { tab: options.tab } : {};
        RouteManager.goto(allRoutes.DOCUMENTS_ROUTE, undefined, undefined, navQs);
    }

    static gotoDocumentTemplateEditor(templateId: string): void {
        RouteManager.goto(allRoutes.TEMPLATE_EDITOR_ROUTE, { templateId });
    }

    static gotoDocumentTemplatePreview(templateId: string): void {
        RouteManager.goto(allRoutes.TEMPLATE_PREVIEW_ROUTE, { templateId });
    }

    static gotoSingleDocumentTemplate(templateId: string): void {
        RouteManager.goto(allRoutes.SINGLE_TEMPLATE_ROUTE, { templateId });
    }

    static gotoMasterlistRecords(): void {
        RouteManager.goto(allRoutes.SERVICES_FEES_MASTERLIST_ROUTE);
    }

    static gotoNewMasterlistRecordEntry(): void {
        RouteManager.goto(allRoutes.SERVICES_FEES_NEW_RECORD_ROUTE);
    }

    static gotoNewProject(templateId?: string): void {
        RouteManager.goto(allRoutes.NEW_PROJECT_ROUTE, undefined, undefined, templateId ? { tmpl: templateId } : undefined);
    }

    static gotoNewProjectTemplate(): void {
        RouteManager.goto(allRoutes.NEW_PROJECT_TEMPLATE_ROUTE);
    }

    static gotoProjectTemplateEditor(templateId: string): void {
        RouteManager.goto(allRoutes.PROJECT_TEMPLATE_EDIT_ROUTE, { templateId });
    }

    static gotoXplanModelOfficePage(): void {
        if (activeWindows[allRoutes.XPLAN_MODELOFFICE_MIGRATION_ROUTE]) {
            const win = activeWindows[allRoutes.XPLAN_MODELOFFICE_MIGRATION_ROUTE];
            if (win && !win.closed) {
                win.focus();
            } else {
                activeWindows[allRoutes.XPLAN_MODELOFFICE_MIGRATION_ROUTE] = window.open(`#${allRoutes.XPLAN_MODELOFFICE_MIGRATION_ROUTE}`);
            }
        } else {
            activeWindows[allRoutes.XPLAN_MODELOFFICE_MIGRATION_ROUTE] = window.open(`#${allRoutes.XPLAN_MODELOFFICE_MIGRATION_ROUTE}`);
        }
    }

    static gotoXplanPlannerCentralPage(): void {
        if (activeWindows[allRoutes.XPLAN_PLANNER_MIGRATION_ROUTE]) {
            const win = activeWindows[allRoutes.XPLAN_PLANNER_MIGRATION_ROUTE];
            if (win && !win.closed) {
                win.focus();
            } else {
                activeWindows[allRoutes.XPLAN_PLANNER_MIGRATION_ROUTE] = window.open(`#${allRoutes.XPLAN_PLANNER_MIGRATION_ROUTE}`);
            }
        } else {
            activeWindows[allRoutes.XPLAN_PLANNER_MIGRATION_ROUTE] = window.open(`#${allRoutes.XPLAN_PLANNER_MIGRATION_ROUTE}`);
        }
    }

    static gotoSettings(): void {
        RouteManager.goto(allRoutes.SETTINGS_ROUTE);
    }

    static canGoBack() {
        return AppStoreActions.getHistory()?.action !== "POP";
    }

    static goBack(preferedBackCall?: () => void): void {
        if (RouteManager.canGoBack()) {
            AppStoreActions.getHistory()?.back();
        } else if (preferedBackCall) {
            preferedBackCall();
        } else {
            RouteManager.gotoHome();
        }
    }

    static goto(route: string, routeParams?: { [k: string]: string }, state?: any, qStrings?: { [k: string]: string }): void {
        const oldPath = AppStoreActions.getHistory()?.location.pathname || "";
        const currentPathname = RouteManager.formatRoutePath(route, routeParams);
        const search = RouteManager.getRouteQueryString(qStrings || {});
        if (volatilePaths.includes(oldPath)) {
            AppStoreActions.getHistory()?.replace({ pathname: currentPathname, state, search } as { pathname: string; state: any });
        } else {
            AppStoreActions.getHistory()?.push({ pathname: currentPathname, state, search } as { pathname: string; state: any });
        }
    }

    static removeQueryPaths() {
        // TODO: Remove it here
        console.log("REMOVING QUERY PATHS FROM ", RouteManager.CURRENT_PATHNAME);
    }

    static get CURRENT_ROUTE(): string {
        const { pathname } = AppStoreActions.getHistory()!.location;
        return Object.values(allRoutes).find((t) => new PathParser(t).test(pathname)) || "";
    }

    static get CURRENT_PATHNAME(): string {
        return AppStoreActions.getHistory()!.location.pathname;
    }

    static get CURRENT_QUERY_STRINGS(): { [k: string]: string } {
        const search = AppStoreActions.getHistory()?.location.search || "";
        return QueryString.parse(search.substring(1) || {}) as { [k: string]: string };
    }
}
