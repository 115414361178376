import CheckIcon from "@ant-design/icons/CheckOutlined";
import PlusIcon from "@ant-design/icons/PlusOutlined";
import { Modal, Spin, Tabs, Form, Input, Upload, FormInstance, Button } from "antd";
import { UploadFile } from "antd/lib/upload/interface";
import React, { useEffect, useState } from "react";
import { Provider } from "react-redux";
import { AppManager } from "../../../manager";
import { getAppStore } from "../../../redux/store";
import Styles from "./FeedbackActionModal.scss";

const FeedbackForm: React.FC<{
    type: "issue" | "feature";
    titleLabel: string;
    descLabel: string;
    form: FormInstance<any>;
    onSubmit: (data: any) => void;
}> = ({ titleLabel, descLabel, form, type, onSubmit }) => {
    const [screenshots, setScreenshots] = useState<UploadFile[]>([]);
    const UploadButton = (
        <div>
            <PlusIcon />
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );
    useEffect(() => {
        form.setFieldsValue({ screenshots });
    }, [screenshots]);
    useEffect(() => {
        form.setFieldsValue({ type });
    }, []);
    return (
        <Form layout="vertical" form={form} initialValues={{ screenshots: [], type }} onFinish={onSubmit}>
            <Form.Item label={titleLabel} rules={[{ required: true }]} name="title">
                <Input style={{ fontSize: 16, fontWeight: "bold" }} placeholder="Issue Title..." />
            </Form.Item>
            <Form.Item label="More Info" rules={[{ required: true }]} name="description">
                <Input.TextArea rows={4} placeholder={descLabel} />
            </Form.Item>
            <Form.Item label="Annotated Screenshots (if applicable)" name="screenshots">
                <Upload
                    listType="picture-card"
                    fileList={screenshots}
                    onChange={(info) =>
                        setScreenshots(
                            info.fileList.map((item) => {
                                item.status = "done";
                                return item;
                            }),
                        )
                    }
                    accept="image"
                    multiple
                    children={UploadButton}
                    customRequest={(req) => {}}
                />
            </Form.Item>
            <Form.Item name="type" />
        </Form>
    );
};

const FeedbackActionModal: React.FC<{ onClose: () => void }> = ({ onClose }) => {
    const [issueForm] = Form.useForm();
    const [featureForm] = Form.useForm();
    const [submitting, setSubmitting] = useState(false);
    const [selectedTab, setSelectedTab] = useState<string>("issue");
    const handleSendFeedback = () => {
        if (selectedTab === "issue") {
            issueForm.submit();
        } else featureForm.submit();
    };
    const handleSubmit = (data: any) => {
        setSubmitting(true);
        const formData = new FormData();
        formData.append("title", data.title);
        formData.append("description", data.description);
        data.screenshots.forEach((item: UploadFile) => {
            formData.append("screenshots", item.originFileObj!, item.name);
        });

        AppManager.misc.apiSubmitIssue(formData, (err) => {
            setSubmitting(false);
            if (err) AppManager.alert.toastError(`${err}`);
            else {
                AppManager.alert.success("Thanks for your feedback", "We shall look into this and hope to get it sorted out as soon as possible.");
                onClose();
            }
        });
    };
    return (
        <Spin spinning={submitting} tip="Submitting issue">
            <div className={Styles.Container} style={{ height: window.innerHeight - 180, maxHeight: 700, overflowY: "auto" }}>
                <div>
                    <Tabs activeKey={selectedTab} onChange={setSelectedTab}>
                        <Tabs.TabPane tab="Bug/Issue" key="issue">
                            <FeedbackForm
                                titleLabel="Bug/Issue Title"
                                type="issue"
                                descLabel="Please tell us what went wrong or what you think could be improved..."
                                form={issueForm}
                                onSubmit={handleSubmit}
                            />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Feature Request" key="feature_request">
                            <FeedbackForm
                                titleLabel="Feature Title"
                                type="feature"
                                descLabel="Please describe your expectations of this feature..."
                                form={featureForm}
                                onSubmit={handleSubmit}
                            />
                        </Tabs.TabPane>
                    </Tabs>
                </div>
                <div className={Styles.ActionsWrapper}>
                    <Button icon={<CheckIcon />} type="primary" onClick={handleSendFeedback} loading={submitting} disabled={submitting}>
                        Send Feedback
                    </Button>
                    <Button style={{ marginLeft: 10 }} onClick={onClose} disabled={submitting}>
                        Cancel
                    </Button>
                </div>
            </div>
        </Spin>
    );
};

export const showFeedbackActionModal = () => {
    const modal = Modal.info({
        title: "What will you like to report",
        width: window.innerWidth < 600 ? window.innerWidth : 600,
        centered: true,
        closable: true,
        content: (
            <Provider store={getAppStore()}>
                <FeedbackActionModal onClose={() => modal.destroy()} />
            </Provider>
        ),
        icon: null,
        okButtonProps: { style: { display: "none" } },
    });

    return modal;
};
