import { Button } from "antd";
import QueryString from "qs";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { AppManager } from "../../../manager";
import AppLoadingPage from "../../AppLoadingPage/AppLoadingPage";
import AppSimplePage from "../../AppSimplePage/AppSimplePage";
import Styles from "./AppInit.scss";

const ErrorDisplay: React.FC<{ text: string }> = ({ text }) => {
    const handleReloadPage = () => {
        window.location.reload();
    };
    return (
        <div className={Styles.ErrorContainer}>
            <div className={Styles.ErrorTitle}>Error!</div>
            <div className={Styles.ErrorText}>{text}</div>
            <div>
                Try{" "}
                <Button size="small" onClick={handleReloadPage}>
                    Reloading
                </Button>{" "}
                the page. If that does not help, please contact technical support with a screenshot of this error
            </div>
        </div>
    );
};

const AppInit = () => {
    const location = useLocation();
    const [loadError, setLoadError] = useState<string>();
    const queryStrings = QueryString.parse(location.search.substring(1) || {}) || {};
    const handleCheckInfo = async () => {
        await AppManager.auth.refreshToken((err) => {
            if (err) AppManager.route.gotoLogin();
        }, queryStrings.auth as string);

        await AppManager.user.apiFetchUserData((err) => {
            if (err) setLoadError(err);
            else {
                const organization = AppManager.org.getUserOrg(queryStrings.org as string);
                if (organization) {
                    AppManager.org.activateOrganization(organization, (err) => {
                        if (err) setLoadError(err);
                        else AppManager.route.gotoHome();
                    });
                } else AppManager.route.gotoLogin();
            }
        });
    };

    useEffect(() => {
        if (queryStrings.org && queryStrings.auth) {
            handleCheckInfo();
        }
    }, []);
    return loadError ? <AppSimplePage content={<ErrorDisplay text={loadError} />} /> : <AppLoadingPage />;
};

export default AppInit;
