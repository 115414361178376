export const HOME_ROUTE = "/home";
export const APP_AUTH_INIT_ROUTE = "/init";
export const INTERNAL_LOGIN_ROUTE = "/auth/login";
export const LOGOUT_ROUTE = "/auth/logout";
export const SIGNUP_ROUTE = "/auth/signup";
export const COMPLETE_DEFAULT_SIGNUP_ROUTE = "/auth-setup/i/:authString";
export const COMPLETE_DEALER_GROUP_SIGNUP_ROUTE = "/auth-setup/d/:authString";
export const CONTACT_REGISTER_ROUTE = "/contact-register/:contactToken/i/:organizationId";
export const USER_ORG_INVITE_ROUTE = "/org-invite/:authToken/i/:organizationId";
export const USER_ORG_INVITE_ACCEPT_ROUTE = `${USER_ORG_INVITE_ROUTE}/accept`;
export const USER_ORG_INVITE_DECLINE_ROUTE = `${USER_ORG_INVITE_ROUTE}/decline`;
export const DOCUMENT_SIGN_ROUTE = "/doc-sign/:authString/i/:organizationId";
export const DASHBOARD_ROUTE = "/home/dashboard";
export const CONTACTS_ROUTE = "/home/contacts";
export const SINGLE_CONTACT_AUDIT_ROUTE = "/home/contacts/audit/:contactId";
export const SINGLE_CONTACT_INDIVIDUAL_ROUTE = "/home/contacts/individual/:contactId";
export const SINGLE_CONTACT_COMPANY_ROUTE = "/home/contacts/company/:contactId";
export const SINGLE_CONTACT_TRUST_ROUTE = "/home/contacts/trust/:contactId";
export const CONTACT_AUDIT_ROUTE = "/home/contacts/audit";
export const DIARY_ROUTE = "/home/diary";
export const TASKS_ROUTE = "/home/tasks";
export const NEW_PROJECT_ROUTE = "/home/tasks/projects/create";
export const SINGLE_PROJECT_ROUTE = "/home/tasks/projects/:projectId";
export const PROJECT_EDIT_ROUTE = "/home/tasks/projects/:projectId/edit";
export const PROJECTS_ROUTE = "/home/projects";
export const NEW_PROJECT_TEMPLATE_ROUTE = "/home/tasks/projects/templates/create";
export const SINGLE_PROJECT_TEMPLATE_ROUTE = "/home/tasks/projects/templates/:templateId";
export const PROJECT_TEMPLATE_EDIT_ROUTE = "/home/tasks/projects/templates/:templateId/edit";
export const DOCUMENTS_ROUTE = "/home/documents";
export const STATEMENT_OF_ADVICE_ROUTE = "/home/soa";
export const SOA_DISCOVERY_ITEM_ROUTE = "/home/soa/discovery/:soaGroupId";
export const SOA_DOC_ITEM_ROUTE = "/home/soa/doc/:soaId";
export const SINGLE_TEMPLATE_ROUTE = "/home/documents/template/:templateId";
export const TEMPLATE_EDITOR_ROUTE = "/home/documents/template/:templateId/edit";
export const TEMPLATE_PREVIEW_ROUTE = "/home/documents/template/:templateId/preview";
export const SERVICES_FEES_ROUTE = "/home/servicesFees";
export const SERVICES_FEES_MASTERLIST_ROUTE = "/home/servicesFees/masterlist";
export const SERVICES_FEES_NEW_RECORD_ROUTE = "/home/servicesFees/masterlist/newRecord";
export const SETTINGS_ROUTE = "/home/settings";
export const APPS_ROUTE = "/home/apps";
export const XPLAN_MIGRATION_ROUTE = "/home/apps/migration/xplan";
export const XPLAN_MODELOFFICE_MIGRATION_ROUTE = "/home/apps/migration/xplan-modeloffice";
export const XPLAN_PLANNER_MIGRATION_ROUTE = "/home/apps/migration/xplan-plannercentral";
