import { IScheduleAttrs } from "../../../models/Schedule/Schedule";
import createReducer from "../../utils/createReducer";
import ActionTypes from "./actionTypes";

export type DiaryStateT = {
    schedules: IScheduleAttrs[];
    hiddenCategories: string[];
    loadingSchedules: boolean;
    schedulesLoaded: boolean;
};

let defaultHiddenCategories: string[] = [];
try {
    defaultHiddenCategories = JSON.parse(localStorage.getItem("HIDDEN-SCHEDULE-CATG") || "[]");
} catch (err) {}

export const initialState: DiaryStateT = {
    schedules: [],
    hiddenCategories: defaultHiddenCategories,
    loadingSchedules: false,
    schedulesLoaded: false,
};

// App Reducer
export default createReducer<DiaryStateT>(initialState, {
    [ActionTypes.SAVE_SCHEDULES]: (state, payload: IScheduleAttrs[]) => ({
        ...state,
        schedules: payload,
    }),
    [ActionTypes.LOADED_SCHEDULES]: (state, payload: boolean) => ({
        ...state,
        schedulesLoaded: payload,
    }),
    [ActionTypes.LOADING_SCHEDULES]: (state, payload: boolean) => ({
        ...state,
        loadingSchedules: payload,
    }),
    [ActionTypes.DELETE_SCHEDULE]: (state, payload: string) => ({
        ...state,
        schedules: state.schedules.filter((t) => t._id !== payload),
    }),
    [ActionTypes.ADD_NEW_SCHEDULE]: (state, payload: IScheduleAttrs) => ({
        ...state,
        schedules: [payload, ...state.schedules],
    }),
    [ActionTypes.SET_HIDDEN_CATEGORIES]: (state, payload: string[]) => ({
        ...state,
        hiddenCategories: payload,
    }),

    [ActionTypes.LOGOUT_ACTION]: (state) => ({
        ...state,
        ...initialState,
    }),
});
