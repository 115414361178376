import moment from "moment";
import { Watchable } from "../_Utils/Watchable";
import { TDocTemplateFieldAttr, TDocumentTemplateAttr } from "./Types";

export type TemplateEventMap = {
    ATTRIBUTES_UPDATED: TDocumentTemplateAttr;
    FIELDS_UPDATED: TDocTemplateFieldAttr[];
    TEMPLATE_DELETED: true;
    TEMPLATE_DOC_DATA_DOWNLOADED: Blob;
};

class DocumentTemplateData extends Watchable<TemplateEventMap> {
    constructor(attrs: TDocumentTemplateAttr) {
        super();
        this.cAttrs = attrs;
        this.attrs = attrs;
    }

    private cFields: TDocTemplateFieldAttr[] = [];
    private cAttrs: TDocumentTemplateAttr;
    private cDocData: Blob | null = null;

    triggerTemplateDeleted() {
        this.trigger("TEMPLATE_DELETED", true);
    }

    set fields(fields: TDocTemplateFieldAttr[]) {
        this.cFields = fields;
        this.trigger("FIELDS_UPDATED", fields);
    }

    get fields() {
        return this.cFields;
    }

    get docData() {
        return this.cDocData;
    }

    set docData(docData: Blob | null) {
        this.cDocData = docData;
        if (docData) {
            this.trigger("TEMPLATE_DOC_DATA_DOWNLOADED", docData);
        }
    }

    set attrs(atr: TDocumentTemplateAttr) {
        this.cAttrs = atr;
        this.cAttrs.createdAt = moment(this.cAttrs.createdAt);
        this.cAttrs.updatedAt = moment(this.cAttrs.updatedAt);

        this.trigger("ATTRIBUTES_UPDATED", atr);

        if (atr.fields) {
            this.fields = atr.fields;
        }
    }

    get attrs() {
        return this.cAttrs;
    }
}

export class DocumentTemplate {
    public data: DocumentTemplateData;
    public readonly id: string;

    constructor(attributes: TDocumentTemplateAttr) {
        this.data = new DocumentTemplateData(attributes);
        this.id = attributes._id;
    }

    updateFields(files: TDocTemplateFieldAttr[]) {
        this.data.fields = files;
        return this;
    }

    updateDocData(data: Blob) {
        this.data.docData = data;
        return this;
    }

    markDeleted() {
        this.data.triggerTemplateDeleted();
    }

    updateTemplate(template: DocumentTemplate) {
        this.updateTemplateAttrs(template.data.attrs);
        this.data.fields = template.data.fields;
        return this;
    }

    updateTemplateAttrs(attrs: TDocumentTemplateAttr) {
        this.data.attrs = attrs;
        return this;
    }

    addOrUpdateField(fieldAttrs: TDocTemplateFieldAttr) {
        const fieldId = fieldAttrs._id;
        if (this.data.fields.find((t) => t._id === fieldId)) {
            this.data.fields = this.data.fields.map((t) => {
                if (t._id === fieldId) return fieldAttrs;
                return t;
            });
        } else {
            this.data.fields = [fieldAttrs, ...this.data.fields];
        }
        return this;
    }

    removeFieldWithId(fieldId: string) {
        this.data.fields = this.data.fields.filter((t) => t._id !== fieldId);
        return this;
    }

    remove() {
        this.data.triggerTemplateDeleted();
    }

    static fromArray(contacts: TDocumentTemplateAttr[]) {
        return contacts.map((t) => new DocumentTemplate(t));
    }
}
