import ActionTypes from "./actionTypes";
import { getAppStore } from "../../store";
import { Organization } from "../../../models/Organization/Organization";
import { LOCAL_STORAGE_KEYS } from "../../../constants/appVars";
import { TUserAttr } from "../../../models/User/User";

export class OrgStoreActions {
    static loadingOrganizations(loading: boolean) {
        getAppStore().dispatch({ type: ActionTypes.LOADING_ORGANIZATIONS, payload: loading });
    }
    static saveOrganizations(orgs: Organization[]) {
        getAppStore().dispatch({ type: ActionTypes.SAVE_ORGANIZATIONS, payload: orgs });
    }
    static getOrganizations() {
        return getAppStore().getState().orgs.orgs;
    }
    static getActiveOrg() {
        return getAppStore().getState().orgs.activeOrg;
    }
    static updateOrganization(org: Organization) {
        const orgs = OrgStoreActions.getOrganizations().map((t) => {
            if (t.id === org.id) {
                return org;
            }
            return t;
        });
        OrgStoreActions.saveOrganizations(orgs);
    }
    static addOrUpdateToOrganizations(org: Organization) {
        let organizations = OrgStoreActions.getOrganizations();
        if (organizations.some((t) => t.data.attrs._id === org.id)) {
            organizations = organizations.map((t) => {
                if (t.data.attrs._id === org.id) {
                    return t.updateOrganization(org);
                }
                return t;
            });
        } else {
            organizations = [org, ...organizations];
        }
        OrgStoreActions.saveOrganizations(organizations);
    }
    static setActiveOrg(org: Organization) {
        localStorage.setItem(LOCAL_STORAGE_KEYS.ACTIVE_ORG, org.id);
        getAppStore().dispatch({ type: ActionTypes.SET_ACTIVE_ORGANIZATION, payload: org });
    }
    static getSavedOrgId() {
        return localStorage.getItem(LOCAL_STORAGE_KEYS.ACTIVE_ORG);
    }
}
