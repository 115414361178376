import moment, { Moment } from "moment";
import CSVParser from "papaparse";

/**
 * Formats the URL so as to provide values for the its dynamic sections or
 * query strings. <br>
 * **Example**: `formatUrl("http://www.mydomain.com/{id}/{name}", {"query":"query_value"}, {id:1234, name:"name_value"})`
 * will return `http://www.mydomain.com/1234/name_value?query=query_value`
 * @param {String} endpoint The endpoint url
 * @param {Object} queryStrings Object containing the query string parameters
 * @param {Object} dynamicContent Object containing the values for the dynamic parts of the url
 */
export function formatUrl(
    endpoint: string,
    queryStrings?: { [k: string]: string | null | undefined } | null,
    dynamicContent?: { [k: string]: string },
) {
    let qs = "";
    queryStrings = queryStrings || {};
    dynamicContent = dynamicContent || {};

    Object.keys(queryStrings).forEach((key) => {
        if (queryStrings![key]) {
            const value = encodeURIComponent(queryStrings![key] || "");
            qs = `${qs}${qs.length > 0 ? "&" : ""}${key}=${value}`;
        }
    });

    Object.keys(dynamicContent).forEach((key) => {
        if (dynamicContent![key]) {
            const re = RegExp(`{${key}}`);
            endpoint = endpoint.replace(re, dynamicContent![key]);
        }
    });

    if (qs.length > 0) {
        endpoint = `${endpoint}?${qs}`;
    }

    return endpoint;
}

export function getAge(dateInput: string | moment.Moment | Date) {
    if (!moment(dateInput).isValid()) return "";
    const today = new Date();
    const birthDate = new Date(moment.isMoment(dateInput) ? dateInput.toDate() : dateInput);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age < 0 ? 0 : age;
}

export const getVarcharEight = () =>
    Array.apply(0, Array(8))
        .map(() => ((charset) => charset.charAt(Math.floor(Math.random() * charset.length)))("ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"))
        .join("");

export function snapToGrid(x: number, y: number) {
    const snappedX = Math.round(x / 8) * 8;
    const snappedY = Math.round(y / 8) * 8;
    return [snappedX, snappedY];
}

export function blobFromUri(data: Blob | string): Blob {
    if (typeof data === "string") {
        const parts = data.split(";base64,");
        const contentType = parts[0];
        const base64 = atob(parts[1]);
        const array = new Uint8Array(base64.length);

        for (let idx = 0; idx < base64.length; idx++) {
            array[idx] = base64.charCodeAt(idx);
        }

        return new Blob([array.buffer], { type: contentType });
    }
    return data;
}

export async function parseCSVInput(csvFile: File | string) {
    return new Promise<string[][]>((resolve, reject) => {
        CSVParser.parse(csvFile, {
            skipEmptyLines: "greedy",
            complete: (results: any) => {
                if (results.errors.length) {
                    reject(results.errors.map((item: Error) => item.message).join(","));
                } else {
                    resolve(results.data as string[][]);
                }
            },
        });
    });
}

export function reorderArray<T extends { [k: string]: any }>(array: T[], colName: string, order: string[]) {
    const secondArray = Array.from(array);
    const result = [];
    for (let i = 0; i < order.length; i++) {
        for (let j = 0; j < secondArray.length; j++) {
            if (secondArray[j][colName] === order[i]) {
                result.push(secondArray.splice(j, 1)[0]);
                break;
            }
        }
    }

    return result;
}

export function getCurrentAUFinancialYear(): [moment.Moment, moment.Moment] {
    const date = new Date();
    const startDate: Date = new Date();
    startDate.setUTCMonth(6);
    startDate.setUTCDate(1);
    startDate.setUTCHours(0, 0, 0);

    const endDate: Date = new Date();
    endDate.setUTCMonth(5);
    endDate.setUTCDate(30);
    endDate.setUTCHours(0, 0, 0);

    const monthNumber = date.getUTCMonth() + 1;

    if (monthNumber >= 7) {
        startDate.setUTCFullYear(date.getUTCFullYear());
        endDate.setUTCFullYear(date.getUTCFullYear() + 1);
    } else {
        startDate.setUTCFullYear(date.getUTCFullYear() - 1);
        endDate.setUTCFullYear(date.getUTCFullYear());
    }

    return [moment(startDate), moment(endDate)];
}

const editDistanceForStrings = (s1: string, s2: string) => {
    s1 = s1.toLowerCase();
    s2 = s2.toLowerCase();

    const costs = [];
    for (let i = 0; i <= s1.length; i++) {
        let lastValue = i;
        for (let j = 0; j <= s2.length; j++) {
            if (i == 0) costs[j] = j;
            else {
                if (j > 0) {
                    let newValue = costs[j - 1];
                    if (s1.charAt(i - 1) != s2.charAt(j - 1)) newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1;
                    costs[j - 1] = lastValue;
                    lastValue = newValue;
                }
            }
        }
        if (i > 0) costs[s2.length] = lastValue;
    }
    return costs[s2.length];
};

export const stringSimilarity = (s1: string, s2: string) => {
    let longer = s1;
    let shorter = s2;
    if (s1.length < s2.length) {
        longer = s2;
        shorter = s1;
    }
    const longerLength = longer.length;
    if (longerLength == 0) {
        return true;
    }
    const similarity = (longerLength - editDistanceForStrings(longer, shorter)) / parseFloat(String(longerLength));
    return similarity >= 0.5 ? true : false;
};

export const removeHTMLTagsFromAString = (str: string) => {
    if (str === null || str === "") return false;
    else str = str.toString();

    // Regular expression to identify HTML tags in
    // the input string. Replacing the identified
    // HTML tag with a null string.
    return str.replace(/(<([^>]+)>)/gi, "");
};

export const getDateISOString = (date: Moment | null) => {
    return date ? moment(date).toISOString() : undefined;
};

export const parseNumberValue = (value: string | number) => {
    const parsedValue = parseFloat(value.toString().replace(/,/g, ""));
    return parsedValue;
};

// get fixed numbers after decimals without rounding off
export const toFixed = (num: number, fixed: number) => {
    const re = new RegExp("^-?\\d+(?:.\\d{0," + (fixed || -1) + "})?");
    return num.toString().match(re)?.[0];
};

export const convertNumberToAbbreviatedString = (value: number) => {
    if (value < 1e3) return value;
    if (value >= 1e3 && value < 1e6) return toFixed(+(value / 1e3), 1) + "k";
    if (value >= 1e6 && value < 1e9) return toFixed(+(value / 1e6), 1) + "M";
    if (value >= 1e9 && value < 1e12) return toFixed(+(value / 1e9), 1) + "B";
    if (value >= 1e12) return toFixed(+(value / 1e12), 1) + "T";
};

export const generateRedShade = () => {
    const maxRedValue = 150;
    const randomValue = Math.floor(Math.random() * (maxRedValue + 1));
    return `rgb(${255},${randomValue},${randomValue})`;
};

/**
 * Waits for an element to become available on the dom and returns the element.
 * If the timeout is exceeded, undefined is returned instead.
 *
 * @param selector the selector to wait for
 * @param timeout the timeout for the wait
 * @returns the element or null if not found
 */
export function waitForElement(selector: string, timeout = 300) {
    return new Promise<HTMLElement | undefined>((resolve) => {
        let foundElt = document.querySelector(selector);
        let timeoutIntv: any = null;
        if (foundElt) {
            return resolve(foundElt as HTMLElement);
        }

        const observer = new MutationObserver((mutations) => {
            foundElt = document.querySelector(selector);
            if (foundElt) {
                resolve(foundElt as HTMLElement);
                clearTimeout(timeoutIntv);
                observer.disconnect();
            }
        });

        observer.observe(document.body, { childList: true, subtree: true });

        timeoutIntv = setTimeout(() => {
            observer.disconnect();
            resolve(undefined);
        }, timeout);
    });
}

export async function convertFileToBase64(file: File) {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
        reader.readAsDataURL(file);

        reader.onload = function () {
            resolve(reader.result);
        };
        reader.onerror = function (error) {
            reject(error);
        };
    });
}
