import { Moment } from "moment";
import { ContactNote } from "../../models/Contact/ContactNote";
import { TContact } from "../../models/Contact/Types";
import { ContactUtils } from "../../models/Contact/Utils";
import { IServiceFeesData } from "../../models/ServicesFees/MasterlistRecord";
import { APIManager } from "../APIManager";
import { ContactAuditManager } from "./AuditManager";
import { CompanyManager } from "./CompanyManager";
import { IndividualManager } from "./IndividualManager";
import { ContactNetworkHelper } from "./NetworkHelper";
import { TrustManager } from "./TrustManager";

export class ContactManager {
    Individual = new IndividualManager();
    Company = new CompanyManager();
    Trust = new TrustManager();
    Audits = new ContactAuditManager();
    networkHelper = ContactNetworkHelper;

    getContact = (contactId: string) => {
        return this.Individual.getContact(contactId) || this.Company.getContact(contactId) || this.Trust.getContact(contactId);
    };

    apiFetchAllContacts = async () => {
        const individuals = await this.Individual.apiFetchAllContacts(() => {});
        const companies = await this.Company.apiFetchAllContacts(() => {});
        const trusts = await this.Trust.apiFetchAllContacts(() => {});

        return { individuals, companies, trusts };
    };

    apiFetchDeletedContacts = async () => {
        const individuals = await this.Individual.apiFetchDeletedContacts(() => {});
        const companies = await this.Company.apiFetchDeletedContacts(() => {});
        const trusts = await this.Trust.apiFetchDeletedContacts(() => {});

        return { individuals, companies, trusts };
    };

    apiFetchContactAttrs = async (contactId: string, cb: (err: string | null, res?: TContact) => void) => {
        try {
            const res = await APIManager.get(APIManager.CONTACT.INDIVIDUAL.ITEM_API(contactId));
            const contact = this.Individual.updateContactAttrOrAddNew(res.data);
            cb(null, contact);
        } catch (err) {
            try {
                const res = await APIManager.get(APIManager.CONTACT.COMPANY.ITEM_API(contactId));
                const contact = this.Company.updateContactAttrOrAddNew(res.data);
                cb(null, contact);
            } catch (err) {
                try {
                    const res = await APIManager.get(APIManager.CONTACT.TRUST.ITEM_API(contactId));
                    const contact = this.Trust.updateContactAttrOrAddNew(res.data);
                    cb(null, contact);
                } catch (err) {
                    cb(`${err}`);
                }
            }
        }
    };

    getContactDisplayName = (contactId: string) => {
        const contact = this.getContact(contactId);
        if (contact) {
            return ContactUtils.getDisplayName(contact);
        }
        return "";
    };
}
