import { QueryResultCallback } from "../../models/Callback/Callback";
import { SubscriptionStoreActions } from "../../redux/services/subscription/actions";
import { APIManager } from "../APIManager";
import { SubscriptionNetworkHelper } from "./NetworkHelper";

export class SubscriptionManager {
    networkHelper = SubscriptionNetworkHelper;
    async apiFetchCustomerInfo(cb: QueryResultCallback<any>) {
        try {
            SubscriptionStoreActions.loadingCustomerInfo(true);
            const resp = await APIManager.get(APIManager.SUBSCRIPTION.CUSTOMER_INFO_API);
            SubscriptionStoreActions.saveCustomerInfo(resp.data);
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        } finally {
            SubscriptionStoreActions.loadingCustomerInfo(false);
        }
    }

    async apiFetchCurrentPlan(cb: QueryResultCallback<any>) {
        try {
            SubscriptionStoreActions.loadingCurrentPlan(true);
            const resp = await APIManager.get(APIManager.SUBSCRIPTION.CURRENT_PLAN_API);
            SubscriptionStoreActions.saveCurrentPlan(resp.data);
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        } finally {
            SubscriptionStoreActions.loadingCurrentPlan(false);
        }
    }

    async apiUpdateCurrentPlanSeats(seats: number, cb: QueryResultCallback<any>) {
        try {
            SubscriptionStoreActions.loadingCurrentPlan(true);
            const resp = await APIManager.put(APIManager.SUBSCRIPTION.CURRENT_PLAN_SEATS_API, {
                seats,
            });
            SubscriptionStoreActions.saveCurrentPlan(resp.data);
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        } finally {
            SubscriptionStoreActions.loadingCurrentPlan(false);
        }
    }

    async apiUpdateCurrentPlanSOALicences(users: string[], cb: QueryResultCallback<any>) {
        try {
            SubscriptionStoreActions.loadingCurrentPlan(true);
            const resp = await APIManager.put(APIManager.SUBSCRIPTION.CURRENT_PLAN_SOA_LICENCES_API, {
                users,
            });
            SubscriptionStoreActions.saveCurrentPlan(resp.data);
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        } finally {
            SubscriptionStoreActions.loadingCurrentPlan(false);
        }
    }

    async apiSetDefaultPaymentMethod(paymentMethodId: string, cb: QueryResultCallback<any>) {
        try {
            const resp = await APIManager.put(APIManager.SUBSCRIPTION.DEFAULT_PAYMENT_METHOD_API, { paymentMethodId });
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiFetchPaymentMethods(cb: QueryResultCallback<any>) {
        try {
            SubscriptionStoreActions.loadingPaymentMethods(true);
            const resp = await APIManager.get(APIManager.SUBSCRIPTION.PAYMENT_METHODS_API);
            SubscriptionStoreActions.savePaymentMethods(resp.data);
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        } finally {
            SubscriptionStoreActions.loadingPaymentMethods(false);
        }
    }

    async apiFetchBillingHistory(cb: QueryResultCallback<any>) {
        try {
            SubscriptionStoreActions.loadingBillingHistory(true);
            const resp = await APIManager.get(APIManager.SUBSCRIPTION.BILLING_HISTORY_API);
            SubscriptionStoreActions.saveBillingHistory(resp.data);
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        } finally {
            SubscriptionStoreActions.loadingBillingHistory(false);
        }
    }
}
