import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Card } from "antd";
import Loading from "../../../components/Loading/Loading";
import BasicHeaderLayout from "../../../layouts/BasicHeaderLayout/BasicHeaderLayout";
import querystring from "qs";
import jsonwebtoken from "jsonwebtoken";
import { AppManager } from "../../../manager";
import SimpleCenteredLayout from "../../../layouts/SimpleCentered/SimpleCenteredLayout";
import BasicErrorView from "../../../components/ErrorView/BasicErrorView/BasicErrorView";

const CheckAuth = () => {
    const location = useLocation();
    const [loadError, setLoadError] = useState<string>();
    useEffect(() => {
        const pathname = location.pathname;
        if (pathname && pathname.startsWith("/access_token")) {
            try {
                const res = querystring.parse(pathname);
                const token = res.id_token as string;
                if (!token) AppManager.route.gotoLogin();
                const decoded = jsonwebtoken.decode(token);
                if (typeof decoded !== "string" && decoded?.nonce) {
                    AppManager.auth.loginUserFromAuth0(token, decoded.nonce, (err) => {
                        if (err) AppManager.route.gotoLogin();
                        else AppManager.route.gotoHome();
                    });
                } else AppManager.route.gotoLogin();
            } catch (err) {
                AppManager.route.gotoLogin();
            }
        } else if (pathname && pathname.startsWith("/error")) {
            const res = querystring.parse(pathname);
            console.log("HERE IS THE LOCATION ", res);
            const errorDesc = res.error_description as string;
            console.log("ERROR DESCRIPTION ", errorDesc);
            setLoadError(errorDesc || "Something went wrong");
        } else {
            AppManager.route.gotoLogin();
        }
    }, []);
    return loadError ? (
        <SimpleCenteredLayout
            children={
                <div style={{ padding: 50 }}>
                    <Card>
                        <BasicErrorView
                            error={loadError}
                            description={`
                Something went wrong while trying to authenticate you. One possible cause of this is that third party cookies are disabled on your browser as we heavily 
                depend on them to securely authenticate you. If you're sure that this is not the issue, please contact technical support with a snapshot of this entire page and we'll be happy to help.
            `}
                        />
                    </Card>
                </div>
            }
        />
    ) : (
        <BasicHeaderLayout title="" content={<Loading text="Authenticating..." />} />
    );
};

export default CheckAuth;
