enum ActionTypes {
    SAVE_NOTIFICATIONS = "SAVE_NOTIFICATIONS",
    LOADED_NOTIFICATIONS = "LOADED_NOTIFICATIONS",
    LOADING_NOTIFICATIONS = "LOADING_NOTIFICATIONS",
    ADD_NEW_NOTIFICATION = "ADD_NEW_NOTIFICATION",
    DELETE_NOTIFICATION = "DELETE_NOTIFICATION",
    READ_ALL_NOTIFICATIONS = "READ_ALL_NOTIFICATIONS",

    LOGOUT_ACTION = "LOGOUT_ACTION",
}

export default ActionTypes;
