import { QueryResultCallback } from "../../models/Callback/Callback";
import { ServiceFeeProvider, TServiceFeeProviderAttrs } from "../../models/ServicesFees/Provider";
import { ServiceFeeStoreActions } from "../../redux/services/servicesfees/actions";
import { APIManager } from "../APIManager";

export class ServiceFeeProviderManager {
    static newServiceFeeProviderObj(data: TServiceFeeProviderAttrs) {
        return new ServiceFeeProvider(data);
    }

    getProvider(providerId: string) {
        return ServiceFeeStoreActions.getProviders().find((t) => t.id === providerId);
    }
    updateProviderAttrOrAddNew(providerAttr: TServiceFeeProviderAttrs, saveToStore = true) {
        let provider = this.getProvider(providerAttr._id);
        if (provider) {
            provider.updateProviderAttrs(providerAttr);
        } else {
            provider = ServiceFeeProviderManager.newServiceFeeProviderObj(providerAttr);
        }
        if (saveToStore) ServiceFeeStoreActions.addOrUpdateToProviders(provider);
        return provider;
    }

    removeProvider(providerId: string) {
        const provider = this.getProvider(providerId);
        if (provider) {
            provider.remove();
            ServiceFeeStoreActions.removeProvider(provider);
        } else console.log("PROVIDER WITH ID ", providerId, " NOT FOUND");
    }

    async apiFetchAllProviders(cb: QueryResultCallback<ServiceFeeProvider[]>) {
        try {
            ServiceFeeStoreActions.loadingProviders(true);
            const resp = await APIManager.get(APIManager.MASTERLIST.MASTERLIST_PROVIDERS_API);
            const providers: ServiceFeeProvider[] = resp.data.map((t: TServiceFeeProviderAttrs) => this.updateProviderAttrOrAddNew(t, false));
            ServiceFeeStoreActions.saveProviders(providers);
            ServiceFeeStoreActions.setProvidersLoaded(true);
            cb(null, providers);
            return providers;
        } catch (err) {
            cb(`${err}`);
        } finally {
            ServiceFeeStoreActions.loadingProviders(false);
        }
    }

    async apiAddProvider(providerData: Partial<TServiceFeeProviderAttrs>, cb: QueryResultCallback<ServiceFeeProvider | null>) {
        try {
            const resp = await APIManager.post(APIManager.MASTERLIST.MASTERLIST_PROVIDERS_API, providerData);
            const provider: ServiceFeeProvider = ServiceFeeProviderManager.newServiceFeeProviderObj(resp.data);
            ServiceFeeStoreActions.addOrUpdateToProviders(provider);
            cb(null, provider);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiFetchAllProviderAdviserRefs(cb: QueryResultCallback<ServiceFeeProvider[]>) {
        try {
            const resp = await APIManager.get(APIManager.MASTERLIST.MASTERLIST_PROVIDERS_ADVISERREF_API);
            ServiceFeeStoreActions.saveAllProvidersAdviserRefs(resp.data);
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiAutodetectProvider(input: { columnHeaders: string[]; fileName: string }, cb: QueryResultCallback<ServiceFeeProvider | null>) {
        try {
            ServiceFeeStoreActions.loadingProviders(true);
            const resp = await APIManager.post(APIManager.MASTERLIST.MASTERLIST_PROVIDER_AUTODETECT_API, input);
            if (resp.data && resp.data.success) {
                const provider = this.updateProviderAttrOrAddNew(resp.data.provider);
                cb(null, provider);
            } else cb(null, null);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiDeleteProvider(providerId: string, cb: QueryResultCallback<ServiceFeeProvider | null>) {
        try {
            const resp = await APIManager.delete(APIManager.MASTERLIST.MASTERLIST_SINGLE_PROVIDER_API(providerId));
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiUpdateProviderCsvCode(
        providerId: string,
        input: { columnHeaders: string[]; fileName: string },
        cb: QueryResultCallback<ServiceFeeProvider | null>,
    ) {
        try {
            const resp = await APIManager.put(APIManager.MASTERLIST.MASTERLIST_PROVIDER_CSVCODE_API(providerId), input);
            if (resp.data) {
                const provider = this.updateProviderAttrOrAddNew(resp.data);
                cb(null, provider);
            } else cb(null, null);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiUpdateProvider(providerId: string, providerData: Partial<TServiceFeeProviderAttrs>, cb: QueryResultCallback<ServiceFeeProvider | null>) {
        try {
            ServiceFeeStoreActions.loadingProviders(true);
            const resp = await APIManager.put(APIManager.MASTERLIST.MASTERLIST_SINGLE_PROVIDER_API(providerId), providerData);
            if (resp.data) {
                const provider = this.updateProviderAttrOrAddNew(resp.data);
                cb(null, provider);
            } else cb(null, null);
        } catch (err) {
            cb(`${err}`);
        }
    }
}
