import { Button, Tag } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { Organization } from "../../models/Organization/Organization";
import { TFullOrganizationAttr } from "../../models/Organization/Types";
import { RootState } from "../../redux/reducers";
import AppSimplePage from "../AppSimplePage/AppSimplePage";
import Styles from "./index.scss";
import subFailedImg from "../../assets/img/subscription_failed_placeholder.png";
import multiSessionsImg from "../../assets/img/error-icon-1.png";
import { AppManager } from "../../manager";

export const SubscriptionIssuePage = () => {
    const activeOrg = useSelector<RootState, Organization>((state) => state.orgs.activeOrg!);
    return (
        <AppSimplePage
            hideSidebar
            content={
                <div className={Styles.SubscriptionIssuePage}>
                    <div className={Styles.Image}>
                        <img src={subFailedImg} />
                    </div>
                    <div className={Styles.Header}>Invalid/Expired Subscription</div>
                    <div className={Styles.Description}>
                        It looks like there are some issues with your ausplan subscription. Please contact support for further assistance
                    </div>
                    <div className={Styles.SubStatusContainer}>
                        <div className={Styles.Name}>Subscription Status</div>
                        <Tag color="red">{(activeOrg.data.attrs as TFullOrganizationAttr).subscription?.status}</Tag>
                    </div>
                </div>
            }
        />
    );
};

export const MultipleSessionIssuePage = () => {
    const forceInit = () => {
        AppManager.realtime.reInitWithForcedAuth();
    };
    return (
        <AppSimplePage
            hideSidebar
            content={
                <div className={Styles.SubscriptionIssuePage}>
                    <div className={Styles.Image} style={{ marginBottom: 20 }}>
                        <img src={multiSessionsImg} style={{ width: 100 }} />
                    </div>
                    <div className={Styles.Header}>Multiple sessions detected</div>
                    <div className={Styles.Description}>Sorry, you can only use one instance of your account at a time</div>
                    <div style={{ marginTop: 20 }}>
                        <Button size="large" type="primary" onClick={forceInit}>
                            Use this session
                        </Button>
                    </div>
                </div>
            }
        />
    );
};
