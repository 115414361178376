import ActionTypes from "./actionTypes";
import { getAppStore } from "../../store";

export class SubscriptionStoreActions {
    static loadingCurrentPlan(loading: boolean) {
        getAppStore().dispatch({ type: ActionTypes.LOADING_CURRENT_PLAN, payload: loading });
    }
    static saveCurrentPlan(currentPlan: any) {
        getAppStore().dispatch({ type: ActionTypes.SAVE_CURRENT_PLAN, payload: currentPlan });
    }

    static loadingCustomerInfo(loading: boolean) {
        getAppStore().dispatch({ type: ActionTypes.LOADING_CUSTOMER_INFO, payload: loading });
    }
    static saveCustomerInfo(customerInfo: any) {
        getAppStore().dispatch({ type: ActionTypes.SAVE_CUSTOMER_INFO, payload: customerInfo });
    }

    static loadingPaymentMethods(loading: boolean) {
        getAppStore().dispatch({ type: ActionTypes.LOADING_PAYMENT_METHODS, payload: loading });
    }
    static savePaymentMethods(paymentMethods: any[]) {
        getAppStore().dispatch({ type: ActionTypes.SAVE_PAYMENT_METHODS, payload: paymentMethods });
    }

    static loadingBillingHistory(loading: boolean) {
        getAppStore().dispatch({ type: ActionTypes.LOADING_BILLING_HISTORY, payload: loading });
    }
    static saveBillingHistory(billingHistory: any[]) {
        getAppStore().dispatch({ type: ActionTypes.SAVE_BILLING_HISTORY, payload: billingHistory });
    }
}
