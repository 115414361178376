import { Path as PathParser } from "path-parser";
import { PROJECT_TEMPLATE_EDIT_ROUTE, SINGLE_PROJECT_TEMPLATE_ROUTE } from "../../constants/appRoutes";
import { AppManager } from "..";
import { IOEventTypes } from "../RealtimeManager";

export class ProjectTemplateNetworkHelper {
    static networkDataReceived = <K extends keyof IOEventTypes>(evt: K, resp: any): void => {
        switch (evt) {
            // Masterlist events
            case "PROJECT_TEMPLATE_CREATED":
            case "PROJECT_TEMPLATE_UPDATED":
                {
                    const template = (resp as IOEventTypes["PROJECT_TEMPLATE_CREATED"]).data;
                    AppManager.project.template.updateTemplateAttrOrAddNew(template);
                }
                break;
            case "PROJECT_TEMPLATE_DELETED":
                AppManager.project.template.removeTemplate((resp as IOEventTypes["PROJECT_TEMPLATE_DELETED"]).data._id);
                break;
        }
    };

    static onConnectionRestoredSync(currentRoute: string, currentPathName: string) {
        switch (currentRoute) {
            case PROJECT_TEMPLATE_EDIT_ROUTE:
            case SINGLE_PROJECT_TEMPLATE_ROUTE:
                const match = new PathParser(currentRoute).test(currentPathName);
                if (match) AppManager.project.template.apiFetchTemplateAttrs(match.templateId, () => {});
                break;
        }
    }
}
