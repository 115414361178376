import { Checkbox, Input, Popover, Progress, Space, Tag } from "antd";
import classNames from "classnames";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import SearchIcon from "@ant-design/icons/SearchOutlined";
import Styles from "./GlobalSearch.scss";
import BarLoader from "react-spinners/BarLoader";
import PulseLoader from "react-spinners/PulseLoader";
import { AppManager } from "../../../manager";
import BasicErrorView from "../../../components/ErrorView/BasicErrorView/BasicErrorView";
import HighlightText from "../../../components/HighlightText/HighlightText";
import IndividualIcon from "@ant-design/icons/UserOutlined";
import CompanyIcon from "@ant-design/icons/BankOutlined";
import TrustIcon from "@ant-design/icons/AuditOutlined";
import FileIcon from "@ant-design/icons/FileOutlined";
import { openBlobItemViewModal } from "../../ObjectViewModal/ObjectViewModal";
import NoteIcon from "@ant-design/icons/EditOutlined";
import ClampText from "../../../components/ClampText/ClampText";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
import { Organization } from "../../../models/Organization/Organization";
import { AutoSizer, List } from "react-virtualized";
import { WindowScroller as _WindowScroller, WindowScrollerProps } from "rv-window-scroller";
import striptags from "striptags";
import { useResizeDetector } from "react-resize-detector";
import "react-virtualized/styles.css";

const WindowScroller = _WindowScroller as unknown as React.FC<WindowScrollerProps>;

const ResultsPlaceHolder: React.FC<{ fetching?: boolean }> = ({ fetching }) => {
    return (
        <div className={Styles.NoResultContainer}>
            {fetching ? <PulseLoader size={12} /> : ""}
            <div>{fetching ? "Searching" : "Enter at least three characters to begin search"}</div>
        </div>
    );
};

const ResultsError: React.FC<{ error: string; fetching?: boolean }> = ({ error, fetching }) => {
    return (
        <div className={Styles.NoResultContainer}>
            {fetching ? (
                <div>
                    <PulseLoader size={12} />
                    <div>Searching</div>
                </div>
            ) : (
                <BasicErrorView error={error} />
            )}
        </div>
    );
};

const NoResultsView: React.FC<{ search: string; fetching?: boolean }> = ({ search, fetching }) => {
    return (
        <div className={Styles.NoResultContainer}>
            {fetching ? (
                <div>
                    <PulseLoader size={12} />
                    <div>Searching</div>
                </div>
            ) : (
                <div>
                    No results for the term <b>&quot;{search}&quot;</b>
                </div>
            )}
        </div>
    );
};

const getMatchedField = (fields: Record<string, any>, text: string): [string, string] => {
    for (const item of Object.keys(fields)) {
        if (typeof fields[item] === "string" && fields[item].toLowerCase().includes(text.toLowerCase())) {
            return [item, fields[item]];
        }
    }
    return ["", ""];
};

type TypeColor = "blue" | "orange" | "magenta" | "pink" | "cyan" | "green" | "purple" | "geekblue" | "volcano" | "gold" | "lime";

const MatchItemDisplay: React.FC<{
    item: Record<string, any>;
    text: string;
    containerWidth: number;
    itemType: string;
    itemIcon: React.ReactNode;
    itemPreview: React.ReactNode;
    onClick: () => void;
    onMount?: () => void;
    typeColor?: TypeColor;
    stripHtml?: boolean;
}> = ({ item, text, itemType, itemPreview, itemIcon, onClick, onMount, typeColor = "default", stripHtml, containerWidth }) => {
    const [matchResult, setMatchResults] = useState<[string, string]>(getMatchedField(item, text));

    useEffect(() => {
        onMount?.();
    }, []);

    useEffect(() => {
        setMatchResults(getMatchedField(item, text));
    }, [item, text]);

    return (
        <div className={Styles.ItemListValueContainer} onClick={onClick}>
            <div className={Styles.MatchValueContainer}>
                <div
                    className={Styles.MatchValue}
                    style={{ position: "relative", textOverflow: "ellipsis", width: containerWidth, overflow: "hidden" }}
                >
                    {matchResult[1].length > 200 ? (
                        <ClampText lines={1} text={striptags(matchResult[1].substring(0, 200))} />
                    ) : (
                        <HighlightText text={matchResult[1]} searches={[text]} stripHtml={stripHtml} />
                    )}
                </div>
                <span>
                    <Tag color={typeColor} className={Styles.MatchField}>
                        {itemType}
                    </Tag>
                </span>
            </div>
            <div className={Styles.MatchEntityContainer}>
                <div className={Styles.Icon}>{itemIcon}</div>
                <div className={Styles.Name}>{itemPreview}</div>
            </div>
        </div>
    );
};

interface ResultItemGroupProps {
    items: Record<string, any>[];
    text: string;
    renderPreview: (item: Record<string, any>) => React.ReactNode;
    itemIcon: React.ReactNode;
    itemType: string;
    typeColor?: TypeColor;
    stripHtml?: boolean;
    onItemClick: (item: Record<string, any>) => void;
    scrollContainerEl?: HTMLElement;
    scrollWidth: number;
}

const ResultItemGroup: React.FC<ResultItemGroupProps> = ({
    items,
    text,
    scrollContainerEl,
    renderPreview,
    onItemClick,
    itemIcon,
    itemType,
    typeColor,
    stripHtml,
    scrollWidth,
}) => {
    const RenderRow = useCallback(
        ({ index, style, key, parent }: any) => {
            const ct = items[index];
            return (
                <div style={style} key={key}>
                    <MatchItemDisplay
                        item={ct}
                        text={text}
                        itemType={itemType}
                        itemIcon={itemIcon}
                        stripHtml={stripHtml}
                        // itemPreview={`${ct.firstName} ${ct.lastName}`}
                        itemPreview={renderPreview(ct)}
                        typeColor={typeColor}
                        onClick={() => onItemClick(ct)}
                        containerWidth={scrollWidth || 100}
                    />
                </div>
            );
        },
        [items, itemType, itemIcon, typeColor, text, stripHtml, renderPreview, scrollWidth],
    );

    return (
        <div className={Styles.ResultsGroup}>
            <div className={Styles.ItemHeader}>{itemType}</div>
            <div className={Styles.ItemList}>
                <WindowScroller scrollElement={scrollContainerEl || undefined}>
                    {({ height, registerChild, scrollTop, isScrolling }) => (
                        <div ref={(e) => registerChild(e)}>
                            <AutoSizer disableHeight={true}>
                                {({ width }) => (
                                    <List
                                        autoHeight
                                        width={width}
                                        height={height}
                                        rowCount={items.length}
                                        rowHeight={75}
                                        rowRenderer={RenderRow}
                                        scrollTop={scrollTop}
                                        isScrolling={isScrolling}
                                        overscanRowCount={2}
                                    />
                                )}
                            </AutoSizer>
                        </div>
                    )}
                </WindowScroller>
            </div>
        </div>
    );
};

const ResultsView: React.FC<{ results: any; text: string }> = ({ results, text }) => {
    const [activeGroup, setActiveGroup] = useState<string>();
    const totalResults = useMemo(() => {
        return Object.keys(results || {}).reduce((a, t) => results[t].length + a, 0);
    }, [results]);

    const activeGroupName = useMemo(
        () => ({
            contactFiles: "Contact Files",
            contactNotes: "Contact Notes",
            documentTemplates: "Document Templates",
            projectTemplate: "Project Templates",
        }),
        [],
    );

    const { ref: containerRef, width = 100 } = useResizeDetector<HTMLDivElement | null>();
    const [containerEl, setContainerEl] = useState<HTMLElement | null>(containerRef.current);

    useEffect(() => {
        setContainerEl(containerRef.current);
    }, [containerRef.current]);

    const itemKeys = useMemo(() => {
        return Object.keys(results || {}).filter((t) => !activeGroup || activeGroup == t);
    }, [activeGroup, results]);

    useEffect(() => {
        if (!itemKeys.length && activeGroup) setActiveGroup(undefined);
    }, [itemKeys, activeGroup]);

    const getNameCapitalized = (name: string) => {
        return `${(name[0] || "").toUpperCase() || ""}${name.slice(1)}`;
    };

    return (
        <div className={Styles.ResultsView} ref={containerRef}>
            <div className={Styles.SectionHead}>Results</div>
            <Space size={[10, 10]} className={Styles.CategoryList}>
                <Tag color={!activeGroup ? "blue" : undefined} onClick={() => setActiveGroup(undefined)}>
                    All ({totalResults})
                </Tag>
                {Object.keys(results || {}).map((item) => (
                    <Tag key={item} onClick={() => setActiveGroup(item)} color={activeGroup == item ? "blue" : undefined}>
                        {getNameCapitalized(activeGroupName[item as keyof typeof activeGroupName] || item)} ({results[item].length})
                    </Tag>
                ))}
            </Space>

            {itemKeys.includes("individuals") && (
                <ResultItemGroup
                    items={results["individuals"]}
                    text={text}
                    onItemClick={(ct) => AppManager.route.gotoSingleIndividual(ct._id)}
                    typeColor="blue"
                    itemIcon={<IndividualIcon />}
                    itemType="Individual"
                    renderPreview={(ct) => `${ct.firstName} ${ct.lastName}`}
                    scrollContainerEl={containerEl || undefined}
                    scrollWidth={width}
                />
            )}
            {itemKeys.includes("companies") && (
                <ResultItemGroup
                    items={results["companies"]}
                    text={text}
                    onItemClick={(ct) => AppManager.route.gotoSingleCompany(ct._id)}
                    typeColor="blue"
                    itemIcon={<CompanyIcon />}
                    itemType="Company"
                    renderPreview={(ct) => `${ct.name}`}
                    scrollContainerEl={containerEl || undefined}
                    scrollWidth={width}
                />
            )}
            {itemKeys.includes("trusts") && (
                <ResultItemGroup
                    items={results["trusts"]}
                    text={text}
                    onItemClick={(ct) => AppManager.route.gotoSingleTrust(ct._id)}
                    typeColor="blue"
                    itemIcon={<TrustIcon />}
                    itemType="Trust"
                    renderPreview={(ct) => `${ct.name}`}
                    scrollContainerEl={containerEl || undefined}
                    scrollWidth={width}
                />
            )}
            {itemKeys.includes("contactFiles") && (
                <ResultItemGroup
                    items={results["contactFiles"]}
                    text={text}
                    onItemClick={(file) => {
                        file.contactType === "Individual" && AppManager.route.gotoSingleIndividual(file.contact, { tab: "files" });
                        file.contactType === "Company" && AppManager.route.gotoSingleCompany(file.contact, { tab: "files" });
                        file.contactType === "Trust" && AppManager.route.gotoSingleTrust(file.contact, { tab: "files" });
                        openBlobItemViewModal(file.blobName, file.type || "application/pdf", file.name);
                    }}
                    typeColor="geekblue"
                    itemIcon={<FileIcon />}
                    itemType="Contact File"
                    renderPreview={(ct) => `${AppManager.contact.getContactDisplayName(ct.contact) || "--"}`}
                    scrollContainerEl={containerEl || undefined}
                    scrollWidth={width}
                />
            )}
            {itemKeys.includes("contactNotes") && (
                <ResultItemGroup
                    items={results["contactNotes"]}
                    text={text}
                    onItemClick={(note) => {
                        note.contactType === "Individual" && AppManager.route.gotoSingleIndividual(note.contact, { tab: "notes" });
                        note.contactType === "Company" && AppManager.route.gotoSingleCompany(note.contact, { tab: "notes" });
                        note.contactType === "Trust" && AppManager.route.gotoSingleTrust(note.contact, { tab: "notes" });
                    }}
                    typeColor="cyan"
                    itemIcon={<NoteIcon />}
                    itemType="Contact Note"
                    stripHtml
                    renderPreview={(ct) => `${AppManager.contact.getContactDisplayName(ct.contact) || "--"}`}
                    scrollContainerEl={containerEl || undefined}
                    scrollWidth={width}
                />
            )}
            {itemKeys.includes("documentTemplates") && (
                <ResultItemGroup
                    items={results["documentTemplates"]}
                    text={text}
                    onItemClick={(docTemplate) => {
                        AppManager.route.gotoDocumentTemplatePreview(docTemplate._id);
                    }}
                    typeColor="orange"
                    itemIcon={null}
                    itemType="Document Template"
                    renderPreview={(dt) => <ClampText lines={2} text={dt.description} /> || <i style={{ color: "#aaa" }}>no description</i>}
                    scrollContainerEl={containerEl || undefined}
                    scrollWidth={width}
                />
            )}

            {itemKeys.includes("tasks") && (
                <ResultItemGroup
                    items={results["tasks"]}
                    text={text}
                    onItemClick={(task) => {
                        if (task.projectRef) {
                            AppManager.route.gotoSingleProject(task.projectRef);
                        } else {
                            AppManager.route.gotoAllTasksAndProjects({ tab: "tasks", item: task._id });
                        }
                    }}
                    typeColor="magenta"
                    itemIcon={null}
                    itemType="Task"
                    renderPreview={(task) => <ClampText lines={2} text={task.description} /> || <i style={{ color: "#aaa" }}>no description</i>}
                    scrollContainerEl={containerEl || undefined}
                    scrollWidth={width}
                />
            )}
            {itemKeys.includes("projects") && (
                <ResultItemGroup
                    items={results["projects"]}
                    text={text}
                    onItemClick={(project) => {
                        AppManager.route.gotoSingleProject(project._id);
                    }}
                    typeColor="gold"
                    itemIcon={null}
                    itemType="Project"
                    renderPreview={(dt) => ""}
                    scrollContainerEl={containerEl || undefined}
                    scrollWidth={width}
                />
            )}
            {itemKeys.includes("projectTemplate") && (
                <ResultItemGroup
                    items={results["projectTemplate"]}
                    text={text}
                    onItemClick={(template) => {
                        AppManager.route.gotoProjectTemplateEditor(template._id);
                    }}
                    typeColor="lime"
                    itemIcon={null}
                    itemType="Project Template"
                    renderPreview={(dt) => ""}
                    scrollContainerEl={containerEl || undefined}
                    scrollWidth={width}
                />
            )}
        </div>
    );
};

const ResultsContent: React.FC<{ searchText: string }> = ({ searchText }) => {
    const [error, setError] = useState("");
    const [includeNotes, setIncludeNotes] = useState(false);
    const [includeFiles, setIncludeFiles] = useState(false);
    const [fetching, setFetching] = useState(false);
    const [results, setResults] = useState<any>({});
    const debounceFetchRef = useRef<any>();

    const fetchResults = useCallback((search = searchText, options?: { notes?: string; files?: string }) => {
        if (!search || search.length < 3) return;
        setError("");
        setFetching(true);
        AppManager.misc.apiSearchOrganization(search, options || {}, (err, res) => {
            setFetching(false);
            if (err) setError(err);
            else setResults(res!);
        });
    }, []);

    useEffect(() => {
        clearTimeout(debounceFetchRef.current);
        debounceFetchRef.current = setTimeout(() => {
            fetchResults(searchText, { notes: includeNotes ? "1" : undefined, files: includeFiles ? "1" : undefined });
        }, 1000);
    }, [searchText, includeNotes, includeFiles]);

    return (
        <div>
            <div style={{ padding: "5px 10px" }}>
                <div className={Styles.SectionHead}>Options</div>
                <Space wrap size={20}>
                    <Checkbox checked={includeFiles} onChange={(e) => setIncludeFiles(e.target.checked)}>
                        Include Files
                    </Checkbox>
                    <Checkbox checked={includeNotes} onChange={(e) => setIncludeNotes(e.target.checked)}>
                        Include Notes
                    </Checkbox>
                </Space>
            </div>
            {searchText.length < 3 ? (
                <ResultsPlaceHolder fetching={fetching} />
            ) : error ? (
                <ResultsError error={error} fetching={fetching} />
            ) : (
                <div style={{ width: "100%", minHeight: 300, padding: 15 }}>
                    {fetching && !!Object.keys(results).length && <BarLoader height={7} width="100%" />}
                    {Object.keys(results).length ? (
                        <ResultsView results={results} text={searchText} />
                    ) : (
                        <NoResultsView search={searchText} fetching={fetching} />
                    )}
                </div>
            )}
        </div>
    );
};

const GlobalSearchInput = () => {
    const activeOrg = useSelector<RootState, Organization>((state) => state.orgs.activeOrg!);
    const [searchText, setSearchText] = useState("");
    const [isFocused, setFocused] = useState(false);
    const [resultsVisible, showResultsView] = useState(false);
    const searchContainerRef = useRef<HTMLDivElement>(null);

    const toggleSearchFocus = (state: boolean) => () => {
        setFocused(state);
        showResultsView(state);
    };

    return (
        <div className={Styles.Container}>
            <div ref={searchContainerRef} className={classNames(Styles.SearchContainer, { [Styles.Active]: isFocused })}>
                <Input
                    className={classNames(Styles.Input, { [Styles.Active]: isFocused })}
                    onFocus={toggleSearchFocus(true)}
                    onChange={(e) => setSearchText(e.target.value)}
                    prefix={<SearchIcon />}
                    value={searchText}
                    placeholder={`Search ${activeOrg.data.attrs.name}...`}
                />
                <Popover
                    overlayClassName={Styles.ResultsViewPopover}
                    open={resultsVisible}
                    placement="bottomRight"
                    content={<ResultsContent searchText={searchText} />}
                    getTooltipContainer={() => searchContainerRef.current!}
                    getPopupContainer={() => searchContainerRef.current!}
                />
            </div>
            <div className={classNames(Styles.ResultsOverlay, { [Styles.Active]: isFocused })} onClick={toggleSearchFocus(false)} />
        </div>
    );
};

export default GlobalSearchInput;
