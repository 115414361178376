import moment from "moment";
import { QueryResultCallback } from "../../models/Callback/Callback";
import { TContactAuditAttr } from "../../models/Contact/Types";
import { APIManager } from "../APIManager";

export class ContactAuditManager {
    async apiFetchAudits(
        contactId: string,
        cb: QueryResultCallback<{ data: TContactAuditAttr[]; hasNext: boolean; totalPages: number }>,
        startDate?: moment.Moment,
        endDate?: moment.Moment,
        page?: number,
    ) {
        try {
            const resp = await APIManager.get(APIManager.CONTACT.AUDIT.ITEM_API(contactId, startDate?.toISOString(), endDate?.toISOString(), page));
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiFetchNoteAudits(
        contactId: string,
        cb: QueryResultCallback<{ data: TContactAuditAttr[]; hasNext: boolean; totalPages: number }>,
        noteId: string,
        startDate?: moment.Moment,
        endDate?: moment.Moment,
        page?: number,
    ) {
        try {
            const resp = await APIManager.get(
                APIManager.CONTACT.AUDIT.NOTE_ITEM_API(contactId, noteId, startDate?.toISOString(), endDate?.toISOString(), page),
            );
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }
}
