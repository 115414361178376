import moment from "moment";
import { Watchable } from "../../_Utils/Watchable";
import { ContactFile, ContactFileAttrs } from "../ContactFile";
import { ContactNote, ContactNoteAttrs } from "../ContactNote";
import { ContactService, ContactServiceAttrs } from "../ContactService";
import { TContactAttrs, TContactAuditAttr, TTrustAttrs } from "../Types";
import { CustomField, CustomFieldsData } from "../CustomFields";

export type TrustEventMap = {
    FILES_UPDATED: ContactFile[];
    SERVICES_UPDATED: ContactService[];
    NOTES_UPDATED: ContactNote[];
    ATTRIBUTES_UPDATED: TTrustAttrs;
    NEW_AUDIT_ITEM: TContactAuditAttr;
    PARTNER_REMOVED: boolean;
    CONTACT_DELETED: boolean;
};

class ContactTrustData extends Watchable<TrustEventMap> {
    constructor(attrs: TTrustAttrs) {
        super();
        this.cAttrs = attrs;
        this.attrs = attrs;
    }

    private cFiles: ContactFile[] = [];
    private cNotes: ContactNote[] = [];
    private cServices: ContactService[] = [];
    private cAttrs: TTrustAttrs;

    triggerDeleted() {
        this.trigger("CONTACT_DELETED", true);
    }

    triggerEvent<T extends keyof TrustEventMap>(event: T, data: TrustEventMap[T]) {
        this.trigger(event, data);
    }

    set files(f: ContactFile[]) {
        this.cFiles = f;
        this.trigger("FILES_UPDATED", f);
    }

    get files() {
        return this.cFiles;
    }

    set notes(n: ContactNote[]) {
        this.cNotes = n;
        this.trigger("NOTES_UPDATED", n);
    }

    get notes() {
        return this.cNotes;
    }

    set services(s: ContactService[]) {
        this.cServices = s;
        this.trigger("SERVICES_UPDATED", s);
    }

    get services() {
        return this.cServices;
    }

    set attrs(atr: TTrustAttrs) {
        if (typeof atr !== "string") {
            this.cAttrs = atr;
            this.cAttrs.dateOfDeed = this.cAttrs.dateOfDeed ? moment(this.cAttrs.dateOfDeed) : undefined;
            this.cAttrs.createdAt = moment(this.cAttrs.createdAt);
            this.cAttrs.updatedAt = moment(this.cAttrs.updatedAt);

            this.trigger("ATTRIBUTES_UPDATED", atr);
        }
    }

    get attrs() {
        return this.cAttrs;
    }
}

export class Trust {
    public data: ContactTrustData;
    public readonly id: string;
    public customFields: CustomField | null = null;

    constructor(attributes: TTrustAttrs) {
        this.data = new ContactTrustData(attributes);
        this.id = attributes._id;
    }

    updateFiles(files: ContactFileAttrs[]) {
        this.data.files = ContactFile.fromArray(files);
        return this;
    }

    updateNotes(notes: ContactNoteAttrs[]) {
        this.data.notes = ContactNote.fromArray(notes);
        return this;
    }

    updateServices(services: ContactServiceAttrs[]) {
        this.data.services = ContactService.fromArray(services);
        return this;
    }

    addOrUpdateNote(noteAttrs: ContactNoteAttrs) {
        const noteId = noteAttrs._id;
        if (this.data.notes.find((t) => t.id === noteId)) {
            this.data.notes = this.data.notes.map((t) => {
                if (t.id === noteId) return t.updateNote(noteAttrs);
                return t;
            });
        } else {
            const note = new ContactNote(noteAttrs);
            this.data.notes = [note, ...this.data.notes];
        }
        return this;
    }

    addOrUpdateFile(fileAttrs: ContactFileAttrs) {
        const fileId = fileAttrs._id;
        if (this.data.files.find((t) => t.id === fileId)) {
            this.data.files = this.data.files.map((t) => {
                if (t.id === fileId) return t.updateFile(fileAttrs);
                return t;
            });
        } else {
            const file = new ContactFile(fileAttrs);
            this.data.files = [file, ...this.data.files];
        }
        return this;
    }

    addOrUpdateService(srvAttrs: ContactServiceAttrs) {
        const serviceId = srvAttrs._id;
        if (this.data.services.find((t) => t.id === serviceId)) {
            this.data.services = this.data.services.map((t) => {
                if (t.id === serviceId) return t.updateService(srvAttrs);
                return t;
            });
        } else {
            this.data.services = [new ContactService(srvAttrs), ...this.data.services];
        }
        return this;
    }

    removeNoteWithId(noteId: string) {
        this.data.notes = this.data.notes.filter((t) => t.attrs._id !== noteId);
        return this;
    }

    removeFileWithId(fileId: string) {
        this.data.files = this.data.files.filter((t) => t.attrs._id !== fileId);
        return this;
    }

    removeServiceWithId(serviceId: string) {
        this.data.services = this.data.services.filter((t) => t.attrs._id !== serviceId);
        return this;
    }

    updateTrust(contact: Trust) {
        this.updateTrustAttrs(contact.data.attrs);
        this.data.notes = contact.data.notes;
        this.data.services = contact.data.services;
        this.data.files = contact.data.files;
        return this;
    }

    updateTrustAttrs(attrs: TTrustAttrs) {
        this.data.attrs = attrs;
        return this;
    }

    updateCustomFieldAttrs(atrs: CustomFieldsData) {
        this.customFields = new CustomField({ ...atrs });
        return this;
    }

    remove() {
        this.data.triggerDeleted();
    }

    newAuditItem(item: TContactAuditAttr) {
        this.data.triggerEvent("NEW_AUDIT_ITEM", item);
    }

    static fromArray(trusts: TTrustAttrs[]) {
        return trusts.map((t) => new Trust(t));
    }

    get attrs() {
        return this.data.attrs;
    }

    get displayName() {
        return this.data.attrs.name;
    }
}
