import React, { useEffect, useState } from "react";
import { Modal, Button } from "antd";
import { ServiceFeeProvider, TServiceFeeProviderAttrs } from "../../models/ServicesFees/Provider";
import { Provider } from "react-redux";
import { getAppStore } from "../../redux/store";
import AppContent from "../AppContent/AppContent";
import CloseIcon from "@ant-design/icons/CloseOutlined";
import { AppManager } from "../../manager";
import Loading from "../../components/Loading/Loading";
import BasicErrorView from "../../components/ErrorView/BasicErrorView/BasicErrorView";
import Styles from "./ObjectViewModal.scss";
import { downloadBlobToFile } from "../../helpers/download";
import DownloadIcon from "@ant-design/icons/DownloadOutlined";
import FileIcon from "@ant-design/icons/FileFilled";
import prettyBytes from "pretty-bytes";
import ViewIcon from "@ant-design/icons/EyeFilled";

const TextObjectView: React.FC<{ data: Blob }> = ({ data }) => {
    const [dataStr, setData] = useState<string | null>(null);
    const loadData = async () => {
        setData(await data.text());
    };
    useEffect(() => {
        loadData();
    }, []);
    return <div className={Styles.TextContentWrapper}>{dataStr === null ? "loading..." : dataStr}</div>;
};

type TAttachment = { blobUrl: string; blobName: string; fileName: string; size: number; type?: string };
const AusplanEmailObjectView: React.FC<{ data: Blob }> = ({ data }) => {
    const [attachments, setAttachments] = useState<TAttachment[]>([]);
    const [downloadingAttachment, setDownloadingAttachment] = useState<string>();
    const [emailText, setEmailText] = useState<string>("");
    const [subjectText, setSubjectText] = useState<string>("");
    const loadData = async () => {
        const emailData = JSON.parse(await data.text());

        setAttachments(emailData.attachments);
        setEmailText(emailData.html);
        setSubjectText(emailData.subject);
    };
    const handleViewAttachment = (att: TAttachment) => {
        openBlobItemViewModal(att.blobName, att.type || "", `Email Attachment - (${att.fileName})`);
    };
    const handleDownloadAttachment = (att: TAttachment) => {
        setDownloadingAttachment(att.blobName);
        AppManager.blobStorage.downloadBlobFromStorage(att.blobName, (err, data) => {
            setDownloadingAttachment(undefined);
            if (err) AppManager.alert.error("Failed to fetch attachment", `${err}`);
            else {
                const reader = new FileReader();
                const blob = new Blob([data!], { type: att.type });
                reader.onloadend = () => {
                    downloadBlobToFile(blob, `Attachment-${att.fileName}`);
                };
                reader.onerror = (err) => AppManager.alert.error("Unexpected error", `${err}`);
                reader.readAsDataURL(blob);
            }
        });
    };
    useEffect(() => {
        loadData();
    }, []);
    return (
        <div className={Styles.EmailContentWrapper}>
            {subjectText && (
                <div className={Styles.Subject}>
                    <i>subject:</i>
                    {subjectText}
                </div>
            )}

            <div className={Styles.EmailContent} dangerouslySetInnerHTML={{ __html: emailText }} />
            {!!attachments.length && (
                <div className={Styles.AttachmentsContainer}>
                    <div className={Styles.Title}>Attachments ({attachments.length})</div>
                    {attachments.map((item, ind) => (
                        <div key={ind} className={Styles.AttachmentItem}>
                            <span>
                                <FileIcon style={{ marginRight: 3 }} />
                                {item.fileName}
                                <span className={Styles.SizeView}>{prettyBytes(item.size)}</span>
                            </span>
                            <span>
                                <Button icon={<ViewIcon />} size="small" onClick={() => handleViewAttachment(item)} style={{ marginRight: 10 }}>
                                    View
                                </Button>
                                <Button
                                    icon={<DownloadIcon />}
                                    size="small"
                                    type="primary"
                                    onClick={() => handleDownloadAttachment(item)}
                                    disabled={!!downloadingAttachment}
                                    loading={downloadingAttachment == item.blobName}
                                />
                            </span>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

const ObjectViewContent: React.FC<{ onClose: () => void; source: string; title: string; raw: Blob }> = ({ onClose, source, title, raw }) => {
    const downloadToFile = () => {
        downloadBlobToFile(raw, title);
    };
    return (
        <AppContent
            header={title}
            showNetworkState={false}
            headerActions={
                <div>
                    {!["ausplan/x-saved-email"].includes(raw.type) && (
                        <Button type="primary" style={{ marginRight: 15 }} onClick={downloadToFile} icon={<DownloadIcon />} size="small">
                            Download
                        </Button>
                    )}
                    <Button danger type="link" style={{ marginRight: 15 }} onClick={onClose} icon={<CloseIcon />} size="small">
                        Close
                    </Button>
                </div>
            }
        >
            <div className={Styles.ObjectViewContainer}>
                <div className={Styles.InnerWrapper}>
                    {raw.type.startsWith("text/") ? (
                        <TextObjectView data={raw} />
                    ) : raw.type === "ausplan/x-saved-email" ? (
                        <AusplanEmailObjectView data={raw} />
                    ) : (
                        <object data={source} className={Styles.ObjectContentWrapper}>
                            <div className="match-parent" style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: 20 }}>
                                <div>
                                    This content can&apos;t be displayed by your browser! You can
                                    <Button size="small" type="link" onClick={downloadToFile}>
                                        download
                                    </Button>
                                    and view it directly on your computer
                                </div>
                            </div>
                        </object>
                    )}
                </div>
            </div>
        </AppContent>
    );
};

const DownloadObjectView: React.FC<{ onClose: () => void; title: string; blobName: string; blobType: string }> = ({
    onClose,
    title,
    blobName,
    blobType,
}) => {
    const [loading, setLoading] = useState(true);
    const [dataB64, setDataB64] = useState<string>();
    const [rawData, setRawData] = useState<Blob>();
    const [loadError, setLoadError] = useState<string>();
    useEffect(() => {
        AppManager.blobStorage.downloadBlobFromStorage(blobName, (err, data) => {
            setLoading(false);
            if (err) setLoadError(`${err}`);
            else {
                const reader = new FileReader();
                const blob = new Blob([data!], { type: blobType });
                reader.onloadend = () => {
                    setDataB64(reader.result as string);
                    setRawData(blob);
                };
                reader.onerror = (err) => setLoadError(`${err}`);
                reader.readAsDataURL(blob);
            }
        });
    }, []);
    return !dataB64 || !rawData ? (
        <AppContent header={title} subHeader="Loading..." showNetworkState={false}>
            <div className="match-parent" style={{ position: "relative" }}>
                <Button style={{ position: "absolute", top: -50, right: 0, fontSize: 15 }} type="link" danger icon={<CloseIcon />} onClick={onClose}>
                    Close
                </Button>
                {loadError ? <BasicErrorView error={loadError} /> : <Loading text="Loading data..." />}
            </div>
        </AppContent>
    ) : (
        <ObjectViewContent onClose={onClose} source={dataB64} title={title} raw={rawData} />
    );
};

export const openObjectViewModal = (blobData: Blob, title: string) => {
    const reader = new FileReader();
    reader.readAsDataURL(blobData);
    reader.onloadend = () => {
        const base64data = reader.result;
        const modal = Modal.info({
            content: (
                <Provider store={getAppStore()}>
                    <div style={{ height: window.innerHeight - 130 }}>
                        <ObjectViewContent
                            source={base64data as string}
                            title={title}
                            raw={blobData}
                            onClose={() => {
                                URL.revokeObjectURL(base64data as string);
                                modal.destroy();
                            }}
                        />
                    </div>
                </Provider>
            ),
            icon: null,
            okButtonProps: { style: { display: "none" } },
            width: window.innerWidth - 50,
            centered: true,
        });

        return modal;
    };
};

export const openBlobItemViewModal = (blobName: string, blobType: string, title: string) => {
    const modal = Modal.info({
        content: (
            <Provider store={getAppStore()}>
                <div style={{ height: window.innerHeight - 130 }}>
                    <DownloadObjectView blobName={blobName} blobType={blobType} title={title} onClose={() => modal.destroy()} />
                </div>
            </Provider>
        ),
        icon: null,
        okButtonProps: { style: { display: "none" } },
        width: window.innerWidth - 50,
        centered: true,
    });
};
