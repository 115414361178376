import { QueryResultCallback } from "../../models/Callback/Callback";
import { Organization } from "../../models/Organization/Organization";
import {
    OrgRoleScopes,
    TConnectedEmailInfo,
    TContactServiceAttr,
    TDealerGroupRequestAttr,
    TFullOrganizationAttr,
    TOrganizationAttr,
    TOrgInvitation,
    TOrgMemberAttr,
} from "../../models/Organization/Types";
import { ISCheduleCategoryAttrs } from "../../models/Schedule/Schedule";
import { TUserAttr } from "../../models/User/User";
import { OrgStoreActions } from "../../redux/services/organizations/actions";
import { APIManager } from "../APIManager";
import { OrgNetworkHelper } from "./NetworkHelper";
import { formatUrl } from "../../helpers/misc";
import { OrgEmailSystemManager } from "./OrgEmailSystemManager";

type TInviteUser = {
    email: string;
    names: string;
};
export class OrgManager {
    networkHelper = OrgNetworkHelper;

    emailSystem = new OrgEmailSystemManager();

    static newOrgObj(data: TOrganizationAttr) {
        return new Organization(data);
    }
    getUserOrg(orgId: string) {
        return OrgStoreActions.getOrganizations().find((t) => t.id === orgId);
    }
    static getActiveOrg() {
        return OrgStoreActions.getActiveOrg();
    }
    async apiFetchOrgMembers(org: Organization, cb: QueryResultCallback<TOrgMemberAttr[]>) {
        try {
            const resp = await APIManager.get(APIManager.ORG.MEMBERS_API(org.id));
            console.log("MEMBERS 1 => ", resp.data);
            org.updateMembers(resp.data);
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }
    async apiUpdateOrgBasicInfo(
        orgId: string,
        orgInfo: { name: string; address: string; abn: string; phone: string; isAuditEnabled: boolean },
        cb: QueryResultCallback<TOrganizationAttr>,
    ) {
        try {
            const resp = await APIManager.put(APIManager.ORG.BASIC_INFO_API(orgId), orgInfo);
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }
    async apiFetchAllOrgInfo(orgId: string, cb: QueryResultCallback<TFullOrganizationAttr>) {
        try {
            const allOrgAttrs = await APIManager.get(APIManager.ORG.ALL_INFO_API(orgId));
            cb(null, allOrgAttrs.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async activateOrganization(org: Organization, cb: QueryResultCallback) {
        try {
            const allOrgAttrs = await APIManager.get(APIManager.ORG.ALL_INFO_API(org.id));
            this.hydrateFullOrganization(allOrgAttrs.data, true);
            cb(null, allOrgAttrs);
        } catch (err) {
            cb(`${err}`);
        }
    }
    saveUserOrganizations(orgs: TOrganizationAttr[]) {
        OrgStoreActions.saveOrganizations(orgs.map((t) => this.updateOrgAttrOrAddNew(t, false)));
    }
    async refreshActiveOrganization(cb: QueryResultCallback = () => {}) {
        const activeOrg = OrgStoreActions.getActiveOrg();
        if (activeOrg) {
            await this.activateOrganization(activeOrg, cb);
            await this.apiFetchOrgInvitations(activeOrg.id, () => {});
            await this.apiFetchDealerGroupRequests(activeOrg.id, () => {});
        }
    }
    getCurrentActiveOrg() {
        return OrgStoreActions.getActiveOrg();
    }
    getPersistedActiveOrg() {
        const savedOrgId = OrgStoreActions.getSavedOrgId();
        if (savedOrgId) {
            return OrgStoreActions.getOrganizations().find((t) => t.id === savedOrgId);
        }
        return null;
    }
    getUserOrgs() {
        return OrgStoreActions.getOrganizations();
    }
    async addToOrgContactServices(org: Organization, service: { name: string }, cb: QueryResultCallback<TContactServiceAttr>) {
        try {
            const resp = await APIManager.post(APIManager.ORG.CONTACT_SERVICES_API, service);
            org.addContactService(resp.data);
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiFetchOrgInvitations(orgId: string, cb: QueryResultCallback<TOrgInvitation[]>) {
        try {
            const resp = await APIManager.get(APIManager.ORG.INVITATIONS_API(orgId));
            const allOrgs = OrgStoreActions.getOrganizations();
            const org = allOrgs.find((t) => t.id === orgId);
            if (org) org.updateInvitations(resp.data);
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiDeleteOrgInvitation(orgId: string, invitationId: string, cb: QueryResultCallback<TOrgInvitation[]>) {
        try {
            await APIManager.delete(APIManager.ORG.INVITATION_ITEM_API(orgId, invitationId));
            await this.apiFetchOrgInvitations(orgId, cb);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiCreateRole(
        orgId: string,
        roleData: { name: string; scopes: string[]; setAsDefault?: boolean },
        cb: QueryResultCallback<TFullOrganizationAttr>,
    ) {
        try {
            const result = await APIManager.post(APIManager.ORG.ROLES_API(orgId), roleData);
            cb(null, result.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiUpdateOrgRole(
        orgId: string,
        roleId: string,
        roleData: { name: string; scopes: string[]; setAsDefault?: boolean },
        cb: QueryResultCallback<TFullOrganizationAttr>,
    ) {
        try {
            const result = await APIManager.put(APIManager.ORG.ROLE_ITEM_API(orgId, roleId), roleData);
            cb(null, result.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiDeleteRole(orgId: string, roleId: string, cb: QueryResultCallback<TFullOrganizationAttr>) {
        try {
            const result = await APIManager.delete(APIManager.ORG.ROLE_ITEM_API(orgId, roleId));
            cb(null, result.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiSetRoleDefault(orgId: string, roleId: string, cb: QueryResultCallback<TFullOrganizationAttr>) {
        try {
            const result = await APIManager.patch(APIManager.ORG.SET_DEFAULT_ROLE_API(orgId, roleId));
            cb(null, result.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiSendOrgInvitations(
        orgId: string,
        invites: TInviteUser[],
        cb: QueryResultCallback<{
            alreadyMembers: TInviteUser[];
            alreadySent: TInviteUser[];
            sentInvitations: TInviteUser[];
            failedToSend: TInviteUser[];
        }>,
    ) {
        try {
            const resp = await APIManager.post(APIManager.ORG.INVITATIONS_API(orgId), invites);
            await this.apiFetchOrgInvitations(orgId, () => {});
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiUpdateMemberRole(orgId: string, memberId: string, roleId: string, cb: QueryResultCallback<TFullOrganizationAttr>) {
        try {
            const result = await APIManager.put(APIManager.ORG.UPDATE_MEMBER_ROLE_API(orgId), { memberId, roleId });
            cb(null, result.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiDeleteMember(orgId: string, memberId: string, cb: QueryResultCallback<TFullOrganizationAttr>) {
        try {
            const result = await APIManager.delete(APIManager.ORG.MEMBER_ITEM_API(orgId, memberId));
            cb(null, result.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiAddServiceLevel(orgId: string, name: string, cb: QueryResultCallback<TFullOrganizationAttr>) {
        try {
            const result = await APIManager.put(APIManager.ORG.SERVICE_LEVELS_API(orgId), { name });
            cb(null, result.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiSetSchedulesCategory(orgId: string, info: Partial<ISCheduleCategoryAttrs>, cb: QueryResultCallback<TFullOrganizationAttr>) {
        try {
            const result = await APIManager.put(APIManager.ORG.SCHEDULES_CATEGORIES_API(orgId), info);
            cb(null, result.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiRemoveSchedulesCategory(orgId: string, categoryId: string, cb: QueryResultCallback<TFullOrganizationAttr>) {
        try {
            const result = await APIManager.delete(APIManager.ORG.SCHEDULES_CATEGORY_ITEM_API(orgId, categoryId));
            cb(null, result.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiSendDealerGroupRequest(orgId: string, dGroupInfo: { email: string; name: string }, cb: QueryResultCallback<TFullOrganizationAttr>) {
        try {
            const result = await APIManager.post(APIManager.ORG.DEALER_GROUP_REQUESTS_API(orgId), dGroupInfo);
            await this.apiFetchDealerGroupRequests(orgId, () => {});
            cb(null, result.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiFetchDealerGroupRequests(orgId: string, cb: QueryResultCallback<TDealerGroupRequestAttr[]>) {
        try {
            const result = await APIManager.get(APIManager.ORG.DEALER_GROUP_REQUESTS_API(orgId));
            cb(null, result.data);
            const organization = this.getUserOrg(orgId);
            organization?.updateDealerGroupRequests(result.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiDeleteDealerGroupRequest(orgId: string, requestId: string, cb: QueryResultCallback<TFullOrganizationAttr>) {
        try {
            await APIManager.delete(APIManager.ORG.DEALER_GROUP_REQUEST_ITEM_API(orgId, requestId));
            await this.apiFetchDealerGroupRequests(orgId, (err, res) => {
                if (res) {
                    const organization = this.getUserOrg(orgId);
                    organization?.updateDealerGroupRequests(res);
                }
            });
            cb(null);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiRemoveDealerGroupFromOrg(orgId: string, dealerGroupId: string, cb: QueryResultCallback<TFullOrganizationAttr>) {
        try {
            await APIManager.delete(APIManager.ORG.DEALER_GROUP_ITEM_API(orgId, dealerGroupId));
            cb(null);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiDeleteServiceLevel(orgId: string, serviceLevelId: string, cb: QueryResultCallback<TFullOrganizationAttr>) {
        try {
            const result = await APIManager.delete(APIManager.ORG.SERVICE_LEVEL_ITEM_API(orgId, serviceLevelId));
            cb(null, result.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiReorderServiceLevels(orgId: string, oldIndex: number, newIndex: number, cb: QueryResultCallback<TFullOrganizationAttr>) {
        try {
            const result = await APIManager.patch(APIManager.ORG.SERVICE_LEVEL_REORDER_API(orgId), { oldIndex, newIndex });
            cb(null, result.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiAddCompletedInitialSetupItem(orgId: string, item: string, cb: QueryResultCallback<TFullOrganizationAttr>) {
        try {
            const result = await APIManager.put(APIManager.ORG.ORG_INITIAL_SETUP_ITEM_API(orgId), { item });
            cb(null, result.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiUpdateInitialSetupStatus(orgId: string, status: string, cb: QueryResultCallback<TFullOrganizationAttr>) {
        try {
            const result = await APIManager.put(APIManager.ORG.ORG_INITIAL_SETUP_STATUS_API(orgId), { status });
            cb(null, result.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    hydrateFullOrganization(data: TFullOrganizationAttr, activate = false) {
        const allOrgs = OrgStoreActions.getOrganizations();

        let org = allOrgs.find((t) => t.id === data._id);
        if (org) {
            org.updateAttributes(data);
        } else {
            org = OrgManager.newOrgObj(data);
            OrgStoreActions.saveOrganizations([...allOrgs, org]);
        }
        const { contactServiceLevels, scheduleCategories, contactServices, members, orgRoles, authorizedDealerGroups, ...attrs } = data;
        org.updateContactServiceLevels(contactServiceLevels);
        org.updateScheduleCategories(scheduleCategories);
        org.updateContactServices(contactServices);
        org.updateMembers(members);
        org.updateRoles(orgRoles);
        org.updateAuthorizedDealerGroups(authorizedDealerGroups);
        org.updateAttributes(attrs);

        if (activate) {
            OrgStoreActions.setActiveOrg(org);
        }

        return org;
    }

    updateActiveOrgMemberUserAttr = (userAttr: TUserAttr) => {
        const activeOrg = OrgStoreActions.getActiveOrg();
        if (activeOrg) {
            activeOrg.updateMemberUserAttr(userAttr);
        }
    };

    updateOrganizationAttr = (orgAttr: TOrganizationAttr) => {
        const organizations = OrgStoreActions.getOrganizations();
        for (const org of organizations) {
            if (orgAttr._id === org.id) {
                org.updateAttributes(orgAttr);
            }
        }

        OrgStoreActions.saveOrganizations(organizations);
    };

    updateOrgAttrOrAddNew(orgAttr: TOrganizationAttr, saveToStore = true) {
        let org = this.getUserOrg(orgAttr._id);
        if (org) {
            org.updateAttributes(orgAttr);
        } else {
            org = OrgManager.newOrgObj(orgAttr);
        }
        if (saveToStore) OrgStoreActions.addOrUpdateToOrganizations(org);
        return org;
    }
}
