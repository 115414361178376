import ActionTypes from "./actionTypes";
import { getAppStore } from "../../store";
import { IScheduleAttrs } from "../../../models/Schedule/Schedule";

export class DiaryStoreActions {
    static loadingSchedules(loading: boolean) {
        getAppStore().dispatch({ type: ActionTypes.LOADING_SCHEDULES, payload: loading });
    }
    static setSchedulesLoaded(state: boolean) {
        getAppStore().dispatch({ type: ActionTypes.LOADED_SCHEDULES, payload: state });
    }
    static saveSchedules(schedules: IScheduleAttrs[]) {
        getAppStore().dispatch({ type: ActionTypes.SAVE_SCHEDULES, payload: schedules });
    }
    static addNewSchedule(schedule: IScheduleAttrs) {
        getAppStore().dispatch({ type: ActionTypes.ADD_NEW_SCHEDULE, payload: schedule });
    }
    static removeSchedule(scheduleId: string) {
        getAppStore().dispatch({ type: ActionTypes.DELETE_SCHEDULE, payload: scheduleId });
    }
    static toggleScheduleCategory(categoryId: string, active?: boolean) {
        let categories: string[] = [];
        try {
            categories = JSON.parse(localStorage.getItem("HIDDEN-SCHEDULE-CATG") || "[]");
        } catch (err) {}
        const allCategories = getAppStore()
            .getState()
            .orgs.activeOrg?.data.schedulesCategories.map((t) => t._id);
        if (allCategories?.length) {
            if (typeof active == "boolean") {
                if (active && !categories.includes(categoryId)) categories = [...categories, categoryId];
                else if (!active) categories = categories.filter((t) => t !== categoryId);
            } else {
                categories = categories.includes(categoryId) ? categories.filter((t) => t !== categoryId) : [...categories, categoryId];
                categories = categories.filter((t) => allCategories.includes(t) || t.startsWith("tmp-"));
            }
        }
        localStorage.setItem("HIDDEN-SCHEDULE-CATG", JSON.stringify(categories));
        getAppStore().dispatch({ type: ActionTypes.SET_HIDDEN_CATEGORIES, payload: categories });
    }
    static getSchedules() {
        return getAppStore().getState().diary.schedules;
    }
}
