import ArrowRightIcon from "@ant-design/icons/ArrowRightOutlined";
import { Button, Card, Spin } from "antd";
import React, { useState, useEffect } from "react";
import BasicHeaderLayout from "../../../layouts/BasicHeaderLayout/BasicHeaderLayout";
import Styles from "./WelcomePage.scss";
import welcomeImg from "../../../assets/img/welcome_illustration2.png";
import SetupItemsContainer from "./SetupItems/SetupItemsContainer";
import { AppManager } from "../../../manager";
import MainLayout from "../../../layouts/MainLayout/MainLayout";
import { useModelEventWatcher } from "../../../hooks/modelHook";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
import { Organization } from "../../../models/Organization/Organization";
import { TFullOrganizationAttr } from "../../../models/Organization/Types";

const WelcomePage = () => {
    const activeOrg = useSelector<RootState, Organization>((r) => r.orgs.activeOrg!);
    const [_orgAttr] = useModelEventWatcher(activeOrg, "ATTRIBUTES_UPDATED", activeOrg.data.attrs);
    const orgAttr = _orgAttr as TFullOrganizationAttr;
    const [showSetup, setShowSetup] = useState(orgAttr.initialSetupStatus !== "not_started");
    const [loading, setLoading] = useState(false);
    const userAttr = AppManager.user.getActiveUser()?.data;

    const handleBegin = () => {
        setLoading(true);
        AppManager.org.apiUpdateInitialSetupStatus(activeOrg.id, "in_progress", (err) => {
            setLoading(false);
            if (err) AppManager.alert.error("Error", `${err}`);
        });
    };

    const handleCancelSetup = () => {
        AppManager.alert.confirmAction(
            "Confirm Action",
            "Do you want to cancel this setup?",
            (res) => {
                if (res === "OK") {
                    setLoading(true);
                    AppManager.org.apiUpdateInitialSetupStatus(activeOrg.id, "canceled", (err) => {
                        setLoading(false);
                        if (err) AppManager.alert.error("Error", `${err}`);
                    });
                }
            },
            { okText: "Yes, Cancel Setup", cancelText: "No" },
        );
    };

    useEffect(() => {
        setShowSetup(orgAttr.initialSetupStatus !== "not_started");
    }, [orgAttr]);

    return (
        <MainLayout dummySidebar hideToggle sidebarCollapsed>
            {showSetup ? (
                <SetupItemsContainer />
            ) : (
                <div className={Styles.Container}>
                    {/* <div className={Styles.LeftContainer}>
                        <div className={Styles.InnerWrapper}>
                            <div className={Styles.WelcomeText}>Welcome!</div>
                            <img src={welcomeImg} width="550" />
                        </div>
                    </div> */}
                    <div className={Styles.RightContainer}>
                        <Spin spinning={loading}>
                            <div className={Styles.WelcomeCard}>
                                <div className={Styles.InnerWrapper}>
                                    <div className={Styles.WelcomeText}>Welcome {userAttr?.attrs.firstName}!</div>
                                    <h2 className={Styles.MainHeader}>Let&apos;s quickly setup your new organization</h2>
                                    {/* <h2 className={Styles.Description}>We shall help you </h2> */}
                                    <div className={Styles.ActionsContainer}>
                                        <Button size="large" type="primary" onClick={handleBegin}>
                                            Let&apos;s Begin <ArrowRightIcon />
                                        </Button>
                                        <Button size="large" type="link" style={{ margin: "0 20px" }} danger onClick={handleCancelSetup}>
                                            I&apos;ll do this later
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Spin>
                    </div>
                </div>
            )}
        </MainLayout>
    );
};

export default WelcomePage;
