import { useState, useEffect, useCallback, useMemo } from "react";
import { AppModel } from "../models/_Utils/AppModel";
import { Watchable } from "../models/_Utils/Watchable";

export const useModelWatcher = <T extends Record<string, any>>(
    cModel: AppModel<Watchable<T>> | undefined,
    watchEvents?: { [key in keyof Partial<T>]: (arg: T[key]) => void } | (keyof T)[],
    initialData?: T[keyof T],
): [keyof T | undefined, T[keyof T] | undefined, (model: AppModel<Watchable<T>>) => void] => {
    const [model, updateModel] = useState(cModel);
    const [eventOccurred, setEventOccured] = useState<keyof T>();
    const [eventData, setEventData] = useState<T[keyof T] | undefined>(initialData);
    const [_, forceRerender] = useState<string>("");

    const globalWatcherCallback = useCallback(
        (event: keyof T, data: T[keyof T]) => {
            if ((Array.isArray(watchEvents) && watchEvents.includes(event)) || !watchEvents) {
                forceRerender(`${Math.random()}${Date.now()}${Math.random()}${Math.random()}`);
                setEventOccured(event);
                setEventData(data);
            }
        },
        [model],
    );

    useEffect(() => {
        if (model?.data) {
            if (watchEvents && !Array.isArray(watchEvents)) {
                Object.keys(watchEvents).forEach((event) => {
                    model.data.watch(event, watchEvents[event]);
                });
            }

            model.data.watchGlobal(globalWatcherCallback);

            return () => {
                if (watchEvents && !Array.isArray(watchEvents)) {
                    Object.keys(watchEvents).forEach((event) => {
                        model.data.unwatch(event, watchEvents[event]);
                    });
                }
                model.data.unwatchGlobal(globalWatcherCallback);
            };
        }
    }, [model]);

    return [eventOccurred, eventData, updateModel];
};

export function useModelEventWatcher<T extends Record<string, any>, R extends keyof T>(
    cModel: AppModel<Watchable<T>> | undefined,
    watchEvent: R,
): [T[R] | undefined, (data: T[typeof watchEvent]) => void, typeof cModel, (t: typeof cModel) => void];

export function useModelEventWatcher<T extends Record<string, any>, R>(
    cModel: AppModel<Watchable<T>> | undefined,
    watchEvent: keyof T,
    initialData: R,
): [R, (data: T[typeof watchEvent]) => void, typeof cModel, (t: typeof cModel) => void];

export function useModelEventWatcher<T extends Record<string, any>, R extends keyof T, M extends AppModel<Watchable<T>>>(
    cModel: M | undefined,
    watchEvent: R,
    initialData: T[R],
): [T[R], (data: T[typeof watchEvent]) => void, typeof cModel, (t: typeof cModel) => void];

export function useModelEventWatcher<T extends Record<string, any>, R extends keyof T, M extends AppModel<Watchable<T>> | undefined>(
    cModel: M | undefined,
    watchEvent: R,
): [T[R] | undefined, (data: T[typeof watchEvent]) => void, typeof cModel, (t: typeof cModel) => void];

export function useModelEventWatcher<T extends Record<string, any>, R>(
    cModel: AppModel<Watchable<T>> | undefined,
    watchEvent: keyof T,
    initialData?: R,
): [R | undefined, (data: any) => void, typeof cModel, (t: typeof cModel) => void] {
    const [model, updateModel] = useState(cModel);
    const [eventData, setEventData] = useState<R | undefined>(initialData);

    const globalWatcherCallback = useCallback(
        (event: keyof T, data: T[keyof T]) => {
            if (watchEvent === event) {
                setEventData(data);
            }
        },
        [setEventData],
    );

    useEffect(() => {
        if (model && model.data) {
            model.data.watchGlobal(globalWatcherCallback);
            // return () => model.data.unwatchGlobal(globalWatcherCallback);
        }
    }, [model, globalWatcherCallback]);

    // useEffect(() => {
    //     updateModel(cModel);
    // }, [cModel]);

    return [eventData, setEventData, model, updateModel];
}
