import ActiveIndicator from "@ant-design/icons/CheckCircleFilled";
import CheckIcon from "@ant-design/icons/CheckCircleOutlined";
import LoadingIcon from "@ant-design/icons/LoadingOutlined";
import { Spin, Steps } from "antd";
import Button from "antd-button-color";
import Avatar from "antd/lib/avatar/avatar";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { AppManager } from "../../../../manager";
import { ICustomerInfo } from "../../../../models/Apps/Migration/XPlanTypes";
import { Organization } from "../../../../models/Organization/Organization";
import AnimatedStatusHeader from "./AnimatedStatusHeader";
import Styles from "./Home.scss";

const InitializationInfoView: React.FC<{ customer: ICustomerInfo }> = ({ customer }) => {
    const curr = customer.currentGroup;
    const currentStep = !customer.scrapedIndividualIdsGroups.includes(curr)
        ? 0
        : !customer.scrapedCompanyIdsGroups.includes(curr)
        ? 1
        : !customer.scrapedTrustIdsGroups.includes(curr)
        ? 2
        : 3;
    return (
        <div className={Styles.InitializationInfoView}>
            <AnimatedStatusHeader tip="Your data will only become accessible from the dashboard after the entire migration process is complete" />
            <div className={Styles.Steps}>
                <Steps current={currentStep}>
                    <Steps.Step
                        title="Initialize Individuals"
                        description={currentStep == 0 ? "Preparing individuals for migration" : "Done"}
                        icon={currentStep == 0 ? <LoadingIcon /> : undefined}
                    />
                    <Steps.Step
                        title="Initialize Companies"
                        description={currentStep == 1 ? "Preparing companies for migration" : currentStep > 1 ? "Done" : ""}
                        icon={currentStep == 1 ? <LoadingIcon /> : undefined}
                    />
                    <Steps.Step
                        title="Initialize Trusts"
                        description={currentStep == 2 ? "Preparing trusts for migration" : currentStep > 2 ? "Done" : ""}
                        icon={currentStep == 2 ? <LoadingIcon /> : undefined}
                    />
                </Steps>
            </div>
        </div>
    );
};

const InitializationStep: React.FC<{
    // onDone: (customer: ICustomerInfo) => void;
    customer?: ICustomerInfo;
    ownedOrgs: Organization[];
    credentials: {
        iressURI: string;
        username: string;
        password: string;
    };
}> = ({ /*onDone,*/ credentials, customer, ownedOrgs }) => {
    // const activeOrg = AppManager.org.getCurrentActiveOrg();
    // const [initializing, setInitializing] = useState(false);
    const [customerInfo, setCustomerInfo] = useState(customer);
    // const [selectedOrgId, setSelectedOrgId] = useState(activeOrg?.id);
    // const [error, setError] = useState("");
    // const handleInitialize = () => {
    //     setInitializing(true);
    //     const data = {
    //         ...credentials,
    //         iressURI: credentials.iressURI || "https://plannercentral.xplan.iress.com.au",
    //         ausplanUserId: selectedOrgId!,
    //     };
    //     AppManager.ausplanApps.xplanMigrator.apiStartMigration(data, (err, resp) => {
    //         setInitializing(false);
    //         if (err) {
    //             AppManager.alert.error("Failed to start migration", `${err}`);
    //             setError(`${err}`);
    //         } else {
    //             AppManager.alert.msgSuccess("Migration has started!");
    //             onDone(resp!);
    //         }
    //     });
    // };
    useEffect(() => {
        setCustomerInfo(customer);
    }, [customer]);
    return (
        <div className={Styles.InitializationContainer}>
            {customerInfo && customerInfo.status !== "not-started" ? (
                <InitializationInfoView customer={customerInfo} />
            ) : (
                <Spin spinning={false} tip="">
                    <div className={Styles.MigrationTargetContainer}>
                        <div className={Styles.Title}>Not Started</div>
                        <div className={Styles.Description}>Waiting for this migration to start</div>
                    </div>
                </Spin>
            )}
        </div>
    );
};

export default InitializationStep;
