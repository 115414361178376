import { Card, Steps } from "antd";
import React, { useState, useEffect } from "react";
import classNames from "classnames";
import Styles from "./SetupItemsContainer.scss";
import dealerGroupImg from "../../../../assets/img/dealer_group_illustration.png";
import inviteUserImg from "../../../../assets/img/invite_users_illustration.png";
import connectEmailImg from "../../../../assets/img/invite_users_illustration.png";
import migrationImg from "../../../../assets/img/migration_illustration.png";
import DealerGroupSetup from "./DealerGroups/DealerGroupSetup";
import UserInviteSetup from "./UserInvites/UserInvitesSetup";
import ConnectEmailSetup from "./ConnectEmail/ConnectEmailSetup";
import MigrationSetup from "./Migration/MigrationSetup";
import { Organization } from "../../../../models/Organization/Organization";
import { RootState } from "../../../../redux/reducers";
import { useSelector } from "react-redux";
import { useModelEventWatcher } from "../../../../hooks/modelHook";
import { TFullOrganizationAttr } from "../../../../models/Organization/Types";
import { AppManager } from "../../../../manager";
import Loading from "../../../../components/Loading/Loading";

const SetupItemsContainer = () => {
    const [finalizing, setFinalizing] = useState(false);
    const activeOrg = useSelector<RootState, Organization>((r) => r.orgs.activeOrg!);
    const [_orgAttr] = useModelEventWatcher(activeOrg, "ATTRIBUTES_UPDATED", activeOrg.data.attrs);
    const orgAttr = _orgAttr as TFullOrganizationAttr;
    const [currentStep, setCurrentStep] = useState(
        !orgAttr.completedInitialSetupItems?.includes("dealer_group")
            ? 0
            : !orgAttr.completedInitialSetupItems?.includes("invite_users")
            ? 1
            : !orgAttr.completedInitialSetupItems?.includes("connect_email")
            ? 2
            : !orgAttr.completedInitialSetupItems?.includes("migration_setup")
            ? 3
            : 4,
    );

    useEffect(() => {
        if (currentStep > 3) {
            setFinalizing(true);
            AppManager.org.apiUpdateInitialSetupStatus(activeOrg.id, "done", (err) => {
                setFinalizing(false);
                if (err) AppManager.alert.error("Error", `${err}`);
                else AppManager.alert.toastSuccess("Setup completed! You are now ready to start using your new account.");
            });
        }
    }, [currentStep]);

    return (
        <div className={Styles.Container}>
            <div className={Styles.StepsView}>
                <Steps size="small" current={currentStep}>
                    <Steps.Step title="Dealer Group" />
                    <Steps.Step title="Invite Users" />
                    <Steps.Step title="Connect Email/Calendar" />
                    <Steps.Step title="Migrate" />
                </Steps>
            </div>
            <div className={Styles.Main}>
                <div className={Styles.LeftContainer}>
                    <div className={Styles.Heading}>
                        {currentStep === 0 && "Dealer Group Setup"}
                        {currentStep === 1 && "Members Setup"}
                        {currentStep === 2 && "Email/Calendar Setup"}
                        {currentStep === 3 && "Migration Setup"}
                    </div>
                    <div className={classNames(Styles.Illustration, "animate__animated animate__fadeIn")} key={`${currentStep}`}>
                        {currentStep === 0 && <img src={dealerGroupImg} width="450" />}
                        {currentStep === 1 && <img src={inviteUserImg} width="450" />}
                        {currentStep === 2 && <img src={connectEmailImg} width="450" />}
                        {currentStep === 3 && <img src={migrationImg} width="450" />}
                    </div>
                </div>
                <div className={Styles.RightContainer}>
                    {currentStep === 0 && <DealerGroupSetup onNext={() => setCurrentStep(1)} />}
                    {currentStep === 1 && <UserInviteSetup onNext={() => setCurrentStep(2)} />}
                    {currentStep === 2 && <ConnectEmailSetup onNext={() => setCurrentStep(3)} />}
                    {currentStep === 3 && <MigrationSetup onNext={() => setCurrentStep(4)} />}
                    {currentStep > 3 && (
                        <div className="match-parent">
                            <Card className="match-parent">
                                {finalizing ? <Loading text="Finalizing..A moment please..." /> : "Something went wrong"}
                            </Card>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default SetupItemsContainer;
