import { QueryResultCallback } from "../../models/Callback/Callback";
import { Document } from "../../models/Document/Document";
import { DocumentTemplate } from "../../models/Document/DocumentTemplate";
import { TDocumentAttr } from "../../models/Document/Types";
import { DocumentStoreActions } from "../../redux/services/documents/actions";
import { APIManager } from "../APIManager";
import { TemplateManager } from "./TemplateManager";

export class MainDocumentManager {
    static newDocumentObj(data: TDocumentAttr) {
        return new Document(data);
    }

    getDocument(documentId: string) {
        return DocumentStoreActions.getDocuments().find((t) => t.id === documentId);
    }

    async apiFetchAllDocuments(cb: QueryResultCallback<Document[]>) {
        try {
            DocumentStoreActions.loadingDocument(true);
            const resp = await APIManager.get(APIManager.DOCUMENT.DOCUMENTS_API());
            const documents: Document[] = resp.data.map((t: TDocumentAttr) => this.updateDocumentAttrOrAddNew(t, false));
            DocumentStoreActions.saveDocuments(documents);
            DocumentStoreActions.setDocumentsLoaded(true);
            cb(null, documents);
        } catch (err) {
            cb(`${err}`);
        } finally {
            DocumentStoreActions.loadingDocument(false);
        }
    }

    async apiFetchDocuments(contact: string | null, status: string | null, cb: QueryResultCallback<Document[]>) {
        try {
            DocumentStoreActions.loadingDocument(true);
            const resp = await APIManager.get(APIManager.DOCUMENT.DOCUMENTS_API(contact, status));
            const documents: Document[] = resp.data.map((t: TDocumentAttr) => this.updateDocumentAttrOrAddNew(t, false));
            DocumentStoreActions.saveDocuments(documents);
            DocumentStoreActions.setDocumentsLoaded(true);
            cb(null, documents);
        } catch (err) {
            cb(`${err}`);
        } finally {
            DocumentStoreActions.loadingDocument(false);
        }
    }

    async apiFetchDocumentAttrs(documentId: string, cb: QueryResultCallback<Document>) {
        try {
            const resp = await APIManager.get(APIManager.DOCUMENT.DOCUMENT_ITEM_API(documentId));
            const document = this.updateDocumentAttrOrAddNew(resp.data);
            cb(null, document);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiDownloadMultifileDocuments(documentId: string, cb: QueryResultCallback<Blob>) {
        try {
            const resp = await APIManager.request({
                url: APIManager.DOCUMENT.DOCUMENT_MULTIFILE_DOWNLOAD_API(documentId),
                method: "get",
                responseType: "blob",
            });
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiDeleteDocument(documentId: string, cb: QueryResultCallback<Document>) {
        try {
            const resp = await APIManager.delete(APIManager.DOCUMENT.DOCUMENT_ITEM_API(documentId));
            const document = this.getDocument(documentId);
            if (document) {
                document.markDeleted();
                DocumentStoreActions.removeDocument(document);
            }
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiResendDocumentToSign(documentId: string, contactId: string, cb: QueryResultCallback<DocumentTemplate[]>) {
        try {
            const document = this.getDocument(documentId);
            if (!document) {
                await this.apiFetchDocumentAttrs(documentId, (err, document) => {
                    if (document) {
                        const { template, contactType } = document.data.attrs;
                        new TemplateManager().apiSendTemplateToSign(template, contactId, contactType, cb);
                    } else cb(err || "Something went wrong");
                });
            } else {
                const { template, contactType } = document.data.attrs;
                new TemplateManager().apiSendTemplateToSign(template, contactId, contactType, cb);
            }
        } catch (err) {
            cb(`${err}`);
        }
    }

    updateDocumentAttrOrAddNew(documentAttr: TDocumentAttr, saveToStore = true) {
        let document = this.getDocument(documentAttr._id);
        if (document) {
            document.updateDocumentAttrs(documentAttr);
        } else {
            document = MainDocumentManager.newDocumentObj(documentAttr);
        }
        if (saveToStore) DocumentStoreActions.addOrUpdateToDocuments(document);

        return document;
    }

    removeDocument(documentId: string) {
        const document = this.getDocument(documentId);
        if (document) {
            document.remove();
            DocumentStoreActions.removeDocument(document);
        }
    }
}
