enum ActionTypes {
    SAVE_RECORDS = "SAVE_RECORDS",
    UPDATE_RECORDS = "UPDATE_RECORDS",
    LOADED_RECORDS = "LOADED_RECORDS",
    LOADING_RECORDS = "LOADING_RECORDS",
    ADD_NEW_RECORDS = "ADD_NEW_RECORDS",
    DELETE_RECORD = "DELETE_RECORD",

    SAVE_PROVIDERS = "SAVE_PROVIDERS",
    SAVE_ALL_PROVIDERS_ADVISERREFS = "SAVE_ALL_PROVIDERS_ADVISERREFS",
    LOADED_PROVIDERS = "LOADED_PROVIDERS",
    LOADING_PROVIDERS = "LOADING_PROVIDERS",
    DELETE_PROVIDER = "DELETE_PROVIDER",

    LOGOUT_ACTION = "LOGOUT_ACTION",
}

export default ActionTypes;
