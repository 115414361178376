import ActionTypes from "./actionTypes";
import { getAppStore } from "../../store";
import { DocumentTemplate } from "../../../models/Document/DocumentTemplate";
import { Document } from "../../../models/Document/Document";

export class DocTemplateStoreActions {
    static loadingTemplate(loading: boolean) {
        getAppStore().dispatch({ type: ActionTypes.LOADING_DOCUMENT_TEMPLATES, payload: loading });
    }
    static setTemplatesLoaded(state: boolean) {
        getAppStore().dispatch({ type: ActionTypes.LOADED_DOCUMENT_TEMPLATES, payload: state });
    }
    static saveTemplates(templates: DocumentTemplate[]) {
        getAppStore().dispatch({ type: ActionTypes.SAVE_DOCUMENT_TEMPLATES, payload: templates });
    }
    static removeTemplate(template: DocumentTemplate) {
        getAppStore().dispatch({ type: ActionTypes.REMOVE_DOCUMENT_TEMPLATE, payload: template });
    }
    static getDocumentTemplates() {
        return getAppStore().getState().documents.templates;
    }
    static addOrUpdateToTemplates(template: DocumentTemplate) {
        let templates = DocTemplateStoreActions.getDocumentTemplates();
        if (templates.some((t) => t.data.attrs._id === template.id)) {
            templates = templates.map((t) => {
                if (t.data.attrs._id === template.id) {
                    return t.updateTemplate(template);
                }
                return t;
            });
        } else {
            templates = [template, ...templates];
        }
        DocTemplateStoreActions.saveTemplates(templates);
    }
}

export class DocumentStoreActions {
    static loadingDocument(loading: boolean) {
        getAppStore().dispatch({ type: ActionTypes.LOADING_DOCUMENTS, payload: loading });
    }
    static setDocumentsLoaded(state: boolean) {
        getAppStore().dispatch({ type: ActionTypes.LOADED_DOCUMENTS, payload: state });
    }
    static saveDocuments(documents: Document[]) {
        getAppStore().dispatch({ type: ActionTypes.SAVE_DOCUMENTS, payload: documents });
    }
    static removeDocument(document: Document) {
        getAppStore().dispatch({ type: ActionTypes.REMOVE_DOCUMENT, payload: document });
    }
    static getDocuments() {
        return getAppStore().getState().documents.documents;
    }
    static addOrUpdateToDocuments(document: Document) {
        let documents = DocumentStoreActions.getDocuments();
        if (documents.some((t) => t.data.attrs._id === document.id)) {
            documents = documents.map((t) => {
                if (t.data.attrs._id === document.id) {
                    return t.updateDocument(document);
                }
                return t;
            });
        } else {
            documents = [document, ...documents];
        }
        DocumentStoreActions.saveDocuments(documents);
    }
}
