import { Path as PathParser } from "path-parser";
import { PROJECT_EDIT_ROUTE, SINGLE_PROJECT_ROUTE } from "../../constants/appRoutes";
import { AppManager } from "..";
import { IOEventTypes } from "../RealtimeManager";

export class ProjectNetworkHelper {
    static networkDataReceived = <K extends keyof IOEventTypes>(evt: K, resp: any): void => {
        switch (evt) {
            // Masterlist events
            case "PROJECT_CREATED":
            case "PROJECT_UPDATED":
                {
                    const project = (resp as IOEventTypes["PROJECT_CREATED"]).data;
                    AppManager.project.updateProjectAttrOrAddNew(project);
                }
                break;
            case "PROJECT_DELETED":
                AppManager.project.removeProject((resp as IOEventTypes["PROJECT_DELETED"]).data._id);
                break;
        }
    };

    static onConnectionRestoredSync(currentRoute: string, currentPathName: string) {
        switch (currentRoute) {
            case SINGLE_PROJECT_ROUTE:
            case PROJECT_EDIT_ROUTE:
                const match = new PathParser(currentRoute).test(currentPathName);
                if (match) AppManager.project.apiFetchProjectAttrs(match.projectId, () => {});
                break;
        }
    }
}
