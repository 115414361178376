import { AppManager } from "..";
import { IOEventTypes } from "../RealtimeManager";

export class UserNetworkHelper {
    static networkDataReceived = <K extends keyof IOEventTypes>(evt: K, resp: any): void => {
        switch (evt) {
            // Masterlist events
            case "USER_UPDATED":
                {
                    const user = (resp as IOEventTypes["USER_UPDATED"]).data;
                    AppManager.user.updateActiveUserInfo(user);
                }
                break;
        }
    };

    static onConnectionRestoredSync(currentRoute: string, currentPathName: string) {}
}
