import CheckIcon from "@ant-design/icons/CheckCircleFilled";
import { Card } from "antd";
import Button from "antd-button-color";
import React, { useEffect, useState } from "react";
import Loading from "../../../components/Loading/Loading";
import BasicHeaderLayout from "../../../layouts/BasicHeaderLayout/BasicHeaderLayout";
import { AppManager } from "../../../manager";
import { TAuthInfo } from "../../../models/Auth/Auth";
import Styles from "./Signup.scss";

const SignupContent: React.FC<{ url: string }> = ({ url }) => {
    return (
        <div className={Styles.ContentContainer}>
            <Card className="match-parent">
                <iframe className="match-parent" src={url}></iframe>
            </Card>
        </div>
    );
};

const SubscriptionCard: React.FC<{ name: string; features: string[]; onSelect: () => void }> = ({ name, features, onSelect }) => {
    return (
        <div className={Styles.SubscriptionCard}>
            <div className={Styles.Header}>
                {name}
                <div className={Styles.HeaderLine} />
            </div>
            <div className={Styles.Content}>
                <div className={Styles.Features}>
                    {features.map((item, ind) => (
                        <div key={ind} className={Styles.FeatureItem}>
                            <CheckIcon /> <div className={Styles.Name}>{item}</div>
                        </div>
                    ))}
                </div>
                <div className={Styles.Actions}>
                    <Button size="large" type="primary" onClick={onSelect}>
                        Create Account
                    </Button>
                </div>
            </div>
        </div>
    );
};

const SignupChoiceView: React.FC<{ authInfo: TAuthInfo }> = ({ authInfo }) => {
    const [signupTypeUrl, setUrl] = useState<string>();
    return signupTypeUrl ? (
        <SignupContent url={signupTypeUrl} />
    ) : (
        <div className={Styles.ChoiceContainer}>
            <div className={Styles.TopBg} />
            <div className={Styles.MainContent}>
                <SubscriptionCard
                    name="Dealer Group"
                    features={[
                        "Dealer group feature 1",
                        "Dealer group feature 2",
                        "Dealer group feature 3",
                        "Dealer group feature 4",
                        "Dealer group feature 5",
                    ]}
                    onSelect={() => setUrl(authInfo.dealerGroupSignupURL)}
                />
                <SubscriptionCard
                    name="Financial Planning"
                    features={[
                        "Financial Planning feature 1",
                        "Financial Planning feature 2",
                        "Financial Planning feature 3",
                        "Financial Planning feature 4",
                        "Financial Planning feature 5",
                    ]}
                    onSelect={() => setUrl(authInfo.signupURL)}
                />
            </div>
        </div>
    );
};

const SignupPage = () => {
    const [loading, setLoading] = useState(true);
    const [error, setLoadError] = useState<string>();
    const [authInfo, setAuthInfo] = useState<TAuthInfo>();
    useEffect(() => {
        setLoading(true);
        AppManager.auth.fetchAuthInfo((err, authInfo) => {
            setLoading(false);
            if (err) {
                setLoadError(err);
                AppManager.alert.toastError(err);
            } else {
                setAuthInfo(authInfo);
            }
        });
    }, []);

    if (loading) {
        return <Loading />;
    } else if (error) {
        return (
            <div style={{ position: "absolute", top: "50%", left: "50%", transform: "traslate(-50%, -50%)", fontSize: 16, color: "#b71c1c" }}>
                {error}
            </div>
        );
    } else if (authInfo) return <BasicHeaderLayout content={<SignupChoiceView authInfo={authInfo} />} title="User Signup" />;
    else
        return (
            <div style={{ position: "absolute", top: "50%", left: "50%", transform: "traslate(-50%, -50%)", fontSize: 16, color: "#b71c1c" }}>
                Unknown error. Please try refreshing this page or contact support if it persists
            </div>
        );
};

export default SignupPage;
