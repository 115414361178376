import { AxiosError, AxiosRequestConfig } from "axios";
import {
    CONTACT_INDIVIDUAL_AUTO_REGISTER_API,
    CONTACT_INDIVIDUAL_DELETABLE_CHECK_API,
    CONTACT_INDIVIDUAL_FILES_API,
    CONTACT_INDIVIDUAL_FILE_ITEM_API,
    CONTACT_INDIVIDUAL_ITEM_API,
    CONTACT_INDIVIDUAL_LIST_API,
    CONTACT_INDIVIDUAL_NOTES_API,
    CONTACT_INDIVIDUAL_NOTE_ITEM_API,
    CONTACT_INDIVIDUAL_SERVICES_API,
    CONTACT_INDIVIDUAL_SERVICE_ITEM_API,
    CONTACT_COMPANY_AUTO_REGISTER_API,
    CONTACT_COMPANY_DELETABLE_CHECK_API,
    CONTACT_COMPANY_FILES_API,
    CONTACT_COMPANY_FILE_ITEM_API,
    CONTACT_COMPANY_ITEM_API,
    CONTACT_COMPANY_LIST_API,
    CONTACT_COMPANY_NOTES_API,
    CONTACT_COMPANY_NOTE_ITEM_API,
    CONTACT_COMPANY_SERVICES_API,
    CONTACT_COMPANY_SERVICE_ITEM_API,
    DOCUMENT_ITEM_API,
    DOCUMENT_LIST_API,
    DOCUMENT_TEMPLATE_ITEM_API,
    DOCUMENT_TEMPLATE_LIST_API,
    DOCUMENT_TEMPLATE_INDIVIDUAL_SIGN_API,
    GET_AUTH_INFO_API,
    GET_AZURE_CREDENTIALS_API,
    HOOK_CONTACT_INFO_API,
    HOOK_OPEN_DOCUMENT_API,
    HOOK_ORGANIZATION_INFO_API,
    HOOK_SIGN_CLEANUP_REGISTER_CONTACT_API as HOOK_SIGN_CLEANUP_CONTACT_REGISTRATION_API,
    HOOK_SIGN_DOCUMENT_API,
    HOOK_DISCOVERY_DOC_ITEM_API,
    HOOK_COMPLETE_DOC_DISCOVERY_API,
    HOOK_UPDATE_DOC_DISCOVERY_API,
    HOOK_UPDATE_CONTACT_API,
    LOGIN_API,
    LOGIN_AUTH0_API,
    MASTERLIST_PROVIDERS_API,
    MASTERLIST_PROVIDER_AUTODETECT_API,
    MASTERLIST_RECORDS_API,
    MASTERLIST_SINGLE_PROVIDER_API,
    MASTERLIST_SINGLE_RECORD_API,
    MASTERLIST_PROVIDER_CSVCODE_API,
    ORG_ALL_INFO_API,
    ORG_CONTACT_SERVICES_API,
    ORG_MEMBERS_API,
    USER_AUTH_CREDENTIALS_API,
    USER_DATA_API,
    USER_TOKEN_REFRESH_API,
    MASTERLIST_PROVIDERS_ADVISERREF_API,
    DIARY_ALL_SCHEDULES_API,
    DIARY_MY_SCHEDULES_API,
    DIARY_COLLEAGUES_SCHEDULES_API,
    DIARY_SINGLE_SCHEDULE_API,
    TASKS_API,
    TASK_SINGLE_API,
    TASK_COMPLETED_API,
    TASK_SUBITEM_COMPLETED_API,
    PROJECT_SINGLE_API,
    PROJECTS_API,
    PROJECT_TEMPLATES_API,
    PROJECT_TEMPLATE_SINGLE_API,
    AZURE_UPLOAD_API,
    AZURE_LIST_API,
    OriginalServiceFeeFiles_LIST,
    OriginalServiceFeeFilesAudit_LIST,
    SERVICE_FEE_FILES_API,
    AZURE_DELETE_API,
    AZURE_UNDELETE_API,
    AZURE_DOWNLOAD_API,
    HOOK_RELATED_TOKENMAPS_INFO_API,
    ORG_BASIC_INFO_API,
    HOOK_ORG_INVITE_INFO_API,
    ORG_INVITATIONS_API,
    ORG_INVITATION_ITEM_API,
    HOOK_ACCEPT_ORG_INVITE_NEW_USER_API,
    HOOK_DECLINE_ORG_INVITE_API,
    HOOK_ACCEPT_ORG_INVITE_API,
    ORG_ROLES_API,
    ORG_SET_DEFAULT_ROLE_API,
    ORG_ROLE_ITEM_API,
    ORG_UPDATE_MEMBER_ROLE_API,
    ORG_MEMBER_ITEM_API,
    ORG_SERVICE_LEVELS_API,
    ORG_SERVICE_LEVEL_ITEM_API,
    USER_NOTIFICATION_SETTINGS_API,
    SUBSCRIPTION_CUSTOMER_INFO_API,
    SUBSCRIPTION_CURRENT_PLAN_API,
    SUBSCRIPTION_PAYMENT_METHODS_API,
    SUBSCRIPTION_BILLING_HISTORY_API,
    SEARCH_ORGANIZATION_API,
    ALL_NOTIFICATIONS_API,
    XPLAN_MGT_CUSTOMER_API as XPLAN_MGT_CUSTOMERS_API,
    XPLAN_MGT_CUSTOMER_ITEM_API,
    XPLAN_MGT_CUSTOMER_RESET_API,
    // XPLAN_MGT_CUSTOMER_TEST_CREDS_API,
    XPLAN_MGT_INDIVIDUALS_API,
    XPLAN_MGT_COMPANIES_API,
    XPLAN_MGT_COMPANY_ITEM_API,
    XPLAN_MGT_INDIVIDUAL_ITEM_API,
    XPLAN_MGT_TRUSTS_API,
    XPLAN_MGT_TRUST_ITEM_API,
    XPLAN_MGT_COMPLETE_MIGRATION_API,
    ANALYTICS_CONTACT_WORTH_API,
    ANALYTICS_DATE_RANGE_AGGREGATION_API,
    ANALYTICS_DATE_RANGE_REVENUE_API,
    ANALYTICS_ENTITY_COUNTS_API,
    ANALYTICS_MONTH_REVENUE_API,
    ANALYTICS_USER_TRANSACTIONS_API,
    ANALYTICS_UPCOMING_BIRTHDAYS_API,
    ANALYTICS_USER_EARNINGS_API,
    ANALYTICS_UPCOMING_EVENTS_API,
    SINGLE_NOTIFICATION_API,
    READ_ALL_NOTIFICATIONS_API,
    SUBMIT_APP_ISSUE_API,
    CONTACT_INDIVIDUAL_DEPENDENTS_API,
    CONTACT_INDIVIDUAL_DEPENDENT_ITEM_API,
    ORG_DEALER_GROUP_ITEM_API,
    ORG_DEALER_GROUPS_API,
    ORG_DEALER_GROUP_REQUESTS_API,
    ORG_DEALER_GROUP_REQUEST_ITEM_API,
    ORG_INITIAL_SETUP_ITEM_API,
    ORG_INITIAL_SETUP_STATUS_API,
    DOCUMENT_TEMPLATE_COMPANY_SIGN_API,
    DOCUMENT_TEMPLATE_TRUST_SIGN_API,
    CONTACT_TRUST_ITEM_API,
    CONTACT_TRUST_LIST_API,
    CONTACT_TRUST_AUTO_REGISTER_API,
    CONTACT_TRUST_NOTES_API,
    CONTACT_TRUST_FILES_API,
    CONTACT_TRUST_SERVICES_API,
    CONTACT_TRUST_NOTE_ITEM_API,
    CONTACT_TRUST_FILE_ITEM_API,
    CONTACT_TRUST_SERVICE_ITEM_API,
    CONTACT_TRUST_DELETABLE_CHECK_API,
    CONTACT_AUDITS_API,
    CONTACT_COMPANY_SEND_EMAIL_API,
    CONTACT_TRUST_SEND_EMAIL_API,
    CONTACT_INDIVIDUAL_SEND_EMAIL_API,
    CONTACT_INDIVIDUAL_LINK_COMPANY_API,
    CONTACT_INDIVIDUAL_UNLINK_COMPANY_API,
    CONTACT_INDIVIDUAL_LINK_TRUST_API,
    CONTACT_INDIVIDUAL_UNLINK_TRUST_API,
    CONNECTED_EMAIL_API,
    EMAIL_SEND_API,
    EMAIL_SIGNATURE_API,
    EMAIL_SIGNATURE_ITEM_API,
    EMAIL_TEMPLATES_API,
    EMAIL_TEMPLATE_ITEM_API,
    XPLAN_MGT_CONTACT_NOTES_API,
    XPLAN_MGT_CONTACT_NOTE_ATTACHMENTS_API,
    ORG_SERVICE_LEVEL_REORDER_API,
    CONTACT_INDIVIDUAL_BULK_EMAIL_API,
    ORG_SCHEDULES_CATEGORIES_API,
    ORG_SCHEDULES_CATEGORY_ITEM_API,
    CONTACT_INDIVIDUAL_NOTE_ITEM_RESTORE_API,
    CONTACT_NOTE_AUDITS_API,
    DOCUMENT_TEMPLATE_ITEM_CONTENTTAGS_API,
    DOCUMENT_TEMPLATE_ITEM_BUILD_API,
    DOCUMENT_TEMPLATE_VERIFY_API,
    DOCUMENT_MULTIFILE_DOWNLOAD_API,
    CONTACT_INDIVIDUAL_FILE_NOTE_ITEM_API,
    MASTERLIST_SERVICE_FEE_AUDIT_LIST_API,
    MASTERLIST_SERVICE_FEE_FILE_DELETE_API,
    CONTACT_INDIVIDUAL_SERVICE_FEE_API,
    CONTACT_TRUST_SERVICE_FEE_API,
    CONTACT_COMPANY_SERVICE_FEE_API,
    MASTERLIST_SERVICE_FEE_FILE_DELETE_ASSOCIATED_ENTRIES_API,
    CONTACT_INDIVIDUAL_ITEM_RESTORE_API,
    CONTACT_COMPANY_ITEM_RESTORE_API,
    CONTACT_TRUST_ITEM_RESTORE_API,
    DISCOVERY_FORM_INDIVIDUAL_SIGN_API,
    DISCOVERY_GROUPS_API,
    DISCOVERY_GROUP_ITEM_API,
    DISCOVERY_GROUP_DOCUMENT_ITEM_API,
    STATEMENTS_OF_ADVICE_API,
    STATEMENT_OF_ADVICE_ITEM_API,
    STATEMENT_OF_ADVICE_DRAFTS_API,
    TASKS_MY_API,
    PROJECT_MY_API,
    TASK_SINGLE_TEMPLATE_API,
    CONTACT_INDIVIDUAL_CUSTOM_FIELDS_API,
    CONTACT_TRUST_CUSTOM_FIELDS_API,
    CONTACT_COMPANY_CUSTOM_FIELDS_API,
    CONTACT_CUSTOM_FIELD_API,
    CONNECTED_EMAIL_ITEM_API,
} from "../constants/apiEndpoints";
import { formatUrl } from "../helpers/misc";
import { apiHTTPRRequest, tokenConfig } from "../helpers/request_helper";
import { AuthStoreActions } from "../redux/services/auth/actions";
import { OrgManager } from "./OrgManager/OrgManager";
import { UserManager } from "./UserManager/UserManager";

class IndividualAPIGroup {
    static ITEM_API(contactId: string): string {
        return formatUrl(CONTACT_INDIVIDUAL_ITEM_API, null, { contactId });
    }
    static ITEM_RESTORE_API(contactId: string): string {
        return formatUrl(CONTACT_INDIVIDUAL_ITEM_RESTORE_API, null, { contactId });
    }
    static SERVICE_FEE_API(contactId: string, start?: string, end?: string): string {
        return formatUrl(CONTACT_INDIVIDUAL_SERVICE_FEE_API, { start, end }, { contactId });
    }
    static get LIST_API(): string {
        return CONTACT_INDIVIDUAL_LIST_API;
    }
    static get CUSTOM_FIELDS_INDIVIDUAL_API(): string {
        return CONTACT_INDIVIDUAL_CUSTOM_FIELDS_API;
    }
    static get CUSTOM_FIELDS_COMPANY_API(): string {
        return CONTACT_COMPANY_CUSTOM_FIELDS_API;
    }
    static get CUSTOM_FIELDS_TRUST_API(): string {
        return CONTACT_TRUST_CUSTOM_FIELDS_API;
    }
    static CUSTOM_FIELD_API(customFieldId: string): string {
        return formatUrl(CONTACT_CUSTOM_FIELD_API, null, { customFieldId });
    }
    static get BULK_EMAIL_API(): string {
        return CONTACT_INDIVIDUAL_BULK_EMAIL_API;
    }
    static get AUTO_REGISTER_API(): string {
        return CONTACT_INDIVIDUAL_AUTO_REGISTER_API;
    }
    static NOTES_API(contactId: string): string {
        return formatUrl(CONTACT_INDIVIDUAL_NOTES_API, null, { contactId });
    }
    static LINK_COMPANY_API(contactId: string): string {
        return formatUrl(CONTACT_INDIVIDUAL_LINK_COMPANY_API, null, { contactId });
    }
    static UNLINK_COMPANY_API(contactId: string): string {
        return formatUrl(CONTACT_INDIVIDUAL_UNLINK_COMPANY_API, null, { contactId });
    }
    static LINK_TRUST_API(contactId: string): string {
        return formatUrl(CONTACT_INDIVIDUAL_LINK_TRUST_API, null, { contactId });
    }
    static UNLINK_TRUST_API(contactId: string): string {
        return formatUrl(CONTACT_INDIVIDUAL_UNLINK_TRUST_API, null, { contactId });
    }
    static FILES_API(contactId: string): string {
        return formatUrl(CONTACT_INDIVIDUAL_FILES_API, null, { contactId });
    }
    static SERVICES_API(contactId: string): string {
        return formatUrl(CONTACT_INDIVIDUAL_SERVICES_API, null, { contactId });
    }
    static DEPENDENTS_API(contactId: string): string {
        return formatUrl(CONTACT_INDIVIDUAL_DEPENDENTS_API, null, { contactId });
    }
    static NOTE_ITEM_API(contactId: string, noteId: string): string {
        return formatUrl(CONTACT_INDIVIDUAL_NOTE_ITEM_API, null, { contactId, noteId });
    }
    static NOTE_ITEM_RESTORE_API(contactId: string, noteId: string): string {
        return formatUrl(CONTACT_INDIVIDUAL_NOTE_ITEM_RESTORE_API, null, { contactId, noteId });
    }
    static DEPENDENT_ITEM_API(contactId: string, dependentId: string): string {
        return formatUrl(CONTACT_INDIVIDUAL_DEPENDENT_ITEM_API, null, { contactId, dependentId });
    }
    static FILE_ITEM_API(contactId: string, fileId: string): string {
        return formatUrl(CONTACT_INDIVIDUAL_FILE_ITEM_API, null, { contactId, fileId });
    }
    static FILE_NOTE_ITEM_API(contactId: string, noteId: string, fileId: string): string {
        return formatUrl(CONTACT_INDIVIDUAL_FILE_NOTE_ITEM_API, null, { contactId, noteId, fileId });
    }
    static SERVICE_ITEM_API(contactId: string, serviceId: string): string {
        return formatUrl(CONTACT_INDIVIDUAL_SERVICE_ITEM_API, null, { contactId, serviceId });
    }
    static CHECK_CONTACT_DELETABLE_API(contactId: string): string {
        return formatUrl(CONTACT_INDIVIDUAL_DELETABLE_CHECK_API, null, { contactId });
    }
    static SEND_EMAIL_API(contactId: string): string {
        return formatUrl(CONTACT_INDIVIDUAL_SEND_EMAIL_API, null, { contactId });
    }
}

class CompanyAPIGroup {
    static ITEM_API(contactId: string): string {
        return formatUrl(CONTACT_COMPANY_ITEM_API, null, { contactId });
    }
    static ITEM_RESTORE_API(contactId: string): string {
        return formatUrl(CONTACT_COMPANY_ITEM_RESTORE_API, null, { contactId });
    }
    static get LIST_API(): string {
        return CONTACT_COMPANY_LIST_API;
    }
    static get LIST_CUSTOM_FIELDS_API(): string {
        return CONTACT_COMPANY_CUSTOM_FIELDS_API;
    }
    static SERVICE_FEE_API(contactId: string, start?: string, end?: string): string {
        return formatUrl(CONTACT_COMPANY_SERVICE_FEE_API, { start, end }, { contactId });
    }
    static get AUTO_REGISTER_API(): string {
        return CONTACT_COMPANY_AUTO_REGISTER_API;
    }
    static NOTES_API(contactId: string): string {
        return formatUrl(CONTACT_COMPANY_NOTES_API, null, { contactId });
    }
    static FILES_API(contactId: string): string {
        return formatUrl(CONTACT_COMPANY_FILES_API, null, { contactId });
    }
    static SERVICES_API(contactId: string): string {
        return formatUrl(CONTACT_COMPANY_SERVICES_API, null, { contactId });
    }
    static NOTE_ITEM_API(contactId: string, noteId: string): string {
        return formatUrl(CONTACT_COMPANY_NOTE_ITEM_API, null, { contactId, noteId });
    }
    static FILE_ITEM_API(contactId: string, fileId: string): string {
        return formatUrl(CONTACT_COMPANY_FILE_ITEM_API, null, { contactId, fileId });
    }
    static SERVICE_ITEM_API(contactId: string, serviceId: string): string {
        return formatUrl(CONTACT_COMPANY_SERVICE_ITEM_API, null, { contactId, serviceId });
    }
    static CHECK_CONTACT_DELETABLE_API(contactId: string): string {
        return formatUrl(CONTACT_COMPANY_DELETABLE_CHECK_API, null, { contactId });
    }
    static SEND_EMAIL_API(contactId: string): string {
        return formatUrl(CONTACT_COMPANY_SEND_EMAIL_API, null, { contactId });
    }
}

class TrustAPIGroup {
    static ITEM_API(contactId: string): string {
        return formatUrl(CONTACT_TRUST_ITEM_API, null, { contactId });
    }
    static ITEM_RESTORE_API(contactId: string): string {
        return formatUrl(CONTACT_TRUST_ITEM_RESTORE_API, null, { contactId });
    }
    static get LIST_API(): string {
        return CONTACT_TRUST_LIST_API;
    }
    static get LIST_CUSTOM_FIELDS_API(): string {
        return CONTACT_TRUST_CUSTOM_FIELDS_API;
    }
    static SERVICE_FEE_API(contactId: string, start?: string, end?: string): string {
        return formatUrl(CONTACT_TRUST_SERVICE_FEE_API, { start, end }, { contactId });
    }
    static get AUTO_REGISTER_API(): string {
        return CONTACT_TRUST_AUTO_REGISTER_API;
    }
    static NOTES_API(contactId: string): string {
        return formatUrl(CONTACT_TRUST_NOTES_API, null, { contactId });
    }
    static FILES_API(contactId: string): string {
        return formatUrl(CONTACT_TRUST_FILES_API, null, { contactId });
    }
    static SERVICES_API(contactId: string): string {
        return formatUrl(CONTACT_TRUST_SERVICES_API, null, { contactId });
    }
    static NOTE_ITEM_API(contactId: string, noteId: string): string {
        return formatUrl(CONTACT_TRUST_NOTE_ITEM_API, null, { contactId, noteId });
    }
    static FILE_ITEM_API(contactId: string, fileId: string): string {
        return formatUrl(CONTACT_TRUST_FILE_ITEM_API, null, { contactId, fileId });
    }
    static SERVICE_ITEM_API(contactId: string, serviceId: string): string {
        return formatUrl(CONTACT_TRUST_SERVICE_ITEM_API, null, { contactId, serviceId });
    }
    static CHECK_CONTACT_DELETABLE_API(contactId: string): string {
        return formatUrl(CONTACT_TRUST_DELETABLE_CHECK_API, null, { contactId });
    }
    static SEND_EMAIL_API(contactId: string): string {
        return formatUrl(CONTACT_TRUST_SEND_EMAIL_API, null, { contactId });
    }
}

class ContactAuditAPIGroup {
    static ITEM_API(contactId: string, start?: string, end?: string, page?: number): string {
        return formatUrl(CONTACT_AUDITS_API, { start, end, page: page ? `${page}` : undefined }, { contactId });
    }

    static NOTE_ITEM_API(contactId: string, noteId: string, start?: string, end?: string, page?: number): string {
        return formatUrl(CONTACT_NOTE_AUDITS_API, { start, end, page: page ? `${page}` : undefined }, { contactId, noteId });
    }
}

class ContactAPIGroup {
    static INDIVIDUAL = IndividualAPIGroup;
    static COMPANY = CompanyAPIGroup;
    static TRUST = TrustAPIGroup;
    static AUDIT = ContactAuditAPIGroup;
}

class DocumentAPIGroup {
    static TEMPLATE_ITEM_API(templateId: string): string {
        return formatUrl(DOCUMENT_TEMPLATE_ITEM_API, null, { templateId });
    }
    static TEMPLATE_ITEM_CONTENTTAGS_API(templateId: string): string {
        return formatUrl(DOCUMENT_TEMPLATE_ITEM_CONTENTTAGS_API, null, { templateId });
    }
    static get TEMPLATE_ITEM_VERIFY_API(): string {
        return DOCUMENT_TEMPLATE_VERIFY_API;
    }
    static TEMPLATE_ITEM_BUILD_API(templateId: string): string {
        return formatUrl(DOCUMENT_TEMPLATE_ITEM_BUILD_API, null, { templateId });
    }
    static get TEMPLATES_API(): string {
        return DOCUMENT_TEMPLATE_LIST_API;
    }
    static get TEMPLATE_INDIVIDUAL_SIGN_API(): string {
        return DOCUMENT_TEMPLATE_INDIVIDUAL_SIGN_API;
    }
    static get DISCOVERY_FORM_INDIVIDUAL_SIGN_API(): string {
        return DISCOVERY_FORM_INDIVIDUAL_SIGN_API;
    }
    static get DISCOVERY_GROUPS_API(): string {
        return DISCOVERY_GROUPS_API;
    }
    static get STATEMENTS_OF_ADVICE_API(): string {
        return STATEMENTS_OF_ADVICE_API;
    }
    static DISCOVERY_GROUP_DOCUMENT_ITEM_API(soaGroupId: string, documentId: string): string {
        return formatUrl(DISCOVERY_GROUP_DOCUMENT_ITEM_API, null, { soaGroupId, documentId });
    }
    static DISCOVERY_GROUP_DOCUMENT_ITEM_COMPLETE_API(soaGroupId: string, documentId: string): string {
        return formatUrl(`${DISCOVERY_GROUP_DOCUMENT_ITEM_API}/complete`, null, { soaGroupId, documentId });
    }
    static DISCOVERY_GROUP_ITEM_API(soaGroupId: string): string {
        return formatUrl(DISCOVERY_GROUP_ITEM_API, null, { soaGroupId });
    }
    static STATEMENT_OF_ADVICE_ITEM_API(soaId: string): string {
        return formatUrl(STATEMENT_OF_ADVICE_ITEM_API, null, { soaId });
    }
    static STATEMENT_OF_ADVICE_DRAFTS_API(soaId: string): string {
        return formatUrl(STATEMENT_OF_ADVICE_DRAFTS_API, null, { soaId });
    }
    static get TEMPLATE_COMPANY_SIGN_API(): string {
        return DOCUMENT_TEMPLATE_COMPANY_SIGN_API;
    }
    static get TEMPLATE_TRUST_SIGN_API(): string {
        return DOCUMENT_TEMPLATE_TRUST_SIGN_API;
    }
    static DOCUMENT_ITEM_API(documentId: string): string {
        return formatUrl(DOCUMENT_ITEM_API, null, { documentId });
    }
    static DOCUMENTS_API(contact?: string | null, status?: string | null): string {
        return formatUrl(DOCUMENT_LIST_API, { ...(contact ? { contact } : {}), ...(status ? { status } : {}) });
    }
    static DOCUMENT_MULTIFILE_DOWNLOAD_API(documentId: string): string {
        return formatUrl(DOCUMENT_MULTIFILE_DOWNLOAD_API, null, { documentId });
    }
}

class MasterlistAPIGroup {
    static get MASTERLIST_PROVIDERS_API(): string {
        return MASTERLIST_PROVIDERS_API;
    }
    static get MASTERLIST_PROVIDERS_ADVISERREF_API(): string {
        return MASTERLIST_PROVIDERS_ADVISERREF_API;
    }
    static MASTERLIST_SINGLE_PROVIDER_API(providerId: string): string {
        return formatUrl(MASTERLIST_SINGLE_PROVIDER_API, null, { providerId });
    }
    static MASTERLIST_PROVIDER_CSVCODE_API(providerId: string): string {
        return formatUrl(MASTERLIST_PROVIDER_CSVCODE_API, null, { providerId });
    }
    static get MASTERLIST_PROVIDER_AUTODETECT_API(): string {
        return MASTERLIST_PROVIDER_AUTODETECT_API;
    }

    static get MASTERLIST_RECORDS_API(): string {
        return MASTERLIST_RECORDS_API;
    }
    static MASTERLIST_SINGLE_RECORD_API(recordId: string): string {
        return formatUrl(MASTERLIST_SINGLE_RECORD_API, null, { recordId });
    }
    static MASTERLIST_SERVICE_FEE_AUDIT_LIST_API(originalServiceFeeFileId: string): string {
        return formatUrl(MASTERLIST_SERVICE_FEE_AUDIT_LIST_API, null, { originalServiceFeeFileId });
    }
    static MASTERLIST_SERVICE_FEE_FILE_DELETE_API(originalServiceFeeFileId: string): string {
        return formatUrl(MASTERLIST_SERVICE_FEE_FILE_DELETE_API, null, { originalServiceFeeFileId });
    }
    static MASTERLIST_SERVICE_FEE_FILE_DELETE_ASSOCIATED_ENTRIES_API(originalServiceFeeFileId: string): string {
        return formatUrl(MASTERLIST_SERVICE_FEE_FILE_DELETE_ASSOCIATED_ENTRIES_API, null, { originalServiceFeeFileId });
    }
}

class ScheduleAPIGroup {
    static get DIARY_ALL_SCHEDULES_API(): string {
        return DIARY_ALL_SCHEDULES_API;
    }
    static get DIARY_MY_SCHEDULES_API(): string {
        return DIARY_MY_SCHEDULES_API;
    }
    static get DIARY_COLLEAGUES_SCHEDULES_API(): string {
        return DIARY_COLLEAGUES_SCHEDULES_API;
    }
    static DIARY_SINGLE_SCHEDULE_API(scheduleId: string): string {
        return formatUrl(DIARY_SINGLE_SCHEDULE_API, null, { scheduleId });
    }
}

class TaskAPIGroup {
    static TASKS_API(query?: string): string {
        return formatUrl(TASKS_API, query ? { type: query } : {});
    }
    static TASK_SINGLE_TEMPLATE_API(query?: string): string {
        return formatUrl(TASK_SINGLE_TEMPLATE_API, query ? { type: query } : {});
    }
    static TASKS_TEMPLATES_API(query?: string): string {
        return formatUrl(TASK_SINGLE_TEMPLATE_API, query ? { type: query } : {});
    }
    static TASKS_My_API(query?: string): string {
        return formatUrl(TASKS_MY_API, query ? { type: query } : {});
    }
    static TASK_SINGLE_API(taskId: string): string {
        return formatUrl(TASK_SINGLE_API, null, { taskId });
    }
    static TASK_COMPLETED_API(taskId: string): string {
        return formatUrl(TASK_COMPLETED_API, null, { taskId });
    }
    static TASK_SUBITEM_COMPLETED_API(taskId: string, taskItemId: string): string {
        return formatUrl(TASK_SUBITEM_COMPLETED_API, null, { taskId, taskItemId });
    }
    static PROJECTS_API(query?: string): string {
        return formatUrl(PROJECTS_API, query ? { type: query } : {});
    }
    static PROJECTS_SINGLE_API(projectId: string): string {
        return formatUrl(PROJECT_SINGLE_API, null, { projectId });
    }

    static PROJECTS_MY_API(query?: string): string {
        return formatUrl(PROJECT_MY_API, query ? { type: query } : {});
    }

    static get PROJECT_TEMPLATES_API(): string {
        return PROJECT_TEMPLATES_API;
    }
    static PROJECT_TEMPLATES_SINGLE_API(templateId: string): string {
        return formatUrl(PROJECT_TEMPLATE_SINGLE_API, null, { templateId });
    }
}

class StorageAPIGroup {
    static get AZURE_LIST_API() {
        return AZURE_LIST_API;
    }

    static get OriginalServiceFeeFiles_LIST() {
        return OriginalServiceFeeFiles_LIST;
    }

    static get OriginalServiceFeeFilesAudit_LIST() {
        return OriginalServiceFeeFilesAudit_LIST;
    }

    static get SERVICE_FEE_FILES_API() {
        return SERVICE_FEE_FILES_API;
    }

    static get AZURE_UPLOAD_API() {
        return AZURE_UPLOAD_API;
    }

    static get AZURE_DELETE_API() {
        return AZURE_DELETE_API;
    }

    static get AZURE_UNDELETE_API() {
        return AZURE_UNDELETE_API;
    }

    static get AZURE_DOWNLOAD_API() {
        return AZURE_DOWNLOAD_API;
    }
}

class OrganizationAPI {
    static MEMBERS_API(organizationId: string): string {
        return formatUrl(ORG_MEMBERS_API, null, { organizationId });
    }

    static ALL_INFO_API(organizationId: string): string {
        return formatUrl(ORG_ALL_INFO_API, null, { organizationId });
    }

    static BASIC_INFO_API(organizationId: string): string {
        return formatUrl(ORG_BASIC_INFO_API, null, { organizationId });
    }

    static get CONTACT_SERVICES_API(): string {
        return ORG_CONTACT_SERVICES_API;
    }

    static INVITATIONS_API(organizationId: string): string {
        return formatUrl(ORG_INVITATIONS_API, null, { organizationId });
    }

    static INVITATION_ITEM_API(organizationId: string, invitationId: string): string {
        return formatUrl(ORG_INVITATION_ITEM_API, null, { organizationId, invitationId });
    }

    static ROLES_API(organizationId: string): string {
        return formatUrl(ORG_ROLES_API, null, { organizationId });
    }

    static ROLE_ITEM_API(organizationId: string, roleId: string): string {
        return formatUrl(ORG_ROLE_ITEM_API, null, { organizationId, roleId });
    }

    static SET_DEFAULT_ROLE_API(organizationId: string, roleId: string): string {
        return formatUrl(ORG_SET_DEFAULT_ROLE_API, null, { organizationId, roleId });
    }

    static UPDATE_MEMBER_ROLE_API(organizationId: string) {
        return formatUrl(ORG_UPDATE_MEMBER_ROLE_API, null, { organizationId });
    }
    static MEMBER_ITEM_API(organizationId: string, memberId: string) {
        return formatUrl(ORG_MEMBER_ITEM_API, null, { organizationId, memberId });
    }
    static SCHEDULES_CATEGORIES_API(organizationId: string) {
        return formatUrl(ORG_SCHEDULES_CATEGORIES_API, null, { organizationId });
    }
    static SCHEDULES_CATEGORY_ITEM_API(organizationId: string, categoryId: string) {
        return formatUrl(ORG_SCHEDULES_CATEGORY_ITEM_API, null, { organizationId, categoryId });
    }
    static SERVICE_LEVELS_API(organizationId: string) {
        return formatUrl(ORG_SERVICE_LEVELS_API, null, { organizationId });
    }
    static SERVICE_LEVEL_ITEM_API(organizationId: string, serviceLevelId: string) {
        return formatUrl(ORG_SERVICE_LEVEL_ITEM_API, null, { organizationId, serviceLevelId });
    }
    static SERVICE_LEVEL_REORDER_API(organizationId: string) {
        return formatUrl(ORG_SERVICE_LEVEL_REORDER_API, null, { organizationId });
    }
    static DEALER_GROUP_API(organizationId: string) {
        return formatUrl(ORG_DEALER_GROUPS_API, null, { organizationId });
    }
    static DEALER_GROUP_REQUESTS_API(organizationId: string) {
        return formatUrl(ORG_DEALER_GROUP_REQUESTS_API, null, { organizationId });
    }
    static DEALER_GROUP_REQUEST_ITEM_API(organizationId: string, requestId: string) {
        return formatUrl(ORG_DEALER_GROUP_REQUEST_ITEM_API, null, { organizationId, requestId });
    }
    static DEALER_GROUP_ITEM_API(organizationId: string, dealerGroupId: string) {
        return formatUrl(ORG_DEALER_GROUP_ITEM_API, null, { organizationId, dealerGroupId });
    }

    static ORG_INITIAL_SETUP_ITEM_API(organizationId: string) {
        return formatUrl(ORG_INITIAL_SETUP_ITEM_API, null, { organizationId });
    }

    static ORG_INITIAL_SETUP_STATUS_API(organizationId: string) {
        return formatUrl(ORG_INITIAL_SETUP_STATUS_API, null, { organizationId });
    }
}

class SubscriptionAPIGroup {
    static get CUSTOMER_INFO_API() {
        return SUBSCRIPTION_CUSTOMER_INFO_API;
    }

    static get CURRENT_PLAN_API() {
        return SUBSCRIPTION_CURRENT_PLAN_API;
    }

    static get CURRENT_PLAN_SEATS_API() {
        return `${SUBSCRIPTION_CURRENT_PLAN_API}/seats`;
    }

    static get CURRENT_PLAN_SOA_LICENCES_API() {
        return `${SUBSCRIPTION_CURRENT_PLAN_API}/soa-licences`;
    }

    static get PAYMENT_METHODS_API() {
        return SUBSCRIPTION_PAYMENT_METHODS_API;
    }

    static get DEFAULT_PAYMENT_METHOD_API() {
        return `${SUBSCRIPTION_PAYMENT_METHODS_API}/default`;
    }

    static get BILLING_HISTORY_API() {
        return SUBSCRIPTION_BILLING_HISTORY_API;
    }
}

class XPlanMigrationAppAPIGroup {
    static get CUSTOMERS_API() {
        return XPLAN_MGT_CUSTOMERS_API;
    }

    static CUSTOMER_ITEM_API(customerId: string) {
        return formatUrl(XPLAN_MGT_CUSTOMER_ITEM_API, null, { customerId });
    }

    static CUSTOMER_RESET_API(customerId: string) {
        return formatUrl(XPLAN_MGT_CUSTOMER_RESET_API, null, { customerId });
    }

    // static get CUSTOMER_TEST_CREDS_API() {
    //     return XPLAN_MGT_CUSTOMER_TEST_CREDS_API;
    // }

    static INDIVIDUALS_API(customerId: string) {
        return formatUrl(XPLAN_MGT_INDIVIDUALS_API, null, { customerId });
    }

    static INDIVIDUAL_ITEM_API(customerId: string, individualId: string) {
        return formatUrl(XPLAN_MGT_INDIVIDUAL_ITEM_API, null, { customerId, individualId });
    }

    static COMPANIES_API(customerId: string) {
        return formatUrl(XPLAN_MGT_COMPANIES_API, null, { customerId });
    }

    static COMPANY_ITEM_API(customerId: string, companyId: string) {
        return formatUrl(XPLAN_MGT_COMPANY_ITEM_API, null, { customerId, companyId });
    }

    static TRUSTS_API(customerId: string) {
        return formatUrl(XPLAN_MGT_TRUSTS_API, null, { customerId });
    }

    static TRUST_ITEM_API(customerId: string, trustId: string) {
        return formatUrl(XPLAN_MGT_TRUST_ITEM_API, null, { customerId, trustId });
    }

    static CONTACT_FILE_NOTES_API(customerId: string, contactId: string) {
        return formatUrl(XPLAN_MGT_CONTACT_NOTES_API, null, { customerId, contactId });
    }

    static CONTACT_FILE_NOTE_ATTACHMENTS_API(customerId: string, contactId: string, fileNoteId: string) {
        return formatUrl(XPLAN_MGT_CONTACT_NOTE_ATTACHMENTS_API, null, { customerId, contactId, fileNoteId });
    }

    static COMPLETE_MIGRATION_API(customerId: string) {
        return formatUrl(XPLAN_MGT_COMPLETE_MIGRATION_API, { finalize: "1" }, { customerId });
    }
}

class AnalyticsAPIGroup {
    static CONTACT_WORTH_API(start: Date, end: Date) {
        return formatUrl(ANALYTICS_CONTACT_WORTH_API, { start: start.toISOString(), end: end.toISOString() });
    }

    static DATE_RANGE_AGGREGATION_API(start: Date, end: Date) {
        return formatUrl(ANALYTICS_DATE_RANGE_AGGREGATION_API, { start: start.toISOString(), end: end.toISOString() });
    }

    static DATE_RANGE_REVENUE_API(start: Date, end: Date) {
        return formatUrl(ANALYTICS_DATE_RANGE_REVENUE_API, { start: start.toISOString(), end: end.toISOString() });
    }

    static get ENTITY_COUNTS_API() {
        return ANALYTICS_ENTITY_COUNTS_API;
    }

    static MONTH_REVENUE_API(date: Date) {
        return formatUrl(ANALYTICS_MONTH_REVENUE_API, { date: date.toISOString() });
    }

    static USER_TRANSACTIONS_API(userId: string, start: Date, end: Date) {
        return formatUrl(ANALYTICS_USER_TRANSACTIONS_API, { start: start.toISOString(), end: end.toISOString() }, { userId });
    }

    static UPCOMING_BIRTHDAYS_API(maxDays?: number) {
        return formatUrl(ANALYTICS_UPCOMING_BIRTHDAYS_API, maxDays ? { maxDays: `${maxDays}` } : {});
    }

    static UPCOMING_EVENTS_API(maxDays?: number) {
        return formatUrl(ANALYTICS_UPCOMING_EVENTS_API, maxDays ? { maxDays: `${maxDays}` } : {});
    }

    static USER_EARNINGS_API(start: Date, end: Date) {
        return formatUrl(ANALYTICS_USER_EARNINGS_API, { start: start.toISOString(), end: end.toISOString() });
    }
}

class NotificationsAPIGroup {
    static get LIST_API() {
        return ALL_NOTIFICATIONS_API;
    }

    static ITEM_API(notificationId: string) {
        return formatUrl(SINGLE_NOTIFICATION_API, null, { notificationId });
    }

    static get READ_ALL_API() {
        return READ_ALL_NOTIFICATIONS_API;
    }
}

export class APIManager {
    static getResponseErrorMsg = async (error: AxiosError) => {
        try {
            if (error.response?.status == 422 && error.response?.data?.errors.length) {
                return Array.from(new Set(error.response?.data?.errors.map((error: any) => error.reason))).join(", ");
            }
            return error.response && error.response.data
                ? error.response.data.message
                    ? error.response.data.message
                    : error.response.data.error
                    ? error.response.data.error
                    : error.response.data instanceof Blob
                    ? JSON.parse(await error.response.data.text()).message || "Something went wrong.."
                    : error.response.data
                : error.message
                ? `${JSON.stringify(error.message, null, 2)}`
                : error
                ? `${JSON.stringify(error, null, 2)}`
                : "Something went wrong";
        } catch (err) {
            return "Something went wrong...";
        }
    };

    static async request(req: AxiosRequestConfig) {
        const token = AuthStoreActions.getUserToken();
        const organizationId = new OrgManager().getCurrentActiveOrg()?.id;
        const userId = new UserManager().getActiveUser()?.id;
        req.url = formatUrl(req.url || "", null, { organizationId: organizationId || "", userId: userId || "" });
        const reqHeaders = tokenConfig(token || "", req.headers).headers;
        try {
            return await apiHTTPRRequest({ ...req, headers: { ...reqHeaders, "x-ausplan-org": organizationId }, timeout: 15 * 60 * 60 * 1000 });
        } catch (err: any) {
            if (err.response && err.response.status === 401) {
                // TODO: Reauthenticate here and retry the request
                throw await APIManager.getResponseErrorMsg(err);
            } else throw await APIManager.getResponseErrorMsg(err);
        }
    }

    static get(endpoint: string, headers?: { [k: string]: any }) {
        return APIManager.request({
            method: "GET",
            url: endpoint,
            headers: headers,
        });
    }

    static post(endpoint: string, body: any, headers?: { [k: string]: any }) {
        return APIManager.request({
            method: "POST",
            url: endpoint,
            headers: headers,
            data: body,
        });
    }

    static put(endpoint: string, body: any, headers?: { [k: string]: any }) {
        return APIManager.request({
            method: "PUT",
            url: endpoint,
            headers: headers,
            data: body,
        });
    }

    static patch(endpoint: string, body?: any, headers?: { [k: string]: any }) {
        return APIManager.request({
            method: "PATCH",
            url: endpoint,
            headers: headers,
            data: body,
        });
    }

    static delete(endpoint: string, headers?: { [k: string]: any }) {
        return APIManager.request({
            method: "DELETE",
            url: endpoint,
            headers: headers,
        });
    }

    static CONTACT = ContactAPIGroup;
    static DOCUMENT = DocumentAPIGroup;
    static MASTERLIST = MasterlistAPIGroup;
    static SCHEDULE = ScheduleAPIGroup;
    static TASKS = TaskAPIGroup;
    static STORAGE = StorageAPIGroup;
    static ORG = OrganizationAPI;
    static SUBSCRIPTION = SubscriptionAPIGroup;
    static NOTIFICATION = NotificationsAPIGroup;
    static APP_XPLAN = XPlanMigrationAppAPIGroup;
    static ANALYTICS = AnalyticsAPIGroup;

    static SEARCH_ORGANIZATION_API(searchText: string, options?: { notes?: string; files?: string }): string {
        return formatUrl(SEARCH_ORGANIZATION_API, { q: searchText, ...(options || {}) });
    }

    static get AUTH_INFO_API(): string {
        return GET_AUTH_INFO_API;
    }
    static get AZURE_CREDENTIALS_API(): string {
        return GET_AZURE_CREDENTIALS_API;
    }
    static get REFRESH_TOKEN_API(): string {
        return USER_TOKEN_REFRESH_API;
    }
    static get AUTH0_LOGIN_API(): string {
        return LOGIN_AUTH0_API;
    }
    static get LOGIN_API(): string {
        return LOGIN_API;
    }
    static get USER_DATA_API(): string {
        return USER_DATA_API;
    }
    static get USER_NOTIFICATION_SETTINGS_API() {
        return USER_NOTIFICATION_SETTINGS_API;
    }
    static get CONNECT_EMAIL_API() {
        return CONNECTED_EMAIL_API;
    }
    static CONNECT_EMAIL_ITEM_API(connectedEmailId: string) {
        return formatUrl(CONNECTED_EMAIL_ITEM_API, null, { connectedEmailId });
    }
    static get EMAIL_SIGNATURE_API() {
        return EMAIL_SIGNATURE_API;
    }
    static EMAIL_SIGNATURE_ITEM_API(signatureId: string) {
        return formatUrl(EMAIL_SIGNATURE_ITEM_API, null, { signatureId });
    }
    static get EMAIL_TEMPLATES_API() {
        return EMAIL_TEMPLATES_API;
    }
    static EMAIL_TEMPLATE_ITEM_API(templateId: string) {
        return formatUrl(EMAIL_TEMPLATE_ITEM_API, null, { templateId });
    }
    static get EMAIL_SEND_API() {
        return EMAIL_SEND_API;
    }
    static USER_AUTH_CREDS_API(checkOld?: boolean): string {
        return formatUrl(USER_AUTH_CREDENTIALS_API, typeof checkOld === "boolean" ? { checkOld: `${checkOld}` } : {});
    }

    static HOOK_ORGANIZATION_INFO_API(organizationId: string, tokenId: string): string {
        return formatUrl(HOOK_ORGANIZATION_INFO_API, null, { organizationId, tokenId });
    }

    static HOOK_CONTACT_INFO_API(organizationId: string, tokenId: string): string {
        return formatUrl(HOOK_CONTACT_INFO_API, null, { organizationId, tokenId });
    }

    static HOOK_INVITE_INFO_API(organizationId: string, tokenId: string): string {
        return formatUrl(HOOK_ORG_INVITE_INFO_API, null, { organizationId, tokenId });
    }

    static HOOK_RELATED_TOKENMAPS_INFO_API(organizationId: string, tokenId: string): string {
        return formatUrl(HOOK_RELATED_TOKENMAPS_INFO_API, null, { organizationId, tokenId });
    }

    static HOOK_UPDATE_CONTACT_API(organizationId: string, tokenId: string): string {
        return formatUrl(HOOK_UPDATE_CONTACT_API, null, { organizationId, tokenId });
    }

    static HOOK_OPEN_DOCUMENT_API(organizationId: string, tokenId: string): string {
        return formatUrl(HOOK_OPEN_DOCUMENT_API, null, { organizationId, tokenId });
    }

    static HOOK_SIGN_DOCUMENT_API(organizationId: string, tokenId: string): string {
        return formatUrl(HOOK_SIGN_DOCUMENT_API, null, { organizationId, tokenId });
    }

    static HOOK_DISCOVERY_DOC_ITEM_API(organizationId: string, tokenId: string): string {
        return formatUrl(HOOK_DISCOVERY_DOC_ITEM_API, null, { organizationId, tokenId });
    }

    static HOOK_COMPLETE_DOC_DISCOVERY_API(organizationId: string, tokenId: string): string {
        return formatUrl(HOOK_COMPLETE_DOC_DISCOVERY_API, null, { organizationId, tokenId });
    }

    static HOOK_UPDATE_DOC_DISCOVERY_API(organizationId: string, tokenId: string): string {
        return formatUrl(HOOK_UPDATE_DOC_DISCOVERY_API, null, { organizationId, tokenId });
    }

    static HOOK_SIGN_CLEANUP_CONTACT_REGISTRATION_API(organizationId: string, tokenId: string): string {
        return formatUrl(HOOK_SIGN_CLEANUP_CONTACT_REGISTRATION_API, null, { organizationId, tokenId });
    }

    static HOOK_ACCEPT_ORG_INVITE_NEW_USER_API(organizationId: string, tokenId: string): string {
        return formatUrl(HOOK_ACCEPT_ORG_INVITE_NEW_USER_API, null, { organizationId, tokenId });
    }

    static HOOK_ACCEPT_ORG_INVITE_API(organizationId: string, tokenId: string): string {
        return formatUrl(HOOK_ACCEPT_ORG_INVITE_API, null, { organizationId, tokenId });
    }

    static HOOK_DECLINE_ORG_INVITE_API(organizationId: string, tokenId: string): string {
        return formatUrl(HOOK_DECLINE_ORG_INVITE_API, null, { organizationId, tokenId });
    }

    static get SUBMIT_APP_ISSUE_API() {
        return SUBMIT_APP_ISSUE_API;
    }
}
