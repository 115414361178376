import { ISimpleUser } from "../User/SimpleUserType";
import { ContactFileAttrs } from "./ContactFile";
import { TContactType } from "./Types";

export type ContactNoteAttrs = {
    _id: string;
    title: string;
    note: string;
    type: string;
    contact: string;
    contactType: TContactType;
    attachments: (ContactFileAttrs | string)[];
    createdBy: string | ISimpleUser;
    modifiedBy?: string | ISimpleUser;
    createdAt: Date;
    deletedAt?: Date;
    updatedAt: Date;
    isDeleted: boolean;
};

export class ContactNote {
    public readonly id: string;
    constructor(public attrs: ContactNoteAttrs) {
        this.id = attrs._id;
    }

    updateNote(noteAttrs: ContactNoteAttrs) {
        this.attrs = noteAttrs;
        return this;
    }

    static fromArray(files: ContactNoteAttrs[]) {
        return files.map((t) => new ContactNote(t));
    }
}
