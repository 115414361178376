import {
    IAnalyticsIndividualWorth,
    IAnalyticsDateRangeAggregate,
    IAnalyticsDateRangeStat,
    IAnalyticsEntityCounts,
    IAnalyticsMonthStat,
    IAnalyticsUpcomingIndividualBirthday,
    IAnalyticsUpcomingEvent,
    IAnalyticsUserEarning,
    IAnalyticsUserTransaction,
} from "../../models/Analytics/Types";
import { QueryResultCallback } from "../../models/Callback/Callback";
import { APIManager } from "../APIManager";

export class AnalyticsManager {
    async apiFetchIndividualsWorth(start: Date, end: Date, cb: QueryResultCallback<IAnalyticsIndividualWorth[]>) {
        try {
            const resp = await APIManager.get(APIManager.ANALYTICS.CONTACT_WORTH_API(start, end));
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiFetchDateRangeAggregation(start: Date, end: Date, cb: QueryResultCallback<IAnalyticsDateRangeAggregate>) {
        try {
            const resp = await APIManager.get(APIManager.ANALYTICS.DATE_RANGE_AGGREGATION_API(start, end));
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiFetchDateRangeRevenue(start: Date, end: Date, cb: QueryResultCallback<IAnalyticsDateRangeStat>) {
        try {
            const resp = await APIManager.get(APIManager.ANALYTICS.DATE_RANGE_REVENUE_API(start, end));
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiFetchEntityCounts(cb: QueryResultCallback<IAnalyticsEntityCounts>) {
        try {
            const resp = await APIManager.get(APIManager.ANALYTICS.ENTITY_COUNTS_API);
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiFetchMonthRevenue(date: Date, cb: QueryResultCallback<IAnalyticsMonthStat>) {
        try {
            const resp = await APIManager.get(APIManager.ANALYTICS.MONTH_REVENUE_API(date));
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiFetchUserTransactions(userId: string, start: Date, end: Date, cb: QueryResultCallback<IAnalyticsUserTransaction[]>) {
        try {
            const resp = await APIManager.get(APIManager.ANALYTICS.USER_TRANSACTIONS_API(userId, start, end));
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiFetchUpcomingBirthdays(maxDays: number, cb: QueryResultCallback<IAnalyticsUpcomingIndividualBirthday[]>) {
        try {
            const resp = await APIManager.get(APIManager.ANALYTICS.UPCOMING_BIRTHDAYS_API(maxDays));
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiFetchUpcomingEvents(maxDays: number, cb: QueryResultCallback<IAnalyticsUpcomingEvent[]>) {
        try {
            const resp = await APIManager.get(APIManager.ANALYTICS.UPCOMING_EVENTS_API(maxDays));
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiFetchUserEarnings(start: Date, end: Date, cb: QueryResultCallback<IAnalyticsUserEarning[]>) {
        try {
            const resp = await APIManager.get(APIManager.ANALYTICS.USER_EARNINGS_API(start, end));
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }
}
