import responsiveObserve from "antd/lib/_util/responsiveObserve";
import { QueryResultCallback } from "../../models/Callback/Callback";
import { Task } from "../../models/Task/Task";
import { TTaskAttr, TTaskCatg, TTaskListItemAttr } from "../../models/Task/Types";
import { TaskStoreActions } from "../../redux/services/task/actions";
import { APIManager } from "../APIManager";
import { TaskNetworkHelper } from "./TaskNetworkHelper";
import { AppManager } from "..";

export class TaskManager {
    networkHelper = TaskNetworkHelper;
    static newTaskObj(data: TTaskAttr) {
        return new Task(data);
    }

    getTask(taskId: string): [Task, TTaskCatg] | undefined {
        const tasksMap = TaskStoreActions.getTasks();
        for (const categ of Object.keys(tasksMap)) {
            const tasks = tasksMap[categ as TTaskCatg];
            if (Array.isArray(tasks)) {
                const found: Task | undefined = tasks?.find((t) => t.id === taskId);
                if (found) return [found, categ as TTaskCatg];
            }
        }
        return undefined;
    }

    getMyTasks() {
        const taskMap = TaskStoreActions.getTasks();
        return taskMap.myTasks as Record<string, any>;
    }

    getMyTaskTemplates() {
        const taskMap = TaskStoreActions.getTemplates();
        return taskMap.templates;
    }

    detectTaskCategory(task: Task): TTaskCatg {
        return TaskStoreActions.detectCateg(task.data.attrs);
    }

    async apiFetchMyTasks(cb: QueryResultCallback<Task[]>) {
        try {
            TaskStoreActions.loadingTasks("myTasks");
            const resp = await APIManager.get(APIManager.TASKS.TASKS_My_API());
            TaskStoreActions.saveTasks({ myTasks: resp.data });
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        } finally {
            TaskStoreActions.loadingTasks(null);
        }
    }

    async apiFetchAllTasks(taskCatg: TTaskCatg, cb: QueryResultCallback<Task[]>) {
        try {
            TaskStoreActions.loadingTasks(taskCatg);
            const resp = await APIManager.get(APIManager.TASKS.TASKS_API(taskCatg));
            const tasks: Task[] = resp.data.map((t: TTaskAttr) => this.updateTaskAttrOrAddNew(t, false));
            TaskStoreActions.saveTasks({ [taskCatg]: tasks });
            TaskStoreActions.setTasksLoaded(taskCatg);
            cb(null, tasks);
        } catch (err) {
            cb(`${err}`);
        } finally {
            TaskStoreActions.loadingTasks(null);
        }
    }

    async apiFetchTaskAttrs(taskId: string, cb: QueryResultCallback<Task>) {
        try {
            const resp = await APIManager.get(APIManager.TASKS.TASK_SINGLE_API(taskId));
            const task = this.updateTaskAttrOrAddNew(resp.data);
            cb(null, task);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiDeleteTask(taskId: string, cb: QueryResultCallback<Task>) {
        try {
            const resp = await APIManager.delete(APIManager.TASKS.TASK_SINGLE_API(taskId));
            const task = this.getTask(taskId);
            if (task) {
                task[0].markDeleted();
                TaskStoreActions.removeTask(task[0]);
            }
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiAddTask(
        taskData: Partial<Omit<TTaskAttr, "dueDate" | "tasklist"> & { dueDate?: string | undefined; tasklist: Partial<TTaskListItemAttr>[] }>,
        cb: QueryResultCallback<Task>,
    ) {
        try {
            const resp = await APIManager.post(APIManager.TASKS.TASKS_API(), taskData);
            const task: Task = TaskManager.newTaskObj(resp.data);
            TaskStoreActions.addOrUpdateToTasks(task);
            cb(null, task);
        } catch (err) {
            cb(`${err}`);
        }
    }
    async apiFetchAllTaskTemplates(taskCatg: TTaskCatg, cb: QueryResultCallback<Task>) {
        try {
            TaskStoreActions.loadingTasks("templates");
            const resp = await APIManager.get(APIManager.TASKS.TASKS_TEMPLATES_API());

            TaskStoreActions.saveTasks({ [taskCatg]: resp.data });
            cb(null, resp?.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiAddTaskTemplate(
        taskData: Partial<Omit<TTaskAttr, "dueDate" | "tasklist"> & { dueDate?: string | undefined; tasklist: Partial<TTaskListItemAttr>[] }>,
        cb: QueryResultCallback<Task>,
    ) {
        try {
            const resp = await APIManager.post(APIManager.TASKS.TASK_SINGLE_TEMPLATE_API(), taskData);

            const taskTemplates = AppManager.task.getMyTaskTemplates();
            TaskStoreActions.saveTasks({ templates: [...taskTemplates, resp.data] });

            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiUpdateTask(
        taskId: string,
        taskData: Partial<Omit<TTaskAttr, "dueDate" | "tasklist"> & { dueDate?: string | undefined; tasklist: Partial<TTaskListItemAttr>[] }>,
        cb: QueryResultCallback<Task>,
    ) {
        try {
            const resp = await APIManager.put(APIManager.TASKS.TASK_SINGLE_API(taskId), taskData);
            const task = this.updateTaskAttrOrAddNew(resp.data);
            cb(null, task);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiMarkTaskCompleted(taskId: string, taskStatus: string, cb: QueryResultCallback<Task>) {
        try {
            const resp = await APIManager.patch(APIManager.TASKS.TASK_COMPLETED_API(taskId), { status: taskStatus });
            const task = this.updateTaskAttrOrAddNew(resp.data);
            cb(null, task);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiMarkTaskItemCompleted(taskId: string, subTaskId: string, cb: QueryResultCallback<Task>) {
        try {
            const resp = await APIManager.patch(APIManager.TASKS.TASK_SUBITEM_COMPLETED_API(taskId, subTaskId));
            const task = this.updateTaskAttrOrAddNew(resp.data);
            cb(null, task);
        } catch (err) {
            cb(`${err}`);
        }
    }

    updateTaskAttrOrAddNew(taskAttr: TTaskAttr, saveToStore = true) {
        const taskTpl = this.getTask(taskAttr._id);
        if (taskTpl) {
            const wasMyAssignedTask = TaskStoreActions.detectCateg(taskTpl[0].data.attrs) === "assigned";
            taskTpl[0].updateTaskAttrs(taskAttr);
            if (saveToStore && (!taskTpl[0].data.attrs.projectRef || wasMyAssignedTask)) TaskStoreActions.addOrUpdateToTasks(taskTpl[0], taskTpl[1]);
            return taskTpl[0];
        } else {
            const task = TaskManager.newTaskObj(taskAttr);
            const isMyAssignedTask = TaskStoreActions.detectCateg(taskAttr) === "assigned";
            if (saveToStore && (!task.data.attrs.projectRef || isMyAssignedTask)) TaskStoreActions.addOrUpdateToTasks(task);
            return task;
        }
    }

    updateTemplateAttrOrAddNew(templateAttr: any, saveToStore = true) {
        const template = TaskManager.newTaskObj(templateAttr);
        if (saveToStore) TaskStoreActions.addOrUpdateToTaskTemplates(template);
        return template;
    }
    removeTask(taskId: string) {
        const taskTpl = this.getTask(taskId);
        if (taskTpl) {
            taskTpl[0].remove();
            TaskStoreActions.removeTask(taskTpl[0]);
        }
    }
}
