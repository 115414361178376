/* eslint-disable @typescript-eslint/ban-types */
import React, { createContext, useState, useContext, MouseEventHandler, useRef, useEffect } from "react";
import { AuthCodeMSALBrowserAuthenticationProvider } from "@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser";
import { InteractionType, PublicClientApplication } from "@azure/msal-browser";
import { Client, PageCollection, PageIterator } from "@microsoft/microsoft-graph-client";
import { useMsal } from "@azure/msal-react";
import config from "./config";
import { IScheduleAttrs } from "../../../models/Schedule/Schedule";
import { Event } from "microsoft-graph";
import moment from "moment";

type CalendarUser = {
    displayName?: string;
    email?: string;
    avatar?: string;
};

export interface OutlookCalError {
    message: string;
    debug?: string;
}

type OutlookCalendarContext = {
    user?: CalendarUser;
    error?: OutlookCalError;
    initializing?: boolean;
    loadingCalendar?: boolean;
    loadedEvents?: IScheduleAttrs[];
    signIn?: MouseEventHandler<HTMLElementEventMap>;
    signOut?: MouseEventHandler<HTMLElementEventMap>;
    setLoadingCalendar?: (state: boolean) => void;
    loadEvents?: (start: Date, end: Date) => IScheduleAttrs[];
    getGraphClient?: () => Client;
    displayError?: Function;
    clearError?: Function;
    authProvider?: AuthCodeMSALBrowserAuthenticationProvider;
};

const outlookCalContext = createContext<OutlookCalendarContext>({
    user: undefined,
    error: undefined,
    signIn: undefined,
    initializing: false,
    signOut: undefined,
    loadedEvents: [],
    setLoadingCalendar: undefined,
    loadEvents: undefined,
    displayError: undefined,
    clearError: undefined,
    authProvider: undefined,
});

export function useOutlookCalContext(): OutlookCalendarContext {
    return useContext(outlookCalContext);
}

interface ProviderCalContextProps {
    children: React.ReactNode;
}

let graphClient: Client | undefined;
const getGraphClient = (authProvider: AuthCodeMSALBrowserAuthenticationProvider) => {
    if (!graphClient) {
        graphClient = Client.initWithMiddleware({
            authProvider,
        });
    }

    return graphClient;
};

export default function OutlookCalProvider({ children }: ProviderCalContextProps) {
    const auth = useOutlookCalContext();

    return <outlookCalContext.Provider value={auth}>{children}</outlookCalContext.Provider>;
}

export async function getUser(authProvider: AuthCodeMSALBrowserAuthenticationProvider): Promise<CalendarUser> {
    const user: CalendarUser = await getGraphClient(authProvider).api("/me").select("displayName,userPrincipalName").get();

    return user;
}

const knownEventIDs: { [k: string]: string } = {};
export function useProvideOutlookCalContext() {
    const [error, setError] = useState<OutlookCalError>();
    const [user, setUser] = useState<CalendarUser>();
    const [initializing, setInitializing] = useState<boolean>();
    const [loadingCalendar, setLoadingCalendar] = useState<boolean>();
    const [loadedEvents, setLoadedEvents] = useState<IScheduleAttrs[]>([]);
    const msal = useMsal();

    const displayError = (msg: string, debug?: string) => {
        setError({ message: msg, debug });
    };

    const clearError = () => {
        setError(undefined);
    };

    const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(msal.instance as PublicClientApplication, {
        account: msal.instance.getActiveAccount()!,
        scopes: config.scopes,
        interactionType: InteractionType.Popup,
    });

    const signIn = async () => {
        console.log("Signing in. Origin: ", window.origin);
        const result = await msal.instance.loginPopup({
            scopes: config.scopes,
            prompt: "select_account",
            redirectUri: window.origin,
        });

        if (result.accessToken) {
            setInitializing(true);
            const user = await getUser(authProvider);
            setInitializing(false);
            setUser({
                displayName: user.displayName,
            });
        }
    };

    const loadEvents = async (start: Date, end: Date) => {
        try {
            setLoadingCalendar(true);
            const resp: PageCollection = await getGraphClient(authProvider)
                .api("/me/calendarview")
                .query({ startDateTime: start.toISOString(), endDateTime: end.toISOString() })
                .select("id,subject,body,start,end")
                .orderby("start/dateTime")
                .top(5000)
                .get();
            const events: Event[] = [];
            if (resp["@odata.nextLink"]) {
                const pageIterator = new PageIterator(getGraphClient(authProvider), resp, (event) => {
                    events.push(event);
                    return true;
                });
                await pageIterator.iterate();
                events.map((t) => t.end?.dateTime);
            } else {
                events.push(...resp.value);
            }
            const items: IScheduleAttrs[] = events.map((t) => {
                knownEventIDs[t.id || ""] = knownEventIDs[t.id || ""] || `${Date.now()}${`${Math.random()}`.substring(2)}`;
                return {
                    _id: knownEventIDs[t.id || ""],
                    startDate: t.start?.dateTime || "",
                    endDate: t.end?.dateTime || "",
                    title: t.subject || "",
                    description: t.body?.content || "",
                    type: "o365",
                    isAllDay: moment(t.end?.dateTime).diff(t.start?.dateTime, "hours") < 24,
                };
            });
            console.log("LOADED EVENTS ", events, " RANGE ", start, " AND ", end);
            setLoadedEvents(items);
        } catch (err) {
            console.log("SOMETHING WENT WRONG WHILE LOADING EVENTS ", err);
            setError({ message: `${err}` });
        } finally {
            setLoadingCalendar(false);
        }

        return [];
    };

    const signOut = async () => {
        await msal.instance.logoutPopup();
        setUser(undefined);
    };

    useEffect(() => {
        const checkUser = async () => {
            if (!user) {
                try {
                    const account = msal.instance.getActiveAccount();
                    setInitializing(true);
                    if (account) {
                        const user = await getUser(authProvider);
                        setUser({ displayName: user.displayName });
                    }
                } catch (err) {
                    console.log("ERROR ", err);
                    setError({ message: `${err}` });
                } finally {
                    setInitializing(false);
                }
            }
        };
        checkUser();
    }, []);

    return {
        user,
        error,
        initializing,
        loadingCalendar,
        loadedEvents,
        signIn,
        signOut,
        displayError,
        clearError,
        getGraphClient,
        setLoadingCalendar,
        loadEvents,
        authProvider,
    };
}
