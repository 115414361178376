import { Document } from "../../../models/Document/Document";
import { DocumentTemplate } from "../../../models/Document/DocumentTemplate";
import createReducer from "../../utils/createReducer";
import ActionTypes from "./actionTypes";

export type DocumentStateT = {
    templates: DocumentTemplate[];
    loadingTemplates: boolean;
    templatesLoaded: boolean;

    documents: Document[];
    loadingDocuments: boolean;
    documentsLoaded: boolean;
};

export const initialState: DocumentStateT = {
    templates: [],
    loadingTemplates: false,
    templatesLoaded: false,
    documents: [],
    loadingDocuments: false,
    documentsLoaded: false,
};

// App Reducer
export default createReducer<DocumentStateT>(initialState, {
    [ActionTypes.SAVE_DOCUMENT_TEMPLATES]: (state, payload: DocumentTemplate[]) => ({
        ...state,
        templates: payload,
    }),
    [ActionTypes.LOADED_DOCUMENT_TEMPLATES]: (state, payload: boolean) => ({
        ...state,
        templatesLoaded: payload,
    }),
    [ActionTypes.LOADING_DOCUMENT_TEMPLATES]: (state, payload: boolean) => ({
        ...state,
        loadingTemplates: payload,
    }),
    [ActionTypes.REMOVE_DOCUMENT_TEMPLATE]: (state, payload: DocumentTemplate) => ({
        ...state,
        templates: state.templates.filter((t) => t.id !== payload.id),
    }),

    [ActionTypes.SAVE_DOCUMENTS]: (state, payload: Document[]) => ({
        ...state,
        documents: payload,
    }),
    [ActionTypes.LOADED_DOCUMENTS]: (state, payload: boolean) => ({
        ...state,
        documentsLoaded: payload,
    }),
    [ActionTypes.LOADING_DOCUMENTS]: (state, payload: boolean) => ({
        ...state,
        loadingDocuments: payload,
    }),
    [ActionTypes.REMOVE_DOCUMENT]: (state, payload: Document) => ({
        ...state,
        documents: state.documents.filter((t) => t.id !== payload.id),
    }),

    [ActionTypes.LOGOUT_ACTION]: (state) => ({
        ...state,
        ...initialState,
    }),
});
