import { Path as PathParser } from "path-parser";
import { DOCUMENTS_ROUTE, SINGLE_TEMPLATE_ROUTE } from "../../constants/appRoutes";
import { AppManager } from "..";
import { IOEventTypes } from "../RealtimeManager";

export class DocumentNetworkHelper {
    static networkDataReceived = <K extends keyof IOEventTypes>(evt: K, resp: any): void => {
        switch (evt) {
            //Document events
            case "DOCUMENT_UPDATED":
            case "DOCUMENT_CREATED":
            case "DOCUMENT_SIGN_REQUESTED":
                AppManager.document.main.updateDocumentAttrOrAddNew((resp as IOEventTypes["DOCUMENT_CREATED"]).data);
                break;
            case "DOCUMENT_DELETED":
                const docData = (resp as IOEventTypes["DOCUMENT_DELETED"]).data;
                AppManager.document.main.removeDocument(docData._id);
                break;

            // document template events
            case "DOCUMENT_TEMPLATE_CREATED":
            case "DOCUMENT_TEMPLATE_UPDATED":
                AppManager.document.template.updateTemplateAttrOrAddNew((resp as IOEventTypes["DOCUMENT_TEMPLATE_CREATED"]).data);
                break;
            case "DOCUMENT_TEMPLATE_DELETED":
                const tmpData = (resp as IOEventTypes["DOCUMENT_TEMPLATE_CREATED"]).data;
                AppManager.document.template.removeTemplate(tmpData._id);
                break;
        }
    };

    static onConnectionRestoredSync(currentRoute: string, currentPathName: string) {
        switch (currentRoute) {
            case DOCUMENTS_ROUTE:
                AppManager.document.main.apiFetchAllDocuments(() => {});
                AppManager.document.template.apiFetchAllTemplates(() => {});
                break;
            case SINGLE_TEMPLATE_ROUTE:
                const match = new PathParser(currentRoute).test(currentPathName);
                if (match) AppManager.document.template.apiFetchTemplateAttrs(match.templateId, () => {});
                else console.error("Route paths do not match. State of this page could not be syncronized");
                break;
        }
    }
}
