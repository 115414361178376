import { ISimpleUser } from "../User/SimpleUserType";

export type ContactServiceAttrs = {
    _id: string;
    name: string;
    owner: ISimpleUser;
    contact: string;
    contactType: "individual" | "company" | "trust";
    serviceDate: Date;
    serviceId: string;
    systemGenerated: boolean;
};

export class ContactService {
    public readonly id: string;
    constructor(public attrs: ContactServiceAttrs) {
        this.id = attrs._id;
    }

    updateService(srvAttrs: ContactServiceAttrs) {
        this.attrs = srvAttrs;
        return this;
    }

    static fromArray(files: ContactServiceAttrs[]) {
        return files.map((t) => new ContactService(t));
    }
}
