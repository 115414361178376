import React, { useEffect, useState } from "react";
import Layout from "antd/lib/layout";
import classNames from "classnames";
import CollapseIcon from "@ant-design/icons/PauseOutlined";
import ExpandIcon from "@ant-design/icons/ArrowRightOutlined";
import Styles from "./MainLayout.scss";
import AppHeader from "../../containers/AppHeader/Header";
import { AppSidebar } from "../../containers/AppSidebar/Sidebar";
import { Alert } from "antd";

const { Sider, Header: AntHeader, Content: AntContent } = Layout;

type MainLayoutProps = {
    Sidebar?: React.ComponentType<{ isCollapsed?: boolean; dummySidebar?: boolean }>;
    Header?: React.ReactNode;
    Content?: React.ReactNode;
    sidebarCollapsed?: boolean;
    hideSidebar?: boolean;
    hideToggle?: boolean;
    dummySidebar?: boolean;
};

const MainLayout: React.FC<MainLayoutProps> = ({
    Sidebar = (props) => <AppSidebar isCollapsed={false} {...props} />,
    Header = <AppHeader />,
    Content,
    hideSidebar,
    dummySidebar,
    hideToggle,
    sidebarCollapsed: defaultCollapsed,
    children,
}) => {
    const [sidebarCollapsed, setSidebarCollapsed] = useState(
        defaultCollapsed !== undefined ? defaultCollapsed : localStorage.getItem("SIDEBAR_STATE") === "collapsed",
    );
    const handleToggleCollapseSidebar = () => {
        setSidebarCollapsed((state) => {
            localStorage.setItem("SIDEBAR_STATE", !state ? "collapsed" : "expanded");
            return !state;
        });
    };
    useEffect(() => {
        console.log("GETTING A RERENDER HERE");
    }, []);
    const isDeployDemo = process.env.DEPLOY_TARGET === "demo";
    return (
        <Layout className={Styles.Container}>
            {!localStorage.getItem("DEMO_NOTIFICATION_SEEN") && isDeployDemo && (
                <Alert
                    showIcon
                    type="warning"
                    closable
                    onClose={() => localStorage.setItem("DEMO_NOTIFICATION_SEEN", "true")}
                    message="You are currently using a demo version of Ausplan. To have exclusive access to all features, please upgrade your plan"
                />
            )}
            <AntHeader className={Styles.HeaderContainer}>{Header}</AntHeader>
            <Layout className="site-layout">
                {!hideSidebar && (
                    <Sider trigger={null} collapsible collapsed={sidebarCollapsed} className={Styles.SidebarContainer}>
                        <Sidebar isCollapsed={sidebarCollapsed} dummySidebar={dummySidebar} />
                        {!hideToggle && (
                            <div
                                className={classNames([
                                    Styles.CollapseButton,
                                    {
                                        [Styles.Collapsed]: sidebarCollapsed,
                                        [Styles.Expanded]: !sidebarCollapsed,
                                    },
                                ])}
                                onClick={handleToggleCollapseSidebar}
                            >
                                {sidebarCollapsed ? <ExpandIcon /> : <CollapseIcon rotate={90} />}
                            </div>
                        )}
                    </Sider>
                )}
                <AntContent className={Styles.ContentContainer}>{children || Content}</AntContent>
            </Layout>
        </Layout>
    );
};

export default MainLayout;
