import { SERVICES_FEES_MASTERLIST_ROUTE, SERVICES_FEES_NEW_RECORD_ROUTE } from "../../constants/appRoutes";
import { AppManager } from "..";
import { IOEventTypes } from "../RealtimeManager";

export class ServiceFeeNetworkHelper {
    static networkDataReceived = <K extends keyof IOEventTypes>(evt: K, resp: any): void => {
        switch (evt) {
            // Masterlist events
            case "MASTERLIST_RECORDS_CREATED":
                {
                    const records = (resp as IOEventTypes["MASTERLIST_RECORDS_CREATED"]).data;
                    AppManager.servicefee.masterlist.updateOrAddRecords(records, true);
                }
                break;
            case "MASTERLIST_RECORD_UPDATED":
                {
                    const record = (resp as IOEventTypes["MASTERLIST_RECORD_UPDATED"]).data;
                    AppManager.servicefee.masterlist.updateOrAddRecords([record]);
                }
                break;
            case "MASTERLIST_RECORD_DELETED":
                AppManager.servicefee.masterlist.removeRecord((resp as IOEventTypes["MASTERLIST_RECORD_DELETED"]).data);
                break;

            // Provider events
            case "SERVICE_FEE_PROVIDER_CREATED":
            case "SERVICE_FEE_PROVIDER_UPDATED":
                AppManager.servicefee.provider.updateProviderAttrOrAddNew((resp as IOEventTypes["SERVICE_FEE_PROVIDER_CREATED"]).data);
                break;
            case "SERVICE_FEE_PROVIDER_DELETED":
                const prvData = (resp as IOEventTypes["SERVICE_FEE_PROVIDER_DELETED"]).data;
                AppManager.servicefee.provider.removeProvider(prvData._id);
                break;
        }
    };

    static onConnectionRestoredSync(currentRoute: string, currentPathName: string) {
        switch (currentRoute) {
            case SERVICES_FEES_MASTERLIST_ROUTE:
                AppManager.servicefee.masterlist.apiFetchAllRecords(() => {});
                AppManager.servicefee.provider.apiFetchAllProviders(() => {});
                break;
            case SERVICES_FEES_NEW_RECORD_ROUTE:
                AppManager.servicefee.provider.apiFetchAllProviders(() => {});
                AppManager.servicefee.provider.apiFetchAllProviderAdviserRefs(() => {});
                break;
        }
    }
}
