import { QueryResultCallback } from "../../models/Callback/Callback";
import { ProjectTemplate } from "../../models/Task/ProjectTemplate";
import { TProjectTemplateAttr } from "../../models/Task/Types";
import { ProjectTemplateStoreActions } from "../../redux/services/project/actions";
import { APIManager } from "../APIManager";
import { ProjectTemplateNetworkHelper } from "./ProjectTemplateNetworkHelper";

export class ProjectTemplateManager {
    networkHelper = ProjectTemplateNetworkHelper;
    static newTemplateObj(data: TProjectTemplateAttr) {
        return new ProjectTemplate(data);
    }

    getTemplate(templateId: string) {
        return ProjectTemplateStoreActions.getTemplates().find((t) => t.id === templateId);
    }

    async apiFetchAllTemplates(cb: QueryResultCallback<ProjectTemplate[]>) {
        try {
            ProjectTemplateStoreActions.loadingTemplates(true);
            const resp = await APIManager.get(APIManager.TASKS.PROJECT_TEMPLATES_API);
            const templates: ProjectTemplate[] = resp.data.map((t: TProjectTemplateAttr) => this.updateTemplateAttrOrAddNew(t, false));
            ProjectTemplateStoreActions.saveTemplates(templates);
            ProjectTemplateStoreActions.setTemplatesLoaded(true);
            cb(null, templates);
        } catch (err) {
            cb(`${err}`);
        } finally {
            ProjectTemplateStoreActions.loadingTemplates(false);
        }
    }

    async apiFetchTemplateAttrs(templateId: string, cb: QueryResultCallback<ProjectTemplate>) {
        try {
            const resp = await APIManager.get(APIManager.TASKS.PROJECT_TEMPLATES_SINGLE_API(templateId));
            const template: ProjectTemplate = this.updateTemplateAttrOrAddNew(resp.data, false);
            ProjectTemplateStoreActions.addOrUpdateToTemplates(template);
            cb(null, template);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiDeleteTemplate(templateId: string, cb: QueryResultCallback<ProjectTemplate>) {
        try {
            const resp = await APIManager.delete(APIManager.TASKS.PROJECT_TEMPLATES_SINGLE_API(templateId));
            const template = this.getTemplate(templateId);
            if (template) {
                template.markDeleted();
                ProjectTemplateStoreActions.removeTemplate(template);
            }
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiAddTemplate(templateData: Partial<TProjectTemplateAttr>, cb: QueryResultCallback<ProjectTemplate>) {
        try {
            const resp = await APIManager.post(APIManager.TASKS.PROJECT_TEMPLATES_API, templateData);
            const template: ProjectTemplate = ProjectTemplateManager.newTemplateObj(resp.data);
            ProjectTemplateStoreActions.addOrUpdateToTemplates(template);
            cb(null, template);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiUpdateTemplate(templateId: string, templateData: Partial<TProjectTemplateAttr>, cb: QueryResultCallback<ProjectTemplate>) {
        try {
            const resp = await APIManager.put(APIManager.TASKS.PROJECT_TEMPLATES_SINGLE_API(templateId), templateData);
            const template = this.updateTemplateAttrOrAddNew(resp.data);
            cb(null, template);
        } catch (err) {
            cb(`${err}`);
        }
    }

    updateTemplateAttrOrAddNew(templateAttr: TProjectTemplateAttr, saveToStore = true) {
        const template = this.getTemplate(templateAttr._id);
        if (template) {
            template.updateTemplateAttrs(templateAttr);
            if (saveToStore) ProjectTemplateStoreActions.addOrUpdateToTemplates(template);
            return template;
        } else {
            const template = ProjectTemplateManager.newTemplateObj(templateAttr);
            if (saveToStore) ProjectTemplateStoreActions.addOrUpdateToTemplates(template);
            return template;
        }
    }

    removeTemplate(templateId: string) {
        const template = this.getTemplate(templateId);
        if (template) {
            template.remove();
            ProjectTemplateStoreActions.removeTemplate(template);
        }
    }
}
