import moment from "moment";
import { Watchable } from "../_Utils/Watchable";
import { TDocTemplateFieldAttr, TDocumentAttr } from "./Types";

export type DocumentEventMap = {
    ATTRIBUTES_UPDATED: TDocumentAttr;
    FIELDS_UPDATED: TDocTemplateFieldAttr[];
    DOCUMENT_DELETED: true;
};

class DocumentData extends Watchable<DocumentEventMap> {
    constructor(attrs: TDocumentAttr) {
        super();
        this.cAttrs = attrs;
        this.attrs = attrs;
    }

    private cFields: TDocTemplateFieldAttr[] = [];
    private cAttrs: TDocumentAttr;

    triggerDocumentDeleted() {
        this.trigger("DOCUMENT_DELETED", true);
    }

    set fields(fields: TDocTemplateFieldAttr[]) {
        this.cFields = fields;

        this.trigger("FIELDS_UPDATED", fields);
    }

    get fields() {
        return this.cFields;
    }

    set attrs(atr: TDocumentAttr) {
        this.cAttrs = atr;
        this.cAttrs.createdAt = moment(this.cAttrs.createdAt);
        this.cAttrs.updatedAt = moment(this.cAttrs.updatedAt);

        this.trigger("ATTRIBUTES_UPDATED", atr);

        if (atr.fields) {
            this.fields = atr.fields;
        }
    }

    get attrs() {
        return this.cAttrs;
    }
}

export class Document {
    public data: DocumentData;
    public readonly id: string;

    constructor(attributes: TDocumentAttr) {
        this.data = new DocumentData(attributes);
        this.id = attributes._id;
    }

    updateFields(files: TDocTemplateFieldAttr[]) {
        this.data.fields = files;
        return this;
    }

    markDeleted() {
        this.data.triggerDocumentDeleted();
    }

    updateDocument(document: Document) {
        this.updateDocumentAttrs(document.data.attrs);
        this.data.fields = document.data.fields;
        return this;
    }

    updateDocumentAttrs(attrs: TDocumentAttr) {
        this.data.attrs = attrs;
        return this;
    }

    addOrUpdateField(fieldAttrs: TDocTemplateFieldAttr) {
        const fieldId = fieldAttrs._id;
        if (this.data.fields.find((t) => t._id === fieldId)) {
            this.data.fields = this.data.fields.map((t) => {
                if (t._id === fieldId) return fieldAttrs;
                return t;
            });
        } else {
            this.data.fields = [fieldAttrs, ...this.data.fields];
        }
        return this;
    }

    remove() {
        this.data.triggerDocumentDeleted();
    }

    static fromArray(contacts: TDocumentAttr[]) {
        return contacts.map((t) => new Document(t));
    }
}
