import React, { useState } from "react";
import { Card, Input, Form, Button, Spin } from "antd";
import Styles from "./DealerGroupSetup.scss";
import UserIcon from "@ant-design/icons/UserOutlined";
import MailIcon from "@ant-design/icons/MailOutlined";
import ArrowRightIcon from "@ant-design/icons/ArrowRightOutlined";
import { AppManager } from "../../../../../manager";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/reducers";
import { Organization } from "../../../../../models/Organization/Organization";

const DealerGroupSetup: React.FC<{ onNext: () => void }> = ({ onNext }) => {
    const activeOrg = useSelector<RootState, Organization>((state) => state.orgs.activeOrg!);
    const [sending, setSending] = useState(false);
    const [preparingNext, setPreparingNext] = useState(false);
    const [skipping, setSkipping] = useState(false);

    const handleSendRequest = (data: any) => {
        setSending(true);
        AppManager.org.apiSendDealerGroupRequest(activeOrg.id, data, (err) => {
            setSending(false);
            if (err) AppManager.alert.toastError(`${err}`);
            else {
                setPreparingNext(true);
                AppManager.alert.toastSuccess("A request has been successfully sent to the dealer group");
                AppManager.org.apiAddCompletedInitialSetupItem(activeOrg.id, "dealer_group", (err) => {
                    setPreparingNext(false);
                    if (err) AppManager.alert.error("Error", `${err}`);
                    else onNext();
                });
            }
        });
    };

    const handleSkip = () => {
        AppManager.alert.confirmAction(
            "Confirm Action",
            "Skip dealer group setup? You can always do this from your settings page",
            (res) => {
                if (res === "OK") {
                    setSkipping(true);
                    AppManager.org.apiAddCompletedInitialSetupItem(activeOrg.id, "dealer_group", (err) => {
                        setSkipping(false);
                        if (err) AppManager.alert.error("Error", `${err}`);
                        else onNext();
                    });
                }
            },
            { okText: "YES, SKIP" },
        );
    };

    return (
        <Card className="match-parent animate__animated animate__zoomIn animate__faster">
            <div className={Styles.Container}>
                <div className={Styles.Header}>Add A Dealer Group</div>
                <div className={Styles.Description}>some description</div>
                <Form layout="vertical" onFinish={handleSendRequest} name="new-dealergroup-request-form-w">
                    <Form.Item label="Name" name="name">
                        <Input prefix={<UserIcon />} placeholder="Enter Dealer Group name..." />
                    </Form.Item>
                    <Form.Item label="Email" name="email" rules={[{ required: true, type: "email" }]}>
                        <Input prefix={<MailIcon />} placeholder="Enter Dealer Group email..." />
                    </Form.Item>
                </Form>
                <div className={Styles.Actions}>
                    <Button
                        size="large"
                        type="primary"
                        htmlType="submit"
                        form="new-dealergroup-request-form-w"
                        disabled={sending || preparingNext || skipping}
                        loading={sending || preparingNext}
                    >
                        Save & Next <ArrowRightIcon />
                    </Button>
                    <Button
                        onClick={handleSkip}
                        style={{ margin: 10 }}
                        type="link"
                        danger
                        disabled={sending || preparingNext || skipping}
                        loading={skipping}
                    >
                        Skip this setup
                    </Button>
                </div>
            </div>
        </Card>
    );
};

export default DealerGroupSetup;
