enum ActionTypes {
    SAVE_CURRENT_PLAN = "SAVE_CURRENT_PLAN",
    LOADING_CURRENT_PLAN = "LOADING_CURRENT_PLAN",

    SAVE_CUSTOMER_INFO = "SAVE_CUSTOMER_INFO",
    LOADING_CUSTOMER_INFO = "LOADING_CUSTOMER_INFO",

    SAVE_PAYMENT_METHODS = "SAVE_PAYMENT_METHODS",
    LOADING_PAYMENT_METHODS = "LOADING_PAYMENT_METHODS",

    SAVE_BILLING_HISTORY = "SAVE_BILLING_HISTORY",
    LOADING_BILLING_HISTORY = "LOADING_BILLING_HISTORY",

    LOGOUT_ACTION = "LOGOUT_ACTION",
}

export default ActionTypes;
