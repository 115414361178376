/**
 * Sets all the screens of the app under their respective routes.
 */

/** */
import React, { useEffect } from "react";
import { Router, Route, Routes, useNavigate } from "react-router-dom";
import Loadable from "react-loadable";
import ProtectedScreenHome from "../pages/Protected";
import {
    COMPLETE_DEFAULT_SIGNUP_ROUTE,
    CONTACT_REGISTER_ROUTE,
    DOCUMENT_SIGN_ROUTE,
    USER_ORG_INVITE_ROUTE,
    HOME_ROUTE,
    LOGOUT_ROUTE,
    SIGNUP_ROUTE,
    COMPLETE_DEALER_GROUP_SIGNUP_ROUTE,
    APP_AUTH_INIT_ROUTE,
    INTERNAL_LOGIN_ROUTE,
} from "../constants/appRoutes";
import RouteComponentHOC from "./routeHelpers/RouteComponent";
import LoginPage from "../pages/Authentication/Login/Login";
import RequiresAuth from "./routeHelpers/RequiresAuth";
import RequiresNoAuth from "./routeHelpers/RequiresNoAuth";
import LogoutPage from "../pages/Authentication/Logout/Logout";
import SignupPage from "../pages/Authentication/Signup/Signup";
import CompleteDefaultSignup from "../pages/Authentication/CompleteSignup/CompleteDefaultSignup";
import CheckAuth from "../pages/Authentication/CheckAuth/CheckAuth";
import { AppStoreActions } from "../redux/services/app/actions";
// import ContactFormCompletePage from "../pages/ExternalPages/ContactFormCompletePage/ContactFormCompletePage";
// import ContactDocumentSignPage from "../pages/ExternalPages/ContactDocumentSignPage/ContactDocumentSignPage";
import Loading from "../components/Loading/Loading";
import BasicHeaderLayout from "../layouts/BasicHeaderLayout/BasicHeaderLayout";
import Page404 from "../pages/ExtraPages/Page404";
import CompleteDealerGroupSignup from "../pages/Authentication/CompleteSignup/CompleteDealerGroupSignup";
import { APP_ENV } from "../constants/appVars";
import AppInit from "../pages/Authentication/AppInit/AppInit";
import { BrowserHistory, createHashHistory } from "history";

const ContactFormCompletePage = Loadable({
    loader: () => import("../pages/ExternalPages/ContactFormCompletePage/ContactFormCompletePage"),
    loading: () => <BasicHeaderLayout content={<Loading text="Initializing" />} title="" />,
});

const ContactDocumentSignPage = Loadable({
    loader: () => import("../pages/ExternalPages/ContactDocumentSignPage/ContactDocumentSignPage"),
    loading: () => <BasicHeaderLayout content={<Loading text="Initializing" />} title="" />,
});

const OrgInvitationPage = Loadable({
    loader: () => import("../pages/ExternalPages/OrgInvitePage"),
    loading: () => <BasicHeaderLayout content={<Loading text="Initializing" />} title="" />,
});

const history = createHashHistory();

type RouterProps = {
    basename?: string;
    children: React.ReactNode;
    history: BrowserHistory;
};

const AppRouter = ({ basename, children, history }: RouterProps) => {
    const [state, setState] = React.useState({
        action: history.action,
        location: history.location,
    });

    React.useLayoutEffect(() => history.listen(setState), [history]);

    return <Router basename={basename} children={children} location={state.location} navigationType={state.action} navigator={history} />;
};

const AuthHomeScreen = RequiresAuth(ProtectedScreenHome);
const NoAuthLoginScreen = RequiresNoAuth(LoginPage);

const GlobalHistoryProvider = () => {
    useEffect(() => {
        AppStoreActions.saveRouterHistory(history);
    }, []);
    return null;
};

const AppRoutes = () => {
    return (
        <AppRouter history={history}>
            <GlobalHistoryProvider />
            {APP_ENV.BUILD_TARGET === "x-sign-pages" ? (
                <Routes>
                    <Route path={CONTACT_REGISTER_ROUTE} element={<ContactFormCompletePage />} />
                    <Route path={DOCUMENT_SIGN_ROUTE} element={<ContactDocumentSignPage />} />
                    <Route path="*" element={<Page404 />} />
                </Routes>
            ) : (
                <Routes>
                    <Route path={`${HOME_ROUTE}/*`} element={<AuthHomeScreen />} />
                    <Route path={INTERNAL_LOGIN_ROUTE} element={<NoAuthLoginScreen />} />
                    <Route path={APP_AUTH_INIT_ROUTE} element={<AppInit />} />
                    <Route path={LOGOUT_ROUTE} element={<LogoutPage />} />
                    <Route path={SIGNUP_ROUTE} element={<SignupPage />} />
                    <Route path={COMPLETE_DEFAULT_SIGNUP_ROUTE} element={<CompleteDefaultSignup />} />
                    <Route path={COMPLETE_DEALER_GROUP_SIGNUP_ROUTE} element={<CompleteDealerGroupSignup />} />
                    <Route path={CONTACT_REGISTER_ROUTE} element={ContactFormCompletePage} />
                    <Route path={DOCUMENT_SIGN_ROUTE} element={<ContactDocumentSignPage />} />
                    <Route path={`${USER_ORG_INVITE_ROUTE}/*`} element={<OrgInvitationPage />} />
                    <Route path="*" element={<CheckAuth />} />
                </Routes>
            )}
        </AppRouter>
    );
};

export default AppRoutes;
