import { QueryResultCallback } from "../../models/Callback/Callback";
import { Organization } from "../../models/Organization/Organization";
import {
    OrgRoleScopes,
    TConnectedEmailInfo,
    TContactServiceAttr,
    TDealerGroupRequestAttr,
    TEmailSignatureInfo,
    TEmailTemplate,
    TFullOrganizationAttr,
    TOrganizationAttr,
    TOrgInvitation,
    TOrgMemberAttr,
} from "../../models/Organization/Types";
import { ISCheduleCategoryAttrs } from "../../models/Schedule/Schedule";
import { TUserAttr } from "../../models/User/User";
import { OrgStoreActions } from "../../redux/services/organizations/actions";
import { APIManager } from "../APIManager";
import { formatUrl } from "../../helpers/misc";

export class OrgEmailSystemManager {
    getUserOrg(orgId: string) {
        return OrgStoreActions.getOrganizations().find((t) => t.id === orgId);
    }
    static getActiveOrg() {
        return OrgStoreActions.getActiveOrg();
    }
    async apiConnectEmail(data: any) {
        const resp = await APIManager.post(APIManager.CONNECT_EMAIL_API, data);
        return resp.data as TConnectedEmailInfo;
    }

    async apiDisconnectEmail(connectedEmailId: string) {
        const resp = await APIManager.delete(APIManager.CONNECT_EMAIL_ITEM_API(connectedEmailId));
        return resp.data as TConnectedEmailInfo;
    }

    async apiSetDefault(connectedEmailId: string) {
        const resp = await APIManager.patch(APIManager.CONNECT_EMAIL_ITEM_API(connectedEmailId) + "/default");
        return resp.data as TConnectedEmailInfo;
    }

    async apiGetConnectedEmails() {
        const resp = await APIManager.get(APIManager.CONNECT_EMAIL_API);
        return resp.data as TConnectedEmailInfo[];
    }

    async apiGetEmailTemplates() {
        const resp = await APIManager.get(APIManager.EMAIL_TEMPLATES_API);
        return resp.data as TEmailTemplate[];
    }

    async apiSendBulkEmail(data: any) {
        const resp = await APIManager.post(APIManager.EMAIL_SEND_API, data);
        return resp.data;
    }

    async apiGetEmailTemplateItem(templateId: string) {
        const resp = await APIManager.get(APIManager.EMAIL_TEMPLATE_ITEM_API(templateId));
        return resp.data as { name: string; ref: string; content: string };
    }

    async apiCreateEmailSignature(data: any) {
        const resp = await APIManager.post(APIManager.EMAIL_SIGNATURE_API, data);
        return resp.data;
    }

    async apiGetEmailSignatures() {
        const resp = await APIManager.get(APIManager.EMAIL_SIGNATURE_API);
        return resp.data as TEmailSignatureInfo[];
    }

    async apiUpdateEmailSignature(signatureId: string, data: any) {
        const resp = await APIManager.put(APIManager.EMAIL_SIGNATURE_ITEM_API(signatureId), data);
        return resp.data;
    }

    async apiDeleteEmailSignagure(signatureId: string) {
        const resp = await APIManager.delete(APIManager.EMAIL_SIGNATURE_ITEM_API(signatureId));
        return resp.data;
    }
}
