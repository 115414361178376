import { Card } from "antd";
import React from "react";
import ContentLoader from "react-content-loader";
import Loading from "../../components/Loading/Loading";
import Logo from "../../components/Logo/Logo";
import MainLayout from "../../layouts/MainLayout/MainLayout";
import SimpleCenteredLayout from "../../layouts/SimpleLayout/SimpleLayout";
import Styles from "./AppLoadingPage.scss";

const Header = () => {
    return (
        <div className={Styles.Header}>
            <div className={Styles.LogoWrapper}>
                <Logo type="with-text-right" />
            </div>
            <ContentLoader height="50%" width="60%" speed={1.2} backgroundColor="rgba(0,0,0,0.3)" foregroundColor="rgba(50,60,100,0.2)">
                <rect x="0" y="0" width="100%" height="40" />
            </ContentLoader>
        </div>
    );
};

const Sidebar = () => {
    return (
        <div className={Styles.Sidebar}>
            <ContentLoader height="100%" width="100%" speed={1.2} backgroundColor="rgba(0,0,0,0.7)" foregroundColor="rgba(0,0,0,0.3)">
                {Array(6)
                    .fill(0)
                    .map((_, ind) => (
                        <React.Fragment key={"" + ind}>
                            <rect x="0" y={ind * 50 + 30} width="100%" height="40" />
                        </React.Fragment>
                    ))}
            </ContentLoader>
        </div>
    );
};

const Content: React.FC<{ loadingText?: string }> = ({ loadingText }) => {
    return (
        <div className={Styles.Content}>
            <Card style={{ position: "relative", padding: 20, width: 300, height: 200 }}>
                <Loading text={loadingText} />
            </Card>
        </div>
    );
};

const AppLoadingPage: React.FC<{ loadingText?: string; simple?: boolean }> = ({ loadingText, simple }) => {
    return simple ? (
        <SimpleCenteredLayout>
            <Content loadingText={loadingText} />
        </SimpleCenteredLayout>
    ) : (
        <MainLayout sidebarCollapsed dummySidebar hideToggle>
            <Content loadingText={loadingText} />
        </MainLayout>
    );
};

export default AppLoadingPage;
