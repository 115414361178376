import React from "react";
import { useSelector } from "react-redux";
import Logo from "../../components/Logo/Logo";
import { useModelEventWatcher } from "../../hooks/modelHook";
import { Organization } from "../../models/Organization/Organization";
import { TFullOrganizationAttr } from "../../models/Organization/Types";
import { RootState } from "../../redux/reducers";
import GlobalSearchInput from "../StatefulComponents/GlobalSearch/GlobalSearch";
import Notifications from "../StatefulComponents/Notifications/Notifications";
import UserAccountButton from "../StatefulComponents/UserAccountButton/UserAccountButton";
import Styles from "./Header.scss";

/**
 * Renders the default app header
 */
export const AppHeader = () => {
    const activeOrg = useSelector<RootState, Organization | undefined>((state) => state.orgs.activeOrg);
    const [orgAttr] = useModelEventWatcher(activeOrg, "ATTRIBUTES_UPDATED", activeOrg?.data.attrs as TFullOrganizationAttr | undefined);
    return (
        <div className={Styles.Container}>
            <div className={Styles.LogoWrapper}>
                <Logo type="with-text-right" />
            </div>
            <div className={Styles.HeaderActions}>
                {activeOrg && ["live", "active"].includes(orgAttr?.subscription?.status || "") && (
                    <div className={Styles.GlobalSearch}>
                        <GlobalSearchInput />
                    </div>
                )}
                <div className={Styles.Notifications}>
                    <Notifications />
                </div>
                <div className={Styles.UserAccountBtn}>
                    <UserAccountButton />
                </div>
            </div>
        </div>
    );
};

export default AppHeader;
