import { Moment } from "moment";
import { QueryResultCallback } from "../../models/Callback/Callback";
import { Individual } from "../../models/Contact/Individual/Individual";
import { IndividualDependentAttrs } from "../../models/Contact/Individual/IndividualDependent";
import { ContactFileAttrs } from "../../models/Contact/ContactFile";
import { ContactNoteAttrs } from "../../models/Contact/ContactNote";
import { ContactServiceAttrs } from "../../models/Contact/ContactService";
import { TIndividualAttrs, TContactDeletableProps } from "../../models/Contact/Types";
import { IndividualStoreActions } from "../../redux/services/contacts/actions";
import { AzureBlobStorage } from "../../redux/utils/AzureBlobStorage";
import { APIManager } from "../APIManager";
import { formatUrl } from "../../helpers/misc";
import { AppManager } from "..";
import { CustomField, CustomFieldsData } from "../../models/Contact/CustomFields";

export class IndividualManager {
    static newContactObj(data: TIndividualAttrs) {
        return new Individual(data);
    }

    static newCustomFieldsObj(data: CustomFieldsData) {
        return new CustomField(data);
    }

    getContact(individualId: string) {
        return IndividualStoreActions.getIndividuals().find((t) => t.id === individualId);
    }

    getCustomFieldIndividual(id: string) {
        return IndividualStoreActions.getCustomFieldsIndividual().find((t) => t.id === id);
    }

    getAllCustomFields() {
        return IndividualStoreActions.getCustomFieldsIndividual();
    }

    async apiFetchAllContacts(cb: QueryResultCallback<Individual[]>, includeDeleted = false, includeProspects = false) {
        try {
            IndividualStoreActions.loadingIndividuals(true);
            const resp = await APIManager.get(
                formatUrl(APIManager.CONTACT.INDIVIDUAL.LIST_API, {
                    includeDeleted: includeDeleted ? "1" : undefined,
                    includeProspects: includeProspects ? "1" : undefined,
                }),
            );
            const individuals: Individual[] = resp.data.map((t: TIndividualAttrs) => this.updateContactAttrOrAddNew(t, false));
            if (!includeDeleted && !includeProspects) {
                IndividualStoreActions.saveIndividuals(individuals);
            }
            IndividualStoreActions.setIndividualLoaded(true);
            cb(null, individuals);
            return individuals;
        } catch (err) {
            cb(`${err}`);
        } finally {
            IndividualStoreActions.loadingIndividuals(false);
        }
    }

    async apiFetchContactServiceFees(
        cb: QueryResultCallback,
        contactId: string,
        startDate: Moment | null | undefined,
        endDate: Moment | null | undefined,
    ) {
        try {
            IndividualStoreActions.loadingIndividuals(true);
            const resp = await APIManager.get(
                APIManager.CONTACT.INDIVIDUAL.SERVICE_FEE_API(contactId, startDate?.toISOString(), endDate?.toISOString()),
            );
            cb(null, resp?.data);
        } catch (err) {
            cb(`${err}`);
        } finally {
            IndividualStoreActions.loadingIndividuals(false);
        }
    }

    async apiFetchDeletedContacts(cb: QueryResultCallback<Individual[]>) {
        try {
            IndividualStoreActions.loadingIndividuals(true);
            const resp = await APIManager.get(formatUrl(APIManager.CONTACT.INDIVIDUAL.LIST_API, { deletedOnly: "1" }));
            const individuals: Individual[] = resp.data.map((t: TIndividualAttrs) => this.updateContactAttrOrAddNew(t, false));
            IndividualStoreActions.setIndividualLoaded(true);
            cb(null, individuals);

            return individuals;
        } catch (err) {
            cb(`${err}`);
        } finally {
            IndividualStoreActions.loadingIndividuals(false);
        }
    }

    async apiFetchProspectContacts(cb: QueryResultCallback<Individual[]>) {
        try {
            IndividualStoreActions.loadingIndividuals(true);
            const resp = await APIManager.get(formatUrl(APIManager.CONTACT.INDIVIDUAL.LIST_API, { prospectOnly: "1" }));
            const individuals: Individual[] = resp.data.map((t: TIndividualAttrs) => this.updateContactAttrOrAddNew(t, false));
            IndividualStoreActions.setIndividualLoaded(true);
            cb(null, individuals);

            return individuals;
        } catch (err) {
            cb(`${err}`);
        } finally {
            IndividualStoreActions.loadingIndividuals(false);
        }
    }

    async apiFetchCustomFieldsContacts(cb: QueryResultCallback<CustomField[]>) {
        try {
            IndividualStoreActions.loadingIndividuals(true);
            const resp = await APIManager.get(formatUrl(APIManager.CONTACT.INDIVIDUAL.CUSTOM_FIELDS_INDIVIDUAL_API));
            const individuals: CustomField[] = resp.data.map((t: CustomFieldsData) => this.updateContactCustomFieldAttrOrAddNew(t));
            IndividualStoreActions.setIndividualLoaded(true);
            cb(null, individuals);

            return individuals;
        } catch (err) {
            cb(`${err}`);
        } finally {
            IndividualStoreActions.loadingIndividuals(false);
        }
    }

    async apiFetchContactAttrs(individualId: string, cb: QueryResultCallback<Individual>) {
        try {
            const resp = await APIManager.get(APIManager.CONTACT.INDIVIDUAL.ITEM_API(individualId));
            const individual = this.updateContactAttrOrAddNew(resp.data);
            cb(null, individual);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiLinkCompany(individualId: string, companyId: string, cb: QueryResultCallback<Individual>) {
        try {
            const resp = await APIManager.post(APIManager.CONTACT.INDIVIDUAL.LINK_COMPANY_API(individualId), { companyId });
            const individual = this.updateContactAttrOrAddNew(resp.data);
            cb(null, individual);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiUnlinkCompany(individualId: string, companyId: string, cb: QueryResultCallback<Individual>) {
        try {
            const resp = await APIManager.post(APIManager.CONTACT.INDIVIDUAL.UNLINK_COMPANY_API(individualId), { companyId });
            const individual = this.updateContactAttrOrAddNew(resp.data);
            cb(null, individual);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiLinkTrust(individualId: string, trustId: string, cb: QueryResultCallback<Individual>) {
        try {
            const resp = await APIManager.post(APIManager.CONTACT.INDIVIDUAL.LINK_TRUST_API(individualId), { trustId });
            const individual = this.updateContactAttrOrAddNew(resp.data);
            cb(null, individual);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiUnlinkTrust(individualId: string, trustId: string, cb: QueryResultCallback<Individual>) {
        try {
            const resp = await APIManager.post(APIManager.CONTACT.INDIVIDUAL.UNLINK_TRUST_API(individualId), { trustId });
            const individual = this.updateContactAttrOrAddNew(resp.data);
            cb(null, individual);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiFetchContactNotes(individualId: string, cb: QueryResultCallback<ContactNoteAttrs[]>) {
        try {
            const resp = await APIManager.get(APIManager.CONTACT.INDIVIDUAL.NOTES_API(individualId));
            const individual = this.getContact(individualId);
            if (individual) {
                individual.updateNotes(resp.data);
            }
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiFetchContactServices(individualId: string, cb: QueryResultCallback<ContactServiceAttrs[]>) {
        try {
            const resp = await APIManager.get(APIManager.CONTACT.INDIVIDUAL.SERVICES_API(individualId));
            const individual = this.getContact(individualId);
            if (individual) {
                individual.updateServices(resp.data);
            }
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiFetchContactFiles(individualId: string, cb: QueryResultCallback<ContactNoteAttrs[]>) {
        try {
            const resp = await APIManager.get(APIManager.CONTACT.INDIVIDUAL.FILES_API(individualId));
            const individual = this.getContact(individualId);
            if (individual) {
                individual.updateFiles(resp.data);
            }
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiFetchIndividualDependents(individualId: string, cb: QueryResultCallback<IndividualDependentAttrs[]>) {
        try {
            const resp = await APIManager.get(APIManager.CONTACT.INDIVIDUAL.DEPENDENTS_API(individualId));
            const individual = this.getContact(individualId);
            if (individual) {
                individual.updateDependents(resp.data);
            }
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async rehydrateContact(individualId: string, cb: QueryResultCallback<Individual[]>, rehydratePartner = true) {
        const individual = this.getContact(individualId);
        const errors: string[] = [];
        const callback = (err: string | null) => {
            if (err) errors.push(err);
        };
        if (individual) {
            await this.apiFetchContactAttrs(individualId, callback);
            await this.apiFetchContactNotes(individualId, callback);
            await this.apiFetchContactFiles(individualId, callback);
            await this.apiFetchContactServices(individualId, callback);
            await this.apiFetchIndividualDependents(individualId, callback);
            if (rehydratePartner && individual.data.partner) await this.rehydrateContact(individual.data.partner.id, callback, false);
        }
        cb(errors.length ? errors.join("\n") : null);
    }

    async apiSaveContactAuto(indvData: Partial<TIndividualAttrs>, templates: string[], cb: QueryResultCallback<Individual>) {
        try {
            const resp = await APIManager.post(APIManager.CONTACT.INDIVIDUAL.AUTO_REGISTER_API, { contactData: indvData, templates });
            const individual: Individual = IndividualManager.newContactObj(resp.data);
            IndividualStoreActions.addOrUpdateToIndividuals(individual);
            cb(null, individual);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiSaveContact(indvData: Partial<TIndividualAttrs>, cb: QueryResultCallback<Individual>) {
        try {
            const resp = await APIManager.post(APIManager.CONTACT.INDIVIDUAL.LIST_API, indvData);
            const individual: Individual = IndividualManager.newContactObj(resp.data);
            IndividualStoreActions.addOrUpdateToIndividuals(individual);
            cb(null, individual);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiSaveCustomField(data: Partial<CustomFieldsData>, cb: QueryResultCallback<CustomField>) {
        try {
            const resp = await APIManager.post(APIManager.CONTACT.INDIVIDUAL.CUSTOM_FIELDS_INDIVIDUAL_API, data);
            const individual: CustomField = IndividualManager.newCustomFieldsObj(resp.data);
            IndividualStoreActions.addOrUpdateCustomFields(individual);
            cb(null, individual);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiUpdateCustomField(customFieldId: string, data: Partial<CustomFieldsData>, cb: QueryResultCallback<CustomField>) {
        try {
            const resp = await APIManager.put(APIManager.CONTACT.INDIVIDUAL.CUSTOM_FIELD_API(customFieldId), data);
            const individual: CustomField = IndividualManager.newCustomFieldsObj(resp.data);
            IndividualStoreActions.addOrUpdateCustomFields(individual);
            cb(null, individual);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiSaveContactNote(
        individualId: string,
        noteData: { title: string; note: string; type: string },
        cb: QueryResultCallback<ContactNoteAttrs[]>,
    ) {
        try {
            const resp = await APIManager.post(APIManager.CONTACT.INDIVIDUAL.NOTES_API(individualId), noteData);
            const individual = this.getContact(individualId);
            if (individual) {
                individual.addOrUpdateNote(resp.data);
            }
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiSaveIndividualDependent(
        individualId: string,
        depData: { title: string; firstName: string; lastName: string; relation: string },
        cb: QueryResultCallback<IndividualDependentAttrs[]>,
    ) {
        try {
            const resp = await APIManager.post(APIManager.CONTACT.INDIVIDUAL.DEPENDENTS_API(individualId), depData);
            const individual = this.getContact(individualId);
            if (individual) {
                individual.addOrUpdateDependent(resp.data);
            }
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiSaveContactService(
        individualId: string,
        serviceData: { serviceId: string; serviceDate: string },
        cb: QueryResultCallback<ContactServiceAttrs[]>,
    ) {
        try {
            const resp = await APIManager.post(APIManager.CONTACT.INDIVIDUAL.SERVICES_API(individualId), serviceData);
            const individual = this.getContact(individualId);
            if (individual) {
                individual.addOrUpdateService(resp.data);
            }
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiSaveContactFile(
        individualId: string,
        contactNames: string,
        file: File,
        source: string,
        cb: QueryResultCallback<ContactFileAttrs>,
        noteId?: string,
    ) {
        const azure = AzureBlobStorage.newInstance();
        const activeOrg = AppManager.org.getCurrentActiveOrg()!;
        const blobName = `${activeOrg.id}/contactdocs/individuals/${individualId}/${contactNames}`;
        azure.uploadToBlobStorage(file, blobName, async (err, respData) => {
            if (err) cb(`${err}`);
            else {
                const fileData = {
                    name: file.name,
                    type: file.type,
                    size: file.size,
                    source,
                    blobName: respData!.blobName,
                    blobUrl: respData!.blobUrl,
                };
                if (noteId) {
                    fileData.noteId = noteId;
                }
                try {
                    const resp = await APIManager.post(APIManager.CONTACT.INDIVIDUAL.FILES_API(individualId), fileData);
                    const individual = this.getContact(individualId);
                    if (individual) {
                        individual.addOrUpdateFile(resp.data);
                    }
                    cb(null, resp.data);
                } catch (err) {
                    azure.deleteBlobFromStorage(respData!.blobName);
                    cb(`${err}`);
                }
            }
        });
    }

    updateContactAttrOrAddNew(contactAttr: TIndividualAttrs, saveToStore = true) {
        let individual = this.getContact(contactAttr._id);
        if (individual) {
            individual.updateIndividualAttrs(contactAttr);
        } else {
            individual = IndividualManager.newContactObj(contactAttr);
        }
        if (saveToStore) IndividualStoreActions.addOrUpdateToIndividuals(individual);
        return individual;
    }

    updateContactCustomFieldAttrOrAddNew(contactAttr: CustomFieldsData, saveToStore = true) {
        let individual = this.getCustomFieldIndividual(contactAttr._id);
        if (individual) {
            individual.updateCustomField(contactAttr);
        } else {
            individual = IndividualManager.newCustomFieldsObj(contactAttr);
        }
        if (saveToStore) IndividualStoreActions.addOrUpdateCustomFields(individual);
        return individual;
    }

    async apiUpdateContactNote(
        individualId: string,
        noteId: string,
        noteData: { title?: string; note?: string; type?: string },
        cb: QueryResultCallback<ContactNoteAttrs[]>,
    ) {
        try {
            const resp = await APIManager.put(APIManager.CONTACT.INDIVIDUAL.NOTE_ITEM_API(individualId, noteId), noteData);
            const individual = this.getContact(individualId);
            if (individual) {
                console.log("NOTE UPDATED ", resp.data);
                individual.addOrUpdateNote(resp.data);
            }
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiUpdateIndividualDependent(individualId: string, dependentId: string, depData: any, cb: QueryResultCallback<IndividualDependentAttrs[]>) {
        try {
            const resp = await APIManager.put(APIManager.CONTACT.INDIVIDUAL.DEPENDENT_ITEM_API(individualId, dependentId), depData);
            const individual = this.getContact(individualId);
            if (individual) {
                console.log("DEPENDENT UPDATED ", resp.data);
                individual.addOrUpdateDependent(resp.data);
            }
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiUpdateContact(individualId: string, contactData: Partial<TIndividualAttrs>, cb: QueryResultCallback<Individual>) {
        try {
            const resp = await APIManager.put(APIManager.CONTACT.INDIVIDUAL.ITEM_API(individualId), contactData);
            const individual = this.updateContactAttrOrAddNew(resp.data);
            cb(null, individual);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiSendBulkEmail(data: any, cb: QueryResultCallback<any>) {
        try {
            const resp = await APIManager.post(APIManager.CONTACT.INDIVIDUAL.BULK_EMAIL_API, data);
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiLoadFullContactNote(individualId: string, noteId: string, cb: QueryResultCallback<ContactNoteAttrs[]>) {
        try {
            const resp = await APIManager.get(APIManager.CONTACT.INDIVIDUAL.NOTE_ITEM_API(individualId, noteId));
            const individual = this.getContact(individualId);
            if (individual) individual.addOrUpdateNote(resp.data);
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    apiFetchContactNoteAttrs = async (contactId: string, noteId: string, cb: (err: string | null, res?: ContactNote) => void) => {
        try {
            const res = await APIManager.get(APIManager.CONTACT.INDIVIDUAL.NOTE_ITEM_API(contactId, noteId));
            cb(null, res.data);
        } catch (err) {
            cb(`${err}`);
        }
    };

    async apiRestoreContactNote(individualId: string, noteId: string, cb: QueryResultCallback<ContactNoteAttrs[]>) {
        try {
            const resp = await APIManager.put(APIManager.CONTACT.INDIVIDUAL.NOTE_ITEM_RESTORE_API(individualId, noteId), {});
            const individual = this.getContact(individualId);
            if (individual) {
                individual.restoreNoteWithId(noteId);
            }
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiDeleteContactNote(individualId: string, noteId: string, cb: QueryResultCallback<ContactNoteAttrs[]>) {
        try {
            // const note = await APIManager.get(APIManager.CONTACT.INDIVIDUAL.NOTE_ITEM_API(individualId, noteId));
            // const noteAttrs: ContactNoteAttrs = note.data;
            // for (const attachment of noteAttrs.attachments) {
            //     await new Promise((resolve, reject) => {
            //         this.apiDeleteContactFile(individualId, typeof attachment == "string" ? attachment : attachment._id, (err, d) => {
            //             if (err) reject(`${err}`);
            //             else resolve(d!);
            //         });
            //     });
            // }
            const resp = await APIManager.delete(APIManager.CONTACT.INDIVIDUAL.NOTE_ITEM_API(individualId, noteId));
            const individual = this.getContact(individualId);
            if (individual) {
                individual.removeNoteWithId(noteId);
            }
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiDeleteIndividualDependent(individualId: string, dependentId: string, cb: QueryResultCallback<IndividualDependentAttrs[]>) {
        try {
            const resp = await APIManager.delete(APIManager.CONTACT.INDIVIDUAL.DEPENDENT_ITEM_API(individualId, dependentId));
            const individual = this.getContact(individualId);
            if (individual) {
                individual.removeDependentWithId(dependentId);
            }
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiDeleteContactService(individualId: string, serviceId: string, cb: QueryResultCallback<ContactServiceAttrs[]>) {
        try {
            const resp = await APIManager.delete(APIManager.CONTACT.INDIVIDUAL.SERVICE_ITEM_API(individualId, serviceId));
            const individual = this.getContact(individualId);
            if (individual) {
                individual.removeServiceWithId(serviceId);
            }
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiDeleteCustomField(customFieldId: string, cb: QueryResultCallback<CustomFieldsData>) {
        try {
            const resp = await APIManager.delete(APIManager.CONTACT.INDIVIDUAL.CUSTOM_FIELD_API(customFieldId));
            const individual = this.getCustomFieldIndividual(customFieldId);

            IndividualStoreActions.removeCustomFieldsIndividual(individual!);
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiDeleteContactFile(individualId: string, fileId: string, cb: QueryResultCallback<ContactFileAttrs>, noteId?: string) {
        // const azure = AzureBlobStorage.newInstance();
        try {
            let resp;
            if (noteId) {
                resp = await APIManager.delete(APIManager.CONTACT.INDIVIDUAL.FILE_NOTE_ITEM_API(individualId, noteId, fileId));
            } else {
                resp = await APIManager.delete(APIManager.CONTACT.INDIVIDUAL.FILE_ITEM_API(individualId, fileId));
            }
            const individual = this.getContact(individualId);
            if (individual) {
                individual.removeFileWithId(fileId);
            }
            cb(null, resp.data);
            // const fileItem = await APIManager.get(APIManager.CONTACT.INDIVIDUAL.FILE_ITEM_API(individualId, fileId));
            // const { blobName } = fileItem.data;
            // azure.deleteBlobFromStorage(blobName, async (err) => {
            //     if (err) cb(`${err}`);
            //     else {
            //         try {

            //         } catch (err) {
            //             azure.undeleteBlobFromStorage(blobName);
            //             cb(`${err}`);
            //         }
            //     }
            // });
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiDeleteContact(individualId: string, cb: QueryResultCallback) {
        try {
            const resp = await APIManager.delete(APIManager.CONTACT.INDIVIDUAL.ITEM_API(individualId));
            this.removeContact(individualId);
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiRestoreContact(individualId: string, cb: QueryResultCallback) {
        try {
            const resp = await APIManager.patch(APIManager.CONTACT.INDIVIDUAL.ITEM_RESTORE_API(individualId));
            this.restoreContact(individualId);
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    restoreContact(individualId: string) {
        const individual = this.getContact(individualId);
        if (individual) {
            IndividualStoreActions.removeIndividual(individual);
        } else console.log("CONTACT WITH ID ", individualId, " NOT FOUND");
    }

    removeContact(individualId: string) {
        const individual = this.getContact(individualId);
        if (individual) {
            individual.remove();
            IndividualStoreActions.removeIndividual(individual);
        } else console.log("CONTACT WITH ID ", individualId, " NOT FOUND");
    }

    removeContactCustomFieldWithId(id: string) {
        const individuals = IndividualStoreActions.getIndividuals();

        const newIndividuals: Individual[] = individuals.map((ind) => {
            ind.data.attrs.customField = ind.data.attrs.customField?.filter((c) => c.template._id !== id) || [];

            return ind;
        });

        IndividualStoreActions.saveIndividuals(newIndividuals);
    }

    addNewContactCustomField(customField: { id: string; template: CustomFieldsData; value: string }) {
        const individuals = IndividualStoreActions.getIndividuals();

        const newIndividuals: Individual[] = individuals.map((ind) => {
            ind.data.attrs.customField = [...(ind.data.attrs.customField || []), customField];

            return ind;
        });

        IndividualStoreActions.saveIndividuals(newIndividuals);
    }

    async apiCheckContactDeletable(individualId: string, cb: QueryResultCallback<TContactDeletableProps>) {
        try {
            const resp = await APIManager.get(APIManager.CONTACT.INDIVIDUAL.CHECK_CONTACT_DELETABLE_API(individualId));
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }

    async apiSendEmail(individualId: string, emailData: { email: string; subject: string; body: string }, cb: QueryResultCallback<any>) {
        try {
            const resp = await APIManager.post(APIManager.CONTACT.INDIVIDUAL.SEND_EMAIL_API(individualId), emailData);
            cb(null, resp.data);
        } catch (err) {
            cb(`${err}`);
        }
    }
}
