import moment from "moment";
import { AppManager } from "../../../manager";
import { QueryResultCallback } from "../../Callback/Callback";
import { Watchable } from "../../_Utils/Watchable";
import { IndividualDependent, IndividualDependentAttrs } from "./IndividualDependent";
import { ContactFile, ContactFileAttrs } from "../ContactFile";
import { ContactNote, ContactNoteAttrs } from "../ContactNote";
import { ContactService, ContactServiceAttrs } from "../ContactService";
import { TCompanyAttrs, TContactAuditAttr, TIndividualAttrs, TTrustAttrs } from "../Types";
import { Company } from "../Company/Company";
import { Trust } from "../Trust/Trust";
import { OrgManager } from "../../../manager/OrgManager/OrgManager";
import { CustomField, CustomFieldsData } from "../CustomFields";

export type IndividualEventMap = {
    FILES_UPDATED: ContactFile[];
    SERVICES_UPDATED: ContactService[];
    PARTNER_UPDATED: Individual | undefined;
    NOTES_UPDATED: ContactNote[];
    NEW_AUDIT_ITEM: TContactAuditAttr;
    DEPENDENTS_UPDATED: IndividualDependent[];
    LINKED_COMPANIES_UPDATED: Company[];
    LINKED_TRUSTS_UPDATED: Trust[];
    ATTRIBUTES_UPDATED: TIndividualAttrs;
    PARTNER_REMOVED: boolean;
    CONTACT_DELETED: true;
};

class IndividualData extends Watchable<IndividualEventMap> {
    constructor(attrs: TIndividualAttrs) {
        super();
        this.cAttrs = attrs;
        this.attrs = attrs;
    }

    private cFiles: ContactFile[] = [];
    private cNotes: ContactNote[] = [];
    private cDependents: IndividualDependent[] = [];
    private cPartner?: Individual;
    private cLinkedCompanies: Company[] = [];
    private cLinkedTrusts: Trust[] = [];
    private cServices: ContactService[] = [];
    private cAttrs: TIndividualAttrs;

    triggerContactDeleted() {
        this.trigger("CONTACT_DELETED", true);
    }

    triggerEvent<T extends keyof IndividualEventMap>(event: T, data: IndividualEventMap[T]) {
        this.trigger(event, data);
    }

    set files(f: ContactFile[]) {
        this.cFiles = f;
        this.trigger("FILES_UPDATED", f);
    }

    get files() {
        return this.cFiles;
    }

    set notes(n: ContactNote[]) {
        this.cNotes = n;
        this.trigger("NOTES_UPDATED", n);
    }

    get notes() {
        return this.cNotes;
    }

    set dependents(d: IndividualDependent[]) {
        this.cDependents = d;
        this.trigger("DEPENDENTS_UPDATED", d);
    }

    get dependents() {
        return this.cDependents;
    }

    set services(s: ContactService[]) {
        this.cServices = s;
        this.trigger("SERVICES_UPDATED", s);
    }

    get services() {
        return this.cServices;
    }

    set partner(p: Individual | undefined) {
        const shouldNotifyUpdated = this.cPartner?.id !== p?.id;
        const shouldNotifyRemoved = this.cPartner && !p;
        this.cPartner = p;

        if (shouldNotifyUpdated) this.trigger("PARTNER_UPDATED", p);
        if (shouldNotifyRemoved) this.trigger("PARTNER_REMOVED", true);
    }

    get partner() {
        return this.cPartner;
    }

    set linkedCompanies(c: Company[]) {
        const shouldNotifyUpdated = this.cLinkedCompanies?.length !== c.length;
        this.cLinkedCompanies = c;
        if (shouldNotifyUpdated) this.trigger("LINKED_COMPANIES_UPDATED", c);
    }

    get linkedCompanies() {
        return this.cLinkedCompanies;
    }

    set linkedTrusts(c: Trust[]) {
        const shouldNotifyUpdated = this.cLinkedTrusts?.length !== c.length;
        this.cLinkedTrusts = c;
        if (shouldNotifyUpdated) this.trigger("LINKED_TRUSTS_UPDATED", c);
    }

    get linkedTrusts() {
        return this.cLinkedTrusts;
    }

    set attrs(atr: TIndividualAttrs) {
        this.cAttrs = atr;
        this.cAttrs.dateOfBirth = this.cAttrs.dateOfBirth ? moment(this.cAttrs.dateOfBirth) : undefined;
        this.cAttrs.annualReviewDate = this.cAttrs.annualReviewDate ? moment(this.cAttrs.annualReviewDate) : undefined;
        this.cAttrs.createdAt = moment(this.cAttrs.createdAt);
        this.cAttrs.updatedAt = moment(this.cAttrs.updatedAt);
        this.cAttrs.adviser = typeof this.cAttrs.adviser === "string" || !this.cAttrs.adviser ? this.cAttrs.adviser : this.cAttrs.adviser._id;

        this.trigger("ATTRIBUTES_UPDATED", atr);

        if (atr.partner) {
            // Update the partner field
            if (typeof atr.partner !== "string") {
                this.partner = AppManager.contact.Individual.updateContactAttrOrAddNew(atr.partner);
            } else {
                this.partner = AppManager.contact.Individual.getContact(atr.partner);
            }
        } else {
            this.partner = undefined;
        }

        if (atr.linkedCompanies) {
            this.linkedCompanies = atr.linkedCompanies.map((compAttr) => AppManager.contact.Company.updateContactAttrOrAddNew(compAttr, true));
        }
        if (atr.linkedTrusts) {
            this.linkedTrusts = atr.linkedTrusts.map((trustAttr) => AppManager.contact.Trust.updateContactAttrOrAddNew(trustAttr, true));
        }
    }

    get attrs() {
        return this.cAttrs;
    }
}

export class Individual {
    public data: IndividualData;
    public customFields?: CustomField[] = [];
    public readonly id: string;
    public names: string;

    constructor(attributes: TIndividualAttrs) {
        this.data = new IndividualData(attributes);
        this.id = attributes._id;
        this.names = `${attributes.firstName} ${attributes.lastName}`;
    }

    updateFiles(files: ContactFileAttrs[]) {
        this.data.files = ContactFile.fromArray(files);
        return this;
    }

    updateNotes(notes: ContactNoteAttrs[]) {
        this.data.notes = ContactNote.fromArray(notes);
        return this;
    }

    updateDependents(dependents: IndividualDependentAttrs[]) {
        this.data.dependents = IndividualDependent.fromArray(dependents);
        return this;
    }

    updateServices(services: ContactServiceAttrs[]) {
        this.data.services = ContactService.fromArray(services);
        return this;
    }

    addOrUpdateNote(noteAttrs: ContactNoteAttrs) {
        const noteId = noteAttrs._id;
        if (this.data.notes.find((t) => t.id === noteId)) {
            this.data.notes = this.data.notes.map((t) => {
                if (t.id === noteId) return t.updateNote(noteAttrs);
                return t;
            });
        } else {
            const note = new ContactNote(noteAttrs);
            this.data.notes = [note, ...this.data.notes];
        }
        return this;
    }

    addOrUpdateDependent(depAttrs: IndividualDependentAttrs) {
        const dependentId = depAttrs._id;
        if (this.data.dependents.find((t) => t.id === dependentId)) {
            this.data.dependents = this.data.dependents.map((t) => {
                if (t.id === dependentId) return t.updateDependent(depAttrs);
                return t;
            });
        } else {
            const dependent = new IndividualDependent(depAttrs);
            this.data.dependents = [dependent, ...this.data.dependents];
        }
        return this;
    }

    addOrUpdateFile(fileAttrs: ContactFileAttrs) {
        const fileId = fileAttrs._id;
        if (this.data.files.find((t) => t.id === fileId)) {
            this.data.files = this.data.files.map((t) => {
                if (t.id === fileId) return t.updateFile(fileAttrs);
                return t;
            });
        } else {
            const file = new ContactFile(fileAttrs);
            this.data.files = [file, ...this.data.files];
        }
        return this;
    }

    addOrUpdateService(srvAttrs: ContactServiceAttrs) {
        const serviceId = srvAttrs._id;
        if (this.data.services.find((t) => t.id === serviceId)) {
            this.data.services = this.data.services.map((t) => {
                if (t.id === serviceId) return t.updateService(srvAttrs);
                return t;
            });
        } else {
            this.data.services = [new ContactService(srvAttrs), ...this.data.services];
        }
        return this;
    }

    removeNoteWithId(noteId: string) {
        const isAuditEnabled = OrgManager.getActiveOrg()?.data.attrs.isAuditEnabled;
        if (isAuditEnabled) {
            this.data.notes = this.data.notes.map((t) => {
                if (t.attrs._id === noteId) {
                    t.attrs.isDeleted = true;
                }
                return t;
            });
        } else {
            this.data.notes = this.data.notes.filter((t) => t.attrs._id !== noteId);
        }
        return this;
    }

    restoreNoteWithId(noteId: string) {
        this.data.notes = this.data.notes.map((t) => {
            if (t.attrs._id === noteId) {
                t.attrs.isDeleted = false;
            }
            return t;
        });

        return this;
    }

    removeDependentWithId(dependentId: string) {
        this.data.dependents = this.data.dependents.filter((t) => t.attrs._id !== dependentId);
        return this;
    }

    removeFileWithId(fileId: string) {
        this.data.files = this.data.files.filter((t) => t.attrs._id !== fileId);
        return this;
    }

    removeServiceWithId(serviceId: string) {
        this.data.services = this.data.services.filter((t) => t.attrs._id !== serviceId);
        return this;
    }

    removeCustomFieldWithId(customFieldId: string) {
        this.customFields = this.customFields?.filter((t) => t.attrs._id !== customFieldId);
        return this;
    }

    updateIndividual(contact: Individual) {
        this.updateIndividualAttrs(contact.data.attrs);
        this.data.notes = contact.data.notes;
        this.data.services = contact.data.services;
        this.data.files = contact.data.files;
        return this;
    }

    updateCustomFieldIndividual(contact: CustomField) {
        this.updateIndividualCustomFieldAttrs(contact.data.attrs);
        return this;
    }

    updateIndividualAttrs(attrs: TIndividualAttrs) {
        this.data.attrs = attrs;
        this.names = `${attrs.firstName} ${attrs.lastName}`;
        return this;
    }

    updateIndividualCustomFieldAttrs(attrs: CustomFieldsData) {
        this.customFields = [...this.customFields!, new CustomField({ ...attrs })];
        return this.customFields;
    }

    remove() {
        this.data.triggerContactDeleted();
    }

    newAuditItem(item: TContactAuditAttr) {
        this.data.triggerEvent("NEW_AUDIT_ITEM", item);
    }

    static loadContacts(cb: QueryResultCallback) {}

    static fromArray(contacts: TIndividualAttrs[]) {
        return contacts.map((t) => new Individual(t));
    }

    get displayName() {
        return `${this.data.attrs.firstName} ${this.data.attrs.lastName}`;
    }
}
