import { Card } from "antd";
import React from "react";
import Logo from "../../components/Logo/Logo";
import MainLayout from "../../layouts/MainLayout/MainLayout";
import Styles from "./AppSimplePage.scss";

const Header = () => {
    return (
        <div className={Styles.Header}>
            <div className={Styles.LogoWrapper}>
                <Logo type="with-text-right" />
            </div>
            <div />
        </div>
    );
};

const Sidebar = () => {
    return <div className={Styles.Sidebar} />;
};

const Content: React.FC<{ content: React.ReactNode }> = ({ content }) => {
    return (
        <div className={Styles.Content}>
            <Card style={{ position: "relative", width: "100%", height: "100%" }}>{content}</Card>
        </div>
    );
};

const AppSimplePage: React.FC<{ content: React.ReactNode; hideSidebar?: boolean }> = ({ content, hideSidebar }) => {
    return <MainLayout hideSidebar={hideSidebar} sidebarCollapsed Content={<Content content={content} />} />;
};

export default AppSimplePage;
