import { TUserAttr } from "../User/User";
import { Watchable } from "../_Utils/Watchable";
import { TOrgMemberAttr } from "./Types";

type MemberEventMap = {
    ATTRIBUTES_UPDATED: TOrgMemberAttr;
    MEMBER_DELETED: null;
};

export class OrgMember extends Watchable<MemberEventMap> {
    public readonly id: string;
    constructor(public attrs: TOrgMemberAttr) {
        super();
        this.id = attrs._id;
    }

    update(mbrAttrs: TOrgMemberAttr) {
        this.attrs = mbrAttrs;
        this.trigger("ATTRIBUTES_UPDATED", mbrAttrs);
        return this;
    }

    updateMemberUserAttr(mbrUserAttr: TUserAttr) {
        this.attrs = { ...this.attrs, user: mbrUserAttr }; //TODO: You might want to extract only attributes needed
        this.trigger("ATTRIBUTES_UPDATED", this.attrs);
        return this;
    }
}
